import { OutlinedInputProps } from "@mui/material";
import { ReactElement, useState } from "react";

import { ColorPickerComponent } from "./ColorPicker.component";

export type Type_Props_ColorPickerContainer = {
    name: string;
    format?: "rounded";
    helperText?: ReactElement | null | string | false;
    selectedColors?: string[];
    hideText?: boolean;
};

export const ColorPickerContainer = ({
    name,
    format,
    selectedColors,
    helperText,
    ...restProps
}: Type_Props_ColorPickerContainer & OutlinedInputProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open: boolean = Boolean(anchorEl);

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <ColorPickerComponent
            name={name}
            setAnchorEl={setAnchorEl}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleClick={handleClick}
            format={format}
            helperText={helperText}
            selectedColors={selectedColors}
            {...restProps}
        />
    );
};
