import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateTimeModel,
    mutationUpdateTimeModel,
    useShowTimeModel,
} from "src/api/tms-commons/timeModels";
import { Type_show_timeModel } from "src/api/tms-commons/timeModels/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DrawerLabel } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { DaysCheckboxField } from "src/components/Components_Common/forms/reactHookFormComponents/days/DaysCheckboxField";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_TimeModelForm = {
    onClose: () => void;
    action: Type_action;
    timeModelIdToUpdate: number | null;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_TimeModelForm = Yup.object().shape({
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    quantity: Yup.number()
        .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
        .integer(FORM_ERR_FMT.INTEGER)
        .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
        .required(FORM_ERR_FMT.REQUIRED),
    workingDays: Yup.number()
        .min(1, FORM_ERR_FMT.MIN_SELECT)
        .required(FORM_ERR_FMT.REQUIRED),
});

export const TimeModelForm = ({
    onClose,
    action,
    timeModelIdToUpdate,
    setIsLoading,
}: Type_Props_TimeModelForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TimeModels");

    const { data, isFetching: isFetchingTimeModel } = useShowTimeModel(
        timeModelIdToUpdate as number,
    );

    const { mutateAsync: mutateCreate } = mutationCreateTimeModel() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateTimeModel() || {};

    const form = useForm<Type_show_timeModel>({
        defaultValues: {
            names: {},
            quantity: undefined,
            workingDays: undefined,
        },
        values: data,
        resolver: yupResolver<any>(Schema_TimeModelForm),
    });

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault();

        // TODO: isDirty, est true dès le premier render

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values);
            } else {
                delete values.id;
                await mutateCreate(values);
            }
            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating time model");
        }

        setIsLoading(false);
    };

    const isFetching = useMemo(
        () =>
            !!(timeModelIdToUpdate && form.getValues("id") === undefined) ||
            isFetchingTimeModel,
        [timeModelIdToUpdate, form.getValues("id"), isFetchingTimeModel],
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"timeModels"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <DrawerLabel variant="h3">
                            {fmt(`TimeModelName`)}
                        </DrawerLabel>
                        <InputMultiLanguages name="names" />

                        <DrawerLabel variant="h3">
                            {fmt(`DailyModel`)}
                        </DrawerLabel>
                        <TextField
                            name="quantity"
                            label={fmt(`Quantity`)}
                            variant={"outlined"}
                        />

                        <DrawerLabel variant="h3">
                            {fmt(`WorkingDays`)}
                        </DrawerLabel>
                        <DaysCheckboxField
                            name="workingDays"
                            dataTestIdPrefix={"TimeModelsForm-workingDays"}
                        />
                    </>
                )}
            </form>
        </FormProvider>
    );
};
