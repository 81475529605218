import { TabContext } from "@mui/lab";
import { Stack, Switch, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import { Header, LoadingBox, Spinner, TMC_Button } from "src/components";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Styled_TabPanelDrawerContent } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { MultiLanguages } from "src/components/Components_Common/forms";
import { multiLanguagesSchema } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.container";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { AutocompleteRoles } from "src/components/Components_Teamoty/autocompletes/AutocompleteRoles";
import { AutocompleteUsers } from "src/components/Components_Teamoty/autocompletes/AutocompleteUsers";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export type Type_Version_DrawerContentComponent_Props = {
    isFetching: boolean;
    onClose: () => void;
    onSubmit: (values: any, props: any) => void;
    data: any;
    isLoading: boolean;
    isUpdateVersion: boolean;
    tab: string;
    setTab: Dispatch<SetStateAction<string>>;
    projectId: number;
    subProjectId: number;
};

const VersionSchema = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object()
            .shape(multiLanguagesSchema.fields)
            .test(
                "at-least-one-name",
                "At least one name must be provided",
                function (value) {
                    return Object.values(value).some((name) => {
                        // Check if the name is a non-empty string
                        return typeof name === "string" && name.trim() !== "";
                    });
                },
            ),
        transversal: Yup.boolean().default(false),
        users: Yup.array().default([]),
        roles: Yup.array().default([]),
    });
});

export const Version_DrawerContentComponent = ({
    isFetching,
    onClose,
    onSubmit,
    data,
    tab,
    setTab,
    isLoading,
    isUpdateVersion,
    projectId,
    subProjectId,
}: Type_Version_DrawerContentComponent_Props) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions.Drawer",
    );

    const drawerTabs = [
        { label: fmt("Tabs.Version"), value: "0" },
        { label: fmt("Tabs.Authorization"), value: "1" },
    ];

    return (
        <TabContext value={tab}>
            {isFetching && <LoadingBox />}
            {!isFetching && (
                <Styled_MainStack>
                    <Header
                        onClose={onClose}
                        title={
                            isUpdateVersion
                                ? data.name
                                : fmt("AlertInfo.TitleCreate")
                        }
                        drawerTabs={drawerTabs}
                        setTabValue={setTab}
                        dataTestIdTitle={
                            isUpdateVersion
                                ? "Version-Drawer-Update-Title"
                                : "Version-Drawer-Create-Title"
                        }
                    />
                    <Formik
                        initialValues={data}
                        onSubmit={onSubmit}
                        validationSchema={VersionSchema}
                        validateOnChange={true}
                    >
                        {({ errors, handleChange, values, isValid }) => {
                            return (
                                <Styled_FormDrawer>
                                    <>
                                        <Styled_TabPanelDrawerContent
                                            value={"0"}
                                        >
                                            <Styled_StackContentDrawer>
                                                <Stack spacing={4}>
                                                    <MultiLanguages
                                                        values={values}
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        errors={errors}
                                                    />
                                                    <Stack
                                                        display={"flex"}
                                                        direction={"row"}
                                                        justifyContent={
                                                            "space-between"
                                                        }
                                                    >
                                                        <Stack
                                                            display={"flex"}
                                                            direction={"column"}
                                                        >
                                                            <Typography variant="body1">
                                                                {fmt(
                                                                    "CrossChecking",
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                color={
                                                                    COLORS.moon500
                                                                }
                                                                variant="body2"
                                                            >
                                                                {fmt(
                                                                    "CrossCheckingSubTitle",
                                                                )}
                                                            </Typography>
                                                        </Stack>

                                                        <Switch
                                                            checked={
                                                                values.transversal
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            name="transversal"
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Styled_StackContentDrawer>
                                        </Styled_TabPanelDrawerContent>
                                        <Styled_TabPanelDrawerContent
                                            value={"1"}
                                        >
                                            <Styled_StackContentDrawer>
                                                <Stack spacing={3} mb={6}>
                                                    <Typography variant="h3">
                                                        {fmt("AuthorizeUsers")}
                                                    </Typography>
                                                    <AutocompleteUsers
                                                        projectId={projectId}
                                                        subProjectId={
                                                            subProjectId
                                                        }
                                                        name="users"
                                                        label={fmt("Users")}
                                                    />
                                                </Stack>

                                                <Stack spacing={3}>
                                                    <Typography variant="h3">
                                                        {fmt("AuthorizeRoles")}
                                                    </Typography>
                                                    <AutocompleteRoles
                                                        name="roles"
                                                        label={fmt("Roles")}
                                                    />{" "}
                                                    {/* TODO: remove */}
                                                </Stack>
                                            </Styled_StackContentDrawer>
                                        </Styled_TabPanelDrawerContent>

                                        <Styled_StackActionDrawer>
                                            <TMC_Button
                                                data-testid="Versions-Drawer-AddVersion-Confirm"
                                                variant="contained"
                                                type="submit"
                                                disabled={!isValid || isLoading}
                                                sx={{ minWidth: 200 }}
                                                endIcon={
                                                    !isLoading && (
                                                        <Icon
                                                            variant="solid"
                                                            icon="check"
                                                        />
                                                    )
                                                }
                                            >
                                                {isLoading ? (
                                                    <Spinner />
                                                ) : isUpdateVersion ? (
                                                    fmt(`CtaUpdate`)
                                                ) : (
                                                    fmt(`CtaCreate`)
                                                )}
                                            </TMC_Button>
                                        </Styled_StackActionDrawer>
                                    </>
                                </Styled_FormDrawer>
                            );
                        }}
                    </Formik>
                </Styled_MainStack>
            )}
        </TabContext>
    );
};
