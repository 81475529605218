import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TfePath, Url } from "src/api/paths";

export const openTFE = (requestConfig: Type_RequestConfig): PostReturnType => {
    return post({ requestConfig }, `${Url.TFE}${TfePath.OPEN}`, requestConfig);
};

export const closeTFE = (
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(`${Url.TFE}${TfePath.CLOSE}`, requestConfig);
};

export const getPingTFE = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.TFE}${TfePath.PING}`, requestConfig);
};

export const getTasksAreasReport = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.TFE}${TfePath.TASKS_AREAS_REPORT}`, requestConfig);
};

export const getPunchlists = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.TFE}${TfePath.PUNCHLIST}`, requestConfig);
};

export const getRoadblocks = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.TFE}${TfePath.ROADBLOCK}`, requestConfig);
};
