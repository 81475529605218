import { Type_Map_planningCompany } from "@cimba-digital-construction-solution/utils/dist/decoder/types";

import {
    formatterIndexCompany,
    formatterSelectListCompany,
} from "src/api/tms-commons/companies/formatters";
import {
    Type_index_company,
    Type_selectList_company,
} from "src/api/tms-commons/companies/types";
import {
    Type_index_projectCompany,
    Type_index_projectCompanyWithMeta,
    Type_post_projectCompany,
    Type_prj_index_projectCompany,
    Type_prj_index_projectCompanyWithMeta,
    Type_prj_post_company,
    Type_prj_put_company,
    Type_prj_show_company,
    Type_put_projectCompany,
    Type_show_projectCompany,
} from "src/api/tms-projects/projectCompanies/types";
import { formatterSelectListSubTrades } from "src/api/tms-projects/subTrades/formatters";
import { Type_selectList_subTrade } from "src/api/tms-projects/subTrades/types";
import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

export const formatterIndexProjectCompanies = (
    projectCompanies: Type_prj_index_projectCompany[],
): Type_index_projectCompany[] => {
    return projectCompanies.map(
        (projectCompany: Type_prj_index_projectCompany) =>
            formatterIndexProjectCompany(projectCompany),
    );
};

export const formatterIndexProjectCompany = (
    projectCompany: Type_prj_index_projectCompany,
): Type_index_projectCompany => {
    return {
        id: projectCompany.id,
        name: projectCompany.name,
        team: projectCompany.team,
        enabled: projectCompany.enabled,
        subTrades: formatterSelectListSubTrades(projectCompany.subTrades),
    };
};

export const formatterIndexProjectCompaniesWithMeta = (
    data: Type_prj_index_projectCompanyWithMeta,
): Type_index_projectCompanyWithMeta => {
    const companies = formatterIndexProjectCompanies(data.data);
    return {
        companies: companies,
        total: data.meta && data.meta.total,
    };
};

export const formatterShowProjectCompany = (
    projectCompany: Type_prj_show_company,
): Type_show_projectCompany => {
    return {
        id: projectCompany.id,
        technicalName: projectCompany.technicalName,
        team: projectCompany.team,
        company: formatterIndexCompany(projectCompany.company),
        companyName: projectCompany.company.name,
        enabled: projectCompany.enabled,
        subTrades: formatterSelectListSubTrades(projectCompany.subTrades),
    };
};

export const formatterUpdateProjectCompany = (
    data: Type_show_projectCompany,
): Type_prj_put_company => {
    return {
        technicalName: data.technicalName,
        subTrades: data.subTrades
            ? data.subTrades.map((trade: Type_selectList_subTrade) => trade.id)
            : [],
        enabled: data.enabled,
        team: data.team,
    };
};

export const formatterCreateProjectCompany = (
    data: Type_post_projectCompany,
): Type_prj_post_company => {
    return {
        id: data.id,
        technicalName: data.technicalName,
        subTrades: data.subTrades
            ? data.subTrades.map(
                  (subTrade: Type_selectList_subTrade) => subTrade.id,
              )
            : [],
        team: data.team,
    };
};

export const formatterSelectOptionCompanies = (
    companies: Type_index_company[],
): Type_SelectOptionItem[] => {
    return companies.map((company: Type_index_company) => ({
        label: company.name,
        value: company.id,
    }));
};

export const formatterStatusProjectCompany = (
    company: Type_put_projectCompany,
): Type_prj_put_company => {
    return {
        enabled: company.enabled,
    };
};

export const formatterCompaniesFromDataPlanningCompanies = (
    companies: Type_Map_planningCompany,
) => {
    return Array.from(companies).reduce<Type_selectList_company[]>(
        (acc, [_key, value]) => {
            acc.push(formatterSelectListCompany(value));
            return acc;
        },
        [],
    );
};
