import { Type_prj_index_subProject } from "src/api/tms-projects/subProjects/types";
import { Type_sch_index_sequence } from "src/api/tms-scheduling/sequences/types";

export type Type_sch_index_sequenceFolder = {
    id: number;
    name: string;
    sequences: Type_sch_index_sequence[];
    enabled: boolean;
};

export type Type_index_sequenceFolder = {
    id: number;
    name: string;
    sequences: Type_sch_index_sequence[];
    enabled?: boolean;
};

export type Type_sch_show_sequenceFolder = {
    id: number;
    names: {
        [key: string]: string;
    };
    subProject: Type_prj_index_subProject;
    sequences: Type_sch_index_sequence[];
    enabled: boolean;
};

export type Type_show_sequenceFolder = {
    id: number;
    names: {
        [key: string]: string;
    };
    subProject: Type_prj_index_subProject;
    sequences: Type_sch_index_sequence[];
    enabled: boolean;
};

export type Type_sch_post_sequenceFolder = {
    names: {
        [key: string]: string;
    };
};

export type Type_post_sequenceFolder = {
    id: null;
    names: {
        [key: string]: string;
    };
};

export type Type_sch_put_sequenceFolderLegacy = {
    id: number;
    names?: {
        [key: string]: string;
    };
    sequences?: Type_sch_index_sequence[];
    enabled?: boolean;
};

export type Type_sch_put_sequenceFolder = {
    names?: {
        [key: string]: string;
    };
    enabled?: boolean;
};

export type Type_put_sequenceFolder = {
    id: number;
    names?: {
        [key: string]: string;
    };
    enabled: boolean;
};

export type Type_sch_select_sequenceFolder = {
    id: number;
    name: string;
    enabled?: boolean;
};

export type Type_select_sequenceFolder = {
    label: string;
    value: number | string;
};

// Types tree sequenceFolders back
export type Type_sch_index_sequenceFolderTree = {
    id: number;
    name: string;
    sequences: Type_sch_index_sequenceFolderTree_sequence[];
};

export type Type_sch_index_sequenceFolderTree_sequence = {
    id: number;
    number: number;
    name: string;
    code: string;
    color: string;
    enabled: boolean;
    tasks: Type_sch_index_sequenceFolderTree_task[];
};

export type Type_sch_index_sequenceFolderTree_task = {
    id: number;
    number: number;
    name: string;
    code: string;
    color: string;
    type: number;
};

// Types tree sequenceFolders front

export type Type_index_sequenceFolderTree = {
    id: number;
    label: string;
    itemId: string;
    itemType: Enum_TreeItem_ItemType.folder;
    enabled: boolean;
    children: Type_index_sequenceFolderTree_sequence[];
};

export type Type_index_sequenceFolderTree_sequence = {
    id: number;
    number: number;
    label: string;
    code: string;
    color: string;
    itemId: string;
    itemType: Enum_TreeItem_ItemType.sequence;
    enabled: boolean;
    children: Type_index_sequenceFolderTree_task[];
};

export type Type_index_sequenceFolderTree_task = {
    id: number;
    number: number;
    label: string;
    code: string;
    color: string;
    type: number;
    itemId: string;
    itemType: Enum_TreeItem_ItemType.task;
    enabled: boolean; // Retrieve this value from the sequence. If the sequence is disabled, the task will also be disabled.
};

// See https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions
export type Type_sequenceFolderTree_item =
    | Type_index_sequenceFolderTree
    | Type_index_sequenceFolderTree_sequence
    | Type_index_sequenceFolderTree_task;

export enum Enum_TreeItem_ItemType {
    task = "task",
    sequence = "sequence",
    folder = "folder",
}
