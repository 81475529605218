import { useMutation, useQuery } from "react-query";

import { KeyDateKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterIndexKeyDate,
    formatterIndexKeyDates,
    formatterPostKeyDate,
    formatterPutKeyDate,
    formatterShowKeyDate,
} from "./formatters";
import {
    createKeyDate,
    indexKeyDates,
    showKeyDate,
    updateKeyDate,
} from "./services";
import {
    Type_index_keyDate,
    Type_post_keyDate,
    Type_put_keyDate,
    Type_sch_show_keyDate,
} from "./types";

// Queries

export const useIndexKeyDate = () => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    const isEnabled: boolean =
        !!requestConfig.projectId && !!requestConfig.subProjectId;
    return useQuery({
        queryKey: [KeyDateKeys.INDEX, { ...requestConfig }],
        queryFn: () => indexKeyDates({ ...requestConfig }),
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.data?.data)
                throw new Error("Wrong format data: useIndexKeyDate");
            return formatterIndexKeyDates(data.data.data);
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const useShowKeyDate = (id?: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    return useQuery({
        queryKey: [KeyDateKeys.SHOW, id, requestConfig],
        queryFn: () => id && showKeyDate(id, requestConfig),
        enabled: !!id,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.data?.data)
                throw new Error("Wrong format data: useShowKeyDate");
            return formatterShowKeyDate(
                data.data.data as Type_sch_show_keyDate,
            );
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

// Mutations

export const mutationCreateKeyDate = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.KeyDate");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    // Channel
    const { sendEvent } = useChannel({});

    return useMutation({
        mutationFn: (data: Type_post_keyDate) =>
            createKeyDate(formatterPostKeyDate(data), requestConfig),
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateKeyDate");
            }

            const formattedData: Type_index_keyDate = formatterIndexKeyDate(
                data.data.data,
            );

            sendEvent("updateDayKeys");

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        keyDate: formattedData.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateKeyDate = () => {
    const { addSuccess, addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.KeyDate");
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    // Channel
    const { sendEvent } = useChannel({});

    return useMutation({
        mutationFn: ({ id, data }: { id: number; data: Type_put_keyDate }) => {
            return updateKeyDate(id, formatterPutKeyDate(data), {
                ...requestConfig,
            });
        },
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateKeyDate");
            }

            const formattedData: Type_index_keyDate = formatterIndexKeyDate(
                data?.data?.data,
            );

            sendEvent("updateDayKeys");

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        keyDate: formattedData.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};
