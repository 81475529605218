import {
    AutocompleteRenderGetTagProps,
    Box,
    ChipTypeMap,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useSelectListSubTrades } from "src/api/tms-projects/subTrades";
import { Type_selectList_subTrade } from "src/api/tms-projects/subTrades/types";
import { useSelectListTrades } from "src/api/tms-projects/trades";
import { Type_selectList_trade } from "src/api/tms-projects/trades/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import {
    Autocomplete,
    Type_Props_Autocomplete,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteSubTrades<
    T extends Type_selectList_subTrade,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    Type_Props_Autocomplete<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
    "options" | "open" | "onOpen" | "onClose" | "loading" | "isFetching"
>;

export const AutocompleteSubTrades = ({
    name,
    label,
    ...restProps
}: Type_Props_AutocompleteSubTrades<
    Type_selectList_subTrade,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>) => {
    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////

    const [open, setOpen] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { isFetching: isFetchingSubTrades, data: subTrades } =
        useSelectListSubTrades(loadData) || {};
    const { isFetching: isFetchingTrades, data: trades } =
        useSelectListTrades(loadData) || {};

    const getGroupLabel = (id: number) => {
        if (trades) {
            const trade = trades.find((objet) => objet.id === id);
            if (trade) {
                return (
                    <Box key={trade.id} sx={{ marginRight: 1 }}>
                        <TMC_Chip
                            label={`${trade.code.toUpperCase()} - ${trade.name}`}
                            backgroundColor={trade.color}
                        />{" "}
                    </Box>
                );
            }
        }
        return "";
    };

    /**
     * Complete trade data
     * Because, when we load the form with Type_show_task, we have a "subtrade" of Type_index_subTrade, which contains 'trade: Type_selectList_trade'.
     * But, when we change the subtrade using the autocomplete, we get a "subtrade" of Type_selectList_subTrade, which only contains the tradeId.
     */
    const { control, setValue } = useFormContext();
    const subTrade = useWatch({
        name: name,
        control: control,
    });

    useEffect(() => {
        // If subTrade change, and we have a tradeId, add Trade data
        if (subTrade?.tradeId && subTrade.trade === undefined) {
            setValue(name, {
                ...subTrade,
                trade: {
                    ...trades?.find(
                        (trade: Type_selectList_trade) =>
                            trade.id === subTrade.tradeId,
                    ),
                },
            });
        }
    }, [subTrade]);

    return (
        <Autocomplete
            name={name}
            label={label}
            options={subTrades || []}
            openOnFocus
            renderTags={(
                tagValue,
                getTagProps: AutocompleteRenderGetTagProps,
            ): JSX.Element[] =>
                tagValue.map((option, index: number) => (
                    <Box key={index} sx={{ marginRight: 2 }}>
                        <TMC_Chip
                            label={`${option.code.toUpperCase()} - ${option.name}`}
                            data-testid={`AutocompleteSubTrades-${option.name.replaceAll(
                                " ",
                                "_",
                            )}`}
                            {...getTagProps({ index })}
                        />
                    </Box>
                ))
            }
            groupBy={(option) => String(option.tradeId)}
            renderGroup={(params) => (
                <div key={params.key}>
                    <Typography variant="h3" p={1}>
                        {getGroupLabel(parseInt(params.group))}
                    </Typography>
                    <>{params.children}</>
                </div>
            )}
            open={open}
            onOpen={() => {
                setOpen(true);
                setLoadData(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={isFetchingSubTrades || isFetchingTrades}
            {...restProps}
        />
    );
};
