import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_priorityOfConstraint } from "src/api/tms-commons/priorityOfConstraints/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ColorChip } from "src/components/Components_Teamoty/chips/ColorChip";
import { PriorityOfConstraintDrawer } from "src/drawers/priorityOfConstraints/PriorityOfConstraintDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeletePriorityOfConstraint } from "src/pages/Pages_Teamoty/Configuration/Page_PriorityOfConstraints/Page_PriorityOfConstraints.container";

export type Type_Props_PriorityOfConstraintsComponent = {
    priorityOfConstraints: Type_index_priorityOfConstraint[];
    isFetching: boolean;
    changeStatus: (
        priorityOfConstraint: Type_index_priorityOfConstraint,
    ) => void;
    removePriorityOfConstraint: (id: number) => void;
    modalDeletePriorityOfConstraint: Type_modalDeletePriorityOfConstraint;
    setModalDeletePriorityOfConstraint: Dispatch<
        SetStateAction<Type_modalDeletePriorityOfConstraint>
    >;
};

export const Page_PriorityOfConstraintsComponent = ({
    priorityOfConstraints,
    isFetching,
    changeStatus,
    removePriorityOfConstraint,
    modalDeletePriorityOfConstraint,
    setModalDeletePriorityOfConstraint,
}: Type_Props_PriorityOfConstraintsComponent) => {
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.PriorityOfConstraints",
    );

    const page = "priorityOfConstraints";

    const [selectedPriorityOfConstraint, setSelectedPriorityOfConstraint] =
        useState<number | null>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleCreatePriority = () => {
        setSelectedPriorityOfConstraint(null);
        setAction("create");
        setOpenDrawer(true);
    };

    const handleEditPriority = (id: number) => {
        setSelectedPriorityOfConstraint(id);
        setAction("update");
        setOpenDrawer(true);
    };

    const handleDuplicatePriority = (id: number) => {
        setSelectedPriorityOfConstraint(id);
        setAction("duplicate");
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "color",
            colType: "color",
            sortable: false,
            headerName: "",
            renderCell: (props: GridRenderCellParams) => {
                return (
                    <ColorChip
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            p: 0,
                        }}
                        backgroundColor={`${props.row.color}`}
                    />
                );
            },
        },

        {
            field: "name",
            sortable: false,
            headerName: fmtTableColumn("PriorityLevel"),
        },
        {
            field: "sortOrder",
            colType: "sortOrder",
            type: "number",
            sortable: false,
            headerName: fmtTableColumn("Order"),
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleEditPriority(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleDuplicatePriority(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_priorityOfConstraint);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeletePriorityOfConstraint({
            isOpen: true,
            props: row as Type_index_priorityOfConstraint,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeletePriorityOfConstraint({
                    isOpen: false,
                });
                removePriorityOfConstraint(
                    modalDeletePriorityOfConstraint?.props?.id as number,
                );
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PagesPriorityOfConstraints-AddOne-btn"
                    onClick={handleCreatePriority}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={priorityOfConstraints}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: handleCreatePriority,
                }}
                onClick={(id: GridRowId) => {
                    handleEditPriority(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <PriorityOfConstraintDrawer
                open={openDrawer}
                onClose={handleCloseDrawer}
                priorityIdToUpdate={selectedPriorityOfConstraint}
                action={action}
                page={page}
            />

            <ModalDelete
                open={modalDeletePriorityOfConstraint.isOpen}
                onClose={() =>
                    setModalDeletePriorityOfConstraint({ isOpen: false })
                }
                item={fmt("DataName")}
                validationString={
                    modalDeletePriorityOfConstraint?.props?.name as string
                }
                actions={actions}
            />
        </>
    );
};
