import {
    Autocomplete,
    IconButton,
    Popover,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { RenderHeaderCellProps } from "react-data-grid";

import { TMC_Button } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { useFiltersContext } from "./TableSequenceAreasFilterContext";

export const FilterRenderer = ({
    tabIndex,
    column,
    columnData,
}: RenderHeaderCellProps<any> & {
    columnData?: any;
}) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    const columnKey = column.value || column.key;
    const { setFilters, setSorting, sort } = useFiltersContext();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [filterMode, setFilterMode] = useState<"contained" | "equal">(
        "contained",
    );
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const [filter, setFilter] = useState<string | string[]>();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSort = () => {
        switch (sort[columnKey]) {
            case "asc":
                setSorting({
                    [columnKey]: "desc",
                });
                break;
            case "desc":
                setSorting({});
                break;
            default:
                setSorting({
                    [columnKey]: "asc",
                });
                break;
        }
    };

    const handleFilter = () => {
        if (
            (Array.isArray(filter) && filter.length <= 0) ||
            (typeof filter == "string" && filter.trim().length == 0)
        ) {
            clearFilter();
        } else if (filter) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [columnKey]: filter,
            }));
        }
        handleClose();
    };

    const clearFilter = () => {
        setFilter(undefined);
        setFilters((prevFilters) => {
            const newObj = { ...prevFilters };
            delete newObj[columnKey];
            return newObj;
        });

        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "filter-popover" : undefined;

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        filterMode: "contained" | "equal",
    ) => {
        if (filterMode) {
            setFilterMode(filterMode);
        }
    };

    return (
        <Stack
            width={"100%"}
            justifyContent={"space-between"}
            direction="row"
            alignItems={"center"}
        >
            <div onClick={handleSort}>{column.name}</div>
            <Stack direction="row" alignItems={"center"}>
                {Object.prototype.hasOwnProperty.call(sort, columnKey) && (
                    <Icon
                        aria-describedby={id}
                        data-testid={`sort-column-${tabIndex}-icon"`}
                        icon={
                            sort[columnKey] === "asc"
                                ? `chevron-down`
                                : `chevron-up`
                        }
                        variant="solid"
                        style={{ cursor: "pointer" }}
                    />
                )}

                <IconButton
                    sx={{ position: "relative" }}
                    aria-label="open-filter"
                    onClick={handleClick}
                    data-testid={`filter-column-${tabIndex}-icon"`}
                >
                    <Icon
                        aria-describedby={id}
                        icon="bars-filter"
                        variant="light"
                    />

                    {filter && (
                        <Typography
                            sx={{
                                backgroundColor: COLORS.teamotyBlue500,
                                borderRadius: "80px",
                                width: "24px",
                                height: "24px",
                                color: COLORS.white,
                                transform: "scale(0.6)",
                                position: "absolute",
                                top: "-2px",
                                right: "-7px",
                            }}
                        >
                            {Array.isArray(filter) ? filter.length : 1}
                        </Typography>
                    )}
                </IconButton>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Stack width={440} py={8} px={12} spacing={4}>
                        <ToggleButtonGroup
                            fullWidth
                            color="primary"
                            value={filterMode}
                            exclusive
                            onChange={handleChange}
                            aria-label="filterMode"
                        >
                            <ToggleButton value="contained">
                                {fmt("Contain")}
                            </ToggleButton>
                            <ToggleButton value="equal">
                                {fmt("IsEqualTo")}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {filterMode == "contained" && (
                            <Stack
                                alignItems={"center"}
                                direction="row"
                                spacing={3}
                            >
                                <Typography variant="body1">
                                    {fmt("Contain")}
                                </Typography>
                                <TextField
                                    onChange={(e) => setFilter(e.target.value)}
                                    fullWidth
                                    value={
                                        typeof filter === "string" ? filter : ""
                                    }
                                />
                            </Stack>
                        )}
                        {filterMode == "equal" && (
                            <Stack
                                alignItems={"center"}
                                spacing={3}
                                direction="row"
                            >
                                <Typography variant="body1">
                                    {fmt("IsEqualTo")}
                                </Typography>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    id="tags-standard"
                                    options={columnData}
                                    getOptionLabel={(option) => option}
                                    value={Array.isArray(filter) ? filter : []}
                                    onChange={(e, value) => setFilter(value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </Stack>
                        )}
                        <Stack
                            justifyContent={"flex-end"}
                            direction="row"
                            alignItems={"center"}
                            spacing={3}
                        >
                            <TMC_Button onClick={clearFilter} color="secondary">
                                {fmt("Reset")}
                            </TMC_Button>
                            <TMC_Button onClick={handleFilter} color="primary">
                                {fmt("Filter")}
                            </TMC_Button>
                        </Stack>
                    </Stack>
                </Popover>
            </Stack>
        </Stack>
    );
};
