import { Box, Grid, InputAdornment, Tooltip, Typography } from "@mui/material";
import * as React from "react";

import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { AutocompleteSubTradeCompanies } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteSubTradeCompanies";
import { CompanyTeamBadge } from "src/components/Components_Teamoty/badges/CompanyTeamBadge";
import {
    DURATION_MIN,
    DURATION_STEP,
    PROGRESSION_STEP,
    TEAM_MIN,
    TEAM_STEP,
    WORKFORCE_MIN,
    WORKFORCE_STEP,
} from "src/configurations/app";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Row,
    RowIcon,
    Section,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_TaskAreaFormContent = {
    taskArea: Type_show_taskArea;
};

export const TaskAreaFormContent = ({
    taskArea,
}: Type_Props_TaskAreaFormContent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTaskArea.Main",
    );

    const renderRow = (
        variant: string,
        icon: string,
        tooltip: string,
        value: string,
    ) => (
        <Row>
            <RowIcon>
                <Tooltip title={fmt(tooltip)} placement="left">
                    <Icon variant={variant} icon={icon} fontSize="small" />
                </Tooltip>
            </RowIcon>
            <Typography variant="body1" color="textSecondary">
                {value}
            </Typography>
        </Row>
    );

    const renderTextField = (
        name: string,
        label: string,
        type: string,
        step: number | null,
        min: number | null,
    ) => (
        <Grid item xs={6}>
            <TextField
                name={name}
                label={fmt(label)}
                type={type}
                inputProps={{ step, min }}
                data-testid={`Form-TaskArea-${name}`}
            />
        </Grid>
    );

    return (
        <Section>
            <Box display="flex" flexDirection="column" gap={2}>
                {renderRow(
                    "light",
                    "layer-group",

                    "Tooltip.Area",
                    taskArea.areaName as string,
                )}
                {renderRow(
                    "kit",

                    "light-tds-sequence",
                    "Tooltip.Sequence",
                    taskArea.sequenceName as string,
                )}
                {shouldDisplay({ name: "subTrade", taskType: taskArea.type }) &&
                    renderRow(
                        "light",
                        "helmet-safety",
                        "Tooltip.Trade",
                        taskArea?.subTrade?.name as string,
                    )}
                {shouldDisplay({
                    name: "company",
                    taskType: taskArea.type,
                }) && (
                    <Row>
                        <RowIcon>
                            <Tooltip
                                title={fmt("Tooltip.Company")}
                                placement="left"
                            >
                                <Icon
                                    variant="light"
                                    icon="building"
                                    fontSize="small"
                                />
                            </Tooltip>
                        </RowIcon>
                        <AutocompleteSubTradeCompanies
                            name="company"
                            subTradeId={taskArea?.subTrade?.id as number}
                            data-testid="Form-Task-company"
                        />
                        <CompanyTeamBadge
                            companyId={taskArea?.company?.id as number}
                        />
                    </Row>
                )}
            </Box>
            <Grid container spacing={2}>
                {shouldDisplay({
                    name: "duration",
                    taskType: taskArea.type,
                }) && (
                    <>
                        {renderTextField(
                            "duration",
                            "Duration",
                            "number",
                            DURATION_STEP,
                            DURATION_MIN,
                        )}
                        <Grid item xs={6}></Grid>
                    </>
                )}
                <Grid item xs={6}>
                    <DatePicker
                        name="earliestDate"
                        label={fmt("EarliestDate")}
                        data-testid="Form-TaskArea-earliestDate"
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                {shouldDisplay({ name: "team", taskType: taskArea.type }) &&
                    renderTextField(
                        "team",
                        "Team",
                        "number",
                        TEAM_STEP,
                        TEAM_MIN,
                    )}
                {shouldDisplay({
                    name: "workforce",
                    taskType: taskArea.type,
                }) &&
                    renderTextField(
                        "workforce",
                        "Workforce",
                        "number",
                        WORKFORCE_STEP,
                        WORKFORCE_MIN,
                    )}
                {shouldDisplay({
                    name: "progressValue",
                    taskType: taskArea.type,
                }) && (
                    <Grid item xs={6}>
                        <TextField
                            name={"progressValue"}
                            label={fmt("Progression")}
                            type={"number"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                step: PROGRESSION_STEP,
                                min: 0,
                                max: 100,
                            }}
                            data-testid={`Form-TaskArea-progressValue`}
                        />
                    </Grid>
                )}
            </Grid>
        </Section>
    );
};
