import { PlanningShapeMilestoneBase } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeMilestoneBase";
import { PlanningShapeTaskBase } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeTaskBase";
import { PlanningShapeTaskLight } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeTaskLight";
import { planningShapeColorFunction } from "src/components/Components_Teamoty/Planning/shapeColorFunction/planningShapeColorFunction";
import { planningShapeLightColorFunction } from "src/components/Components_Teamoty/Planning/shapeColorFunction/planningShapeLightColorFunction";
import { planningShapeMilestoneColorFunction } from "src/components/Components_Teamoty/Planning/shapeColorFunction/planningShapeMilestoneColorFunction";
import { planningShapeDeliveryFunction } from "src/components/Components_Teamoty/Planning/shapeFunction/planningShapeDeliveryFunction";
import { planningShapeMilestoneFunction } from "src/components/Components_Teamoty/Planning/shapeFunction/planningShapeMilestoneFunction";
import { planningShapeStudyFunction } from "src/components/Components_Teamoty/Planning/shapeFunction/planningShapeStudyFunction";
import { planningShapeTechnicalFunction } from "src/components/Components_Teamoty/Planning/shapeFunction/planningShapeTechnicalFunction";
import { planningShapeWaitingFunction } from "src/components/Components_Teamoty/Planning/shapeFunction/planningShapeWaitingFunction";
import { planningShapeWorkFunction } from "src/components/Components_Teamoty/Planning/shapeFunction/planningShapeWorkFunction";

export const components_shape_planning = {
    work: planningShapeWorkFunction,
    study: planningShapeStudyFunction,
    waiting: planningShapeWaitingFunction,
    technical: planningShapeTechnicalFunction,
    milestone: planningShapeMilestoneFunction,
    delivery: planningShapeDeliveryFunction,
};

export const color_shape_planning = {
    work: planningShapeColorFunction,
    study: planningShapeColorFunction,
    waiting: planningShapeLightColorFunction,
    technical: planningShapeLightColorFunction,
    milestone: planningShapeMilestoneColorFunction,
    delivery: planningShapeMilestoneColorFunction,
};

export type Type_props_component_shape_planning =
    keyof typeof components_shape_planning;
export const componentsTaskPlanning = {
    work: PlanningShapeTaskBase,
    study: PlanningShapeTaskBase,
    waiting: PlanningShapeTaskBase,
    technical: PlanningShapeTaskLight,
    milestone: PlanningShapeMilestoneBase,
    delivery: PlanningShapeMilestoneBase,
};
