import { Checkbox } from "@mui/material";
import { RenderCellProps, useRowSelection } from "react-data-grid";

import { useCoreIntl } from "src/hooks/useCoreIntl";

export const CheckboxCell = ({
    column,
    row,
    tabIndex,
    onRowChange,
}: RenderCellProps<any>) => {
    return (
        <Checkbox
            key={tabIndex}
            value={row[column.key]}
            checked={!!row[column.key]}
            onChange={() => {
                onRowChange({
                    ...row,
                    [column.key]: !row[column.key],
                });
            }}
        ></Checkbox>
    );
};

export const HeaderCheckbox = ({ ...props }) => {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Actions");
    return (
        <Checkbox
            aria-label={fmt("SelectAll")}
            value={isRowSelected}
            checked={isRowSelected}
            tabIndex={props.tabIndex}
            onChange={(e) => {
                onRowSelectionChange({
                    type: "HEADER",
                    checked: e.target.checked,
                });
            }}
        />
    );
};
