import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_unitType } from "src/api/tms-commons/unitTypes/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { UnitTypeDrawer } from "src/drawers/unitTypes/UnitTypeDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteUnitType } from "./Page_UnitTypes.container";

export type Type_Props_Page_UnitTypesComponent = {
    unitTypes: Type_index_unitType[];
    removeUnitType: (id: number) => void;
    changeStatus: (unitType: Type_index_unitType) => void;
    modalDeleteUnitType: Type_modalDeleteUnitType;
    setModalDeleteUnitType: Dispatch<SetStateAction<Type_modalDeleteUnitType>>;
    isFetching: boolean;
};

export const Page_UnitTypesComponent = ({
    unitTypes,
    removeUnitType,
    changeStatus,
    modalDeleteUnitType,
    setModalDeleteUnitType,
    isFetching,
}: Type_Props_Page_UnitTypesComponent) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.UnitTypes");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "unitTypes";

    const [selectedUnitType, setSelectedUnitType] = useState<number | null>(
        null,
    );
    const [openDrawer, setOpenDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleUnitTypeCreate = () => {
        setSelectedUnitType(null);
        setAction("create");
        setOpenDrawer(true);
    };

    const handleUnitTypeEdit = (unitTypeId: number) => {
        setSelectedUnitType(unitTypeId);
        setAction("update");
        setOpenDrawer(true);
    };
    const callbackEdit = (row: GridRowModel) => {
        handleUnitTypeEdit(row.id);
    };

    const handleUnitTypeDuplicate = (unitTypeId: number) => {
        setSelectedUnitType(unitTypeId);
        setAction("duplicate");
        setOpenDrawer(true);
    };
    const callbackDuplicate = (row: GridRowModel) => {
        handleUnitTypeDuplicate(row.id);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_unitType);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteUnitType({
            isOpen: true,
            props: row as Type_index_unitType,
        });
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
    ];

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteUnitType({ isOpen: false });
                removeUnitType(modalDeleteUnitType?.props?.id as number);
            },
        },
    };
    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageUnitTypes-AddOne-btn"
                    page={page}
                    onClick={handleUnitTypeCreate}
                />
            </HeaderGrid>
            <Table
                rows={unitTypes}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: handleUnitTypeCreate,
                }}
                onClick={(id: GridRowId) => {
                    handleUnitTypeEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ModalDelete
                open={modalDeleteUnitType.isOpen}
                onClose={() => setModalDeleteUnitType({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteUnitType?.props?.name as string}
                actions={actions}
            />

            <UnitTypeDrawer
                open={openDrawer}
                onClose={handleCloseDrawer}
                unitTypeId={selectedUnitType}
                action={action}
                page={page}
            />
        </>
    );
};
