import TabContext from "@mui/lab/TabContext";
import { Stack, Switch, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { FormikProvider } from "formik";
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";

import { Type_select_timeModel } from "src/api/tms-commons/timeModels/types";
import { Type_show_subProject } from "src/api/tms-projects/subProjects/types";
import { Header, LoadingBox, TMC_Button } from "src/components";
import { TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextFieldDeprecated.component";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionsDrawerReverse,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Styled_TabPanelDrawerContent } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { DaysCheckboxField } from "src/components/Components_Common/forms/formik/days/DaysCheckboxField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_DefaultSettings_DrawerContentComponent = {
    onClose: () => void;
    tab: string;
    formik: any;
    setTab: Dispatch<SetStateAction<string>>;
    subProject?: Type_show_subProject;
    timeModels: Type_select_timeModel[];
    isLoadingUpdate: boolean;
    isFetchingTimeModel: boolean;
};

export const DefaultSettings_DrawerContentComponent = ({
    formik,
    onClose,
    tab,
    setTab,
    subProject,
    timeModels,
    isLoadingUpdate,
    isFetchingTimeModel,
}: Type_Props_DefaultSettings_DrawerContentComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.DefaultSettings",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const drawerTabs = [
        { label: fmt("Drawer.TabSequence"), value: "0" },
        { label: fmt("Drawer.TabVersion"), value: "1" },
    ];

    const minDurations = [
        { value: 0, name: fmt("MinDuration0") },
        { value: 0.25, name: fmt("MinDuration0.25") },
        { value: 0.5, name: fmt("MinDuration0.5") },
        { value: 1, name: fmt("MinDuration1") },
    ];

    const limitVersions = [
        { value: 0, name: fmt("VersionLimitNone") },
        { value: 1, name: "1" },
        { value: 2, name: "2" },
        { value: 3, name: "3" },
        { value: 4, name: "4" },
    ];

    const [minDurationToggle, setMinDurationToggle] = useState(false);

    useEffect(() => {
        if (subProject && subProject.minDuration) {
            setMinDurationToggle(subProject.minDuration > 0);
        }
    }, [subProject]);

    return (
        <TabContext value={tab}>
            <Styled_MainStack>
                <Header
                    onClose={onClose}
                    title={fmt("Drawer.Title")}
                    setTabValue={setTab}
                    drawerTabs={drawerTabs}
                    dataTestIdTitle={"DefaultSettings-Drawer-Title"}
                />
                {!subProject && <LoadingBox />}
                {subProject && (
                    <FormikProvider value={formik}>
                        <Styled_FormDrawer onSubmit={formik.handleSubmit}>
                            <Styled_TabPanelDrawerContent value="0">
                                <Styled_StackContentDrawer gap={4}>
                                    <Typography variant="body1Bold">
                                        {fmt("Drawer.PlanningSettings")}
                                    </Typography>
                                    <Stack
                                        display={"inline-flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        width={"100%"}
                                    >
                                        <Typography variant={"body1"}>
                                            {fmt("MinDuration")}
                                        </Typography>
                                        <Switch
                                            checked={minDurationToggle}
                                            onChange={(e) => {
                                                setMinDurationToggle(
                                                    e.target.checked,
                                                );
                                                if (!e.target.checked) {
                                                    formik.setFieldValue(
                                                        "minDuration",
                                                        "0",
                                                    );
                                                }
                                            }}
                                            data-testid="PlanningSettings-Drawer-switch-minDuration"
                                        />
                                    </Stack>
                                    {minDurationToggle && (
                                        <TextField
                                            data-testid="PlanningSettings-Drawer-minDuration"
                                            select
                                            label={fmt("MinDuration")}
                                            name={"minDuration"}
                                            onChange={(
                                                event: ChangeEvent<
                                                    | HTMLTextAreaElement
                                                    | HTMLInputElement
                                                >,
                                            ) =>
                                                formik.setFieldValue(
                                                    "minDuration",
                                                    event.target.value,
                                                )
                                            }
                                            defaultValue={
                                                subProject.minDuration
                                            }
                                        >
                                            {minDurations.map((option) => (
                                                <MenuItem
                                                    data-testid={`PlanningSettings-Drawer-minDuration-${option.name}`}
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    <DaysCheckboxField
                                        name="workingDays"
                                        dataTestIdPrefix={
                                            "DefaultSettings-WorkingDays"
                                        }
                                    />
                                    <Typography variant="body1Bold">
                                        {fmt("Drawer.TaskSettings")}
                                    </Typography>
                                    <Stack
                                        display={"inline-flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        width={"100%"}
                                    >
                                        <Typography variant={"body1"}>
                                            {fmt("OptimizedFlow")}
                                        </Typography>
                                        <Switch
                                            name={"optimizedFlow"}
                                            data-testid="PlanningSettings-Drawer-switch-optimizedFlow"
                                            {...formik.getFieldProps(
                                                "optimizedFlow",
                                            )}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            checked={
                                                formik.values.optimizedFlow
                                            }
                                        />
                                    </Stack>
                                    {!isFetchingTimeModel && (
                                        <TextField
                                            select
                                            name={"timeModel"}
                                            data-testid="PlanningSettings-Drawer-timeModel"
                                            onChange={(
                                                event: ChangeEvent<
                                                    | HTMLTextAreaElement
                                                    | HTMLInputElement
                                                >,
                                            ) => {
                                                formik.setFieldValue(
                                                    "timeModel",
                                                    {
                                                        id: event.target.value,
                                                        name: event.target
                                                            .value,
                                                    },
                                                );
                                            }}
                                            label={fmt("TimeModel")}
                                            defaultValue={
                                                subProject.timeModel?.id
                                            }
                                        >
                                            {timeModels.map((option) => (
                                                <MenuItem
                                                    data-testid={`PlanningSettings-Drawer-timeModel-${option.name}`}
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Styled_StackContentDrawer>
                            </Styled_TabPanelDrawerContent>
                            <Styled_TabPanelDrawerContent value="1">
                                <Styled_StackContentDrawer gap={4}>
                                    <Typography variant="body1Bold">
                                        {fmt("Drawer.VersionSettings")}
                                    </Typography>
                                    <TextField
                                        select
                                        name={"versionLimit"}
                                        data-testid="PlanningSettings-Drawer-versionLimit"
                                        onChange={(
                                            event: ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >,
                                        ) =>
                                            formik.setFieldValue(
                                                "versionLimit",
                                                event.target.value,
                                            )
                                        }
                                        label={fmt("VersionLimit")}
                                        defaultValue={subProject.versionLimit}
                                    >
                                        {limitVersions.map((option) => (
                                            <MenuItem
                                                data-testid={`PlanningSettings-Drawer-versionLimit-${option.name}`}
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Styled_StackContentDrawer>
                            </Styled_TabPanelDrawerContent>
                            <Styled_StackActionsDrawerReverse>
                                <TMC_Button
                                    data-testid="PlanningSettings-Drawer-submit"
                                    color="primary"
                                    type="submit"
                                    disabled={
                                        isLoadingUpdate ||
                                        !subProject ||
                                        isFetchingTimeModel
                                    }
                                    sx={{ minWidth: 200 }}
                                    endIcon={
                                        !isLoadingUpdate &&
                                        !isFetchingTimeModel && (
                                            <Icon
                                                variant="solid"
                                                icon="check"
                                            />
                                        )
                                    }
                                >
                                    {isLoadingUpdate || isFetchingTimeModel ? (
                                        <Icon
                                            variant="solid"
                                            icon="spinner-third"
                                            spin
                                        />
                                    ) : (
                                        fmtCta("Save")
                                    )}
                                </TMC_Button>
                            </Styled_StackActionsDrawerReverse>
                        </Styled_FormDrawer>
                    </FormikProvider>
                )}
            </Styled_MainStack>
        </TabContext>
    );
};
