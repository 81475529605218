import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import {
    Control,
    FieldPath,
    FormProvider,
    useForm,
    useWatch,
} from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateMetadataProject,
    mutationUpdateMetadataProject,
    useShowMetadataProject,
} from "src/api/tms-commons/metadata";
import {
    Type_post_metadataProject,
    Type_show_metadataProject,
} from "src/api/tms-commons/metadata/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { AutocompleteFreeSolo } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSolo/AutocompleteFreeSolo";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import {
    formatterTradMetadataTypesSelect,
    metadataTypesSelect,
    metadataWithValues,
} from "src/configurations/metadataTypes";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_MetadataProjectForm = {
    onClose: () => void;
    metadataIdToUpdate: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_MetadataProject = Yup.object().shape({
    metadataType: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    key: Yup.string()
        .test("isValidFormat", FORM_ERR_FMT.FORMAT_KEY_VALUE, (value) => {
            return /^[a-zA-Z0-9-_]+$/.test(value || "");
        })
        .trim()
        .max(100, FORM_ERR_FMT.TOO_LONG)
        .required(FORM_ERR_FMT.REQUIRED),
    values: Yup.array().when("metadataType", (metadataType, schema) => {
        if (
            metadataType[0] &&
            ["checkbox", "radio", "select"].includes(metadataType[0])
        ) {
            return schema.min(1, FORM_ERR_FMT.REQUIRED);
        } else return schema.nullable();
    }),
    forFilter: Yup.bool(),
    sort: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, FORM_ERR_FMT.MUST_BE_POSITIF)
        .nullable(),
});

const AutocompleteValues = ({
    control,
    values,
    label,
}: {
    control: Control<Type_show_metadataProject | Type_post_metadataProject>;
    values: any;
    label: string;
}) => {
    const metadataType = useWatch({
        control,
        name: "metadataType",
    });

    if (metadataType && metadataWithValues.includes(metadataType)) {
        return (
            <Grid item xs={12}>
                <AutocompleteFreeSolo
                    options={values}
                    name="values"
                    label={label}
                    renderInputProps={{ variant: "outlined" }}
                />
            </Grid>
        );
    }

    return <></>;
};

export const MetadataProjectForm = ({
    onClose,
    metadataIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_MetadataProjectForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataProject",
    );

    const { isFetching, data } = useShowMetadataProject(
        metadataIdToUpdate as number,
    );

    const form = useForm<Type_show_metadataProject | Type_post_metadataProject>(
        {
            ...useFormDefaultConfig,
            defaultValues: {
                id: undefined,
                names: {},
                key: "",
                metadataType: "",
                values: [],
                forFilter: false,
            },
            values: data,
            resolver: yupResolver<any>(Schema_MetadataProject),
        },
    );

    const onError = (errors: any) => {
        Object.entries(errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                form.setError(
                    key as
                        | FieldPath<
                              | Type_show_metadataProject
                              | Type_post_metadataProject
                          >
                        | `root.${string}`
                        | "root",
                    { type: "server", message: value[0] },
                    { shouldFocus: true },
                );
            }
        });
    };

    const { mutateAsync: mutateCreate } =
        mutationCreateMetadataProject(onError) || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateMetadataProject();

    const handleSubmit = async (
        values: Type_show_metadataProject | Type_post_metadataProject,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault;

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_metadataProject);
            } else {
                await mutateCreate(values as Type_post_metadataProject);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating metadata");
        }

        setIsLoading(false);
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"metadataProjects"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={4}>
                        <Select
                            name="metadataType"
                            options={formatterTradMetadataTypesSelect(
                                metadataTypesSelect,
                            )}
                            label={fmt("Labels.MetadataType")}
                            data-testid="MetadataProject-DrawerContent-metadataType"
                            variant="outlined"
                        />
                        <InputMultiLanguages
                            name="names"
                            label={fmt("Metadata")}
                        />
                        <TextField
                            label={fmt("Labels.Key")}
                            data-testid="MetadataProject-DrawerContent-key"
                            {...form.register("key")}
                        />
                        <AutocompleteValues
                            control={form.control}
                            values={form.getValues("values")}
                            label={fmt("Labels.Values")}
                        />
                        <TextField
                            label={fmt("Labels.Sort")}
                            type="number"
                            data-testid="MetadataProject-DrawerContent-sort"
                            {...form.register("sort")}
                        />
                        <Switch
                            sx={{
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                            name={"forFilter"}
                            label={fmt("Labels.Filterable")}
                            data-testid="MetadataProject-DrawerContent-filterable"
                        />
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
