import { useMutation, useQuery } from "react-query";

import { Type_Filter_Sequence } from "src/api/filters";
import { SequenceAreaKeys } from "src/api/tms-scheduling/keys";
import {
    createSequenceArea,
    deleteSequenceArea,
    indexSequenceAreas,
    updateSequenceArea,
} from "src/api/tms-scheduling/sequenceAreas/services";
import {
    Type_index_sequenceArea,
    Type_sch_index_sequenceArea,
    Type_sch_post_sequenceArea,
    Type_sch_put_sequenceArea,
} from "src/api/tms-scheduling/sequenceAreas/types";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterIndexSequenceArea,
    formatterIndexSequenceAreas,
} from "./formatter";

export const useIndexSequenceAreas = (
    filters: Type_Filter_Sequence = {},
    enabled?: boolean,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [SequenceAreaKeys.INDEX, requestConfig, filters],
        queryFn: () => indexSequenceAreas(requestConfig, filters),
        refetchOnWindowFocus: false,
        select: (data) => {
            const SequenceAreas: Type_sch_index_sequenceArea[] =
                data?.data?.data;
            return formatterIndexSequenceAreas(SequenceAreas);
        },
    });
};

export const mutationCreateSequenceArea = (
    callback?: (sequenceArea: Type_index_sequenceArea) => void,
) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (newSequenceArea: Type_sch_post_sequenceArea) =>
            createSequenceArea(newSequenceArea, requestConfig),
        onSuccess: (data: any) => {
            if (data.success) {
                const sequenceArea: Type_index_sequenceArea =
                    formatterIndexSequenceArea(data.data.data);
                callback ? callback(sequenceArea) : sequenceArea;
            }
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationUpdateSequenceArea = (
    callback?: (sequenceArea: Type_index_sequenceArea) => void,
) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (sequenceArea: Type_sch_put_sequenceArea) => {
            const { id, ...data } = sequenceArea;

            return updateSequenceArea(
                {
                    id: id,
                    data,
                },
                requestConfig,
            );
        },
        onSuccess: (resp) => {
            const sequenceArea: Type_index_sequenceArea =
                formatterIndexSequenceArea(resp.data.data);
            callback ? callback(sequenceArea) : sequenceArea;
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteSequenceArea = (callback?: (id: number) => void) => {
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => deleteSequenceArea(id, requestConfig),
        onSuccess: (data, id) => {
            callback ? callback(id) : id;
        },
        onError: (err: any) => {
            console.error(fmtError("GenericError", {}), err);

            return err;
        },
    });
};
