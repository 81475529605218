import { Stack, Typography } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid-pro";
import React from "react";

import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { invertColor } from "src/utils/colors";

type Type_Props_TradeCell = {
    row: GridRowModel;
};

export const TradeCell = ({ row }: Type_Props_TradeCell) => {
    const backgroundColor =
        row?.subTrades?.length > 0 ? row.color : "transparent";
    const colorText =
        backgroundColor !== "transparent"
            ? invertColor(row.color)
            : "text.primary";

    return (
        <Stack gap={1} direction={"row"} alignItems={"center"}>
            <TMC_Chip
                backgroundColor={row.enabled ? backgroundColor : "common.white"}
                label={row?.code?.toUpperCase()}
                size={"small"}
                colorText={colorText}
                withShadow={!!row.subTrades?.length}
                sx={{ minWidth: "40px" }}
            />
            <Typography
                variant={"body1Medium"}
                color={row.enabled ? "text.primary" : "text.disabled"}
            >
                {row.name}
            </Typography>
        </Stack>
    );
};
