import {
    CardActionArea,
    CardContent,
    List,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

import { Type_default_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { Type_default_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import defaultCover from "src/assets/images/defaultWorkspaceCover.png";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_WorkspaceCard,
    Styled_WorkspaceCardIcon,
    Styled_WorkspaceCardMedia,
} from "src/layouts/Layout_Workspace/WorkspaceCards/stylesheet";

type Type_Props_WorkspaceCard = {
    workspace: Type_default_workspace;
    selected: string;
    setSelected: Dispatch<SetStateAction<string>>;
};

export const WorkspaceCard = ({
    workspace,
    selected,
    setSelected,
}: Type_Props_WorkspaceCard) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Workspace");

    const isSelected = selected === workspace.type;

    const handleClick = () => {
        setSelected(isSelected ? "" : workspace.type);
    };

    return (
        <Styled_WorkspaceCard
            ownerState={{ selected: isSelected }}
            onClick={handleClick}
            data-testid={`Workspace-Card-type-${workspace.type}`}
        >
            {isSelected && (
                <Styled_WorkspaceCardIcon variant="solid" icon="circle-check" />
            )}
            <CardActionArea
                title={fmt(workspace.description)}
                onClick={handleClick}
            >
                <Styled_WorkspaceCardMedia
                    component="img"
                    image={defaultCover}
                />
                <CardContent sx={{ height: "172px" }}>
                    <Typography
                        gutterBottom
                        variant="h3"
                        sx={{ textTransform: "none" }}
                    >
                        {fmt(workspace.name)}
                    </Typography>
                    <Typography
                        variant="body1"
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        noWrap
                    >
                        {fmt(workspace.description)}
                    </Typography>
                    <List>
                        {workspace.views.map(
                            (view: Type_default_view, index: number) => (
                                <ListItemText key={index}>
                                    <Typography variant="body2" paddingLeft={1}>
                                        • {fmt(view.name + ".Name")}
                                    </Typography>
                                </ListItemText>
                            ),
                        )}
                    </List>
                </CardContent>
            </CardActionArea>
        </Styled_WorkspaceCard>
    );
};
