import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import * as Yup from "yup";

import {
    mutationCreateAreaType,
    mutationUpdateAreaType,
    useShowAreaType,
} from "src/api/tms-commons/areaTypes";
import {
    Type_post_areaType,
    Type_show_areaType,
} from "src/api/tms-commons/areaTypes/types";
import { useSelectListProjectTypes } from "src/api/tms-commons/projectTypes";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { useFormDefaultConfig } from "src/configurations/app";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AreaTypeForm = {
    onClose: () => void;
    areaTypeIdToUpdate?: number | undefined;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_AreaTypeForm = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
        projectTypes: Yup.array().optional(),
    });
});

export const AreaTypeForm = ({
    onClose,
    areaTypeIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_AreaTypeForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.AreaTypes");

    const { mutateAsync: mutateCreate } = mutationCreateAreaType() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateAreaType();

    const { isFetching: isFetchingGetProjectTypes, data: projectTypes } =
        useSelectListProjectTypes();

    const { isFetching: isFetchingAreaType, data } = useShowAreaType(
        areaTypeIdToUpdate as number,
    );

    const form = useTmcForm<Type_show_areaType | Type_post_areaType>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
            projectTypes: [],
        },
        values: data,
        resolver: yupResolver<any>(Schema_AreaTypeForm),
        onClose,
        setIsLoading,
    });

    const handleSubmit = async (values: any) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_areaType);
            } else {
                delete values.id;
                await mutateCreate(values as Type_post_areaType);
            }
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating areaType");
        }
    };

    const isFetching = useMemo(
        () =>
            !!(areaTypeIdToUpdate && isFetchingAreaType) ||
            isFetchingGetProjectTypes,
        [areaTypeIdToUpdate, isFetchingGetProjectTypes, isFetchingAreaType],
    );

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"areaTypes"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <InputMultiLanguages
                            name="names"
                            label={fmt(`AreaTypeName`)}
                        />
                        <Autocomplete
                            isFetching={isFetchingGetProjectTypes}
                            name="projectTypes"
                            options={projectTypes || []}
                            multiple={true}
                            label={fmt(`SelectMultipleProjectTypes`)}
                            renderInputProps={{ variant: "outlined" }}
                        ></Autocomplete>
                    </Stack>
                )}
            </form>
        </TmcFormProvider>
    );
};
