import { Box, Skeleton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";

import { useSelectListSubProjects } from "src/api/tms-projects/subProjects";
import { Type_selectList_subProject } from "src/api/tms-projects/subProjects/types";
import { TMC_TextField } from "src/components/Components_Common";
import { Type_Props_Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

type Type_Props_SelectSubProject = Omit<Type_Props_Select, "options"> & {
    setSubProjectId: Dispatch<SetStateAction<string | undefined>>;
};

export const SelectSubProject = ({
    name,
    value,
    setSubProjectId,
    ...props
}: Type_Props_SelectSubProject) => {
    const { isFetching, data } = useSelectListSubProjects();

    // Récupérer automatiquement le premier de la liste si aucune valeur n'est sélectionnée
    useEffect(() => {
        if (data?.length && value === undefined) {
            setSubProjectId(data[0].id.toString());
        }
    }, [data, value, setSubProjectId]);

    return (
        <>
            {isFetching || !data?.length ? (
                <Box>
                    <Skeleton variant={"rectangular"} height={32} />
                </Box>
            ) : data?.length === 1 ? (
                <TMC_TextField
                    name={name}
                    value={data[0].name}
                    {...props}
                    inputProps={{
                        ...props.inputProps,
                        "data-testid": `Select-${name}-selected`,
                    }}
                />
            ) : (
                <TMC_TextField
                    select
                    name={name}
                    value={value ?? data[0].id}
                    {...props}
                    inputProps={{
                        ...props.inputProps,
                        "data-testid": `Select-${name}-selected`,
                    }}
                >
                    {data.map((option: Type_selectList_subProject) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                            disabled={!option?.enabled}
                            data-testid={`Select-MenuItem-${name}-${option.id}`}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TMC_TextField>
            )}
        </>
    );
};
