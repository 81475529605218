import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Collapse, Stack } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { getCsrfToken } from "src/api/fetch";
import { mutationLogin } from "src/api/tms-gateway/auth";
import { Type_post_login } from "src/api/tms-gateway/auth/types";
import { mutationCheckCGU } from "src/api/tms-users/auth";
import { Spinner, TMC_Button } from "src/components";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { TextFieldPassword } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldPassword/TextFieldPassword";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { URL_BASE } from "src/router";
import { getLocalStorageItem } from "src/utils/localStorageServices";

const Schema_LoginForm = Yup.object().shape({
    email: Yup.string()
        .email(FORM_ERR_FMT.MUST_BE_EMAIL)
        .required(FORM_ERR_FMT.REQUIRED),
    password: Yup.string().required(FORM_ERR_FMT.REQUIRED),
});

export const LoginForm = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Identification");
    // const lang = getLocalStorageItem("language");

    const navigate = useNavigate();
    const urlParams = useParams();

    const {
        mutateAsync: login,
        isLoading: isLoadingLogin,
        isError,
    } = mutationLogin();
    const { mutateAsync: checkCGU, isLoading: isLoadingCheckCGU } =
        mutationCheckCGU();

    const handleSubmit = async (
        values: Type_post_login,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        try {
            await getCsrfToken();

            const { data } = await login(values);

            if (!data.data.user.company_id) {
                navigate(
                    generatePath(`${URL_BASE}/add-company`, { ...urlParams }),
                    { replace: true },
                );
                return;
            }

            await handlePostLogin();
        } catch (e) {
            console.error("error", e);
        }
    };

    const handlePostLogin = async () => {
        try {
            await checkCGU();
            navigateToProjects();
        } catch (error) {
            redirectToCGU();
        }
    };

    const navigateToProjects = () => {
        const path = generatePath(`${URL_BASE}/projects`, { ...urlParams });
        navigate(path, { replace: true });
    };

    const redirectToCGU = () => {
        const lang = getLocalStorageItem("language");
        navigate(`/${lang}/cgu`, { replace: true });
    };

    const form = useForm<Type_post_login>({
        ...useFormDefaultConfig,
        defaultValues: {
            email: "",
            password: "",
        },
        resolver: yupResolver(Schema_LoginForm),
    });

    const isLoading =
        isLoadingLogin || isLoadingCheckCGU || form.formState.isSubmitting;

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Stack gap={2}>
                    <TextField
                        variant={"outlined"}
                        data-testid="Identification-email"
                        name="email"
                        label={fmt(`EmailLabel`)}
                        autoComplete="on"
                    />
                    <TextFieldPassword
                        name="password"
                        label={fmt(`PasswordLabel`)}
                        dataTestId="Identification-password"
                    />
                    {/*<Stack*/}
                    {/*    flexDirection="row"*/}
                    {/*    flexWrap="nowrap"*/}
                    {/*    justifyContent="end"*/}
                    {/*    alignItems="center"*/}
                    {/*>*/}
                    {/*    <Link*/}
                    {/*        to={`/${lang}/forget-password`}*/}
                    {/*        data-testid="Identification-link-forgot-password"*/}
                    {/*    >*/}
                    {/*        <Typography*/}
                    {/*            variant="body3Underline"*/}
                    {/*            color={"primary.main"}*/}
                    {/*        >*/}
                    {/*            {fmt("ForgotteddnPassword")}*/}
                    {/*        </Typography>*/}
                    {/*    </Link>*/}
                    {/*</Stack>*/}
                    <TMC_Button
                        data-testid="Identification-form-submit"
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                    >
                        {isLoading ? <Spinner style="button" /> : fmt("SignIn")}
                    </TMC_Button>
                    <Collapse in={isError}>
                        <Alert severity="error">
                            {fmt("ErrorIdentification")}
                        </Alert>
                    </Collapse>
                </Stack>
            </form>
        </FormProvider>
    );
};
