import { TabContext } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_project } from "src/api/tms-commons/projects/types";
import { TMC_Button } from "src/components";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { CreateProjectDrawer } from "src/drawers/project/CreateProjectDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Cards_ProjectsComponent } from "src/pages/Pages_Teamoty/Page_Projects/Cards";
import { COLORS } from "src/theme/stylesheet";

import { Type_modalDeleteProject } from "./Page_Projects.container";
import {
    Styled_TabListContainerProjects,
    Styled_TabListProjects,
    Styled_TabPanelProjects,
    Styled_TabProjects,
} from "./Page_Projects.style";

export type Type_Props_Page_ProjectsComponent = {
    projects: Type_index_project[];
    archivedProjects: Type_index_project[];
    favouritesProjects: Type_index_project[];
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (project: Type_index_project) => void;
    handleProjectArchived: (id: number, project: Type_index_project) => void;
    deleteProject: (id: number) => void;
    modalDeleteProject: Type_modalDeleteProject;
    setModalDeleteProject: Dispatch<SetStateAction<Type_modalDeleteProject>>;
};

export const Page_ProjectsComponent = ({
    projects,
    archivedProjects,
    addProjectToFavourites,
    deleteFavouriteProject,
    handleProjectStatus,
    favouritesProjects,
    handleProjectArchived,
    deleteProject,
    modalDeleteProject,
    setModalDeleteProject,
}: Type_Props_Page_ProjectsComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Projects");
    const page = "projects";

    enum ProjectTabsValues {
        DEFAULT = "allProjects",
        ALL_PROJECTS = "allProjects",
        ARCHIVED_PROJECTS = "archivedProjects",
    }
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const [value, setValue] = useState(ProjectTabsValues.DEFAULT);
    const handleChange = (
        event: React.SyntheticEvent,
        newValue: ProjectTabsValues,
    ) => {
        event.stopPropagation();
        setValue(newValue);
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteProject({ isOpen: false });
                deleteProject(modalDeleteProject?.card?.id as number);
            },
        },
    };

    return (
        <>
            <Stack
                direction="column"
                width="100%"
                paddingX={7}
                paddingTop={5}
                gap={4}
                sx={{ height: "calc(100vh - 70px)", overflowY: "auto" }}
            >
                <HeaderGrid>
                    <Typography variant="h5" color={"moon.500"}>
                        {fmt("FavouritesProjects")}
                    </Typography>
                    <Stack direction={"row"} gap={2}>
                        <AddButton
                            data-testid="ProjectsPage-AddOne-btn"
                            onClick={handleOpenDrawer}
                            page={page}
                            label={fmt("CtaCreate")}
                        />
                        <TMC_Button
                            onClick={() => null}
                            variant="outlined"
                            endIcon={
                                <Icon
                                    variant="solid"
                                    icon="arrow-up-arrow-down"
                                />
                            }
                        >
                            {fmt("CtaExport")}
                        </TMC_Button>
                    </Stack>
                </HeaderGrid>
                <Cards_ProjectsComponent
                    cards={favouritesProjects}
                    addProjectToFavourites={addProjectToFavourites}
                    deleteFavouriteProject={deleteFavouriteProject}
                    handleProjectStatus={handleProjectStatus}
                    handleProjectArchived={handleProjectArchived}
                    dataTestIdRef="Favorites"
                />

                <Box sx={{ width: "100%" }}>
                    <TabContext value={value}>
                        <Styled_TabListContainerProjects>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                flexWrap="nowrap"
                                alignItems="baseline"
                                gap={2}
                            >
                                <Styled_TabListProjects onChange={handleChange}>
                                    <Styled_TabProjects
                                        label={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={2}
                                            >
                                                {fmt("TabsLabel.AllProjects")}
                                                <TMC_Chip
                                                    colorText={COLORS.blue600}
                                                    backgroundColor={
                                                        COLORS.blue300
                                                    }
                                                    label={projects.length}
                                                />
                                            </Stack>
                                        }
                                        value={ProjectTabsValues.ALL_PROJECTS}
                                        disableRipple
                                    />
                                    <Styled_TabProjects
                                        label={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={2}
                                            >
                                                {fmt(
                                                    "TabsLabel.ArchiveProjects",
                                                )}
                                                <TMC_Chip
                                                    colorText={COLORS.moon500}
                                                    backgroundColor={
                                                        COLORS.moon200
                                                    }
                                                    label={
                                                        archivedProjects.length
                                                    }
                                                />
                                            </Stack>
                                        }
                                        value={
                                            ProjectTabsValues.ARCHIVED_PROJECTS
                                        }
                                        disableRipple
                                    />
                                </Styled_TabListProjects>
                                {/*<Stack*/}
                                {/*    direction="row"*/}
                                {/*    flexWrap="nowrap"*/}
                                {/*    alignItems="baseline"*/}
                                {/*    gap={2}*/}
                                {/*>*/}
                                {/*    <Typography*/}
                                {/*        variant="body3"*/}
                                {/*        color={COLORS.moon500}*/}
                                {/*    >*/}
                                {/*        {fmt("SortBy")}*/}
                                {/*    </Typography>*/}
                                {/*    <TMC_Button*/}
                                {/*        variant={"text"}*/}
                                {/*        sx={{ color: COLORS.blue700 }}*/}
                                {/*        endIcon={*/}
                                {/*            <Icon*/}
                                {/*                variant="solid"*/}
                                {/*                icon="arrow-up-arrow-down"*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <Typography*/}
                                {/*            variant="body3Underline"*/}
                                {/*            color={COLORS.blue700}*/}
                                {/*        >*/}
                                {/*            A-Z*/}
                                {/*        </Typography>*/}
                                {/*    </TMC_Button>*/}
                                {/*    <TMC_Button*/}
                                {/*        variant={"text"}*/}
                                {/*        sx={{ color: COLORS.blue700 }}*/}
                                {/*        endIcon={*/}
                                {/*            <Icon*/}
                                {/*                variant="solid"*/}
                                {/*                icon="arrow-up-arrow-down"*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <Typography*/}
                                {/*            variant="body3Underline"*/}
                                {/*            color={COLORS.blue700}*/}
                                {/*        >*/}
                                {/*            {fmt("CtaLastOpened")}*/}
                                {/*        </Typography>*/}
                                {/*    </TMC_Button>*/}
                                {/*    <TMC_Button*/}
                                {/*        variant={"text"}*/}
                                {/*        sx={{ color: COLORS.blue700 }}*/}
                                {/*        endIcon={*/}
                                {/*            <Icon*/}
                                {/*                variant="solid"*/}
                                {/*                icon="arrow-up-arrow-down"*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <Typography*/}
                                {/*            variant="body3Underline"*/}
                                {/*            color={COLORS.blue700}*/}
                                {/*        >*/}
                                {/*            {fmt("CtaStatus")}*/}
                                {/*        </Typography>*/}
                                {/*    </TMC_Button>*/}
                                {/*</Stack>*/}
                            </Stack>
                        </Styled_TabListContainerProjects>
                        <Styled_TabPanelProjects
                            value={ProjectTabsValues.ALL_PROJECTS}
                        >
                            <Cards_ProjectsComponent
                                cards={projects}
                                addProjectToFavourites={addProjectToFavourites}
                                deleteFavouriteProject={deleteFavouriteProject}
                                handleProjectStatus={handleProjectStatus}
                                handleProjectArchived={handleProjectArchived}
                                dataTestIdRef="List"
                            />
                        </Styled_TabPanelProjects>
                        <Styled_TabPanelProjects
                            value={ProjectTabsValues.ARCHIVED_PROJECTS}
                        >
                            <Cards_ProjectsComponent
                                cards={archivedProjects}
                                addProjectToFavourites={addProjectToFavourites}
                                deleteFavouriteProject={deleteFavouriteProject}
                                handleProjectStatus={handleProjectStatus}
                                handleProjectArchived={handleProjectArchived}
                                modalDeleteProject={modalDeleteProject}
                                setModalDeleteProject={setModalDeleteProject}
                                dataTestIdRef="Archived"
                            />
                        </Styled_TabPanelProjects>
                    </TabContext>
                </Box>
            </Stack>
            <CreateProjectDrawer
                onClose={handleCloseDrawer}
                open={openDrawer}
                page={page}
            />
            <ModalDelete
                open={modalDeleteProject.isOpen}
                onClose={() => setModalDeleteProject({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteProject?.card?.name as string}
                actions={actions}
            />
        </>
    );
};
