import React from "react";
import { Column } from "react-data-grid";

import { SelectColumn } from "src/components/Components_Common/MatrixDataGrid/Formatters/SelectorCell";

import { CellExpanderFormatter } from "./CellExpanderRenderer";

type Type_MatrixColumns_Props = {
    columns: Column<any>[];
    dispatch?: React.Dispatch<any>;
    enableDragDrop?: boolean;
    isLoading?: boolean;
    handleContextMenu?: (row: any, event: any) => void;
    hideSelectColumn?: boolean;
    hideIdColumn?: boolean;
};

export const MatrixColumns = ({
    columns,
    hideSelectColumn = false,
    hideIdColumn = false,
    dispatch,
    enableDragDrop = false,
    isLoading = false,
    handleContextMenu,
}: Type_MatrixColumns_Props): Column<any>[] => {
    const renderChevronCell = ({
        row,
        tabIndex,
    }: {
        row: any;
        tabIndex: number;
    }) => {
        const hasChildren = row.children.length > 0;
        const marginPercentage = row.depth * 20;

        const style = {
            marginInlineStart: marginPercentage,
            transition: "margin-inline-start 0.3s",
        };

        return (
            <>
                {hasChildren && (
                    <CellExpanderFormatter
                        style={style}
                        row={row}
                        tabIndex={tabIndex}
                        expanded={row.isExpanded === true}
                        onCellExpand={() =>
                            dispatch
                                ? dispatch({
                                      id: row.id,
                                      type: "toggleSubRow",
                                  })
                                : null
                        }
                    />
                )}
                <div className="rdg-cell-value">
                    <div style={style}>{row.id}</div>
                </div>
            </>
        );
    };

    // Add Select & ID Columns

    let _columns: Column<any>[] = [];
    if (!hideSelectColumn) {
        _columns.push(
            SelectColumn({ enableDragDrop, isLoading, handleContextMenu }),
        );
    }
    if (!hideIdColumn) {
        _columns.push({
            key: "id",
            name: "",
            width: 150,
            minWidth: 150,
            frozen: true,
            resizable: true,
            renderCell: renderChevronCell,
        });
    }

    _columns = [..._columns, ...columns];

    return _columns;
};
