import { useTheme } from "@mui/material";
import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import React, { useState } from "react";

import { mutationDeleteLicense } from "src/api/tms-commons/licenses";
import { Type_index_license } from "src/api/tms-commons/licenses/types";
import { StartDateEndDateCell } from "src/components/Components_Common/cellComponents/StartDateEndDateCell/StartDateEndDateCell";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import {
    Table,
    Type_column,
    Type_menuDropDown,
} from "src/components/Components_Common/Table/Table";
import { TMC_Button } from "src/components/index";
import { LicenseDrawer } from "src/drawers/licenses/LicenseDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_modalDeleteLicense = {
    isOpen: boolean;
    props?: Type_index_license;
};

type Type_Props_TableLicenses = {
    title: string;
    licenses: Type_index_license[];
    projectId: number;
};

export const Table_Licenses = ({
    title,
    licenses,
    projectId,
}: Type_Props_TableLicenses) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Licenses");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const [selectedLicense, setSelectedLicense] = useState<number | null>(null);

    const page = "licenses";

    const [modalDeleteLicense, setModalDeleteLicense] =
        useState<Type_modalDeleteLicense>({
            isOpen: false,
        });

    const { mutate: mutateDelete } = mutationDeleteLicense();

    const [openLicenseDrawer, setOpenLicenseDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleLicenseCreate = (): void => {
        setSelectedLicense(null);
        setAction("create");
        setOpenLicenseDrawer(true);
    };

    const handleLicenseEdit = (licenseTypeId: number): void => {
        setSelectedLicense(licenseTypeId);
        setAction("update");
        setOpenLicenseDrawer(true);
    };

    const handleCloseLicenseDrawer = (): void => {
        setOpenLicenseDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("LicenseTypeName"),
        },
        {
            field: "duration",
            headerName: fmtTableColumn("Duration"),
            width: 88,
        },
        {
            field: "startDate",
            headerName: fmtTableColumn("Dates"),
            sortable: false,
            colType: "dates",
            renderCell: (props: GridRenderCellParams) => {
                return (
                    <StartDateEndDateCell
                        startDate={props.row.startDate}
                        endDate={props.row.endDate}
                    />
                );
            },
        },
        {
            field: "renewal",
            headerName: fmtTableColumn("Renewal"),
        },
        {
            field: "cost",
            headerName: fmtTableColumn("Cost"),
        },
    ];

    const menuDropDown: Type_menuDropDown = {};

    menuDropDown.callbackEdit = (row: GridRowModel) => {
        handleLicenseEdit(row.id);
    };

    menuDropDown.callbackDelete =
        licenses.length === 1
            ? undefined
            : (row: GridRowModel) => {
                  setModalDeleteLicense({
                      isOpen: true,
                      props: row as Type_index_license,
                  });
              };

    const actions = {
        onSubmit: {
            onClick: () => {
                mutateDelete(modalDeleteLicense?.props?.id as number);
                setModalDeleteLicense({ isOpen: false });
            },
        },
    };

    const theme = useTheme();

    return (
        <>
            <SectionTitle title={title}>
                <TMC_Button
                    variant={"text"}
                    data-testid="TableLicense-AddNewLicense-btn"
                    onClick={handleLicenseCreate}
                    startIcon={
                        <Icon variant="solid" icon="plus" fontSize="small" />
                    }
                    sx={{
                        width: "fit-content",
                        blockSize: "fit-content",
                        color: theme?.palette?.card?.selected,
                    }}
                >
                    {fmt(`AddLicense`)}
                </TMC_Button>
            </SectionTitle>
            <Table
                rows={licenses}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: false,
                    dataName: fmt("LicenseDataName"),
                    action: handleLicenseCreate,
                }}
                onClick={(id: GridRowId) => {
                    handleLicenseEdit(parseInt(id as string));
                }}
                menuDropDown={menuDropDown}
                page={page}
            />
            <LicenseDrawer
                action={action}
                numberId={selectedLicense}
                onClose={handleCloseLicenseDrawer}
                open={openLicenseDrawer}
                projectId={projectId}
            />
            <ModalDelete
                open={modalDeleteLicense.isOpen}
                onClose={() => setModalDeleteLicense({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteLicense?.props?.name as string}
                actions={actions}
            />
        </>
    );
};
