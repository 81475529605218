import { getTaskColor } from "@cimba-digital-construction-solution/utils/dist/decoder/tools/getTaskColor";
import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import {
    Type_post_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { CompleteButtonRhf } from "src/components/Components_Common/forms/reactHookFormComponents/CompleteButtonRhf/CompleteButtonRhf";
import {
    HeaderToolbar,
    taskIcon,
} from "src/components/Components_Teamoty/contextualDrawers/HeaderToolbar/HeaderToolbar";
import { useLanguage } from "src/contexts/languages";
import { Styled_HeaderInput } from "src/layouts/Layout_ContextualDrawer/ContextualDrawer.style";
import {
    Header,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

type Type_Props_TaskAreaFormHeader = {
    taskArea: Type_show_taskArea;
    handleSubmit: (values: Type_post_taskArea) => void;
    onRefresh?: () => void;
};

export const taskLinearBackground = (bgColor: string) =>
    `linear-gradient(90deg, ${bgColor} 0%, ${invertColor(invertColor(bgColor))} 161%)`;

export const TaskAreaFormHeader = ({
    taskArea,
    handleSubmit,
    onRefresh,
}: Type_Props_TaskAreaFormHeader) => {
    const { languages } = useLanguage();
    const { register } = useFormContext();
    const { openPaper, closePaper } = useContextualDrawer();

    const localeToShortLocale = (locale: string) =>
        locale.slice(0, 2).toUpperCase();

    const backgroundColor = getTaskColor(
        taskArea.type!,
        taskArea.withTradeColor!,
        taskArea?.color || undefined,
        taskArea.subTrade?.trade.color,
    );

    const handleClickTask = (): void => {
        closePaper("taskArea");
        openPaper("task", { id: taskArea.taskId });
    };

    return (
        <Header
            data-testid="DrawerHeader"
            ownerState={{ borderBottom: true }}
            sx={{
                color: invertColor(backgroundColor),
                background: taskLinearBackground(backgroundColor),
            }}
        >
            <HeaderToolbar
                dataTestIdRef="TaskArea-Header"
                notes={"taskAreaNotes"}
                notesCount={taskArea.notesCount}
                onClose={() => closePaper("taskArea")}
                taskId={taskArea.taskId}
                areaId={taskArea.areaId}
                taskAreaId={taskArea.id}
                showFlagObservations={true}
                onRefresh={onRefresh}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={(theme) => ({
                        opacity: theme.opacity.mid,
                    })}
                >
                    {taskIcon[taskArea.type as number]}
                    <Typography
                        data-testid={"Toolbar-Header-Task-Name"}
                        onClick={handleClickTask}
                        color={"inherit"}
                        variant="body3Medium"
                        sx={{
                            ":hover": {
                                cursor: "pointer",
                                textDecoration: "underline",
                            },
                        }}
                    >
                        {taskArea.taskName}
                    </Typography>
                </Stack>
            </HeaderToolbar>
            <Stack direction={"row"} alignItems={"start"}>
                <Stack flexGrow={1}>
                    {languages.map((language, index) => (
                        <Grid container key={index} rowSpacing={0.5}>
                            <Grid item xs={1}>
                                {index === 0 ? ( // TODO should be refactored
                                    <CompleteButtonRhf
                                        name="progressValue"
                                        task={taskArea}
                                        taskType={taskArea.type as number}
                                        backgroundColor={backgroundColor}
                                        handleSubmit={handleSubmit}
                                    />
                                ) : (
                                    <Typography
                                        variant={"body3"}
                                        paddingInlineStart={3}
                                        sx={(theme) => ({
                                            opacity: theme.opacity.mid,
                                        })}
                                    >
                                        {localeToShortLocale(language)}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={11} paddingInlineStart={2}>
                                <Styled_HeaderInput
                                    {...register(`names.${language}`)}
                                    data-testid={`taskArea-drawer-input-name-${language}`}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
            </Stack>
        </Header>
    );
};
