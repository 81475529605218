import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Form, FormProvider, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";

import { Type_dialogParamsDeprecated } from "src/components/Components_Common/Dialog/DialogGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { AutocompleteUnits } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteUnits";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Type_createNewProduct } from "./ProductTypesTaskForm";

type Type_Props_PeopleTaskFormDialog = {
    setIsFormLoading: Dispatch<SetStateAction<boolean>>;
    dialogParams: Type_dialogParamsDeprecated;
    formId: string;
    productTaskValue: string;
    createNewProduct: (data: Type_createNewProduct) => Promise<void>;
    onClose: () => void;
};

const newProductTypeValidationSchema = Yup.object().shape({
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    unit: Yup.object()
        .shape({
            name: Yup.string(),
            id: Yup.number(),
        })
        .required(FORM_ERR_FMT.REQUIRED),
});

export const ProductTypeTaskFormDialog = ({
    setIsFormLoading,
    dialogParams,
    formId,
    productTaskValue,
    onClose,
    createNewProduct,
}: Type_Props_PeopleTaskFormDialog) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");
    const language = getLocalStorageItem("language");
    const form = useForm<any>({
        defaultValues: {
            names: {
                [language]: productTaskValue,
            },
        },
        mode: "onSubmit",
        resolver: yupResolver<any>(newProductTypeValidationSchema),
    });

    const unit = useWatch({
        name: "unit",
        defaultValue: null,
        control: form.control,
    });

    const onSubmit = async () => {
        setIsFormLoading(true);
        if (dialogParams.action === "create") {
            await createNewProduct({
                ...form.getValues(),
                unit_id: unit.id,
            });
        } else console.log("ProductTypeTaskFormDialog on submit update");
        setIsFormLoading(false);
        onClose();
    };

    return (
        <FormProvider {...form}>
            <Form onSubmit={() => onSubmit()} id={formId}>
                <Stack gap={2} direction="column" sx={{ flexGrow: 1 }}>
                    <InputMultiLanguages
                        name="names"
                        label={fmtLabels("Translation")}
                    />
                    <AutocompleteUnits
                        data-testid={`product-type-unit`}
                        label={fmtLabels("Units")}
                        renderInputProps={{
                            variant: "outlined",
                        }}
                        name="unit"
                    />
                </Stack>
            </Form>
        </FormProvider>
    );
};
