import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IntlProvider } from "react-intl";

import {
    countryCodeLanguageRtl,
    countryCodeLanguages,
    languageFiles,
} from "src/assets/translations";
import { flattenMessages, NestedDictionary } from "src/utils/intl";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";
import {
    PathElement,
    redirectTo,
    replaceUrlField,
} from "src/utils/urlFormatter";

export const DEFAULT_LANGUAGE: string = "en_GB";
const NAVIGATOR_LANG: string = navigator.language.replace(/-/g, "_");
const STORAGE_LANG: string = getLocalStorageItem("language") || "";
const URL_LANG: string = window.location.pathname.split("/")[1];

type InitialStateType = {
    language: string;
    languages: typeof countryCodeLanguages;
    switchLanguage: (lang: string) => void;
    setLanguages: (languages: typeof countryCodeLanguages) => void;
};

// Valeurs initiales du contexte
const initialLanguagesState: InitialStateType = {
    language: ((): string => {
        if (countryCodeLanguages.includes(STORAGE_LANG)) return STORAGE_LANG;
        else if (countryCodeLanguages.includes(NAVIGATOR_LANG))
            return NAVIGATOR_LANG;
        return DEFAULT_LANGUAGE;
    })(),
    languages: countryCodeLanguages,
    switchLanguage: () => {},
    setLanguages: () => {},
};

type LanguagesProviderType = {
    children: JSX.Element;
};

// Création du contexte à partir des valeurs initiales
export const LanguagesContext = createContext(initialLanguagesState);

export const useLanguage = () => {
    const context = useContext(LanguagesContext);
    if (!context) {
        throw new Error(
            "useLanguage must be used within a LanguageContextProvider",
        );
    }
    return context;
};

// Création du Provider fournissant le contexte (Valeurs, et fonction de modifications)
export const LanguagesProvider = ({ children }: LanguagesProviderType) => {
    const [state, setState] = useState<InitialStateType>(initialLanguagesState);

    // Fonctions exportées par le contexte
    const languagesFunc = useMemo(() => {
        return {
            // Modifie la langue courante, la stock le localstorage, puis redirige.
            switchLanguage: (language: string) =>
                setState((Prev: InitialStateType) => {
                    setLocalStorageItem("language", language);
                    if (
                        document.dir === "ltr" &&
                        countryCodeLanguageRtl.includes(language)
                    )
                        document.dir = "rtl";
                    else if (
                        document.dir === "rtl" &&
                        !countryCodeLanguageRtl.includes(language)
                    )
                        document.dir = "ltr";
                    document.documentElement.lang = language.split("_")[0];
                    if (language !== URL_LANG)
                        redirectTo(replaceUrlField(PathElement.LANG, language));
                    return {
                        ...Prev,
                        language,
                    };
                }),

            setLanguages: (languages: typeof countryCodeLanguages) =>
                setState((prevStateLanguages: InitialStateType) => ({
                    ...prevStateLanguages,
                    languages,
                })),
        };
    }, []);

    // State du contexte avec les variables et les fonctions
    const languagesState: typeof initialLanguagesState = useMemo(() => {
        return {
            language: state.language,
            languages: state.languages,
            ...languagesFunc,
        };
    }, [state.language, state.languages, languagesFunc]);

    // Lors du chargement de la page utilise la dernière langue sélectionnée
    // Ou sélectionne la langue du navigateur si celle ci est prise en charge
    // Si non, on met en anglais
    useEffect(() => {
        if (countryCodeLanguages.includes(URL_LANG))
            languagesState.switchLanguage(URL_LANG);
        else if (countryCodeLanguages.includes(STORAGE_LANG))
            languagesState.switchLanguage(STORAGE_LANG);
        else if (countryCodeLanguages.includes(NAVIGATOR_LANG))
            languagesState.switchLanguage(NAVIGATOR_LANG);
        else languagesState.switchLanguage(DEFAULT_LANGUAGE);
    }, []);

    return (
        <LanguagesContext.Provider value={languagesState}>
            <IntlProvider
                locale={languagesState.language.replace(/_/g, "-")}
                messages={flattenMessages(
                    languageFiles[languagesState.language] as NestedDictionary,
                )}
            >
                {children}
            </IntlProvider>
        </LanguagesContext.Provider>
    );
};
