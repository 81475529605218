import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Type_index_resourceType } from "src/api/tms-projects/resourceTypes/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ResourceTypeDrawer } from "src/drawers/resourceTypes/ResourceTypeDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteResourceType } from "src/pages/Pages_Teamoty/Project/Settings/Page_ResourceTypes/Page_ResourceTypes.container";

export type Type_Props_ResourceTypesComponent = {
    resourceTypes: Type_index_resourceType[];
    isFetching: boolean;
    changeStatus: (resourceType: any) => void;
    removeResourceType: (id: number) => void;
    modalDelete: Type_modalDeleteResourceType;
    setModalDelete: Dispatch<SetStateAction<Type_modalDeleteResourceType>>;
};

export const Page_ResourceTypesComponent = ({
    resourceTypes,
    isFetching,
    changeStatus,
    removeResourceType,
    modalDelete,
    setModalDelete,
}: Type_Props_ResourceTypesComponent) => {
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.ResourceTypes",
    );
    const page = "resourceTypes";

    const [selectedResourceType, setSelectedResourceType] = useState<
        number | null
    >(null);
    const [openResourceTypeDrawer, setOpenResourceTypeDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (id: number | null, action: Type_action) => {
        setSelectedResourceType(id);
        setAction(action);
        setOpenResourceTypeDrawer(true);
    };
    const handleResourceTypeCreate = () => openDrawer(null, "create");

    const handleResourceTypeEdit = (id: number) => openDrawer(id, "update");

    const handleResourceTypeDuplicate = (id: number) =>
        openDrawer(id, "duplicate");

    const handleCloseResourceTypeDrawer = () => {
        setOpenResourceTypeDrawer(false);
    };

    const callbackEdit = (row: GridRowModel) => {
        handleResourceTypeEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleResourceTypeDuplicate(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_resourceType);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDelete({
            isOpen: true,
            props: row as Type_index_resourceType,
        });
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("ResourceType"),
        },
        {
            field: "resourceModelName",
            headerName: fmtTableColumn("ResourceModel"),
        },
    ];

    // Temporairement caché, remettre resourceTypes plus tard
    const resourceTypesWithoutSharedResourceTypes = useMemo(
        () =>
            resourceTypes?.filter((resourceType) => resourceType.subProject) ||
            [],
        [resourceTypes],
    );

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="ResourceType-AddOne-btn"
                    onClick={() => handleResourceTypeCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={resourceTypesWithoutSharedResourceTypes}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleResourceTypeCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleResourceTypeEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackStatus,
                    callbackDelete,
                    callbackEdit,
                    callbackDuplicate,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ResourceTypeDrawer
                open={openResourceTypeDrawer}
                onClose={handleCloseResourceTypeDrawer}
                resourceTypeId={selectedResourceType}
                page={page}
                action={action}
            />
            <ModalDelete
                open={modalDelete.isOpen}
                onClose={() => setModalDelete({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDelete?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDelete({ isOpen: false });
                            removeResourceType(
                                modalDelete?.props?.id as number,
                            );
                        },
                    },
                }}
            />
        </>
    );
};
