import {
    Type_com_index_unit,
    Type_com_post_unit,
    Type_com_put_unit,
    Type_com_selectList_unit,
    Type_com_show_unit,
    Type_index_unit,
    Type_post_unit,
    Type_put_unit,
    Type_selectList_unit,
    Type_show_unit,
} from "src/api/tms-commons/units/types";

export const formatterIndexUnits = (
    units: Type_com_index_unit[],
): Type_index_unit[] => {
    return units.map((unit: Type_com_index_unit) => {
        return formatterIndexUnit(unit);
    });
};
export const formatterIndexUnit = (
    unit: Type_com_index_unit,
): Type_index_unit => {
    return {
        id: unit.id,
        name: unit.name,
        symbol: unit.symbol,
        unitType: unit.unitType,
        unitTypeName: unit.unitType.name,
        enabled: unit.enabled,
    };
};

export const formatterShowUnit = (unit: Type_com_show_unit): Type_show_unit => {
    return {
        id: unit.id,
        names: unit.names,
        unitType: unit.unitType.id,
        symbols: unit.symbols,
        enabled: unit.enabled,
    };
};

export const formatterCreateUnit = (
    unit: Type_post_unit,
): Type_com_post_unit => {
    return {
        names: unit.names,
        symbols: unit.symbols,
        unit_type_id: unit.unitType,
    };
};

export const formatterUpdateUnit = (
    unit: Type_show_unit,
): Type_com_put_unit => {
    return {
        names: unit.names,
        symbols: unit.symbols,
        unit_type_id: unit.unitType,
    };
};

export const formatterSelectListUnit = (
    data: Type_com_selectList_unit[],
): Type_selectList_unit[] => {
    if (!data) return [];
    return data.map((item) => ({
        id: item.id,
        name: item.name,
    }));
};

export const formatterStatusUnit = (unit: Type_put_unit): Type_com_put_unit => {
    return {
        enabled: unit.enabled,
    };
};
