import { useMutation, useQuery } from "react-query";

import { PunchlistKeys } from "src/api/tms-commons/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterCreatePunchlist,
    formatterIndexPunchlists,
} from "./formatters";
import { createPunchlist, indexPunchlists } from "./services";
import { Type_post_punchlist, Type_sch_index_punchlist } from "./types";

export const useIndexPunchlists = (areaId: number, taskId: number) => {
    const { requestConfig } = useProject();

    const isEnabled: boolean =
        !!requestConfig.projectId &&
        !!requestConfig.subProjectId &&
        !!requestConfig.versionId &&
        !!taskId;

    return useQuery({
        queryKey: [PunchlistKeys.INDEX, requestConfig, areaId, taskId],
        queryFn: () =>
            indexPunchlists({
                ...requestConfig,
                areaId: areaId,
                taskId: taskId,
            }),
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        select: (resp) => {
            return formatterIndexPunchlists(
                resp?.data?.data as Type_sch_index_punchlist[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationCreatePunchlist = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Punchlist");
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning, addSuccess } = useToast();

    return useMutation({
        mutationFn: (punchlist: Type_post_punchlist) =>
            createPunchlist(formatterCreatePunchlist(punchlist), {
                ...requestConfig,
                areaId: punchlist.areaId,
                taskId: punchlist.taskId,
            }),
        onSuccess: (resp): void => {
            if (resp.success) {
                sendEvent("postPunchlist");
                addSuccess({
                    description: fmt("ToastSuccess"),
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
