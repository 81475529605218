import { Stack } from "@mui/material";
import {
    DataGridProProps,
    GridRenderCellParams,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Type_index_subTrade } from "src/api/tms-projects/subTrades/types";
import {
    mutationImportTrades,
    useExportTrades,
} from "src/api/tms-projects/trades";
import { Type_index_trade } from "src/api/tms-projects/trades/types";
import { TMC_Button, TMC_ExportImportDropDown } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import { Type_menuDropDown } from "src/components/Components_Common/Table/Table";
import { TreeTable } from "src/components/Components_Common/TreeTable/TreeTable";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { TradeCell } from "src/components/Components_Teamoty/cells";
import { useProject } from "src/contexts/project";
import { SubTradeDrawer } from "src/drawers/subTrades/SubTradeDrawer";
import { TradeDrawer } from "src/drawers/trades/TradeDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteTrade } from "src/pages/Pages_Teamoty/Project/Settings/Page_Trades/Page_Trades.container";

export type Type_Props_PageTradesComponent = {
    trades: Type_index_trade[];
    isFetching: boolean;
    changeStatusTrade: (trade: Type_index_trade) => void;
    changeStatusSubTrade: (subTrade: Type_index_subTrade) => void;
    modalDelete: Type_modalDeleteTrade;
    setModalDelete: Dispatch<SetStateAction<Type_modalDeleteTrade>>;
    deleteTrade: (id: number) => void;
    deleteSubTrade: (id: number, tradeId: number) => void;
    tradesColors?: string[];
};

export const Page_TradesComponent = ({
    trades,
    isFetching,
    changeStatusTrade,
    changeStatusSubTrade,
    modalDelete,
    setModalDelete,
    deleteTrade,
    deleteSubTrade,
    tradesColors,
}: Type_Props_PageTradesComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Trades",
    );
    const { formatMessageWithPartialKey: fmtTable } = useCoreIntl("Table");
    const page = "trades";
    const { requestConfig } = useProject();

    const [selectedTrade, setSelectedTrade] = useState<number | null>(null);
    const [selectedSubTrade, setSelectedSubTrade] = useState<number | null>(
        null,
    );
    const [openTradeDrawer, setOpenTradeDrawer] = useState(false);
    const [openSubTradeDrawer, setOpenSubTradeDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");
    const [actionSubTrade, setActionSubTrade] = useState<Type_action>("");

    const { refetch, isFetching: isExportingTrades } = useExportTrades();
    const {
        mutateAsync: mutateImportTrades,
        error: importErrors,
        isLoading: isImportingTrades,
        reset: resetImportTradesState,
    } = mutationImportTrades();

    const handleExport = () => {
        refetch();
    };

    const openDrawerTrade = (id: number | null, action: Type_action) => {
        setSelectedTrade(id);
        setAction(action);
        setOpenTradeDrawer(true);
    };
    const handleTradeCreate = () => openDrawerTrade(null, "create");
    const handleTradeEdit = (row: GridRowModel) =>
        openDrawerTrade(row.id, "update");
    const handleCloseTradeDrawer = () => {
        setOpenTradeDrawer(false);
    };

    const openDrawerSubTrade = (
        tradeId: number | null,
        subTradeId: number | null,
        action: Type_action,
    ) => {
        setSelectedTrade(tradeId);
        setSelectedSubTrade(subTradeId);
        setActionSubTrade(action);
        setOpenSubTradeDrawer(true);
    };

    const handleSubTradeCreate = (tradeId: number) =>
        openDrawerSubTrade(tradeId, null, "create");

    const handleSubTradeEdit = (tradeId: number, subTradeId: number) => {
        handleCloseTradeDrawer();
        openDrawerSubTrade(tradeId, subTradeId, "update");
    };

    const handleCloseSubTradeDrawer = () => {
        setOpenSubTradeDrawer(false);
    };

    const dropDownItems: Type_menuDropDown = {
        callbackEdit: (row: GridRowModel) => {
            row.subTrades
                ? handleTradeEdit(row)
                : handleSubTradeEdit(row.trade_id, row.id);
        },
        callbackStatus: (row: GridRowModel) => {
            row.subTrades
                ? changeStatusTrade(row as Type_index_trade)
                : changeStatusSubTrade(row as Type_index_subTrade);
        },
        callbackDelete: (row: GridRowModel) =>
            setModalDelete({
                isOpen: true,
                props: row as Type_index_trade,
            }),
    };

    const columns: DataGridProProps["columns"] = [
        {
            field: "team",
            flex: 1,
            headerName: fmtTable("Column.Team"),
        },
        {
            field: "addTrade",
            headerName: "",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const show =
                    params.row.subTrades &&
                    params.row.enabled &&
                    (requestConfig.subProjectId ? params.row.subProject : true);

                if (show)
                    return (
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            height={"100%"}
                        >
                            <TMC_Button
                                variant={"text"}
                                data-testid="SubTrade-AddOne-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleSubTradeCreate(params.row.id);
                                }}
                                startIcon={<Icon variant="solid" icon="plus" />}
                            >
                                {fmt("AddSubTrade")}
                            </TMC_Button>
                        </Stack>
                    );
            },
        },
    ];

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDelete({ isOpen: false });
                if (
                    modalDelete?.props?.subTrades &&
                    modalDelete?.props?.subTrades?.length > 0
                ) {
                    deleteTrade(modalDelete?.props?.id as number);
                } else {
                    deleteSubTrade(
                        modalDelete.props.id as number,
                        modalDelete.props.trade_id as number,
                    );
                }
            },
        },
    };

    const tradesWithoutSharedTrades = useMemo(() => {
        let res = trades || [];

        // Temporairement caché, remettre trades dans rows pour remettre
        res = res.filter((trade) => trade.subProject);

        // Maps pour désactiver les subTrades si le trade est désactivé
        return res.map((trade) => {
            if (trade.enabled) {
                return trade;
            } else {
                return {
                    ...trade,
                    subTrades: trade.subTrades?.map((subtrade) => ({
                        ...subtrade,
                        hasParentDisabled: true,
                        enabled: false,
                    })),
                };
            }
        });
    }, [trades]);

    return (
        <>
            <HeaderGrid>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                    <TMC_ExportImportDropDown
                        title={fmt(`ImportTrades`)}
                        importTypes={["xlsx", "xls"]}
                        exportType="xlsx"
                        isLoading={isExportingTrades || isImportingTrades}
                        errors={importErrors}
                        onAbort={resetImportTradesState}
                        onExport={handleExport}
                        onImport={mutateImportTrades}
                    />
                    <AddButton
                        data-testid="Trades-AddOne-btn"
                        onClick={handleTradeCreate}
                        variant="contained"
                        startIcon={<Icon variant="solid" icon="plus" />}
                        page={page}
                    />
                </Stack>
            </HeaderGrid>
            <TreeTable
                treeData
                columns={columns}
                data={tradesWithoutSharedTrades}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: handleTradeCreate,
                }}
                menuDropDown={dropDownItems}
                onClick={(row: GridRowModel) => {
                    if (row.subTrades && actionSubTrade !== "create") {
                        handleTradeEdit(row);
                    } else {
                        handleSubTradeEdit(row.trade_id, row.id);
                    }
                }}
                page={page}
                hierarchy={"subTrades"}
                groupHeaderName={fmtTable("Column.Trades")}
                groupingCellComponent={(row) => <TradeCell row={row} />}
            />

            <TradeDrawer
                open={openTradeDrawer}
                onClose={handleCloseTradeDrawer}
                tradeId={selectedTrade}
                page={page}
                action={action}
                tradesColors={tradesColors}
            />
            <SubTradeDrawer
                tradeId={selectedTrade}
                open={openSubTradeDrawer}
                onClose={handleCloseSubTradeDrawer}
                subTradeId={selectedSubTrade}
                page={page}
                action={actionSubTrade}
            />
            <ModalDelete
                open={modalDelete.isOpen}
                onClose={() => setModalDelete({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDelete?.props?.name as string}
                actions={actions}
            />
        </>
    );
};
