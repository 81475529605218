import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { KeyDatesPath, Url } from "src/api/paths";

import { Type_sch_post_keyDate, Type_sch_put_keyDate } from "./types";

export const createKeyDate = (
    data: Type_sch_post_keyDate,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${KeyDatesPath.KEY_DATES}`,
        requestConfig,
    );
};

export const indexKeyDates = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${KeyDatesPath.KEY_DATES}`, requestConfig);
};

export const showKeyDate = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${KeyDatesPath.KEY_DATES}/${id}`,
        requestConfig,
    );
};

export const updateKeyDate = (
    id: number,
    data: Type_sch_put_keyDate,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${KeyDatesPath.KEY_DATES}/${id}`,
        requestConfig,
    );
};
