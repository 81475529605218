import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { LicenseTypeKeys } from "src/api/tms-commons/keys";
import {
    createLicenseType,
    deleteLicenseType,
    indexLicenseTypes,
    indexLicenseTypesModes,
    selectListLicenseTypes,
    showLicenseType,
    statusLicenseType,
    updateLicenseType,
} from "src/api/tms-commons/licenseTypes/services";
import {
    Type_put_licenseType,
    Type_show_licenseType,
} from "src/api/tms-commons/licenseTypes/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterIndexLicenseType,
    formatterIndexLicenseTypes,
    formatterIndexLicenseTypesMode,
    formatterPostLicenseType,
    formatterPutLicenseType,
    formatterSelectListLicenseTypes,
    formatterShowLicenseType,
    formatterStatusLicenseType,
} from "./formatters";

export const useIndexLicenseTypes = () => {
    return useQuery({
        queryKey: [LicenseTypeKeys.INDEX],
        queryFn: indexLicenseTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexLicenseTypes");
            }
            return formatterIndexLicenseTypes(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useSelectListLicenseTypes = (params?: string) => {
    return useQuery({
        queryKey: [LicenseTypeKeys.SELECT_LIST, params],
        queryFn: () => selectListLicenseTypes(params),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListLicenseTypes");
            }

            return formatterSelectListLicenseTypes(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useIndexLicenseTypesModes = () => {
    return useQuery({
        queryKey: [LicenseTypeKeys.INDEX_MODES],
        queryFn: indexLicenseTypesModes,
        refetchOnWindowFocus: false,
        onError: (err) => {
            return err;
        },
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexLicenseTypesModes");
            }

            return formatterIndexLicenseTypesMode(data.data.data);
        },
    });
};

export const mutationCreateLicenseType = () => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.LicenseTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newLicenseType: Type_show_licenseType) =>
            createLicenseType(formatterPostLicenseType(newLicenseType)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateLicenseType");
            }

            const newLicenceType = formatterIndexLicenseType(data.data.data);

            await queryClient.invalidateQueries([LicenseTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        licenseType: newLicenceType.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateLicenseType = () => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.LicenseTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (licenseTypeToUpdate: Type_show_licenseType) =>
            updateLicenseType(
                licenseTypeToUpdate.id,
                formatterPutLicenseType(licenseTypeToUpdate),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateLicenseType");
            }

            await queryClient.invalidateQueries([LicenseTypeKeys.INDEX]);

            const newLicenceType = formatterIndexLicenseType(data.data.data);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        licenseType: newLicenceType.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusLicenseType = () => {
    const { addSuccess, addWarning } = useToast();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.LicenseTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newStatus: Type_put_licenseType) => {
            return statusLicenseType(
                newStatus.id,
                formatterStatusLicenseType(newStatus),
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusLicenseType");
            }

            const newLicenceType = formatterIndexLicenseType(data.data.data);

            await queryClient.invalidateQueries([LicenseTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        licenseType: newLicenceType.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteLicenseType = () => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.LicenseTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => {
            return deleteLicenseType(id);
        },
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteLicenseType");
            }

            await queryClient.invalidateQueries([LicenseTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessDelete", {}),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowLicenseType = (id: number) => {
    return useQuery({
        queryKey: [LicenseTypeKeys.SHOW, id],
        queryFn: () => showLicenseType(id),
        refetchOnWindowFocus: false,
        select: (data): Type_show_licenseType => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowLicenseType");
            }

            return formatterShowLicenseType(data.data.data);
        },
        onError: (err: any) => {
            return err;
        },
        enabled: !!id,
    });
};
