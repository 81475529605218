import { FormLabel, TextField as MuiTextfield, Stack } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import * as React from "react";

import {
    Type_DataTestIdComponent,
    Type_ReadonlyComponent,
} from "src/components/Components_Common/types";

export type Type_Props_TextField = TextFieldProps &
    Type_ReadonlyComponent &
    Type_DataTestIdComponent;

export const TextField = ({
    label,
    readonly = false,
    dataTestId,
    ...props
}: Type_Props_TextField) => {
    return (
        <Stack gap={0.5}>
            {label && <FormLabel htmlFor={props.name}>{label}</FormLabel>}
            <MuiTextfield
                id={props.name}
                {...props}
                disabled={readonly || props.disabled}
                inputProps={{
                    "data-testid": dataTestId,
                    ...props.inputProps,
                }}
            />
        </Stack>
    );
};
