import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Type_get_me } from "src/api/tms-gateway/auth/types";
import { Type_put_user } from "src/api/tms-users/users/types";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_UserInfosForm = Yup.lazy(() => {
    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .email(FORM_ERR_FMT.MUST_BE_EMAIL)
            .required(FORM_ERR_FMT.REQUIRED),
        lastName: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
        firstName: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
        phone: Yup.string().nullable(),
    });
});

type Type_Props_UserInfosForm = {
    onSubmit: (values: Type_put_user) => void;
    user: Type_get_me | null;
    formId: string;
};

export const UserInfosForm = ({
    onSubmit,
    user,
    formId,
}: Type_Props_UserInfosForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MyAccount.Profile");

    const handleSubmit = async (
        values: Type_put_user,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault;
        onSubmit(values);
    };

    const form = useForm({
        defaultValues: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phone: user?.phone,
        },
        resolver: yupResolver<any>(Schema_UserInfosForm),
    });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={formId}>
                <Stack gap={2}>
                    <TextField name="firstName" label={fmt(`Firstname`)} />
                    <TextField name="lastName" label={fmt(`Lastname`)} />
                    <TextField name="email" type="email" label={fmt(`Email`)} />
                    <TextField name="phone" type="tel" label={fmt(`Phone`)} />
                </Stack>
            </form>
        </FormProvider>
    );
};
