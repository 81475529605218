import { Type_api_selectList, Type_selectList } from "src/api/types";

export const formatterSelectList = (
    selectList: Type_api_selectList[],
): Type_selectList[] => {
    return selectList.map((selectListItem: Type_api_selectList) => ({
        id: selectListItem.id,
        name: selectListItem.name,
    }));
};
