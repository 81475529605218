import {
    DataGridProProps,
    GridColDef,
    GridRowModel,
} from "@mui/x-data-grid-pro";

import { DataGridVersionComponent } from "src/components/Components_Teamoty/DataGridVersion/DataGridVersion.component";
import {
    DataGridDropDown,
    Type_ActionMenu_Item,
} from "src/components/Components_Teamoty/DataGridVersion/DropDownMenu/DataGridDropDownMenu";

export type Type_DataGrid_Props = DataGridProProps & {
    name: string;
    rows: GridRowModel[];
    handleAddNewRow?: () => void;
    columns: GridColDef[];
    isFetching?: boolean;
    dropDownItems?: Type_ActionMenu_Item[];
    canAddNewRow?: boolean;
    newButtonText?: string;
};

export const DataGridVersionContainer = (props: Type_DataGrid_Props) => {
    const actionColumn: GridColDef = {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        flex: 0.04,
        align: "right",
        renderCell: (params: any) => (
            <DataGridDropDown
                name={props.name}
                params={params}
                items={props.dropDownItems || []}
            />
        ),
    };

    const updatedColumns = props.dropDownItems
        ? [...props.columns, actionColumn]
        : props.columns;

    return <DataGridVersionComponent {...props} columns={updatedColumns} />;
};
