import { FormikValues } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { VersionKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexVersion,
    formatterIndexVersions,
    formatterMutateVersion,
    formatterShowVersion,
} from "src/api/tms-projects/versions/formatters";
import {
    createVersion,
    deleteVersion,
    indexVersions,
    selectListVersions,
    showVersion,
    updateVersion,
} from "src/api/tms-projects/versions/services";
import {
    Type_prj_index_version,
    Type_prj_show_version,
} from "src/api/tms-projects/versions/types";

export const useIndexVersions = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useQuery({
        queryKey: [VersionKeys.INDEX, requestConfig],
        queryFn: () => indexVersions(requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const versions: Type_prj_index_version[] = data?.data?.data;
            versions ? callback(formatterIndexVersions(versions)) : null;
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowVersion = (
    callback: any,
    requestConfig: Type_RequestConfig,
    id: number,
) => {
    return useQuery(
        [VersionKeys.SHOW, id],
        () => showVersion(id, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                const version: Type_prj_show_version = res.data.data;
                callback(formatterShowVersion(version));
            },
            onError: (err) => {
                console.error(err);
                return err;
            },
        },
    );
};

export const mutationCreateVersion = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useMutation({
        mutationFn: (version: FormikValues) =>
            createVersion(
                formatterMutateVersion(version as Type_prj_index_version),
                requestConfig,
            ),
        onSuccess: async (data: any, _newVersion: FormikValues) => {
            if (data.success) {
                const version: Type_prj_index_version = data.data.data;
                callback(formatterIndexVersion(version));
            }
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationUpdateVersion = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (versionToUpdate: any) =>
            updateVersion(
                formatterMutateVersion(versionToUpdate),
                requestConfig,
            ),
        onSuccess: async (_response) => {
            /* INFO: The callback is not necessary here because we are going to call indexVersions. */
            // const _version: Type_prj_put_version = _response.data.data;
            // callback(_version);
            await queryClient.invalidateQueries({
                queryKey: [VersionKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useDeleteVersion = (
    callback: (id: number) => void,
    requestConfig: Type_RequestConfig,
) => {
    return useMutation({
        mutationFn: (id: number) => deleteVersion(id, requestConfig),
        onSuccess: (_data, id) => {
            callback(id);
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useSelectListVersion = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useQuery({
        queryKey: [VersionKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListVersions(requestConfig),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            data?.data?.data ? callback(data?.data?.data) : null;
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};
