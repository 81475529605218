import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { USER_ORIGIN, USER_STATUS } from "src/api/tms-users/users/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { displayFormattedDate } from "src/utils/date";

type Type_Props_UserActivityCell = {
    props: GridRenderCellParams;
};

export const UserActivityCell = ({ props }: Type_Props_UserActivityCell) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Teamoty.Users");

    const { project } = useProject();
    const { lastActivityDate, status, origin } = props.row;

    const isInvited =
        status === USER_STATUS.INVITED && origin === USER_ORIGIN.INVITATION;
    const isPending = status === USER_STATUS.TO_VALIDATE && !!project;

    if (lastActivityDate) {
        return <>{displayFormattedDate(lastActivityDate)}</>;
    }
    if (isInvited) {
        return (
            <TMC_Chip
                label={fmt("Invited")}
                size={"small"}
                backgroundColor={"primary.main"}
                colorText={"primary.contrastText"}
            />
        );
    } else if (isPending) {
        return (
            <TMC_Chip
                label={fmt("Pending")}
                size={"small"}
                backgroundColor={"error.main"}
                colorText={"error.contrastText"}
            />
        );
    }
    return <>-</>;
};
