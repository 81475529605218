import { FormikValues } from "formik";
import React, { useMemo, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationCreateVersionNote,
    mutationUpdateVersionNote,
    useDeleteVersionNote,
    useIndexVersionNotes,
} from "src/api/tms-projects/notes";
import {
    Type_index_version_note,
    Type_prj_put_version_note,
} from "src/api/tms-projects/notes/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Version_Notes_DrawerContentComponent } from "./Version_Notes_DrawerContent.component";

export type Version_DrawerContentContainerPropsType = {
    onClose: () => void;
    versionId: number;
    submitStep?: number | undefined;
    requestConfig: Type_RequestConfig;
};

export const Version_Notes_DrawerContentContainer = ({
    onClose,
    versionId,
    requestConfig,
}: Version_DrawerContentContainerPropsType) => {
    const { add } = useToast();

    const [notes, setNotes] = useState<Type_index_version_note[]>([]);

    const addNote = (note: Type_index_version_note) => {
        setNotes((prevNotes) => {
            return [note, ...prevNotes];
        });
    };

    const updateNote = (note: Type_index_version_note) => {
        setNotes((prevNotes) => {
            const updatedNotes = prevNotes.map((v) => {
                if (v.id === note.id) {
                    return note;
                }
                return v;
            });
            return [...updatedNotes];
        });
    };

    const removeNote = async (id: number) => {
        setNotes(notes.filter((note): boolean => note.id != id));
        add({
            type: "success",
            description: fmt("Notes.ToastSuccessDelete"),
        });
    };

    const { mutateAsync: mutateCreate, isLoading: createLoading } =
        mutationCreateVersionNote(addNote, requestConfig);

    const { mutateAsync: mutateUpdate, isLoading: updateLoading } =
        mutationUpdateVersionNote(updateNote, requestConfig);

    const { mutateAsync: mutateDelete, isLoading: deleteLoading } =
        useDeleteVersionNote(removeNote, requestConfig);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions.Drawer",
    );

    const { isFetching: isFetchingNotes } = useIndexVersionNotes(
        setNotes,
        requestConfig,
    );

    const handleSubmit = async (values: FormikValues, callback?: any) => {
        try {
            await mutateCreate(values);
            add({
                type: "success",
                description: fmt("Notes.ToastSuccessCreate"),
            });
            callback();
        } catch (e: any) {
            add({
                type: "warning",
                description: e.message,
            });
        }
    };

    const handleUpdate = async (values: Type_prj_put_version_note) => {
        try {
            await mutateUpdate(values);
            add({
                type: "success",
                description: fmt("Notes.ToastSuccessUpdate"),
            });
        } catch (e: any) {
            add({
                type: "warning",
                description: e.message,
            });
        }
    };

    const isFetching = useMemo(
        () => isFetchingNotes || !!(versionId && notes === undefined),
        [isFetchingNotes, versionId, notes],
    );

    const isLoading = useMemo(
        () => createLoading || updateLoading || deleteLoading,
        [createLoading, updateLoading, deleteLoading],
    );

    return (
        <Version_Notes_DrawerContentComponent
            isFetching={isFetching}
            isLoading={isLoading}
            onClose={onClose}
            notes={notes}
            onSubmit={handleSubmit}
            handleDelete={mutateDelete}
            onUpdate={handleUpdate}
            versionId={versionId}
        />
    );
};
