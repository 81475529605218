import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";

import { TMC_Button } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { APP_BAR_HEIGHT } from "src/components/Components_Teamoty/TopBar/stylesheet";
import { AvatarMenuButton } from "src/components/Components_Teamoty/TopBar/TopBar_Right/AvatarMenu/AvartarMenuButton";
import { AvatarMenu } from "src/components/Components_Teamoty/TopBar/TopBar_Right/AvatarMenu/AvatarMenu";
import { LanguageMenu } from "src/components/Components_Teamoty/TopBar/TopBar_Right/LanguageMenu/LanguageMenu";
import { LanguageMenuButton } from "src/components/Components_Teamoty/TopBar/TopBar_Right/LanguageMenu/LanguageMenuButton";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { ProjectUserInvitationDrawer } from "src/drawers/projectUsers/ProjectUserInvitationDrawer";
import { UserInvitationDrawer } from "src/drawers/users/UserInvitationDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const TopBar_Right = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar");
    const { hasPermission } = useUser();
    const { project, requestConfig } = useProject();

    const [openDrawer, setOpenDrawer] = useState({
        user: false,
        projectUser: false,
    });
    const toggleDrawer = (drawerType: "user" | "projectUser") => {
        setOpenDrawer((prev) => ({ ...prev, [drawerType]: !prev[drawerType] }));
    };

    const [menuState, setMenuState] = useState({
        anchorEl: null as HTMLElement | null,
        menuType: "" as "avatar" | "language" | "",
    });

    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        menuType: "avatar" | "language",
    ) => {
        setMenuState({
            anchorEl: event.currentTarget,
            menuType,
        });
    };

    const handleClose = () => {
        setMenuState({
            anchorEl: null,
            menuType: "",
        });
    };

    const canInvite = !project
        ? hasPermission("users_create")
        : hasPermission("projectUsers_create");
    const drawerType = !project ? "user" : "projectUser";

    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            height={APP_BAR_HEIGHT}
            gap={2}
            marginRight={2}
        >
            <Box sx={{ display: { xs: "none", md: "flex" } }} gap={2}>
                <LanguageMenuButton
                    handleClick={(event) => handleClick(event, "language")}
                />
                {menuState.menuType === "language" && (
                    <LanguageMenu
                        anchorEl={menuState.anchorEl}
                        open={Boolean(menuState.anchorEl)}
                        handleClose={handleClose}
                    />
                )}

                {canInvite && (
                    <TMC_Button
                        variant="ghost"
                        color="inheritWhite"
                        data-testid="Topbar-button-invite"
                        size="medium"
                        onClick={() => toggleDrawer(drawerType)}
                        sx={{
                            ":hover": {
                                backgroundColor: theme.palette.navbar.hover,
                            },
                        }}
                        startIcon={
                            <Icon
                                icon="user-plus"
                                variant="solid"
                                fontSize="small"
                            />
                        }
                    >
                        {fmt("Invite")}
                    </TMC_Button>
                )}
            </Box>

            <AvatarMenuButton
                handleClick={(event) => handleClick(event, "avatar")}
            />
            {menuState.menuType === "avatar" && (
                <AvatarMenu
                    open={Boolean(menuState.anchorEl)}
                    anchorEl={menuState.anchorEl}
                    handleClose={handleClose}
                />
            )}

            <UserInvitationDrawer
                page="users"
                open={openDrawer.user}
                onClose={() => toggleDrawer("user")}
                requestConfig={{}}
            />
            <ProjectUserInvitationDrawer
                page="projectUsers"
                open={openDrawer.projectUser}
                onClose={() => toggleDrawer("projectUser")}
                requestConfig={requestConfig}
            />
        </Box>
    );
};
