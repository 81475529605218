import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";
import * as Yup from "yup";

import {
    mutationCreateSubTrade,
    mutationUpdateSubTrade,
    useShowSubTrade,
} from "src/api/tms-projects/subTrades";
import {
    formatterCreateSubTrade,
    formatterUpdateSubTrade,
} from "src/api/tms-projects/subTrades/formatters";
import {
    Type_post_subTrade,
    Type_show_subTrade,
} from "src/api/tms-projects/subTrades/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { AutocompleteCompanies } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteCompanies";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SubTradeForm = {
    tradeId: number | null;
    onClose: () => void;
    subTradeId: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_SubTrade = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(inputMultiLanguagesSchema.fields),
        code: Yup.string(),
        team: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
            .nullable(),
        companies: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number(),
                    name: Yup.string(),
                }),
            )
            .nullable(),
    });
});

export const SubTradeForm = ({
    tradeId,
    onClose,
    subTradeId,
    action,
    setIsLoading,
}: Type_Props_SubTradeForm) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { mutateAsync: mutateCreate } = mutationCreateSubTrade() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateSubTrade() || {};

    const { isFetching, data } = useShowSubTrade(subTradeId as number) || {};

    const form = useTmcForm<Type_show_subTrade | Type_post_subTrade>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_SubTrade),
        onClose,
        setIsLoading,
    });

    const handleSubmit = (values: any) => {
        try {
            if (action === "update") {
                mutateUpdate(formatterUpdateSubTrade(values));
            } else {
                delete values.id;
                values.tradeId = tradeId;
                mutateCreate(formatterCreateSubTrade(values));
            }
            onClose();
        } catch (e: any) {
            console.log(e);
        }
    };

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"subTrade"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={4}>
                        <InputMultiLanguages name="names" />
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    name="code"
                                    label={fmtLabels(`Code`)}
                                    variant={"outlined"}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    name="team"
                                    type="number"
                                    label={fmtLabels(`Team`)}
                                    variant={"outlined"}
                                />
                            </Grid>
                        </Grid>
                        <AutocompleteCompanies
                            name="companies"
                            label={fmtLabels("Companies")}
                            renderInputProps={{
                                variant: "outlined",
                            }}
                        />
                    </Stack>
                )}
            </form>
        </TmcFormProvider>
    );
};
