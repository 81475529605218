import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Stack,
    Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import { useSelectListResourceTypes } from "src/api/tms-projects/resourceTypes";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { COLORS } from "src/theme/stylesheet";

type Type_Props_AutocompleteResourceTypes = {
    name: string;
    label: string;
    desc?: string;
    projectId?: number;
    subProjectId?: number;
};

export const AutocompleteResourceTypes = ({
    name,
    label,
    desc,
}: Type_Props_AutocompleteResourceTypes) => {
    const formik: any = useFormikContext();
    const { isFetching, data: resourceTypes } =
        useSelectListResourceTypes() || {};

    const controlledValue = formik.values[name]
        ? resourceTypes?.find(
              (resourceType): boolean =>
                  resourceType.id === formik.values[name].id,
          )
        : null;

    return (
        <>
            {!isFetching && (
                <Stack width={"100%"} flexDirection={"column"}>
                    <Autocomplete
                        data-testid={`autocomplete-resourceTypes-${name}`}
                        id={`autocomplete-resourceTypes-${name}`}
                        multiple={false}
                        loading={isFetching}
                        onChange={(e, value) =>
                            formik.setFieldValue(name, value)
                        }
                        value={
                            !isFetching ? controlledValue : formik.values[name]
                        }
                        options={resourceTypes || []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(
                            option,
                            selectedValues,
                        ): boolean => option?.id === selectedValues.id}
                        renderInput={(
                            params: AutocompleteRenderInputParams,
                        ) => (
                            <TMC_TextField
                                {...params}
                                name={name}
                                label={label}
                                inputProps={{
                                    ...params.inputProps,
                                    "data-testid": `autocomplete-resourceTypes-${name}-input`,
                                }}
                            />
                        )}
                        renderOption={(props: object, option: any) => (
                            <li
                                {...props}
                                data-testid={`autocomplete-resourceTypes-${name}-input-${option.name}`}
                            >
                                {option.name}
                            </li>
                        )}
                        componentsProps={{
                            clearIndicator: {
                                // @ts-expect-error data-testid are not on IconButtonProps
                                "data-testid": `autocomplete-resourceTypes-clearButton-${name}`,
                            },
                        }}
                    />
                    {desc?.length && (
                        <Typography variant={"body2"} color={COLORS.moon500}>
                            {desc}
                        </Typography>
                    )}
                </Stack>
            )}
        </>
    );
};
