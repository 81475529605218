import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { useSelectListTimeModels } from "src/api/tms-commons/timeModels";
import { mutationUpdateDefaultSettings } from "src/api/tms-projects/subProjects";
import { Type_show_subProject } from "src/api/tms-projects/subProjects/types";
import { useProject } from "src/contexts/project";
import { DefaultSettings_DrawerContentComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DefaultSettings/DrawerContent/DefaultSettings_DrawerContent.component";

export type Type_Projects_DrawerContentContainerProps = {
    subProject?: Type_show_subProject;
    onClose: () => void;
    step: string;
};

const Schema_DefaultSettings = Yup.object().shape({
    optimizedFlow: Yup.boolean(),
    versionLimit: Yup.number(),
    minDuration: Yup.number(),
    timeModel: Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
    }),
});

export const DefaultSettings_DrawerContentContainer = ({
    subProject,
    onClose,
    step,
}: Type_Projects_DrawerContentContainerProps) => {
    const { requestConfig } = useProject();

    const [tab, setTab] = useState<string>(step);

    const initialValues = {
        id: subProject?.id,
        optimizedFlow: subProject?.optimizedFlow,
        minDuration: subProject?.minDuration,
        timeModel: subProject?.timeModel,
        versionLimit: subProject?.versionLimit,
        workingDays: subProject?.workingDays,
    };

    const { isFetching: isFetchingTimeModel, data: timeModels } =
        useSelectListTimeModels() || {};

    const { mutateAsync: mutateUpdate, isLoading: isLoadingUpdate } =
        mutationUpdateDefaultSettings(
            requestConfig.projectId,
            requestConfig.subProjectId!,
        ) || {};

    const handleSubmit = async (values: any) => {
        // TODO: isDirty, select et checkbox à fixer, formik à modifier...
        try {
            await mutateUpdate(values);
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Schema_DefaultSettings,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <DefaultSettings_DrawerContentComponent
            formik={formik}
            onClose={onClose}
            tab={tab}
            setTab={setTab}
            subProject={subProject}
            timeModels={timeModels || []}
            isLoadingUpdate={isLoadingUpdate}
            isFetchingTimeModel={isFetchingTimeModel}
        />
    );
};
