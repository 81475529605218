import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_timeModel } from "src/api/tms-commons/timeModels/types";
import { DaysBadge } from "src/components/Components_Common/Days/DaysBadge";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { TimeModelsDrawer } from "src/drawers/timeModels/TimeModelsDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteTimeModel } from "src/pages/Pages_Teamoty/Configuration/Page_TimeModels/Page_TimeModels.container";

export type Type_Props_Page_TimeModelsComponent = {
    timeModels: Type_index_timeModel[];
    isFetching: boolean;
    changeStatusTimeModel: (timeModel: Type_index_timeModel) => void;
    removeTimeModel: (id: number) => void;
    modalDeleteTimeModel: Type_modalDeleteTimeModel;
    setModalDeleteTimeModel: Dispatch<
        SetStateAction<Type_modalDeleteTimeModel>
    >;
};

export const Page_TimeModelsComponent = ({
    timeModels,
    isFetching,
    changeStatusTimeModel,
    removeTimeModel,
    modalDeleteTimeModel,
    setModalDeleteTimeModel,
}: Type_Props_Page_TimeModelsComponent) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.TimeModels");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "timeModels";

    const [selectedTimeModel, setSelectedTimeModel] = useState<number | null>(
        null,
    );
    const [openTimeModelDrawer, setOpenTimeModelDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (
        resourceModelId: number | null,
        action: Type_action,
    ) => {
        setSelectedTimeModel(resourceModelId);
        setAction(action);
        setOpenTimeModelDrawer(true);
    };

    const handleCloseTimeModelDrawer = () => {
        setOpenTimeModelDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
        },
        {
            field: "quantity",
            headerName: fmtTableColumn("DailyModel"),
        },
        {
            field: "workingDays",
            colType: "workingDays",
            headerName: fmtTableColumn("WorkingDays"),
            renderCell: (params) => {
                return <DaysBadge disableEdition initialValue={params.value} />;
            },
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        openDrawer(row.id, "update");
    };

    const callbackDuplicate = (row: GridRowModel) => {
        openDrawer(row.id, "duplicate");
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatusTimeModel(row as Type_index_timeModel);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteTimeModel({
            isOpen: true,
            props: row as Type_index_timeModel,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteTimeModel({ isOpen: false });
                removeTimeModel(modalDeleteTimeModel?.props?.id as number);
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageTimeModels-AddOne-btn"
                    onClick={() => openDrawer(null, "create")}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={timeModels}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => openDrawer(null, "create"),
                }}
                onClick={(id: GridRowId) => {
                    openDrawer(parseInt(id as string), "update");
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ModalDelete
                open={modalDeleteTimeModel.isOpen}
                onClose={() => setModalDeleteTimeModel({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteTimeModel?.props?.name as string}
                actions={actions}
            />
            <TimeModelsDrawer
                open={openTimeModelDrawer}
                onClose={handleCloseTimeModelDrawer}
                timeModelIdToUpdate={selectedTimeModel}
                action={action}
                page={page}
            />
        </>
    );
};
