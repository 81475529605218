import { Stack } from "@mui/material";
import React, { useState } from "react";

import { Header } from "src/components";
import { AreaForm } from "src/forms/areas/AreaForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_props_AreaContextualDrawer = {
    id: number;
};
export const AreaContextualDrawer = ({
    id,
}: Type_props_AreaContextualDrawer) => {
    const [, setIsLoading] = useState(false);
    const { closePaper } = useContextualDrawer();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerArea",
    );
    return (
        <Styled_PaperContextualLayout>
            <Header
                title={fmt("Area")}
                onClose={() => closePaper("areas")}
                dataTestIdTitle={"Areas-Drawer-Update-Title"}
            />
            <Stack padding={4}>
                <AreaForm
                    onClose={() => closePaper("area")}
                    action={"update"}
                    setIsLoading={setIsLoading}
                    areaIdToUpdate={id}
                    isOnBlur={true}
                />
            </Stack>
        </Styled_PaperContextualLayout>
    );
};
