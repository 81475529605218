import {
    Column,
    RenderEditCellProps,
    RenderHeaderCellProps,
    textEditor,
} from "react-data-grid";

import { Type_index_areaType } from "src/api/tms-commons/areaTypes/types";
import {
    AutoCompleteCell,
    AutoCompleteEditCell,
} from "src/components/Components_Common/MatrixDataGrid/Formatters/AutoCompleteCell";
import {
    CheckboxCell,
    HeaderCheckbox,
} from "src/components/Components_Common/MatrixDataGrid/Formatters/CheckBoxCell";
import InputTextCell from "src/components/Components_Common/MatrixDataGrid/Formatters/InputTextCell";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { FilterRenderer } from "./TableSequenceAreasFilter";
import { getDepth } from "./TableSequenceAreasHelpers";

export const TableSequenceAreasColumns = (
    areaTypes: Type_index_areaType[],
    enableOrderEdition: boolean,
    rows: any[],
): Column<any>[] => {
    const AreaTypesOptions = areaTypes?.map((obj) => ({
        id: obj.id,
        name: obj.name,
    }));

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    const columnArray = [];
    for (let i = 0; i <= getDepth(rows); i++) {
        const index = i + 1;
        columnArray.push(
            {
                key: `name_${i}`,
                name: `${fmt("AreaName")} ${index}`,
                width: "10%",
                resizable: true,
                editable: false,
                sortable: true,
                renderEditCell: textEditor,
                renderHeaderCell: (p: RenderHeaderCellProps<any>) => (
                    <FilterRenderer
                        {...p}
                        columnData={Array.from(
                            new Set(
                                rows
                                    .map((row) => row[`name_${i}`])
                                    .filter(Boolean),
                            ),
                        )}
                    />
                ),
            },
            {
                key: `areaType_${i}.id`,
                value: `areaType_${i}.name`,
                name: `${fmt("AreaType")} ${index}`,
                width: "10%",
                options: AreaTypesOptions,
                resizable: true,
                sortable: true,
                editable: false,
                renderEditCell: AutoCompleteEditCell,
                renderCell: AutoCompleteCell,
                renderHeaderCell: (p: RenderHeaderCellProps<any>) => (
                    <FilterRenderer
                        {...p}
                        columnData={Array.from(
                            new Set(
                                rows
                                    .map((row) => row[`areaType_${i}`])
                                    .filter(Boolean)
                                    .map((areaType) => areaType.name),
                            ),
                        )}
                    />
                ),
            },
        );
    }

    columnArray.push(
        {
            key: "selected",
            name: `Selection`,
            width: "7%",
            resizable: true,
            editable: true,
            cellClass: "flex align-center items-center justify-center",
            headerCellClass: "flex align-center items-center justify-center",
            renderCell: CheckboxCell,
            renderHeaderCell: HeaderCheckbox,
        },
        {
            key: "order",
            name: fmt("Order"),
            width: "7%",
            resizable: true,
            editable: enableOrderEdition,
            cellClass: `${
                enableOrderEdition ? "" : "cell-no-editable"
            } flex align-center items-center justify-center relative`,
            renderCell(p: RenderEditCellProps<any>) {
                const isOrderDefined =
                    enableOrderEdition && p.row.selected && !p.row.order;

                return (
                    <>
                        {isOrderDefined && (
                            <span className="absolute top-3 right-3 w-2 h-2 bg-red-500 rounded-full"></span>
                        )}
                        <div>{p.row.order}</div>
                    </>
                );
            },
            renderEditCell: (p: RenderEditCellProps<any, unknown>) =>
                InputTextCell({
                    ...p,
                    type: "number",
                }),
        },
    );

    return columnArray;
};
