import { Column, textEditor } from "react-data-grid";

import { Type_index_areaType } from "src/api/tms-commons/areaTypes/types";
import {
    AutoCompleteCell,
    AutoCompleteEditCell,
} from "src/components/Components_Common/MatrixDataGrid/Formatters/AutoCompleteCell";
import { ColorPickerCellEdit } from "src/components/Components_Common/MatrixDataGrid/Formatters/ColorPickerCell";
import {
    DropDownCell,
    DropDownCellEditor,
} from "src/components/Components_Common/MatrixDataGrid/Formatters/DropDownCell";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const TableAreasColumns = (
    areaTypes: Type_index_areaType[],
): Column<any>[] => {
    const AreaTypesOptions = areaTypes?.map((obj) => ({
        id: obj.id,
        name: obj.name,
    }));
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas.Table",
    );
    return [
        {
            key: "name",
            name: fmt("AreaDefaultName"),
            width: "40%",
            resizable: true,
            renderEditCell: textEditor,
        },
        {
            key: "color",
            name: fmt("Color"),
            width: "5%",
            resizable: true,
            cellClass: "flex align-center items-center justify-center",
            renderEditCell: ColorPickerCellEdit,
            renderCell: ColorPickerCellEdit,
        },
        {
            key: "areaType.id",
            value: "areaType.name",
            name: fmt("AreaType"),
            options: AreaTypesOptions,
            width: "5%",
            resizable: true,
            cellClass: "flex align-center items-center justify-center",
            renderEditCell: DropDownCellEditor,
            renderCell: DropDownCell,
        },
        {
            key: "areaType.id",
            value: "areaType.name",
            name: fmt("AreaType"),
            width: "30%",
            options: AreaTypesOptions,
            resizable: true,
            renderEditCell: AutoCompleteEditCell,
            renderCell: AutoCompleteCell,
        },
    ];
};
