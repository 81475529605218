import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import {
    mutationCreateArea,
    mutationUpdateArea,
    useShowArea,
} from "src/api/tms-projects/areas";
import { Type_show_area } from "src/api/tms-projects/areas/types";
import { multiLanguagesSchema } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.container";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { Areas_DrawerContentComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/DrawerContent/Areas_DrawerContent.component";

export const Schema_Area = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(multiLanguagesSchema.fields),
        color: Yup.string().required(FORM_ERR_FMT.REQUIRED),
        areaType: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .required(),
        parentArea: Yup.object()
            .shape({
                id: Yup.number().nullable(),
                name: Yup.string(),
            })
            .nullable(),
        drawing: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        resource: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        resourceType: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        scheduling: Yup.boolean(),
        permanentUse: Yup.boolean(),
    });
});

export type Type_props_Areas_DrawerContentContainer = {
    action: "create" | "update";
    areaIdToUpdate?: number;
    onClose: () => void;
};

/**
 * @deprecated
 */
export const Areas_DrawerContentContainer = ({
    action,
    areaIdToUpdate,
    onClose,
}: Type_props_Areas_DrawerContentContainer) => {
    const [tab, setTab] = useState<"0" | "1">("0");
    const [data, setData] = useState<Type_show_area>({
        id: areaIdToUpdate as number,
        names: {},
        color: "",
        areaType: {
            id: 0,
            name: "",
            enabled: true,
        },
        parent: null,
        scheduling: true,
        permanentUse: false,
        sortOrder: 0,
        drawing: {
            id: 0,
            name: "",
        },
        resourceType: null,
        resource: null,
        show: true,
    });

    const { isFetching } = useShowArea(areaIdToUpdate as number, setData) || {};

    const { mutateAsync: mutateCreate, isLoading: createLoading } =
        mutationCreateArea() || {};

    const { mutateAsync: mutateUpdate, isLoading: updateArea } =
        mutationUpdateArea() || {};

    const handleSubmit = async (values: any) => {
        try {
            if (action === "update") {
                await mutateUpdate({
                    ...values,
                    color: values.color,
                });
            } else {
                await mutateCreate(values);
            }

            onClose();
        } catch (e: any) {
            console.log(e);
        }
    };

    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: Schema_Area,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <Areas_DrawerContentComponent
            tab={tab}
            setTab={setTab}
            action={action}
            onClose={onClose}
            formik={formik}
            isFetching={isFetching}
            isLoading={createLoading || updateArea}
        />
    );
};
