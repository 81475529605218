import { Box } from "@mui/material";
import {
    DataGridProProps,
    GridApiPro,
    GridCell,
    GridColDef,
    GridRenderCellParams,
    GridRow,
    GridRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { memo, ReactElement, useMemo } from "react";

import {
    DropdownMenuGeneric,
    Type_CustomMenuItem,
} from "src/components/Components_Common/DropdownMenuGeneric/DropdownMenuGeneric";
import { LoadingOrNoData } from "src/components/Components_Common/LoadingOrNoData/LoadingOrNoData";
import {
    GRID_HEIGHT,
    Type_menuDropDown,
} from "src/components/Components_Common/Table/Table";
import {
    Styled_Props_Table,
    Styled_Table,
} from "src/components/Components_Common/Table/Table.style";
import { CustomGroupingCell } from "src/components/Components_Common/TreeTable/CustomGroupingCell/CustomGroupingCell";
import { useUser } from "src/contexts/user";

type Type_Props_TreeTable = Styled_Props_Table & {
    data: any[];
    groupHeaderName?: string;
    onClick?: (row: GridRowModel) => void;
    noDataConfig: {
        isFetching: boolean;
        dataName: string;
        action: () => void;
    };
    menuDropDown?: Type_menuDropDown;
    menuItems?: Type_CustomMenuItem[];
    page: string;
    hierarchy: string;
    groupingCellComponent?: (row: GridRowModel) => ReactElement;
};

export const TreeTable = memo(function TreeTable({
    data,
    groupHeaderName,
    columns,
    onClick,
    noDataConfig,
    menuDropDown,
    menuItems,
    page,
    hierarchy,
    groupingCellComponent,
    ...props
}: Type_Props_TreeTable) {
    const { checkPermission, user } = useUser();
    const CAN_CREATE_UPDATE = useMemo(
        () => checkPermission(page, "create"),
        [page, user],
    );
    const CAN_DELETE = useMemo(
        () => checkPermission(page, "delete"),
        [page, user],
    );
    const apiRef: React.MutableRefObject<GridApiPro> = useGridApiRef();

    const handleClick = (row: GridRowModel) => {
        if (typeof onClick === "function" && CAN_CREATE_UPDATE) {
            onClick(row);
        }
    };

    const groupingColDef: DataGridProProps["groupingColDef"] = {
        headerName: groupHeaderName || "",
        flex: 1,
        renderCell: (params) => (
            <CustomGroupingCell {...params}>
                {groupingCellComponent
                    ? groupingCellComponent(params.row)
                    : params.row.id}
            </CustomGroupingCell>
        ),
    };

    const dropDownMenuColumn: GridColDef | undefined =
        (menuDropDown && CAN_CREATE_UPDATE) || (menuDropDown && CAN_DELETE)
            ? {
                  field: "menuDropDown",
                  headerName: "",
                  maxWidth: 35,
                  minWidth: 35,
                  renderCell: (props: GridRenderCellParams) => {
                      if (props?.row?.hasParentDisabled) return null;
                      return (
                          <DropdownMenuGeneric
                              id={props.row.id}
                              props={props.row}
                              {...menuDropDown}
                              page={page}
                              menuItems={props?.row?.enabled ? menuItems : []}
                              showEdit={(row) => !!row?.enabled}
                              showDuplicate={(row) => !!row.enabled}
                          />
                      );
                  },
                  sortable: false,
              }
            : undefined;

    if (dropDownMenuColumn) columns = [...columns, dropDownMenuColumn];

    const rowsData = useMemo(
        () => (props.treeData ? formatRows(data, hierarchy) : data),
        [data, props.treeData],
    );

    return (
        <Box
            height={GRID_HEIGHT}
            width="100%"
            sx={{
                overflowY: "hidden",
                overflowX: "auto",
            }}
            data-testid={`TreeTable-${page}-Container`}
        >
            <Styled_Table
                ownerState={{
                    showCursor:
                        CAN_CREATE_UPDATE && typeof onClick === "function",
                }}
                columns={columns}
                rows={noDataConfig.isFetching ? [] : rowsData} // [] permet que le loader s affiche si duplicate
                apiRef={apiRef}
                getRowId={
                    props.treeData ? (row) => handleRowId(row) : (row) => row.id
                }
                getTreeDataPath={(row) => row.path}
                hideFooter
                disableColumnMenu
                groupingColDef={groupingColDef}
                disableColumnResize
                columnHeaderHeight={40}
                rowHeight={props.rowHeight || 40}
                defaultGroupingExpansionDepth={-1}
                getCellClassName={(params) => {
                    const disabled = !params.row.enabled
                        ? "teamoty-cell-disabled"
                        : "";
                    const hidden =
                        params?.row?.show === false
                            ? "teamoty-cell-hidden"
                            : "";

                    return `teamoty-cell-${params.field} ${disabled} ${hidden}`;
                }}
                slots={{
                    cell: (props) => (
                        <GridCell
                            {...props}
                            data-testid={`cell-${props.rowId}-${props.column.field}`}
                        />
                    ),
                    row: (props) => (
                        <GridRow
                            {...props}
                            {...(onClick && {
                                onClick: () =>
                                    props.row.enabled && handleClick(props.row),
                            })}
                            data-testid={`row-${props.rowId}`}
                        />
                    ),
                    noRowsOverlay: () => (
                        <LoadingOrNoData
                            isFetching={noDataConfig.isFetching}
                            dataName={noDataConfig.dataName}
                            action={noDataConfig.action}
                            hasPermission={CAN_CREATE_UPDATE}
                            dataTestIdRef={page}
                        />
                    ),
                }}
                {...props}
            />
        </Box>
    );
}, arePropsEqual);

const formatRows = (rows: any[], hierarchy: string): GridRowModel[] => {
    const rowsToDisplay: any[] = [];

    const getHierarchy = (row: any, parentField?: string[]) => {
        const currentField = parentField
            ? [...parentField, `${parentField}.${row.id}`]
            : [`${row.id}`];
        const currentRow = { ...row, path: currentField };
        rowsToDisplay.push(currentRow);
        row[hierarchy]?.forEach((child: any) =>
            getHierarchy(child, currentField),
        );
    };

    rows.forEach((row) => getHierarchy(row));
    return rowsToDisplay;
};

const handleRowId = (row: GridRowModel) => {
    return row.path[row.path.length - 1];
};

function arePropsEqual(
    oldProps: Type_Props_TreeTable,
    newProps: Type_Props_TreeTable,
) {
    if (!oldProps.rows || !newProps.rows) return false;
    return (
        oldProps.rows?.length === newProps.rows?.length &&
        oldProps.rows?.every((oldDatum, index) => {
            const newData = newProps.rows && newProps.rows[index];
            return oldDatum === newData;
        })
    );
}
