import { Box, Button, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import {
    mutationCreateSequenceFolderLegacy,
    useIndexSequenceFolders,
} from "src/api/tms-scheduling/sequenceFolders";
import { Type_index_sequenceFolder } from "src/api/tms-scheduling/sequenceFolders/types";
import { mutationCreateSequence } from "src/api/tms-scheduling/sequences";
import { Type_index_sequence } from "src/api/tms-scheduling/sequences/types";
import { mutationCreateTask } from "src/api/tms-scheduling/tasks";
import { Type_sch_post_task } from "src/api/tms-scheduling/tasks/types";
import { Type_CountryCode } from "src/assets/translations";
import { Spinner } from "src/components/Components_Common";
import { defaultFirstTaskName } from "src/components/Components_Teamoty/Flow/Flow.const";
import { useLanguage } from "src/contexts/languages";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS_TASK } from "src/theme/stylesheet";
import { getDate } from "src/utils/date";

type Type_props_Blank = {
    setSequenceId: Dispatch<SetStateAction<number | undefined>>;
    setShowPertDiagram: Dispatch<SetStateAction<boolean>>;
};
export const Blank = ({
    setSequenceId,
    setShowPertDiagram,
}: Type_props_Blank) => {
    const { languages } = useLanguage();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.Blank",
    );

    // states
    const [, setNewSequence] = useState<Type_index_sequence>();
    const [, setNewSequenceFolder] = useState<Type_index_sequenceFolder>();

    // fn fetching
    const { isFetching, data: sequenceFolders } = useIndexSequenceFolders();

    // fn mutate create
    const { mutateAsync: mutateCreateSequence, isLoading: isLoadingSequence } =
        mutationCreateSequence(setNewSequence);
    const {
        mutateAsync: mutateCreateSequenceFolder,
        isLoading: isLoadingSequenceFolder,
    } = mutationCreateSequenceFolderLegacy(setNewSequenceFolder);

    const { mutateAsync: mutateCrateTask, isLoading: isLoadingTask } =
        mutationCreateTask({});

    // global isLoading
    const isLoading = useMemo(
        () => isLoadingSequence || isLoadingSequenceFolder || isLoadingTask,
        [isLoadingSequence, isLoadingSequenceFolder, isLoadingTask],
    );

    const handleStart = async () => {
        let defaultSequence: {
            names: { [p: string]: string };
            color: string;
            earliestDate: Dayjs | null;
            sequenceFolder: { id: number };
        } = {
            names: {},
            color: "#000000",
            earliestDate: getDate(),
            sequenceFolder: { id: 0 },
        };
        languages.forEach((language: Type_CountryCode) => {
            defaultSequence.names[language] = fmt(
                `NewSequenceNames.${language}`,
            );
        });
        if (sequenceFolders && sequenceFolders.length) {
            defaultSequence = {
                ...defaultSequence,
                sequenceFolder: { id: sequenceFolders[0].id },
            };
        } else {
            const defaultFolderSequence: { names: { [p: string]: string } } = {
                names: {},
            };
            languages.forEach((language: Type_CountryCode) => {
                defaultFolderSequence.names[language] = fmt(
                    `NewFolderNames.${language}`,
                );
            });
            const respFolder = await mutateCreateSequenceFolder(
                defaultFolderSequence,
            );
            // TODO what if respFolder.data.data.id doesn't existe ?
            defaultSequence = {
                ...defaultSequence,
                sequenceFolder: { id: respFolder.data.data.id },
            };
        }

        const respSequence = await mutateCreateSequence(defaultSequence);
        const sequenceId = respSequence.data.data.id;
        const task: Type_sch_post_task = {
            names: {},
            sequence_id: sequenceId,
            xy: [60, 60],
            color: COLORS_TASK[0],
        };

        languages.forEach((language: Type_CountryCode) => {
            task.names[language] = `${defaultFirstTaskName} ${language}`;
        });

        await mutateCrateTask(task).then(() => {
            setSequenceId(sequenceId);
            setShowPertDiagram(true);
        });
    };

    return (
        <Box component="div" sx={{ flexGrow: 1 }}>
            <Stack
                sx={{
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Button
                    disabled={isFetching || isLoading}
                    onClick={handleStart}
                    type="button"
                    variant="contained"
                    data-testid={"Planning-Blank-Button-GettingStarted"}
                    endIcon={
                        isLoading && <Spinner size="small" style="button" />
                    }
                >
                    {fmt("GettingStarted")}
                </Button>
            </Stack>
        </Box>
    );
};
