import * as React from "react";
import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { CompanyPath } from "src/api/paths";
import {
    createCompany,
    indexCompanies,
    removeCompany,
    searchCompany,
    selectListCompanies,
    showCompany,
    statusCompany,
    updateCompany,
} from "src/api/tms-commons/companies/services";
import {
    Type_com_index_companyWithMeta,
    Type_com_selectList_company,
    Type_index_company,
    Type_put_company,
    Type_show_company,
} from "src/api/tms-commons/companies/types";
import { CompanyKeys } from "src/api/tms-commons/keys";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterIndexCompaniesWithMeta,
    formatterIndexCompany,
    formatterPostCompany,
    formatterPutCompany,
    formatterSelectListCompanies,
    formatterShowCompany,
    formatterStatusCompany,
} from "./formatters";

export const useSelectListCompanies = () => {
    return useQuery({
        queryKey: [CompanyKeys.SELECT_LIST],
        queryFn: selectListCompanies,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListCompanies");
            }
            return formatterSelectListCompanies(
                data.data.data as Type_com_selectList_company[],
            );
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useIndexCompanies = (requestConfig?: Type_RequestConfig) => {
    return useQuery({
        queryKey: [CompanyKeys.INDEX, requestConfig],
        queryFn: () => indexCompanies(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data) {
                throw new Error("Wrong format data: useShowCompany");
            }

            return formatterIndexCompaniesWithMeta(
                data.data as Type_com_index_companyWithMeta,
            );
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useShowCompany = (id: number) => {
    return useQuery({
        queryKey: [CompanyKeys.SHOW, id],
        queryFn: () => showCompany(id),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowCompany");
            }
            return formatterShowCompany(data.data.data);
        },
        enabled: !!id,
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationStatusCompany = (requestConfig: Type_RequestConfig) => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_company) => {
            return statusCompany(
                formatterStatusCompany(newStatus),
                newStatus.id as number,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusCompany");
            }

            await queryClient.invalidateQueries({
                queryKey: [CompanyKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateCompany = (requestConfig?: Type_RequestConfig) => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (company: Type_show_company) =>
            createCompany(formatterPostCompany(company)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateCompany");
            }
            const company = formatterIndexCompany(data.data.data);

            await queryClient.invalidateQueries({
                queryKey: [CompanyKeys.INDEX, requestConfig],
            });

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        company: company.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationCreateCompany", err);
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateCompany = (requestConfig?: Type_RequestConfig) => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (company: Type_show_company) =>
            updateCompany(company.id, formatterPutCompany(company)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateCompany");
            }
            const company: Type_index_company = formatterIndexCompany(
                data.data.data,
            );

            await queryClient.invalidateQueries({
                queryKey: [CompanyKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [CompanyPath.COMPANIES, "thumbnail", company.id],
            });

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        company: company.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateCompany", err);
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const useSearchCompany = (
    search: string,
    callback?: any,
): UseQueryResult => {
    return useQuery(["searchCompany", search], () => searchCompany(search), {
        refetchOnWindowFocus: false,
        enabled: search !== "",
        select: (data): string[] => {
            if (data.success) {
                const result: any[] = [
                    ...(data?.data?.data.like || []),
                    ...(data?.data?.data.levenshtein || []),
                ].slice(0, 10);
                if (!result.length && callback) {
                    callback(result);
                }
                return result;
            }
            return [];
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteCompany = (requestConfig: Type_RequestConfig) => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");

    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => removeCompany(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteCompany");
            }
            await queryClient.invalidateQueries({
                queryKey: [CompanyKeys.INDEX, requestConfig],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateCompany", err);
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
