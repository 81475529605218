import { Grid, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";

import { useSubprojectPdfPicture } from "src/api/tms-projects/subProjects";
import { Type_show_subProject } from "src/api/tms-projects/subProjects/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { DaysBadge } from "src/components/Components_Common/Days/DaysBadge";
import { SettingsRowLine } from "src/components/Components_Common/SettingsRowLine/SettingsRowLine";
import { SettingsRowTitle } from "src/components/Components_Common/SettingsRowTitle/SettingsRowTitle";
import { Styled_ImagePreviewSm } from "src/components/Components_Common/UploadImage/UploadImage.style";
import { PdfAppearanceDrawer } from "src/drawers/pdfAppearance/PdfAppearanceDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_Page_DefaultSettingsComponent = {
    subProject?: Type_show_subProject;
    openDrawer: (step: string) => void;
};

export const Page_DefaultSettingsComponent = ({
    subProject,
    openDrawer,
}: Type_Props_Page_DefaultSettingsComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.DefaultSettings",
    );
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    ///////////////////////////////////
    ///     States                  ///
    ///////////////////////////////////

    const [openPDFSettingsDrawer, setOpenPDFSettingsDrawer] = useState(false);

    const handleClosePdfAppearanceDrawer = () => {
        setOpenPDFSettingsDrawer(false);
    };

    ///////////////////////////////////
    ///     Queries                 ///
    ///////////////////////////////////
    const { data: leftPicture } =
        useSubprojectPdfPicture(
            subProject?.id,
            "left",
            subProject?.updatedAt,
        ) || {};

    const { data: rightPicture } =
        useSubprojectPdfPicture(
            subProject?.id,
            "right",
            subProject?.updatedAt,
        ) || {};

    return (
        <>
            <Stack rowGap={4} mt={6}>
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("Sequences")}
                        dataTestId={"Default-settings-btn-edit-Sequences"}
                        handleClick={() => {
                            openDrawer("0");
                        }}
                    />
                    <SettingsRowLine
                        title={fmt("MinDuration")}
                        dataTestId={"Default-settings-MinDuration"}
                        value={
                            subProject?.minDuration
                                ? fmt(`MinDuration${subProject?.minDuration}`)
                                : ""
                        }
                    />
                    <SettingsRowLine
                        title={fmt("OptimizedFlow")}
                        dataTestId={"Default-settings-OptimizedFlow"}
                        value={
                            subProject?.optimizedFlow
                                ? fmtLabels("Yes")
                                : fmtLabels("No")
                        }
                    />
                    <SettingsRowLine
                        title={fmt("TimeModel")}
                        dataTestId={"Default-settings-TimeModel"}
                        value={subProject?.timeModel?.name}
                    />
                    <SettingsRowLine
                        title={fmt("WorkingDays")}
                        dataTestId={"Default-settings-WorkingDays"}
                    >
                        {subProject && (
                            <DaysBadge
                                disableEdition
                                initialValue={subProject?.workingDays}
                            />
                        )}
                    </SettingsRowLine>
                </Grid>
                <Divider />
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("Versions")}
                        dataTestId={"Default-settings-btn-edit-Versions"}
                        handleClick={() => {
                            openDrawer("1");
                        }}
                    />
                    <SettingsRowLine
                        title={fmt("VersionLimit")}
                        dataTestId={"Default-settings-VersionLimit"}
                        value={
                            subProject?.versionLimit &&
                            subProject?.versionLimit > 0
                                ? subProject?.versionLimit
                                : fmt(`VersionLimitNone`)
                        }
                    />
                </Grid>
                <Divider />
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("PDFAppearance")}
                        dataTestId={"Default-settings-btn-edit-PDFAppearance"}
                        handleClick={() => {
                            setOpenPDFSettingsDrawer(true);
                        }}
                    />
                    <SettingsRowLine
                        title={fmt("Title")}
                        dataTestId={"Default-settings-Title"}
                        value={subProject?.exportTitle}
                    />
                    <SettingsRowLine
                        title={fmt("SubTitle")}
                        dataTestId={"Default-settings-SubTitle"}
                        value={subProject?.exportSubTitle}
                    />
                    <SettingsRowLine
                        title={fmt("BackgroundColor")}
                        dataTestId={"Default-settings-BackgroundColor"}
                    >
                        <Stack direction="row" spacing={2}>
                            <TMC_Chip
                                data-testid={`Default-settings-BackgroundColor-color`}
                                data-testid-color={
                                    subProject?.headerBackgroundColor
                                }
                                withBorder={true}
                                backgroundColor={
                                    subProject?.headerBackgroundColor
                                }
                            />
                            <Typography>
                                {subProject?.headerBackgroundColor}
                            </Typography>
                        </Stack>
                    </SettingsRowLine>
                    <SettingsRowLine
                        title={fmt("LeftPicture")}
                        dataTestId={"Default-settings-LeftPicture"}
                    >
                        <ImagePreview
                            imageUrl={leftPicture as string}
                            emptyMessage={fmt("NoData")}
                            dataTestId={"LeftPicture"}
                        />
                    </SettingsRowLine>
                    <SettingsRowLine
                        title={fmt("RightPicture")}
                        dataTestId={"Default-settings-RightPicture"}
                    >
                        <ImagePreview
                            imageUrl={rightPicture as string}
                            emptyMessage={fmt("NoData")}
                            dataTestId={"RightPicture"}
                        />
                    </SettingsRowLine>
                </Grid>
            </Stack>

            <PdfAppearanceDrawer
                page="subProjects"
                open={openPDFSettingsDrawer}
                onClose={handleClosePdfAppearanceDrawer}
                subProject={subProject}
            />
        </>
    );
};

type Type_Props_ImagePreview = {
    imageUrl?: string;
    emptyMessage: string;
    dataTestId: string;
};

const ImagePreview = ({
    imageUrl,
    emptyMessage,
    dataTestId,
}: Type_Props_ImagePreview) => {
    return imageUrl ? (
        <Styled_ImagePreviewSm
            style={{
                backgroundImage: `url(${imageUrl})`,
            }}
        />
    ) : (
        <Typography
            variant="body1"
            data-testid={`Default-settings-${dataTestId}-empty-data`}
        >
            {emptyMessage}
        </Typography>
    );
};

ImagePreview.displayName = "ImagePreview";
