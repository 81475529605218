// attention de garder l'ordre avec Enum_typePlanningTask
export const Conv_typePlanningTask: string[] = [
    "none",
    "work",
    "study",
    "waiting",
    "milestone",
    "delivery",
    "technical",
];
