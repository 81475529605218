import { Stack, Typography } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ColorChip } from "src/components/Components_Teamoty/chips/ColorChip";

type Type_Props_AreaCell = {
    row: GridRowModel;
};
export const AreaCell = ({ row }: Type_Props_AreaCell) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flex={1}
            paddingRight={1}
        >
            <Stack direction={"row"} gap={1}>
                <ColorChip
                    backgroundColor={row.enabled ? row.color : "common.white"}
                    sx={(theme) => ({
                        border: theme.border.default,
                    })}
                />
                <Typography
                    variant={"body1Medium"}
                    color={
                        !row.enabled || !row.show
                            ? "text.disabled"
                            : "text.primary"
                    }
                >
                    {row.name}
                </Typography>
            </Stack>
            {!row.show && <Icon icon="eye-slash" variant="solid" />}{" "}
        </Stack>
    );
};
