import {
    Autocomplete,
    AutocompleteRenderInputParams,
    AutocompleteValue,
    createFilterOptions,
    Skeleton,
} from "@mui/material";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import { ChipTypeMap } from "@mui/material/Chip";
import * as React from "react";
import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { countryCodeLanguageRtl } from "src/assets/translations";
import {
    TMC_FormHelperText,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants";
import {
    getOptionLabelDefault,
    renderOptionDefault,
    renderTagsDefault,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

type Type_Props_AutocompleteFreeSoloDialog<
    T extends { id: number },
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    "renderInput"
> & {
    autoFocus?: boolean;
    isFetching?: boolean;
    name: string;
    label?: string;
    renderInputProps?: any;
    onAddNewValue: (value: any) => void;
};

const filter = createFilterOptions<any>();

export const AutocompleteFreeSoloDialog = <
    T extends { id: number },
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
    isFetching = false,
    name,
    autoFocus = false,
    label,
    defaultValue,
    getOptionLabel = getOptionLabelDefault,
    renderOption = renderOptionDefault,
    renderTags = renderTagsDefault,
    renderInputProps = {
        variant: "standard",
    },
    onAddNewValue,
    freeSolo = true as FreeSolo,
    multiple = true as Multiple,
    ...restProps
}: Type_Props_AutocompleteFreeSoloDialog<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
>) => {
    // i18n
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );

    const { formState, control } = useFormContext(); // retrieve those props

    // handle focus
    const autocompleteRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isFetching && autoFocus && autocompleteRef.current) {
            const input = autocompleteRef.current.querySelector(
                "input",
            ) as HTMLInputElement;
            if (input) {
                input.click();
                // Simulate a keyDown event to open the options dropdown
                const keyboardEvent = new KeyboardEvent("keydown", {
                    key: "ArrowDown",
                });
                input.dispatchEvent(keyboardEvent);
            }
        }
    }, [isFetching, autoFocus]);

    const parseNewValueName = (value: string) => {
        const firstQuoteIndex = value.indexOf('"');
        const lastQuoteIndex = value.lastIndexOf('"');

        return value.substring(firstQuoteIndex + 1, lastQuoteIndex).trim();
    };

    const addNewValueParsed = (value: any) => {
        value.name = parseNewValueName(value.name);
        return value;
    };

    return (
        <>
            {isFetching ? (
                <Skeleton variant="rounded" height={60} />
            ) : (
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue ?? null}
                    render={({
                        field: { ref, onChange, onBlur: onBlurRhf, value },
                    }) => (
                        <Autocomplete
                            ref={autocompleteRef}
                            {...restProps}
                            freeSolo={freeSolo}
                            getOptionLabel={getOptionLabel}
                            multiple={multiple}
                            id={`Autocomplete-${name}`}
                            data-testid={`Autocomplete-${name}`}
                            isOptionEqualToValue={(
                                option,
                                selectedValues,
                            ): boolean => option?.id === selectedValues.id}
                            renderInput={(
                                params: AutocompleteRenderInputParams,
                            ) => {
                                return (
                                    <TMC_TextField
                                        {...params}
                                        {...renderInputProps}
                                        inputRef={ref} // RHF
                                        label={label}
                                        inputProps={{
                                            ...params.inputProps,
                                            onBlur: (e) => {
                                                if (params.inputProps.onBlur) {
                                                    params.inputProps.onBlur(
                                                        e as React.FocusEvent<HTMLInputElement>,
                                                    );
                                                }
                                                onBlurRhf();
                                            },
                                            "data-testid": `Autocomplete-${name}-input`,
                                        }}
                                        // RHF
                                        error={!!formState.errors[name]}
                                        helperText={
                                            formState.errors[name] ? (
                                                <TMC_FormHelperText
                                                    inputName={name as string}
                                                    helperText={
                                                        formState.errors?.[name]
                                                            ?.message as string
                                                    }
                                                    isRtlLang={isRtlLang}
                                                />
                                            ) : null
                                        }
                                    />
                                );
                            }}
                            renderTags={renderTags}
                            renderOption={(
                                props: any,
                                option: any,
                                ...rest
                            ) => {
                                // Permet de rendre l'option du addNewValue différemment des autres si il y a surcouche
                                if (option?.id === -1)
                                    return renderOptionDefault(props, option);
                                return renderOption(props, option, ...rest);
                            }}
                            // RHF
                            value={value || (multiple ? [] : null)}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== "") {
                                    filtered.push({
                                        id: -1,
                                        name: `${fmtActions("Add")} "${params.inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            onChange={(
                                event,
                                value: AutocompleteValue<
                                    T,
                                    Multiple,
                                    DisableClearable,
                                    FreeSolo
                                >,
                            ) => {
                                if (Array.isArray(value)) {
                                    const lastItem = value[value.length - 1];

                                    if (value.length === 0) {
                                        onChange(value);
                                    } else if (
                                        lastItem &&
                                        typeof lastItem !== "string" &&
                                        lastItem.id === -1
                                    ) {
                                        onAddNewValue(
                                            addNewValueParsed(lastItem),
                                        );
                                    } else {
                                        const filteredValue = value.filter(
                                            (v) =>
                                                typeof v !== "string" &&
                                                v.id !== -1,
                                        );
                                        onChange(filteredValue);
                                    }
                                } else {
                                    if (
                                        value &&
                                        typeof value !== "string" &&
                                        value?.id === -1
                                    ) {
                                        onAddNewValue(addNewValueParsed(value));
                                    } else {
                                        onChange(value);
                                    }
                                }
                            }}
                        />
                    )}
                />
            )}
        </>
    );
};
