/**
 * This function will convert a row to a row adapted for the matrix table.
 * @param {any} row
 * @param {number} parentDepth=0
 * @returns {any}
 */

export const rowFormatter = (row: any, parentDepth: number = 0): any => {
    row.isExpanded = false;
    row.depth = parentDepth;

    if (row.children && row.children.length > 0) {
        for (const child of row.children) {
            rowFormatter(child, parentDepth + 1);
        }
    } else {
        row.children = [];
    }

    return row;
};
