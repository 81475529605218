import { GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationDeleteUser,
    mutationStatusUser,
    useIndexUsers,
} from "src/api/tms-users/users";
import { Type_index_user } from "src/api/tms-users/users/types";

import { Page_UsersComponent } from "./Page_Users.component";

export type Type_modalDeleteUser = {
    isOpen: boolean;
    props?: Type_index_user;
};

export const Page_UsersContainer = () => {
    const [pagination, setPagination] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 25,
    });

    const [requestConfig, setRequestConfig] = useState<Type_RequestConfig>({
        params: {
            page: 1,
            per_page: 25,
        },
    });

    const { isFetching, data: usersFetched } =
        useIndexUsers(requestConfig) || {};

    const { mutate: mutateUserStatus } =
        mutationStatusUser(requestConfig) || {};

    const [modalDeleteUser, setModalDeleteUser] =
        useState<Type_modalDeleteUser>({
            isOpen: false,
        });

    useEffect(() => {
        setRequestConfig({
            params: {
                page: pagination.page + 1,
                per_page: pagination.pageSize,
            },
        });
    }, [pagination]);

    const rowCountRef = useRef(usersFetched?.total || 0);

    const rowCount = useMemo(() => {
        if (usersFetched?.total !== undefined) {
            rowCountRef.current = usersFetched?.total;
        }
        return rowCountRef.current;
    }, [usersFetched?.total]);

    const { mutate: mutateUserDelete } =
        mutationDeleteUser(requestConfig) || {};

    const removeUser = (id: number) => {
        mutateUserDelete(id);
    };

    const changeStatus = (id: number, user: Type_index_user) => {
        mutateUserStatus({
            id,
            enabled: !user.enabled,
        });
    };

    const users = usersFetched?.users ?? [];

    return (
        <Page_UsersComponent
            isFetching={isFetching}
            users={users}
            changeStatus={changeStatus}
            removeUser={removeUser}
            modalDeleteUser={modalDeleteUser}
            setModalDeleteUser={setModalDeleteUser}
            pagination={pagination}
            setPagination={setPagination}
            rowCount={rowCount}
            requestConfig={requestConfig}
        />
    );
};
