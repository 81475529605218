import { useEffect, useState } from "react";

import { useIndexMetadataProjects } from "src/api/tms-commons/metadata";
import { useProjectCover, useShowProject } from "src/api/tms-commons/projects";
import { useIndexProjectLanguages } from "src/api/tms-projects/languages";
import { useProject } from "src/contexts/project";
import { Page_ProjectDetailsComponent } from "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectDetail/Page_ProjectDetails.component";

export const Page_ProjectDetailsContainer = () => {
    // get context for project
    const { project } = useProject();

    const { isFetching: isFetchingProject, data: projectData } =
        useShowProject(project?.id) || {};

    const { data: img } = useProjectCover(projectData || null) || {};

    const [metadataProjects, setMetadataProjects] = useState<
        Record<string, string>
    >({});

    const { data, isFetching: isFetchingMetadata } =
        useIndexMetadataProjects() || {};

    useEffect(() => {
        if (data) {
            const result: Record<string, string> = {};
            data.forEach((metadata) => {
                result[metadata.key as string] = metadata.name;
            });
            setMetadataProjects(result);
        }
    }, [data]);

    const { isFetching: isFetchingLanguage, data: languages } =
        useIndexProjectLanguages(project?.id) || {};

    return (
        <Page_ProjectDetailsComponent
            project={projectData || null}
            metadataProjects={metadataProjects}
            img={img as string | ""}
            languages={languages}
            isFetchingMetadata={isFetchingMetadata}
            isFetchingLanguage={isFetchingLanguage}
            isFetchingProject={isFetchingProject}
        />
    );
};
