import { useCallback, useEffect, useReducer, useState } from "react";
import ReactDataGrid, {
    CellMouseEvent,
    Column,
    DataGridProps,
    RenderRowProps,
    RowsChangeData,
} from "react-data-grid";

import { ConfirmDeleteModal } from "src/components";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { DataGridStyled, LoadingAbsolute } from "./MatrixDataGrid.style";
import { MatrixColumns } from "./Renderers/ColumnsRenderer";
import { ContextMenu } from "./Renderers/ContextMenuRenderer";
import { DraggableRowRenderer } from "./Renderers/DraggableRowRenderer";

import { Spinner } from "..";

export type Row<R> = {
    id: number;
    children: R[] | [];
    depth: number;
    parent_id: number | null;
    isExpanded?: boolean;
    name?: string;
    hidden?: boolean;
};

type Action<R> = {
    type: "toggleSubRow" | "deleteSubRow" | "updateRows";
    id?: number;
    updatedRows?: R[];
};

export type Type_MatrixDataGrid_Props<R> = DataGridProps<R> & {
    rows: R[];
    columns: Column<R>[];
    isDragable?: boolean;
    isFetching?: boolean;
    isLoading?: boolean;
    setRows: (R: any) => any;
    handleRowsChange?: (row: R[], column: string) => Promise<void>;
    onDelete?: (row: R) => void;
    onUpdate?: (row: R) => void;
    hideIdColumn?: boolean;
    hideSelectColumn?: boolean;
    hideContextMenu?: boolean;
    disableHighlightCells?: boolean;
};

type Type_modalDelete<R> = {
    isOpen: boolean;
    row?: R;
};

export const MatrixDataGrid = <R extends Row<R>>({
    rows: _rows,
    setRows,
    columns,
    isDragable = false,
    isFetching = false,
    isLoading = false,
    handleRowsChange = async () => {},
    onUpdate = () => {},
    onDelete = () => {},
    hideIdColumn = false,
    disableHighlightCells = false,
    hideSelectColumn = false,
    hideContextMenu = false,
    ...props
}: Type_MatrixDataGrid_Props<R>) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtConfirmDelete } = useCoreIntl(
        "Modal.ConfirmDelete",
    );

    const getChildrenCountRecursive = (rows: R[], parentId: number): number => {
        let count = 0;
        for (const row of rows) {
            if (row.parent_id === parentId) {
                count++; // Count the direct children
                count += getChildrenCountRecursive(rows, row.id); // Recursively count nested children
            }
        }
        return count;
    };

    const toggleSubRow = (rows: R[], id: number): any[] => {
        const rowIndex = rows.findIndex((r) => r.id === id);
        const row = rows[rowIndex];
        const { children } = row;
        if (!children) return rows;

        const newRows = [...rows];
        newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };

        if (row.isExpanded) {
            const nestedChildrenCount = getChildrenCountRecursive(
                rows,
                Number(id),
            );

            newRows.splice(rowIndex + 1, nestedChildrenCount);
        } else {
            newRows.splice(rowIndex + 1, 0, ...children);
        }
        return newRows;
    };

    const reducer = (rows: R[], { type, id, updatedRows }: Action<R>): R[] => {
        switch (type) {
            case "toggleSubRow":
                return toggleSubRow(rows, Number(id));
            case "updateRows":
                return updatedRows || rows;
            default:
                return rows;
        }
    };

    const [modalDeleteRow, setModalDeleteRow] = useState<Type_modalDelete<R>>({
        isOpen: false,
    });

    const [selectedCell, setSelectedCell] = useState<null | {
        rowId: number;
        columnId: number;
    }>(null);

    const [rows, dispatch] = useReducer(reducer, _rows);

    useEffect(() => {
        setRows(rows);
    }, [rows]);

    const [selectedRows, setSelectedRows] = useState<any>(
        (): ReadonlySet<number> => new Set(),
    );

    const [contextMenuProps, setContextMenuProps] = useState<{
        row: R;
        anchor: CellMouseEvent;
    } | null>();

    const handleCloseContextMenu = () => {
        setContextMenuProps(null);
    };

    useEffect(() => {
        updateRows(_rows);
    }, [_rows]);

    const renderRow = useCallback(
        (key: React.Key, props: RenderRowProps<R>) => {
            function onRowReorder(fromIndex: number, toIndex: number) {
                // TODO to fix

                // const toTarget = { index: fromIndex, id: rows[toIndex].id };
                const newRows = [...rows];

                // Remove the item from its original position
                const [removed] = newRows.splice(fromIndex, 1);

                // Adjust the toIndex if it was after the removed item
                toIndex = toIndex > fromIndex ? toIndex - 1 : toIndex;

                // Insert the removed item at the new position
                newRows.splice(toIndex, 0, removed);

                // newRows.splice(toIndex, 0, newRows.splice(from.index, 1)[0]);

                updateRows(newRows);
            }
            if (props.row.hidden) return null;
            return (
                <DraggableRowRenderer<R>
                    key={props.row.id}
                    enableDragDrop={isDragable}
                    onRowReorder={onRowReorder}
                    {...props}
                />
            );
        },
        [_rows, rows],
    );

    const highlightUpdatedCell = (rowId: number, columnId: number) => {
        const cell = document.querySelector(
            `[data-rowid="${rowId}"] > [aria-colindex="${columnId}"]`,
        ) as HTMLElement;
        const iconId = `update-icon-${rowId}-${columnId}`;

        // Check if the cell already contains the icon
        const existingIcon = document.getElementById(iconId);

        if (!cell.classList.contains("cell-updated")) {
            cell.classList.add("cell-updated");
            cell.style.position = "relative";

            // Create the update icon only if it doesn't exist
            if (!existingIcon) {
                const icon = document.createElement("i");
                icon.id = iconId;
                icon.classList.add("fa-arrows-rotate", "fa-solid");
                icon.style.position = "absolute";
                icon.style.right = "5px";
                icon.style.top = "5px";
                icon.style.color = `${COLORS.yellow800}`;

                // Append the icon to the updated cell
                cell.appendChild(icon);
            }
        }
    };

    const updateRows = (rows: R[]) => {
        dispatch({
            type: "updateRows",
            updatedRows: rows,
        });
    };
    const onRowsChange = async (
        rows: R[],
        { indexes, column }: RowsChangeData<R>,
    ) => {
        const updatedRows: R[] = indexes.map((index) => rows[index]);

        handleRowsChange(updatedRows, column.key);

        updateRows(rows);

        setTimeout(() => {
            for (const row of updatedRows) {
                if (!disableHighlightCells) {
                    highlightUpdatedCell(row.id, column.idx + 1);
                }
            }
        }, 100);
    };

    function handleFill({ columnKey, sourceRow, targetRow }: any): R {
        const topLevelKey = columnKey.split(".")[0];
        targetRow[topLevelKey] = sourceRow[topLevelKey];
        return { ...targetRow, [columnKey]: sourceRow[columnKey as keyof R] };
    }

    const handleContextMenu = ({ row }: { row: R }, event: CellMouseEvent) => {
        if (!hideContextMenu) {
            if (event.preventGridDefault) {
                event.preventGridDefault();
            }
            event.preventDefault();
            setContextMenuProps({
                row: row,
                anchor: event,
            });
        }
    };

    const cellSelected = ({ row, column }: any) => {
        const colIndex = column.idx + 1;
        const updatedCell = document.querySelector(
            `[data-rowid="${row.id}"] > [aria-colindex="${colIndex}"]`,
        ) as HTMLElement;

        if (updatedCell.classList.contains("cell-updated")) {
            setSelectedCell({
                rowId: row.id,
                columnId: colIndex,
            });
        }
    };

    useEffect(() => {
        const handleClick = () => {
            if (selectedCell) {
                highlightUpdatedCell(selectedCell.rowId, selectedCell.columnId);
                setSelectedCell(null);
            }
        };

        if (selectedCell) {
            document.addEventListener("click", handleClick);
        }
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [selectedCell]);

    return (
        <>
            <DataGridStyled>
                {isFetching && (
                    <LoadingAbsolute>
                        <Spinner />
                    </LoadingAbsolute>
                )}

                <ReactDataGrid
                    columns={MatrixColumns({
                        columns,
                        dispatch,
                        enableDragDrop: isDragable,
                        isLoading,
                        handleContextMenu: handleContextMenu,
                        hideIdColumn,
                        hideSelectColumn,
                    })}
                    rows={rows}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    rowHeight={47}
                    onCellDoubleClick={cellSelected}
                    renderers={{ renderRow }}
                    onRowsChange={onRowsChange}
                    onCellContextMenu={handleContextMenu}
                    rowKeyGetter={(row) => row.id}
                    onFill={handleFill}
                    headerRowHeight={32}
                    className={`rdg-light fill-grid ${
                        isFetching ? "opacity-50" : ""
                    }`}
                    {...props}
                />

                {contextMenuProps && (
                    <ContextMenu
                        onUpdate={onUpdate}
                        onDelete={(row: R) =>
                            setModalDeleteRow({ isOpen: true, row })
                        }
                        onClose={handleCloseContextMenu}
                        {...contextMenuProps}
                    />
                )}
            </DataGridStyled>

            <ConfirmDeleteModal
                open={modalDeleteRow.isOpen}
                header={{
                    title: fmtConfirmDelete("Title"),
                    onClose: () => setModalDeleteRow({ isOpen: false }),
                }}
                actions={{
                    onClose: {
                        onClick: () => setModalDeleteRow({ isOpen: false }),
                        content: fmtActions("Cancel"),
                    },
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteRow({ isOpen: false });
                            if (modalDeleteRow.row) {
                                onDelete(modalDeleteRow.row);
                            }
                        },
                        content: fmtActions("Confirm"),
                    },
                }}
                form={{
                    validationString: modalDeleteRow.row?.name,
                }}
            ></ConfirmDeleteModal>
        </>
    );
};
