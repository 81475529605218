import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateKeyDate,
    mutationUpdateKeyDate,
    useIndexKeyDate,
} from "src/api/tms-scheduling/keyDates";
import {
    Type_post_keyDate,
    Type_put_keyDate,
    Type_show_keyDate,
} from "src/api/tms-scheduling/keyDates/types";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTasks";
import { SelectTimeModel } from "src/components/Components_Teamoty/forms/select/SelectTimeModel";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Schema_keyDate = Yup.object().shape({
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    date: Yup.mixed<Dayjs>().required(FORM_ERR_FMT.REQUIRED),
    color: Yup.string().nullable(),
    SCurve: Yup.boolean(),
    bufferAlert: Yup.number().nullable(),
    bufferValue: Yup.number().nullable(),
    timeModelId: Yup.number().required(FORM_ERR_FMT.REQUIRED),
    areas: Yup.array().nullable(),
    taskNumbers: Yup.array().nullable(),
});

const initialValue: Type_post_keyDate = {
    names: {},
    color: undefined,
    code: undefined,
    date: undefined,
    SCurve: false,
    bufferAlert: undefined,
    bufferValue: undefined,
    timeModelId: undefined,
    areas: [],
    taskNumbers: [],
};

type Type_Props_KeyDateForm = {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    defaultValues: Type_show_keyDate | { date: any };
    closeDialog: () => void;
};

export const KeyDateForm = ({
    setIsLoading,
    closeDialog,
    defaultValues,
}: Type_Props_KeyDateForm) => {
    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.KeyDate");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");
    const { formatMessageWithPartialKey: fmtDateForm } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.DateForm",
    );

    // Api
    const { mutateAsync: mutateCreateKeyDate } = mutationCreateKeyDate();
    const { mutateAsync: mutateUpdateKeyDate } = mutationUpdateKeyDate();
    const { data: fetchedKeyDates, isFetching } = useIndexKeyDate();

    // Rhf
    const form = useForm({
        ...useFormDefaultConfig,
        defaultValues: {
            ...initialValue,
            ...defaultValues,
        },
        resolver: yupResolver<any>(Schema_keyDate),
    });

    // Utils
    const handleSubmit = async (
        values: Type_post_keyDate | Type_put_keyDate,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        setIsLoading(true);
        try {
            if ((defaultValues as Type_show_keyDate)?.id)
                await mutateUpdateKeyDate({
                    id: (defaultValues as Type_show_keyDate)?.id,
                    data: values as Type_put_keyDate,
                });
            else await mutateCreateKeyDate(values as Type_post_keyDate);
            setIsLoading(false);

            closeDialog();
        } catch (err) {
            console.log(err);
        }
    };

    const selectedColors: string[] = useMemo(() => {
        return !isFetching && fetchedKeyDates
            ? fetchedKeyDates.map(({ color }) => color!)
            : [];
    }, [fetchedKeyDates, isFetching]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"keyDate"}>
                <Stack rowGap={6}>
                    <InputMultiLanguages name="names" />
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField name="code" label={fmtLabels(`Code`)} />
                        </Grid>
                        <Grid item xs={6} alignSelf={"end"}>
                            <ColorPicker
                                name="color"
                                data-testid="PriorityOfConstraint-Drawer-color"
                                selectedColors={selectedColors}
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        display="flex"
                        direction="row"
                        flexWrap="nowrap"
                        spacing={4}
                    >
                        <DatePicker
                            name="date"
                            label={fmtLabels("Date")}
                            data-testid="Project-Settings-DayOff-Drawer-StartDate"
                        />
                        <SelectTimeModel
                            name={"timeModelId"}
                            size={"small"}
                            label={fmtDateForm("TimeModel")}
                            variant={"outlined"}
                            data-testid="Form-KeyDate-timeModelId"
                            custom={false}
                        />
                    </Stack>
                    <AutocompleteAreas
                        renderInputProps={{ variant: "outlined" }}
                        label={fmtDateForm("Areas")}
                        name="areas"
                        multiple
                    />
                    <AutocompleteTasks
                        renderInputProps={{ variant: "outlined" }}
                        label={fmtDateForm("Tasks")}
                        name="taskNumbers"
                        multiple
                    />
                    <Typography variant="body1Bold">
                        {fmt("Options")}
                    </Typography>
                    <Switch
                        sx={{
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                        name={"SCurve"}
                        label={fmtLabels("DisplayOnScurve")}
                        data-testid="MetadataUser-DrawerContent-filterable"
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};
