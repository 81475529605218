import { Box } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useEffect } from "react";
import {
    CalculatedColumn,
    RenderCellProps,
    RenderEditCellProps,
} from "react-data-grid";
import * as Yup from "yup";

import { ColorPicker } from "src/components/Components_Common";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";

type Type_ColorPickerColumnProps<R> = {
    defaultColor: string;
    column: CalculatedColumn<R>;
    row: R;
    onColorChange: (color: string) => void;
};

const ColorPickerColumn = ({
    defaultColor,
    column,
    row,
    onColorChange,
}: Type_ColorPickerColumnProps<any>) => {
    const { key } = column;

    const Schema_Trade = Yup.object().shape({
        color: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    });

    const formik = useFormik({
        initialValues: {
            color: defaultColor,
        },
        validationSchema: Schema_Trade,
        onSubmit: () => {},
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        formik.setValues({
            color: defaultColor,
        });
    }, [defaultColor]);

    useEffect(() => {
        const newColor = formik.values.color;
        if (newColor !== defaultColor) {
            onColorChange(newColor);
        }
    }, [formik.values.color]);

    return (
        <FormikProvider value={formik}>
            <ColorPicker
                hideText
                data-testid={`Matrix-${key}-${row.id}`}
                name="color"
                fullWidth
                sx={{ boxShadow: "none", border: "none" }}
            />
        </FormikProvider>
    );
};

export const ColorPickerCellEdit = ({
    column,
    row,
    onRowChange,
}: RenderEditCellProps<any> | RenderCellProps<any>) => {
    const { key } = column;

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pl: 5,
            }}
        >
            <ColorPickerColumn
                column={column}
                row={row}
                defaultColor={row[key]}
                onColorChange={(color) => {
                    onRowChange({ ...row, [key]: color });
                }}
            />
        </Box>
    );
};
