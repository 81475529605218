import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import * as axiosRetry from "axios-retry";

import { getLocalStorageItem } from "src/utils/localStorageServices";
import { redirectToIdentification } from "src/utils/urlFormatter";

import { Url } from "./paths";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

// Intercepteur de réponse, redirection vers le login si conditions respectées
axios.interceptors.response.use(
    (response) => response,
    async (err) => {
        const status = err?.response?.status;
        const requestUrl = err?.response?.config?.url;

        if (
            (status === 401 && !requestUrl.includes("login")) || // Non authentifié
            status === 403 || // Accès refusé
            status === 419 // Jeton expiré
        ) {
            // if (import.meta.env.PROD) {   // TODO fix tests
            const lang = getLocalStorageItem("language") || "en_GB";
            window.location.href = `/${lang}/login`;
            // }
        }

        return Promise.reject(err);
    },
);

// Configuration de la gestion du réessai automatique
axiosRetry.default(axios, {
    retries: 3, // Nombre de tentatives
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error: AxiosError) => {
        const status = error?.response?.status;
        return status === 500 || status === 501; // Réessaye seulement si 500 ou 501
    },
    onRetry: (retryCount: number, error: AxiosError) => {
        console.debug(`Axios retry count: `, [
            retryCount,
            error?.request,
            error?.response,
        ]);
    },
});

// Enumération des codes de statut HTTP
export enum StatusCodes {
    OK = 200,
    CREATED = 201,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    ERROR = 500,
}

export type Type_RequestConfig = AxiosRequestConfig & {
    withCredentials?: boolean;
    projectId?: number;
    subProjectId?: number;
    versionId?: number;
    taskId?: number;
    sequenceId?: number;
    resourceId?: number;
    areaId?: number;
    taskNumber?: number;
    taskAreaId?: number;
    constraintId?: number;
    token?: string;
};

type Type_Headers = {
    language: string;
    Accept: string;
    projectId?: number;
    subProjectId?: number;
    versionId?: number;
    taskId?: number;
    sequenceId?: number;
    resourceId?: number;
    areaId?: number;
    taskNumber?: number;
    taskAreaId?: number;
    constraintId?: number;
    token?: string;
};

export type ApiResponse<T> = {
    data: {
        data: T;
        success: boolean;
    };
};

// Centralisation de la gestion des headers
export const getHeaders = (config?: Type_RequestConfig): Type_Headers => {
    const language = getLocalStorageItem("language");

    return {
        language,
        Accept: "application/json",
        ...(config || {}),
    };
};

// Fonction utilitaire pour la gestion des réponses
const handleResponse = <T>(
    response: AxiosResponse,
    options?: {
        callback?: (response: AxiosResponse) => any;
        returnHeaders?: boolean;
    },
): { status: number; data: T; success: boolean } | any => {
    if (typeof options?.callback === "function") {
        return options.callback(response);
    }

    return {
        status: response.status,
        data: response.data,
        ...(options?.returnHeaders ? { headers: response.headers } : {}),
        success: true,
    };
};

// Fonction utilitaire pour la gestion des erreurs
const handleError = (error: AxiosError) => {
    if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
        redirectToIdentification(error?.config?.url ?? "");
    }
    return Promise.reject({
        status: error?.response?.status,
        data: error.response?.data,
        success: false,
    });
};

export type PostReturnType = Promise<any>;

export const post = async (
    body: object,
    url: string,
    config?: Type_RequestConfig,
): PostReturnType => {
    return axios
        .post(url, body, {
            ...config,
            headers: { ...getHeaders(config) },
        })
        .then(handleResponse)
        .catch(handleError);
};

export type PutReturnType = Promise<any>;

export const put = async (
    body: object,
    url: string,
    config?: Type_RequestConfig,
): PutReturnType => {
    return axios
        .put(url, body, {
            headers: getHeaders(config),
            ...config,
        })
        .then(handleResponse)
        .catch(handleError);
};

export type GetReturnType = Promise<any>;

export const get = async (
    url: string,
    config?: Type_RequestConfig,
    callback?: any,
    signal?: AbortSignal,
): GetReturnType => {
    return await axios
        .get(url, {
            headers: getHeaders(config),
            ...config,
            ...(signal && { signal }),
        })
        .then((response: AxiosResponse) =>
            handleResponse(response, {
                callback: callback,
                returnHeaders: true,
            }),
        )
        .catch(handleError);
};

export type RemoveReturnType = Promise<any>;

export const remove = async (
    url: string,
    config?: Type_RequestConfig,
): GetReturnType => {
    return axios
        .delete(url, {
            headers: getHeaders(config),
            ...config,
        })
        .then(handleResponse)
        .catch(handleError);
};

// Récupération du jeton CSRF
export const getCsrfToken = async (
    config?: Type_RequestConfig,
): GetReturnType => {
    return axios
        .get(Url.CSRF, {
            headers: getHeaders(config),
            ...config,
        })
        .then(handleResponse)
        .catch(handleError);
};

// Récupération d'une image
export const getImg = async (
    url: string,
    config?: Type_RequestConfig,
): GetReturnType => {
    return get(url, { responseType: "blob", ...config });
};
