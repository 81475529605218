import { Dayjs } from "dayjs";

import { displayFormattedDate } from "src/utils/date";

type Type_StartDateEndDateCellProps = {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
};

export const StartDateEndDateCell = ({
    startDate,
    endDate,
}: Type_StartDateEndDateCellProps) => {
    const formattedStartDate = displayFormattedDate(startDate);
    const formattedEndDate = displayFormattedDate(endDate);

    return (
        <span>
            {formattedStartDate === formattedEndDate
                ? `${formattedStartDate}`
                : `${formattedStartDate} → ${formattedEndDate}`}
        </span>
    );
};
