import { Autocomplete, Stack } from "@mui/material";
import { useState } from "react";
import { type RenderCellProps, RenderEditCellProps } from "react-data-grid";

import { TMC_Chip, TMC_TextField } from "src/components/Components_Common";
import { COLORS } from "src/theme/stylesheet";

const extractValueFromRow = (row: any, key: string) => {
    const keys = key.split(".");
    let value = row;
    for (const k of keys) {
        if (value && typeof value === "object" && k in value) {
            value = value[k];
        } else {
            value = null;
            break;
        }
    }
    return value != null ? (Array.isArray(value) ? value : [value]) : null;
};

export const AutoCompleteEditCell = (props: RenderEditCellProps<any>) => {
    const { column, row, onRowChange } = props;
    const rowValue = extractValueFromRow(row, column.key);

    const [isFocused, setIsFocused] = useState(true);

    const handleChange = (eventValue: any) => {
        const selectedOption = eventValue.find(
            (item: any) => typeof item === "object",
        );
        onRowChange({
            ...row,
            [column.key.split(".")[0]]: selectedOption,
        });
    };

    const filterOptions = (
        options: any[],
        { inputValue }: { inputValue: string },
    ) => {
        return options.filter((option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()),
        );
    };

    return (
        <Autocomplete
            data-testid={`autocomplete-matrix-${column.key}-${row.id}`}
            id={`autocomplete-datagrid-${column.key}-${row.id}`}
            multiple
            fullWidth
            open={isFocused}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            filterOptions={filterOptions}
            disableClearable
            inputMode="text"
            disableCloseOnSelect
            autoHighlight
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => handleChange(value)}
            value={rowValue || []}
            options={column.options || []}
            renderInput={(params) => (
                <TMC_TextField
                    {...params}
                    size="small"
                    sx={{ py: 2, px: 1, outline: "none" }}
                    fullWidth
                    variant="standard"
                />
            )}
            renderTags={() => <></>}
            renderOption={(
                { className: _className, style: _style, ...props }: any,
                option,
            ) => {
                const isSelectedOption =
                    rowValue && rowValue.includes(option.id);

                return (
                    <TMC_Chip
                        backgroundColor={
                            isSelectedOption ? COLORS.blue500 : COLORS.grey
                        }
                        clickable={true}
                        sx={{ mx: 2, my: 1, color: COLORS.moon700 }}
                        label={option.name}
                        id={option.id}
                        {...props}
                    />
                );
            }}
            style={{ width: 200 }}
        />
    );
};

export const AutoCompleteCell = (props: RenderCellProps<any>) => {
    const { column, row } = props;

    const rowValue = extractValueFromRow(row, column.value || "");
    return (
        <Stack
            spacing={2}
            height={"100%"}
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
        >
            {rowValue &&
                rowValue.map((label: string, index: number) => (
                    <TMC_Chip
                        data-testid={`${column.key}-${row.id}`}
                        key={index}
                        label={label}
                    />
                ))}
        </Stack>
    );
};
