import { FormikValues } from "formik";

import {
    Type_index_sequence,
    Type_post_dup_sequence,
    Type_put_sequence,
    Type_sch_index_sequence,
    Type_sch_post_dup_sequence,
    Type_sch_post_sequence,
    Type_sch_put_sequence,
    Type_sch_show_sequence,
    Type_show_sequence,
} from "src/api/tms-scheduling/sequences/types";
import { Type_event_sequence } from "src/hooks/useChannel";
import { DEFAULT_COLORS } from "src/theme/stylesheet";
import { formatDateForBack, formatDateForFront } from "src/utils/date";

export const formatterIndexSequences = (
    sequences: Type_sch_index_sequence[],
): Type_index_sequence[] => {
    return sequences.map((sequence: Type_sch_index_sequence) => {
        return formatterIndexSequence(sequence);
    });
};

export const formatterIndexSequence = (
    sequence: Type_sch_index_sequence,
): Type_index_sequence => {
    return {
        id: sequence.id,
        number: sequence.number,
        code: sequence.code,
        name: sequence.name,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: formatDateForFront(sequence.earliestDate),
        sequenceFolder: sequence.sequenceFolder,
        optimizeByArea: sequence.optimizeByArea,
        enabled: sequence.enabled,
    };
};

export const formatterShowSequence = (
    sequence: Type_sch_show_sequence,
): Type_show_sequence => {
    return {
        id: sequence.id,
        number: sequence.number,
        code: sequence.code ? sequence.code : "",
        names: sequence.names,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: formatDateForFront(sequence.earliestDate),
        sequenceFolder: sequence.sequenceFolder,
        subProject: sequence.subProject,
        version: sequence.version,
        enabled: sequence.enabled,
        fixedSize: sequence.fixedSize,
        optimizeByArea: sequence.optimizeByArea,
        quantity: sequence.quantity,
        time: sequence.time,
        numberOfTeams: sequence.numberOfTeams,
        workforce: sequence.workforce,
        linkType: sequence.linkType,
        optimizedFlow: sequence.optimizedFlow,
        timeModel: sequence.timeModel,
        areas: sequence.areas,
        notesCount: sequence.notesCount,
    };
};

export const formatterIndexToEventSequence = (
    lang: string,
    eventSequence: Type_event_sequence,
) => {
    return {
        names: {
            [lang as string]: eventSequence.name as string,
        },
        color: (eventSequence.color as string) ?? DEFAULT_COLORS.sequence,
        sequenceFolder: eventSequence.sequenceFolder as {
            id: number;
            name: string;
            enabled: boolean;
        },
        enabled: eventSequence.enabled,
    };
};

export const formatterCreateSequence = (
    sequence: FormikValues,
): Type_sch_post_sequence => {
    return {
        names: sequence.names,
        code: sequence.code,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: formatDateForBack(sequence.earliestDate),
        sequenceFolder_id: sequence.sequenceFolder?.id,
        fixedSize: sequence.fixedSize,
        optimizeByArea: sequence.optimizeByArea,
        enabled: sequence.enabled,
        quantity: sequence.quantity,
        time: sequence.time,
        numberOfTeams: sequence.numberOfTeams,
        workforce: sequence.workforce,
        linkType: sequence.linkType,
        optimizedFlow: sequence.optimizedFlow,
        timeModel_id: sequence.timeModel?.id,
        // areas: sequence.areas,
        areas: [{ areaId: 1, order: 1 }],
    };
};

export const formatterCreateDefaultSequence = (sequence: {
    names: { [p: string]: string };
    sequenceFolder: { id: number };
}): {
    names: { [p: string]: string };
    sequenceFolder_id: number;
} => {
    return {
        names: sequence.names,
        sequenceFolder_id: sequence.sequenceFolder?.id,
    };
};

export const formatterFormSequence = (
    sequence: Type_show_sequence,
): Type_put_sequence => {
    return {
        id: sequence.id,
        names: sequence.names,
        code: sequence.code,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: sequence.earliestDate,
        sequenceFolderId: sequence.sequenceFolder.id,
        proportionalSize: !sequence.fixedSize, // on inverse la valeur car la design demande d'activer la taille proportionnelle quand le switch est true et non la taille fixe
        optimizeByArea: sequence.optimizeByArea,
    };
};

export const formatterDuplicateSequence = (
    sequence: Type_post_dup_sequence,
): Type_sch_post_dup_sequence => {
    return {
        names: sequence.names,
        destinationVersionId: sequence.version!,
        //use default values:
        withLinksSequences: false,
        withNotes: false,
        withLinksTaskAreas: false,
    };
};

export const formatterUpdateSequence = (
    sequence: Type_put_sequence,
): Type_sch_put_sequence => {
    return {
        id: sequence.id,
        names: sequence.names,
        code: sequence.code === null ? undefined : sequence.code,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: formatDateForBack(sequence.earliestDate),
        sequenceFolder_id: sequence.sequenceFolderId as number,
        fixedSize: !sequence.proportionalSize, // on inverse une deuxième fois la valeur pour envoyer la bonne au back
        optimizeByArea: sequence.optimizeByArea,
        enabled: sequence.enabled,
        // quantity: sequence.quantity,
        // time: sequence.time,
        // numberOfTeams: sequence.numberOfTeams,
        // workforce: sequence.workforce,
        // linkType: sequence.linkType,
        // optimizedFlow: sequence.optimizedFlow,
        // timeModel_id: sequence.timeModel?.id,
        // areas: sequence.areas,
        areas: [{ areaId: 1, order: 1 }],
    };
};
