import { styled } from "@mui/material/styles";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

export interface Styled_Props_Table extends DataGridProProps {
    ownerState?: {
        showCursor?: boolean;
    };
}

export const Styled_Table = styled(DataGridPro)<Styled_Props_Table>(
    ({ ownerState }) => ({
        "&.MuiDataGrid-root": {
            ".MuiDataGrid-row": {
                "&:hover": !ownerState?.showCursor
                    ? { cursor: "default" }
                    : { cursor: "pointer" },
            },
        },
    }),
) as typeof DataGridPro;
