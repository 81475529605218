import * as React from "react";
import { ReactNode } from "react";

import { useGetLanguages } from "src/api/tms-projects/languages";
import { languagesList as languagesListDefault } from "src/assets/translations";
import { InputMultiLanguagesComponent } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { useProject } from "src/contexts/project";

export type Type_Props_InputMultiLanguages = {
    name: string;
    label?: string | ReactNode;
};

export const InputMultiLanguages = ({
    ...props
}: Type_Props_InputMultiLanguages) => {
    const { isLoading, requestConfig } = useProject();

    const { data: fetchedLanguages } = useGetLanguages() || {};

    return (
        <>
            {!isLoading && !requestConfig.projectId ? (
                <InputMultiLanguagesComponent
                    {...props}
                    languagesList={languagesListDefault}
                />
            ) : (
                <InputMultiLanguagesComponent
                    {...props}
                    languagesList={fetchedLanguages}
                />
            )}
        </>
    );
};
