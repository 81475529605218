import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import * as Yup from "yup";

import {
    mutationCreateUnit,
    mutationUpdateUnit,
    useShowUnit,
} from "src/api/tms-commons/units";
import {
    Type_post_unit,
    Type_show_unit,
} from "src/api/tms-commons/units/types";
import { useSelectListUnitTypes } from "src/api/tms-commons/unitTypes";
import { Type_index_unitType } from "src/api/tms-commons/unitTypes/types";
import { LoadingBox } from "src/components";
import { Button } from "src/components/Components_Common/_MuiComponentsVariants/Button/Button.component";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { UnitTypeDrawer } from "src/drawers/unitTypes/UnitTypeDrawer";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_UnitForm = {
    onClose: () => void;
    action: Type_action;
    unitIdToUpdate: number | undefined;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_UnitForm = Yup.object().shape({
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
    symbols: Yup.object().shape(inputMultiLanguagesSchema.fields),
    unitType: Yup.number().min(1).required(FORM_ERR_FMT.REQUIRED),
});

export const StyledTmcButton = styled(Button)(({ theme }: any) => ({
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
}));

export const UnitForm = ({
    onClose,
    action,
    unitIdToUpdate,
    setIsLoading,
}: Type_Props_UnitForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Units");

    const { data } = useShowUnit(unitIdToUpdate as number);
    const { isFetching: isFetchingUnitTypes, data: unitTypes } =
        useSelectListUnitTypes();

    const { mutateAsync: mutateCreate } = mutationCreateUnit() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateUnit() || {};

    const page = "unitTypes";
    const [openDrawerUnitType, setOpenDrawerUnitType] = useState(false);

    const handleUnitTypeCreate = () => {
        setOpenDrawerUnitType(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawerUnitType(false);
    };

    const form = useTmcForm<Type_show_unit | Type_post_unit>({
        defaultValues: {
            id: unitIdToUpdate,
            names: {},
            symbols: {},
            unitType: undefined,
            enabled: true,
        },
        values: data,
        resolver: yupResolver<any>(Schema_UnitForm),
        onClose,
        setIsLoading,
    });

    const addNewUnitToUnitsList = (unitType: Type_index_unitType) => {
        form.setValue("unitType", unitType.id);
    };

    const handleSubmit = async (values: Type_show_unit | Type_post_unit) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_unit);
            } else {
                await mutateCreate(values as Type_post_unit);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating unit");
        }
    };

    const isFetching = useMemo(
        () =>
            !!(unitIdToUpdate && form.getValues("id") === undefined) ||
            isFetchingUnitTypes,
        [unitIdToUpdate, form.getValues("id"), isFetchingUnitTypes],
    );

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"units"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <Select
                            name="unitType"
                            options={unitTypes || []}
                            label={fmt(`AssignUnitType`)}
                            data-testid="UnitDrawer-Select-unitType-select"
                            variant={"outlined"}
                        />
                        <Stack
                            flexWrap={"nowrap"}
                            direction={"row"}
                            alignItems={"center"}
                        >
                            <Typography variant={"body2"}>
                                {fmt(`CanTFindUnitType`)}
                            </Typography>
                            <StyledTmcButton
                                data-testid="Unit-DrawerContent-AddOneUnitType-btn"
                                startIcon={<Icon variant="solid" icon="plus" />}
                                onClick={handleUnitTypeCreate}
                                variant="text"
                                size="medium"
                            >
                                {fmt(`CtaAddOne`)}
                            </StyledTmcButton>
                        </Stack>
                        <Stack rowGap={4}>
                            <InputMultiLanguages
                                name="names"
                                label={fmt(`UnitName`)}
                            />
                            <InputMultiLanguages
                                name="symbols"
                                label={fmt(`Symbol`)}
                            />
                        </Stack>
                    </>
                )}
            </form>

            <UnitTypeDrawer
                open={openDrawerUnitType}
                onClose={handleCloseDrawer}
                unitTypeId={null}
                onFormSubmit={addNewUnitToUnitsList}
                action={"create"}
                page={page}
            />
        </TmcFormProvider>
    );
};
