import { styled } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { COLORS } from "src/theme/stylesheet";

export const Styled_DataGrid = styled(DataGridPro)(({ theme }: any) => ({
    "&.MuiDataGrid-root": {
        borderWidth: 0,
        "& > .MuiDataGrid-main": {
            borderRadius: theme.shape.borderRadiusLarge,
            border: `1px solid ${COLORS.grey}`,
        },
        "& > .MuiDataGrid-toolbarContainer": {
            marginRight: 0,
            marginLeft: 0,
            "&  .MuiInputBase-formControl": {
                borderRadius: theme.shape.borderRadiusLarge,
            },
        },
    },
    "& .MuiDataGrid-row": {
        "& > .MuiDataGrid-cell": {
            outline: "none !important",
            maxWidth: "unset !important",
            display: "flex",
            alignItems: "center",
        },
    },
}));
