import { DrawerProps } from "@mui/material";
import React from "react";

import { useShowSubProject } from "src/api/tms-projects/subProjects";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { useDrawer } from "src/contexts/drawer";
import { useProject } from "src/contexts/project";
import { DefaultSettings_DrawerContentContainer as DrawerContentEdit } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DefaultSettings/DrawerContent/DefaultSettings_DrawerContent.container";
import { Page_DefaultSettingsComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DefaultSettings/Page_DefaultSettings.component";

export const Page_DefaultSettingsContainer = () => {
    const { setDrawer, closeDrawer } = useDrawer();
    const { requestConfig } = useProject();

    // queries & mutations
    const { isFetching, data } =
        useShowSubProject(
            requestConfig.projectId,
            requestConfig.subProjectId as number,
        ) || {};

    // utils
    const drawerOptions: DrawerProps = {
        anchor: "right",
        ModalProps: {
            BackdropProps: {
                onClick: () => null,
            },
        },
        elevation: 2,
    };

    const openDrawer = (step: string) => {
        setDrawer(
            <DrawerContentEdit
                subProject={data}
                onClose={closeDrawer}
                step={step}
            />,
            drawerOptions,
        );
    };

    return (
        <>
            {isFetching && <FullSpinner />}
            {!isFetching && data && (
                <Page_DefaultSettingsComponent
                    subProject={data}
                    openDrawer={openDrawer}
                />
            )}
        </>
    );
};
