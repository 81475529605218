import { useField, useFormikContext } from "formik";
import * as React from "react";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { DaysCheckbox } from "src/components/Components_Common/Days/DaysCheckbox";

type Type_Props_Days = {
    name: string;
    dataTestIdPrefix: string;
};

export const DaysCheckboxField = ({
    name,
    dataTestIdPrefix,
}: Type_Props_Days) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    return (
        <>
            <DaysCheckbox
                initialValue={field.value}
                valueCallback={(value) => setFieldValue(name, value)}
                dataTestIdPrefix={dataTestIdPrefix}
                name={name}
            />
            {meta.touched && meta.error && (
                <TMC_FormHelperText
                    helperText={meta.error as string}
                    inputName={name}
                />
            )}
        </>
    );
};
