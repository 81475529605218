import { useCallback, useEffect, useMemo, useState } from "react";

import { useIndexAreaTypes } from "src/api/tms-commons/areaTypes";
import {
    mutationDeleteArea,
    mutationUpdateArea,
    useIndexAreas,
} from "src/api/tms-projects/areas";
import { Type_index_area } from "src/api/tms-projects/areas/types";
import { MatrixDataGrid } from "src/components";
import { rowFormatter } from "src/components/Components_Common/MatrixDataGrid/Helpers/RowFormatter";
import { Row } from "src/components/Components_Common/MatrixDataGrid/MatrixDataGrid";
import { useDrawer } from "src/contexts/drawer";
import { useProject } from "src/contexts/project";
import { Areas_DrawerContentContainer } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/DrawerContent/Areas_DrawerContent.container";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { TableAreasColumns } from "./TableAreasColumns";

type Area_Row = Row<Type_index_area>;

export const MatrixTableAreas = () => {
    const [areas, setAreas] = useState<Area_Row[]>([]);

    const { subProject } = useProject();
    const { isFetching: isFetchingAreasTypes, data: fetchedAreaTypes = [] } =
        useIndexAreaTypes() || {};

    const { setDrawer, closeDrawer } = useDrawer();

    const { isFetching: isFetchingAreas, data: fetchedAreas } =
        useIndexAreas() || {};

    useEffect(() => {
        if (fetchedAreas && !isFetchingAreas) {
            const formattedAreas = fetchedAreas.map((area) =>
                rowFormatter(area),
            );
            setAreas(formattedAreas);
        }
    }, [isFetchingAreas]);

    const openUpdateDrawer = useCallback(
        (id: number) => {
            setDrawer(
                <Areas_DrawerContentContainer
                    action={"update"}
                    areaIdToUpdate={id}
                    onClose={closeDrawer}
                />,
            );
        },
        [subProject, areas],
    );

    const { mutateAsync: mutateUpdateArea, isLoading: updateLoadingUpdate } =
        mutationUpdateArea() || {};

    const { mutateAsync: mutateDeleteArea, isLoading: isLoadingDelete } =
        mutationDeleteArea() || {};

    const onRowsChange = async (rows: Area_Row[] | any, columnKey: string) => {
        const language = getLocalStorageItem("language");
        for (const row of rows) {
            if (columnKey === "name") {
                row.names = {
                    [language]: row.name,
                };
            }
            if (columnKey === "areaType.id") {
                row.areaTypeId = row.areaType.id;
            }
            await mutateUpdateArea(row);
        }
    };

    const isLoading = useMemo(
        () => isLoadingDelete || updateLoadingUpdate,
        [isLoadingDelete, updateLoadingUpdate],
    );

    const isFetching = useMemo(
        () => isFetchingAreasTypes || isFetchingAreas,
        [isFetchingAreasTypes, isFetchingAreas],
    );

    const Columns = TableAreasColumns(fetchedAreaTypes);

    return (
        <MatrixDataGrid<any>
            isDragable={false} // disable because doesn't work yet
            rows={areas}
            setRows={setAreas}
            isLoading={isLoading}
            isFetching={isFetching}
            handleRowsChange={onRowsChange}
            onDelete={(row) => mutateDeleteArea(row.id)}
            onUpdate={(area) => openUpdateDrawer(area.id)}
            columns={Columns}
        />
    );
};
