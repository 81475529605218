import { useTheme } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    DEFAULT_PAGINATION_MODEL,
    PAGE_SIZE_OPTIONS,
} from "src/api/pagination";
import { Type_index_projectUser } from "src/api/tms-projects/projectUsers/types";
import { USER_STATUS } from "src/api/tms-users/users/types";
import { Type_CustomMenuItem } from "src/components/Components_Common/DropdownMenuGeneric/DropdownMenuGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
    Type_menuDropDown,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import {
    UserActivityCell,
    UserNameCell,
    UserSubscriptionCell,
} from "src/components/Components_Teamoty/cells";
import { useProject } from "src/contexts/project";
import { InformationDrawer } from "src/drawers/projectUsers/InformationDrawer";
import { ProjectUserInvitationDrawer } from "src/drawers/projectUsers/ProjectUserInvitationDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteProjectUser } from "./Page_ProjectUsers.container";

export type Type_Props_ProjectUsersComponent = {
    projectUsers: Type_index_projectUser[];
    isFetching: boolean;
    pagination: GridPaginationModel;
    setPagination: Dispatch<SetStateAction<GridPaginationModel>>;
    rowCount: number;
    config: Type_RequestConfig;
    removeProjectUser: (id: number) => void;
    declineProjectUser: (id: number) => void;
    modalDeleteProjectUser: Type_modalDeleteProjectUser;
    setModalDeleteProjectUser: Dispatch<
        SetStateAction<Type_modalDeleteProjectUser>
    >;
    changeStatusProjectUser: (data: Type_index_projectUser) => void;
    acceptProjectUser: (data: Type_index_projectUser) => void;
};

export const Page_ProjectUsersComponent = ({
    projectUsers,
    isFetching,
    pagination,
    setPagination,
    rowCount,
    config,
    removeProjectUser,
    modalDeleteProjectUser,
    setModalDeleteProjectUser,
    changeStatusProjectUser,
    declineProjectUser,
    acceptProjectUser,
}: Type_Props_ProjectUsersComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Users",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLists } = useCoreIntl("Lists");

    const [openProjectUserDrawer, setProjectOpenUserDrawer] = useState(false);
    const [openDrawerInfo, setOpenDrawerInfo] = useState(false);
    const [selectedUser, setSelectedUser] = useState<number>(0);
    const { requestConfig } = useProject();
    const theme = useTheme();

    const openDrawerUserInfo = (id: number) => {
        setSelectedUser(id);
        setOpenDrawerInfo(!openDrawerInfo);
    };

    const closeDrawerInfo = () => {
        setOpenDrawerInfo(false);
    };

    const toggleProjectUserDrawer = () => {
        setProjectOpenUserDrawer(!openProjectUserDrawer);
    };

    const dropdownMenu: Type_menuDropDown = {};

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteProjectUser({
            isOpen: true,
            props: row as Type_index_projectUser,
        });
    };

    dropdownMenu.callbackStatus = (row: GridRowModel) => {
        changeStatusProjectUser(row as Type_index_projectUser);
    };

    const showItem = (row: GridRowModel) => {
        if (!requestConfig.subProjectId) {
            return (
                row.status !== USER_STATUS.TO_VALIDATE &&
                row.status !== USER_STATUS.INVITED
            );
        } else {
            return (
                row.status !== USER_STATUS.TO_VALIDATE &&
                row.status !== USER_STATUS.INVITED &&
                row.subProject !== null
            );
        }
    };

    dropdownMenu.showStatus = showItem;

    const pageName = "projectUsers";

    const gridColumns: Type_column[] = [
        {
            field: "name",
            sortable: false,
            headerName: fmtTableColumn("Name"),
            renderCell: (props: GridRenderCellParams) => {
                return (
                    <UserNameCell
                        id={props.row.id}
                        enabled={props.row.enabled}
                        email={props.row.email}
                        name={props.row.name}
                    />
                );
            },
        },
        {
            field: "companyName",
            headerName: fmtTableColumn("Company"),
        },
        {
            field: "roleName",
            headerName: fmtTableColumn("Role"),
        },
        {
            field: "subProjectName",
            headerName: fmtTableColumn("Subproject"),
        },
        {
            field: "activity",
            headerName: fmtTableColumn("Activity"),
            sortable: false,
            renderCell: (props: GridRenderCellParams) => {
                return <UserActivityCell props={props} />;
            },
        },
        {
            field: "subscription",
            colType: "subscription",
            headerName: "",
            sortable: false,
            renderCell: (props: GridRenderCellParams) => {
                return (
                    <UserSubscriptionCell
                        props={props}
                        handleAccept={() =>
                            acceptProjectUser(
                                props.row as Type_index_projectUser,
                            )
                        }
                        handleDecline={() => declineProjectUser(props.row.id)}
                        disabled={isFetching}
                    />
                );
            },
        },
    ];

    const menuItems: Type_CustomMenuItem[] = [
        {
            label: fmtLists("Actions.SeeDetails"),
            icon: {
                name: "info-circle",
                variant: "light",
            },
            call: (props: GridRowModel) => {
                openDrawerUserInfo(parseInt(props.id));
            },
        },
        {
            label: fmtLists("Actions.BanFromProject"),
            icon: {
                name: "ban",
                variant: "light",
                color: theme.palette.error.main,
            },
            call: (props: GridRowModel) => {
                callbackDelete(props);
            },
            show: (props: GridRowModel) => showItem(props),
        },
    ];

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteProjectUser({
                    isOpen: false,
                });
                removeProjectUser(modalDeleteProjectUser?.props?.id as number);
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PagesUsers-AddOne-btn"
                    onClick={toggleProjectUserDrawer}
                    page={pageName}
                    label={fmtActions("Invite")}
                />
            </HeaderGrid>
            <Table
                rows={projectUsers}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: toggleProjectUserDrawer,
                }}
                onClick={(id: GridRowId) => {
                    !isFetching && openDrawerUserInfo(parseInt(id as string));
                }}
                page={pageName}
                height={GRID_HEIGHT}
                menuDropDown={dropdownMenu}
                menuItems={menuItems}
                pagination
                {...projectUsers}
                paginationMode={"server"}
                paginationModel={pagination}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                initialState={{
                    pagination: DEFAULT_PAGINATION_MODEL,
                }}
                onPaginationModelChange={setPagination}
                rowCount={rowCount}
                hideFooter={false}
            />
            <ProjectUserInvitationDrawer
                page={pageName}
                open={openProjectUserDrawer}
                onClose={toggleProjectUserDrawer}
                requestConfig={{ ...config, ...requestConfig }}
            />
            <ModalDelete
                open={modalDeleteProjectUser.isOpen}
                onClose={() => setModalDeleteProjectUser({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteProjectUser?.props?.name as string}
                actions={actions}
            />
            <InformationDrawer
                open={openDrawerInfo}
                onClose={closeDrawerInfo}
                userId={selectedUser}
                page={pageName}
            />
        </>
    );
};
