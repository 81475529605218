import {
    Type_com_index_favouriteProject,
    Type_com_post_favouriteProject,
} from "src/api/tms-commons/favouriteProjects/types";
import { Type_index_project } from "src/api/tms-commons/projects/types";
import { formatDateForFront } from "src/utils/date";

export const formatterIndexFavouriteProject = (
    favouriteProject: Type_com_index_favouriteProject,
): Type_index_project => {
    return {
        id: favouriteProject.project.id,
        name: favouriteProject.project.name,
        startDate: formatDateForFront(favouriteProject.project.startDate),
        slug: favouriteProject.project.slug,
        thumbnail: favouriteProject.project.thumbnail,
        status: favouriteProject.project.status,
        archived: favouriteProject.project.archived,
        archivedDate: formatDateForFront(favouriteProject.project.archivedDate),
        enabled: favouriteProject.project.enabled,
        company: favouriteProject.project.company,
        lastUpdate: formatDateForFront(favouriteProject.project.lastUpdate),
        favouriteId: favouriteProject.id,
    };
};
export const formatterIndexFavouriteProjects = (
    favouriteProjects: Type_com_index_favouriteProject[],
): Type_index_project[] => {
    return favouriteProjects.map(
        (favouriteProject: Type_com_index_favouriteProject) =>
            formatterIndexFavouriteProject(favouriteProject),
    );
};

export const formatterCreateFavouriteProject = (
    id: number,
): Type_com_post_favouriteProject => {
    return {
        project_id: id,
    };
};
