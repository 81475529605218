import {
    Divider,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useLayoutSidebar } from "src/layouts/Layout_Sidebar/Layout_Sidebar_Provider";
import {
    Styled_MenuItem,
    Styled_MenuListSidebar,
    Styled_StackSideBar,
} from "src/layouts/Layout_Sidebar/NavigationMenu/NavigationMenu.style";
import {
    Type_sidebar_block,
    Type_sidebar_category,
    Type_sidebar_configuration,
} from "src/layouts/Layout_Sidebar/types";
import { URL_BASE } from "src/router";
import { COLORS } from "src/theme/stylesheet";

export const NavigationMenu = ({
    nameLabel,
    baseUrl,
    blocks,
}: Type_sidebar_configuration) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl(
        `MenuSidebar.${nameLabel}.Labels`,
    );
    const { formatMessageWithPartialKey: fmtBlockTitles } = useCoreIntl(
        `MenuSidebar.${nameLabel}.BlockTitles`,
    );
    const { hasPermission } = useUser();
    const navigate = useNavigate();
    const urlParams = useParams();
    const { isCategoryActive } = useLayoutSidebar();

    const generateCategoryPath = useCallback(
        (category: Type_sidebar_category) => {
            let path = category.urlName;
            if (category?.subCategories?.length) {
                path = path + "/" + category.subCategories[0].urlName;
            }

            const basePath = generatePath(`${URL_BASE}${baseUrl}`, {
                ...urlParams,
            });
            return `${basePath}/${path}`;
        },
        [baseUrl],
    );

    const navigateTo = useCallback(
        (category: Type_sidebar_category) => {
            return navigate(generateCategoryPath(category));
        },
        [generateCategoryPath],
    );

    return (
        <>
            {blocks?.map((block: Type_sidebar_block, ind: number) => {
                return (
                    <Stack
                        key={ind}
                        data-testid={`navigationMenu-block-${block.title}`}
                    >
                        {/* BLOCK TITLE */}
                        {block.title && (
                            <Styled_StackSideBar>
                                <Typography
                                    variant="body3Bold"
                                    color={COLORS.moonAlpha500}
                                    noWrap
                                >
                                    {fmtBlockTitles(block.title)}
                                </Typography>
                            </Styled_StackSideBar>
                        )}
                        {/* ITEMS */}
                        <Styled_MenuListSidebar>
                            {block.categories.map(
                                (
                                    category: Type_sidebar_category,
                                    index: number,
                                ) => {
                                    if (
                                        hasPermission(
                                            category.permissionKeys,
                                        ) ||
                                        category.permissionKeys.length === 0
                                    ) {
                                        // DIVIDER
                                        let divider = null;
                                        if (category.divider) {
                                            divider = (
                                                <Divider
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                />
                                            );
                                        }

                                        return [
                                            divider,
                                            <Styled_MenuItem
                                                data-testid={`menuListItem-${category?.nameLabel}`}
                                                key={ind + index}
                                                onClick={() =>
                                                    navigateTo(category)
                                                }
                                                selected={isCategoryActive(
                                                    {
                                                        nameLabel,
                                                        baseUrl,
                                                        blocks,
                                                    },
                                                    category,
                                                )}
                                                sx={{
                                                    ...category.styled,
                                                }}
                                            >
                                                {category?.icon && (
                                                    <ListItemIcon>
                                                        <Icon
                                                            variant={
                                                                category?.variant
                                                            }
                                                            icon={
                                                                category?.icon
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                )}

                                                <ListItemText
                                                    disableTypography
                                                    title={fmtLabels(
                                                        category?.nameLabel,
                                                    )}
                                                >
                                                    {fmtLabels(
                                                        category?.nameLabel,
                                                    )}
                                                </ListItemText>
                                            </Styled_MenuItem>,
                                        ];
                                    }
                                    return null;
                                },
                            )}
                        </Styled_MenuListSidebar>
                    </Stack>
                );
            })}
        </>
    );
};
