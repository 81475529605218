export enum AreaWorkLevelKeys {
    SELECT_LIST = "selectListAreaWorkLevels",
}

export enum ConstraintKeys {
    INDEX = "indexConstraints",
}

export enum ConstraintCommentKeys {
    INDEX = "indexConstraintComments",
}

export enum KeyDateKeys {
    INDEX = "indexKeyDates",
    SHOW = "showKeyDate",
}

export enum PeopleKeys {
    SELECT = "selectPeople",
    SHOW = "showPeople",
}

export enum PeopleTaskKeys {
    INDEX = "indexPeopleTask",
}

export enum PeopleTaskAreaKeys {
    INDEX = "indexPeopleTaskArea",
}

export enum ProductTypeTaskKeys {
    INDEX = "indexProductTypeTasks",
    SHOW = "showProductTypeTask",
}

export enum ProgressHistoryKeys {
    INDEX = "indexProgressHistory",
    SHOW = "showProgressHistory",
}

export enum ResourceTaskKeys {
    INDEX = "indexResourceTasks",
    SHOW = "showResourceTask",
}

export enum SequenceAreaKeys {
    INDEX = "indexSequenceAreas",
}

export enum SequenceFolderKeys {
    INDEX = "indexSequenceFolders",
    SHOW = "showSequenceFolder",
    SELECT_LIST = "selectListSequenceFolders",
    TREE = "treeSequenceFolders",
}

export enum SequenceNoteKeys {
    INDEX = "indexSequenceNotes",
    SHOW = "showSequenceNote",
    SELECT_LIST = "selectListSequenceNotes",
}

export enum SequenceKeys {
    INDEX = "indexSequences",
    SHOW = "showSequence",
}

export enum TagKeys {
    SELECT = "selectTags",
}

export enum TaskAreaKeys {
    INDEX = "indexTaskArea",
    SHOW = "showTaskArea",
}

export enum TaskLinkKeys {
    INDEX = "indexTaskLinks",
    SHOW = "showTaskLink",
}

export enum TaskNoteKeys {
    INDEX = "indexTaskNotes",
}

export enum TaskAreaNoteKeys {
    INDEX = "indexTaskAreaNotes",
}

export enum TaskKeys {
    INDEX = "indexTasks",
    SHOW = "showTask",
}
