import { Row } from "src/components/Components_Common/MatrixDataGrid/MatrixDataGrid";

/**
 * Function for appending custom properties such as "name" and "areaType".
 * @param {any} areas:any[]
 * @returns {any}
 */
export const formatAreas = (areas: any[]): any => {
    // Create a map of areas for efficient parent lookup
    const areaMap = new Map(areas.map((area) => [area.id, area]));

    // Iterate over each area and add the name_${depth} properties
    for (const area of areas) {
        // Initialize the name properties with empty strings
        area.selected = false;
        for (let i = 0; i <= getDepth(areas); i++) {
            area[`name_${i}`] = "";
            area[`areaType_${i}`] = null;
        }

        // Set the name_${depth} properties based on the area's depth and its parents' names
        let currentArea = area;
        let depth = currentArea.depth;
        while (currentArea && depth >= 0) {
            area[`name_${depth}`] = currentArea.name;
            area[`areaType_${depth}`] = currentArea.areaType;
            currentArea = areaMap.get(currentArea.parent_id);
            depth--;
        }
    }

    return areas;
};

export const getMaxOrder = (areas: any[]): number =>
    Math.max(...areas.map((area) => area.order));

export const getDepth = (areas: Row<any>[]) =>
    Math.max(...areas.map((area) => area.depth));

export const sortAreas = (data: Row<any>[]): any => {
    const sortedData: any[] = [];

    function sortRecursive(parentId: number | null) {
        data.forEach((item) => {
            if (item.parent_id === parentId) {
                sortedData.push(item);
                sortRecursive(item.id);
            }
        });
    }

    sortRecursive(null);
    return sortedData;
};

export const getKeyPath = (obj: any, path: string) => {
    return path.split(".").reduce((acc, key) => {
        if (acc === null || acc === undefined) return acc;
        return acc[key];
    }, obj);
};

export const getValue = (obj: any, key: string) => {
    const keys = key.split(".");
    let value = obj;

    for (const k of keys) {
        if (!value || value[k] === undefined) {
            return null; // Return null if the property doesn't exist or is null
        }
        value = value[k];
    }
    return value;
};

export function generateComparator(key: string, order: any) {
    return function (a: any, b: any) {
        const valueA = getValue(a, key);
        const valueB = getValue(b, key);

        if (order === "asc") {
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
    };
}
