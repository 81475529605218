import { useMutation, useQuery } from "react-query";

import { ResourceTaskKeys } from "src/api/tms-scheduling/keys";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { formatterIndexResourceTask } from "./formatter";
import {
    createResourceTask,
    indexResourceTasks,
    removeResourceTask,
    showResourceTask,
    updateResourceTask,
} from "./services";
import {
    Type_index_resourceTasks,
    Type_sch_post_resourceTasks,
    Type_sch_put_resourceTasks,
} from "./types";

export const useIndexResourceTask = (
    callback: (resourceTasks: Type_index_resourceTasks[]) => void,
    taskId: number,
    uniqueId?: string,
) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    const isEnabled: boolean =
        !!requestConfig.projectId &&
        !!requestConfig.subProjectId &&
        !!requestConfig.versionId &&
        !!taskId;

    return useQuery({
        queryKey: [
            ResourceTaskKeys.INDEX,
            { ...requestConfig, taskId },
            uniqueId,
        ],
        queryFn: ({ signal }) =>
            indexResourceTasks({ ...requestConfig, taskId }, signal),
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            callback(formatterIndexResourceTask(data.data.data));
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
        staleTime: MEDIUM_STALE_TIME,
    });
};

export const useShowResourceTask = (callback: any, id?: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    return useQuery(
        [ResourceTaskKeys.SHOW, id, requestConfig],
        () => showResourceTask(id || 0, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                callback(formatterIndexResourceTask(data.data.data));
            },
            onError: (err) => {
                console.error(fmtErrors("GenericError", {}), err);
                addWarning({ description: fmtErrors("GenericError", {}) });
                return err;
            },
        },
    );
};

export const mutationCreateResourceTask = (taskId: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_resourceTasks) =>
            createResourceTask(data, { ...requestConfig, taskId }),
        onSuccess: (data: any) => {
            return data.data.data;
        },
        onError: (err: any): void => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationUpdateResourceTask = (taskId: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_put_resourceTasks) => {
            return updateResourceTask(data, { ...requestConfig, taskId });
        },
        onSuccess: (data) => {
            return data.data.data;
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationDeleteResourceTask = (taskId: number) => {
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) =>
            removeResourceTask(id, { ...requestConfig, taskId }),
        onSuccess: (_data, id) => {
            id;
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};
