import dayjs from "dayjs";

import { formatterSelectListCompany } from "src/api/tms-commons/companies/formatters";
import {
    formatterIndexLicenses,
    formatterPostLicense,
} from "src/api/tms-commons/licenses/formatters";
import {
    Type_com_index_project,
    Type_com_post_project,
    Type_com_put_project,
    Type_com_show_project,
    Type_index_project,
    Type_metadataProject,
    Type_post_project,
    Type_put_project,
    Type_show_project,
} from "src/api/tms-commons/projects/types";
import {
    formatDateForBack,
    formatDateForFront,
    getCurrentFormat,
    getDate,
    isValidDate,
} from "src/utils/date";

export const formatterCreateProject = (
    project: Type_post_project,
): Type_com_post_project => {
    return {
        name: project.name,
        postCode: project.postCode,
        startDate: formatDateForBack(project.startDate) as string,
        city: project.city,
        country: project.country,
        slug: project.slug,
        cover: project.thumbnail[0].data_url,
        company_id: project.companyId,
        projectTypes: project.projectTypes.map(({ id }) => Number(id)),
        license: formatterPostLicense({
            license_type_id: project.licenseType!.id!,
            startDate: project.licenseStartDate!,
            renewal: project.renewal,
            cost: project.cost,
        }),
        metadata: formatterMetadataUpdate(project?.metadata),
    };
};

export const formatterShowProject = (
    project: Type_com_show_project,
): Type_show_project => {
    return {
        id: project.id,
        name: project.name,
        startDate: formatDateForFront(project.startDate),
        postCode: project.postCode,
        city: project.city,
        country: project.country,
        slug: project.slug,
        cover: project.cover,
        thumbnail: project.thumbnail,
        companyId: project.company?.id,
        company: project?.company,
        projectTypes: project.projectTypes.sort((a: any, b: any) =>
            a.name?.localeCompare(b.name),
        ),
        metadata: formatterMetaDataProjectToArray(project.metadata),
        licenses: formatterIndexLicenses(project.licenses),
        archived: project?.archived,
        enabled: project?.enabled,
        status: project?.status,
        lastUpdate: formatDateForFront(project?.lastUpdate),
        archivedDate: formatDateForFront(project?.archivedDate),
    };
};

export const formatterIndexProjects = (
    projects: Type_com_index_project[],
): Type_index_project[] => {
    return projects.map((project: Type_com_index_project) => ({
        id: project.id,
        name: project.name,
        startDate: formatDateForFront(project.startDate),
        slug: project.slug,
        thumbnail: project.thumbnail,
        status: project.status,
        archived: project.archived,
        archivedDate: formatDateForFront(project.archivedDate),
        company: formatterSelectListCompany(project.company),
        lastUpdate: formatDateForFront(project.lastUpdate),
        enabled: project.enabled,
    }));
};

export const formatterStatusProject = (
    project: Type_put_project,
): Type_com_put_project => {
    return {
        enabled: project.enabled,
    };
};

export const formatterArchiveProject = (
    project: Type_put_project,
): Type_com_put_project => {
    return {
        archived: project.archived,
    };
};

export const formatterUpdateProject = (
    project: Type_show_project,
): Type_com_put_project => {
    let updatedProject = {
        name: project.name,
        startDate: formatDateForBack(project.startDate),
        postCode: project.postCode,
        city: project.city,
        country: project.country,
        slug: project.slug,
        company_id: project.companyId,
        projectTypes: project.projectTypes.map(
            (projectType: any) => projectType.id,
        ),
        metadata: formatterMetadataUpdate(project.metadata),
    };

    if (Array.isArray(project.thumbnail) && project.thumbnail[0]?.data_url)
        updatedProject = {
            ...updatedProject,
            cover: project.thumbnail[0]?.data_url,
        } as any;

    return updatedProject;
};

export const formatterMetadataUpdate = (
    metadata?: Record<string, string> | null,
) => {
    if (!metadata) return null;

    const formattedUpdatedMetadata: any = {};

    for (const [label, value] of Object.entries(metadata)) {
        if (Array.isArray(value) && value.length < 1) continue;
        if (value === null || value.length < 1) continue;

        formattedUpdatedMetadata[label] = isValidDate(value)
            ? formatDateForBack(value)
            : value;
    }

    return formattedUpdatedMetadata;
};

/**
 * formatted metadata object into Array
 * @param metadata
 */
export const formatterMetaDataProjectToArray = (
    metadata: Record<string, string> | null,
): Type_metadataProject[] => {
    if (!metadata) return [];

    const res: Type_metadataProject[] = [];

    for (const [label, value] of Object.entries(metadata)) {
        let result = value;
        let isDate = false;
        //check if value is date
        if (
            !Array.isArray(value) &&
            dayjs(value.substring(0, 10), "YYYY-MM-DD", true).isValid()
        ) {
            result = getDate(value).format(getCurrentFormat());
            isDate = true;
        }

        res.push({ label: label, value: result, isDate: isDate });
    }
    return res;
};
