import { useMutation, useQuery } from "react-query";

import { ApiResponse } from "src/api/fetch";
import { ProductTypeTaskKeys } from "src/api/tms-scheduling/keys";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { formatterIndexProductTypeTask } from "./formatter";
import {
    createProductTypeTask,
    indexProductTypeTasks,
    removeProductTypeTask,
    showProductTypeTask,
    updateProductTypeTask,
} from "./services";
import {
    Type_index_productTypeTasks,
    Type_sch_index_productTypeTasks,
    Type_sch_post_productTypeTasks,
    Type_sch_put_productTypeTasks,
} from "./types";

export const useIndexProductTypeTask = (taskId: number, uniqueId?: string) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    const isEnabled: boolean =
        !!requestConfig.projectId &&
        !!requestConfig.subProjectId &&
        !!requestConfig.versionId &&
        !!taskId;

    return useQuery<
        ApiResponse<Type_sch_index_productTypeTasks[]>,
        unknown,
        Type_index_productTypeTasks[]
    >({
        queryKey: [
            ProductTypeTaskKeys.INDEX,
            { ...requestConfig, taskId },
            uniqueId,
        ],
        queryFn: ({ signal }) =>
            indexProductTypeTasks({ ...requestConfig, taskId }, signal),
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        select: (data): Type_index_productTypeTasks[] => {
            return formatterIndexProductTypeTask(data.data.data);
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
        staleTime: MEDIUM_STALE_TIME,
    });
};

export const useShowProductTypeTask = (id?: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    return useQuery<
        ApiResponse<Type_sch_index_productTypeTasks[]>,
        unknown,
        Type_index_productTypeTasks[]
    >(
        [ProductTypeTaskKeys.SHOW, id, requestConfig],
        () => showProductTypeTask(id || 0, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            select: (data): Type_index_productTypeTasks[] => {
                return formatterIndexProductTypeTask(data.data.data);
            },
            onError: (err) => {
                console.error(fmtErrors("GenericError", {}), err);
                addWarning({ description: fmtErrors("GenericError", {}) });
                return err;
            },
        },
    );
};

export const mutationCreateProductTypeTask = (taskId: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_productTypeTasks) =>
            createProductTypeTask(data, { ...requestConfig, taskId }),
        onSuccess: (res) => {
            return res.data.data;
        },
        onError: (err: any): void => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationUpdateProductTypeTask = (taskId: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_put_productTypeTasks) => {
            return updateProductTypeTask(data, { ...requestConfig, taskId });
        },
        onSuccess: (res) => {
            return res.data.data;
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationDeleteProductTypeTask = (taskId: number) => {
    const { addWarning, addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmtMessage } =
        useCoreIntl("ToastMessage");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) =>
            removeProductTypeTask(id, { ...requestConfig, taskId }),
        onSuccess: (_data, id) => {
            addSuccess({
                description: fmtMessage("DeletedSuccessfully"),
            });
            id;
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};
