import { GridPaginationModel } from "@mui/x-data-grid";
import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    DEFAULT_PAGINATION_MODEL,
    PAGE_SIZE_OPTIONS,
} from "src/api/pagination";
import { Type_index_company } from "src/api/tms-commons/companies/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { CompanyDrawer } from "src/drawers/companies/CompanyDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteCompany } from "src/pages/Pages_Teamoty/UsersAndCompanies/Page_Companies/Page_Companies.container";

export type Type_Props_Page_CompaniesComponent = {
    companies: Type_index_company[];
    changeStatus: (company: Type_index_company) => void;
    deleteCompany: (id: number) => void;
    modalDeleteCompany: Type_modalDeleteCompany;
    setModalDeleteCompany: Dispatch<SetStateAction<Type_modalDeleteCompany>>;
    isFetching: boolean;
    pagination: GridPaginationModel;
    setPagination: Dispatch<SetStateAction<GridPaginationModel>>;
    rowCount: number;
    requestConfig: Type_RequestConfig;
};

export const Page_CompaniesComponent = ({
    companies,
    changeStatus,
    deleteCompany,
    modalDeleteCompany,
    setModalDeleteCompany,
    isFetching,
    pagination,
    setPagination,
    rowCount,
    requestConfig,
}: Type_Props_Page_CompaniesComponent) => {
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.Companies");

    const page = "companies";

    const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
    const [openCompanyDrawer, setOpenCompanyDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (companyId: number | null, action: Type_action) => {
        setSelectedCompany(companyId);
        setAction(action);
        setOpenCompanyDrawer(true);
    };
    const handleCreateCompany = () => openDrawer(null, "create");

    const handleEditCompany = (id: number) => openDrawer(id, "update");

    const handleCloseDrawer = () => {
        setOpenCompanyDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            sortable: true,
            headerName: fmtTableColumn("Name"),
        },
        {
            field: "cityZipCode",
            sortable: false,
            headerName: fmtTableColumn("CityZipCode"),
        },
        {
            field: "country",
            sortable: false,
            headerName: fmtTableColumn("Country"),
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleEditCompany(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_company);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteCompany({
            isOpen: true,
            props: row as Type_index_company,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteCompany({
                    isOpen: false,
                });
                deleteCompany(modalDeleteCompany?.props?.id as number);
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PagesCompanies-AddOne-btn"
                    onClick={() => handleCreateCompany()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={companies}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleCreateCompany(),
                }}
                onClick={(id: GridRowId) => {
                    handleEditCompany(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
                //pagination
                pagination
                {...companies}
                paginationMode={"server"}
                paginationModel={pagination}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                initialState={{
                    pagination: DEFAULT_PAGINATION_MODEL,
                }}
                onPaginationModelChange={setPagination}
                rowCount={rowCount}
                hideFooter={false}
            />

            <CompanyDrawer
                open={openCompanyDrawer}
                onClose={handleCloseDrawer}
                companyIdToUpdate={selectedCompany}
                action={action}
                page={page}
                requestConfig={requestConfig}
            />
            <ModalDelete
                open={modalDeleteCompany.isOpen}
                onClose={() => setModalDeleteCompany({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteCompany?.props?.name as string}
                actions={actions}
            />
        </>
    );
};
