import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Type_RequestConfig } from "src/api/fetch";
import { useIndexProjectRoles } from "src/api/tms-commons/projectRoles";
import { useSelectListProjectCompanies } from "src/api/tms-projects/projectCompanies";
import { mutationCreateProjectUser } from "src/api/tms-projects/projectUsers";
import { Type_post_projectUserInvitation } from "src/api/tms-projects/projectUsers/types";
import { mutationCreateUserInvitation } from "src/api/tms-users/users";
import { LoadingBox } from "src/components";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ProjectUserInvitationForm = {
    onClose: () => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    requestConfig: Type_RequestConfig;
};

const Schema_ProjectUserInvitation = Yup.lazy(() => {
    return Yup.object().shape({
        email: Yup.string()
            .email(FORM_ERR_FMT.MUST_BE_EMAIL)
            .required(FORM_ERR_FMT.REQUIRED),
        roleId: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        company: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        subProject: Yup.number().nullable(FORM_ERR_FMT.REQUIRED),
    });
});

export const ProjectUserInvitationForm = ({
    onClose,
    setIsLoading,
    requestConfig,
}: Type_Props_ProjectUserInvitationForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectUserInvitation",
    );
    const { subProject } = useProject();

    const { isFetching: isFetchingProjectRoles, data: projectRoles } =
        useIndexProjectRoles();
    const { isFetching: isFetchingCompanies, data: companies } =
        useSelectListProjectCompanies();
    const { mutateAsync: mutateCreateUserInvitation } =
        mutationCreateUserInvitation(requestConfig);
    const { mutateAsync: mutateCreateProjectUser } =
        mutationCreateProjectUser(requestConfig);

    const form = useForm<Type_post_projectUserInvitation>({
        ...useFormDefaultConfig,
        defaultValues: {
            email: "",
        },
        resolver: yupResolver<any>(Schema_ProjectUserInvitation),
    });

    const handleSubmit = async (values: Type_post_projectUserInvitation) => {
        setIsLoading(true);

        try {
            const user = await mutateCreateUserInvitation(values);

            if (user.data.data.id) {
                await mutateCreateProjectUser({
                    data: { ...values, subProject: subProject?.id },
                    userId: user?.data?.data.id,
                });
            }
            onClose();
        } catch (e: any) {
            console.error("Error when creating user");
        }
        setIsLoading(false);
    };

    const isFetching = useMemo(() => {
        return isFetchingProjectRoles || isFetchingCompanies;
    }, [isFetchingProjectRoles, isFetchingCompanies]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"projectUserInvitation"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <TextField
                            label={fmt("Email")}
                            data-testid="ProjectUserInvitation-Drawer-email"
                            name={"email"}
                            variant={"outlined"}
                        />
                        <Select
                            variant={"outlined"}
                            name="roleId"
                            options={formatSelectListToSelectOptionItem(
                                projectRoles || [],
                            )}
                            label={fmt("Roles")}
                            data-testid="ProjectUserInvitation-Drawer-roles"
                        />
                        <Select
                            variant={"outlined"}
                            name="company"
                            options={formatSelectListToSelectOptionItem(
                                companies || [],
                            )}
                            label={fmt("Company")}
                            data-testid="ProjectUserInvitation-Drawer-company"
                        />

                        {requestConfig.subProjectId && (
                            <TextField
                                name={""}
                                variant={"outlined"}
                                label={fmt("SubProject")}
                                defaultValue={subProject?.name}
                                disabled
                            />
                        )}
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
