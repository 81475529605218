import { FormikValues } from "formik";
import { useMutation, useQuery } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { VersionNoteKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexVersionNotes,
    formatterVersionNote,
} from "src/api/tms-projects/notes/formatters";
import {
    createVersionNote,
    deleteVersionNote,
    indexVersionNotes,
    selectListVersionNotes,
    showVersionNote,
    updateVersionNote,
} from "src/api/tms-projects/notes/services";
import { Type_prj_index_version_note } from "src/api/tms-projects/notes/types";

export const useIndexVersionNotes = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useQuery({
        queryKey: [VersionNoteKeys.INDEX, requestConfig],
        queryFn: () => indexVersionNotes(requestConfig),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const notes: Type_prj_index_version_note[] = data?.data?.data;
            notes ? callback(formatterIndexVersionNotes(notes)) : null;
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowVersionNote = (
    callback: any,
    requestConfig: Type_RequestConfig,
    id: number,
) => {
    return useQuery(
        [VersionNoteKeys.SHOW, id],
        () => showVersionNote(id, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                const note: Type_prj_index_version_note = res.data.data;
                callback(formatterVersionNote(note));
            },
            onError: (err) => {
                console.error(err);
                return err;
            },
        },
    );
};

export const mutationCreateVersionNote = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useMutation({
        mutationFn: (note: FormikValues) =>
            createVersionNote(
                note as Type_prj_index_version_note,
                requestConfig,
            ),
        onSuccess: (data: any, _newNote: FormikValues) => {
            if (data.success) {
                const note: Type_prj_index_version_note = data.data.data;
                callback(formatterVersionNote(note));
            }
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationUpdateVersionNote = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useMutation({
        mutationFn: (noteToUpdate: any) =>
            updateVersionNote(noteToUpdate, requestConfig),
        onSuccess: async (_response) => {
            const noteUpdated: Type_prj_index_version_note =
                _response.data.data;
            callback(formatterVersionNote(noteUpdated));
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useDeleteVersionNote = (
    callback: (id: number) => void,
    requestConfig: Type_RequestConfig,
) => {
    return useMutation({
        mutationFn: (id: number) => deleteVersionNote(id, requestConfig),
        onSuccess: (_data, id) => {
            callback(id);
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useSelectListVersionNotes = (
    callback: any,
    requestConfig: Type_RequestConfig,
) => {
    return useQuery({
        queryKey: [VersionNoteKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListVersionNotes(requestConfig),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            data?.data?.data ? callback(data?.data?.data) : null;
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};
