import { Box, Stack } from "@mui/material";
import React, { useState } from "react";

import { Type_selectList_projectUser } from "src/api/tms-projects/projectUsers/types";
import { Type_sch_put_people } from "src/api/tms-scheduling/people/types";
import {
    DialogGeneric,
    Type_dialogParamsDeprecated,
} from "src/components/Components_Common/Dialog/DialogGeneric";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { UserSmallCard } from "src/components/Components_Scheduling";
import { Type_createNewPeople } from "src/forms/peopleTasks/PeopleTaskForm";
import { PeopleTaskFormDialog } from "src/forms/peopleTasks/PeopleTaskFormDialog";
import { Type_event_dialog, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { hasKey } from "src/utils/object";

type Type_Props_AutocompletePeopleTasks = {
    name: string;
    data: Type_selectList_projectUser[];
    placeholder: string;
    updatePeople: (data: {
        id: number;
        data: Type_sch_put_people;
    }) => Promise<void>;
    createNewPeople: (data: Type_createNewPeople) => Promise<void>;
    label?: string;
    autoFocus?: boolean;
};

type Type_optionsWithGroup = Type_selectList_projectUser & {
    name: string;
    group: string;
    userId?: number;
};

export const AutocompletePeopleTasks = ({
    name,
    data,
    autoFocus,
    placeholder,
    updatePeople,
    createNewPeople,
}: Type_Props_AutocompletePeopleTasks) => {
    const [dialogParams, setDialogParams] =
        useState<Type_dialogParamsDeprecated>({
            open: false,
            action: "create",
            id: null,
        });
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
    const [newPeopleTaskName, setNewPeopleTaskName] = useState<string>("");

    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");
    const { formatMessageWithPartialKey: fmtExt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    useChannel({
        eventHandler: ({ event, data }) => {
            const dialogParamsData = data as Type_event_dialog;
            if (event === "updatePeople") {
                setDialogParams(dialogParamsData);
            }
        },
    });

    const options: Type_optionsWithGroup[] = data?.map(
        (person: Type_selectList_projectUser) =>
            ({
                ...person,
                name: `${person.firstName} ${person.lastName}`,
            }) as Type_optionsWithGroup,
    );

    const closeDialog = () => {
        setDialogParams((prev) => ({ ...prev, open: false, id: null }));
        setNewPeopleTaskName("");
    };
    const openDialog = () =>
        setDialogParams((_prev) => ({
            action: "create",
            open: true,
            id: null,
        }));

    const formId = "addOrUpdateExtPeopleTask";
    const title = `${dialogParams?.id ? fmtAction("Update") : fmtAction("New")} ${fmtLabels("People")}`;

    return (
        <Stack width={"100%"} flexDirection={"column"}>
            <AutocompleteFreeSoloDialog
                autoFocus={autoFocus}
                name={name}
                disableCloseOnSelect
                groupBy={(option) => option?.group}
                size="small"
                options={options}
                renderOption={(props, option) => {
                    return (
                        <Box
                            component="li"
                            key={`people-task-form-autocomplete-option-${option.id}`}
                            data-testid={`people-task-form-autocomplete-option-${option.id}`}
                            {...props}
                            sx={{ padding: 0, margin: 0 }}
                        >
                            <UserSmallCard
                                selected={props["aria-selected"] as boolean}
                                id={option.id as number}
                                firstName={option.firstName}
                                lastName={option.lastName}
                                email={option.email}
                                badgeLabel={
                                    !hasKey(option, "userId") || option?.userId
                                        ? undefined
                                        : fmtExt("Ext")
                                }
                            />
                        </Box>
                    );
                }}
                renderInputProps={{
                    placeholder: placeholder,
                }}
                onAddNewValue={(value) => {
                    setNewPeopleTaskName(value.name);
                    openDialog();
                }}
            />
            <DialogGeneric
                open={dialogParams.open}
                formId={formId}
                dialogTitle={title}
                isFormLoading={isFormLoading}
                setDialogParams={setDialogParams}
                onClose={() => setNewPeopleTaskName("")}
            >
                <PeopleTaskFormDialog
                    setIsFormLoading={setIsFormLoading}
                    dialogParams={dialogParams}
                    formId={formId}
                    onClose={closeDialog}
                    createNewPeople={createNewPeople}
                    updatePeople={updatePeople}
                    peopleTaskValue={newPeopleTaskName}
                />
            </DialogGeneric>
        </Stack>
    );
};
