import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { LoginForm } from "src/forms/login/LoginForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_LoginContainer = styled(Stack)(() => ({
    flexGrow: 1,
    maxWidth: "400px",
    width: "100%",
    justifyContent: "center",
}));

export const Page_Identification = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Identification");

    return (
        <Styled_LoginContainer data-testid="Identification-form">
            <Typography variant="h1" marginBottom={4}>
                {fmt("Hello")}
            </Typography>
            <LoginForm />
        </Styled_LoginContainer>
    );
};
