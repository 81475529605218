import { type RenderRowProps, Row } from "react-data-grid";
import { useDrag, useDrop } from "react-dnd";

import "./style.css";
import { Row as RowType } from "src/components/Components_Common/MatrixDataGrid/MatrixDataGrid";
const rowDraggingClassname = "row-dragging";
const rowOverClassname = "row-over";

export type indexObject = {
    index: number;
    id: number;
};

interface DraggableRowRenderProps<T> extends RenderRowProps<T> {
    onRowReorder: (source: number, target: number) => void;
    key: any;
    enableDragDrop?: boolean;
}

export function DraggableRowRenderer<T extends RowType<T>>({
    rowIdx,
    isRowSelected,
    className,
    enableDragDrop = false,
    onRowReorder,
    ...props
}: DraggableRowRenderProps<T>) {
    const [{ isDragging }, drag] = useDrag({
        type: "ROW_DRAG",
        item: { index: rowIdx },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: "ROW_DRAG",
        drop({ index }: { index: number }) {
            onRowReorder(index, rowIdx);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    className = `${className} ${isDragging ? rowDraggingClassname : ""} ${
        isOver ? rowOverClassname : ""
    }`;

    return (
        <Row
            ref={(ref) => {
                if (enableDragDrop && ref) {
                    drag(ref.firstElementChild);
                }
                drop(ref);
            }}
            data-rowid={props.row.id}
            rowIdx={rowIdx}
            isRowSelected={isRowSelected}
            className={className}
            {...props}
        />
    );
}
