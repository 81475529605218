import { getTaskColor } from "@cimba-digital-construction-solution/utils/dist/decoder/tools/getTaskColor";

import { formatterSelectListCompany } from "src/api/tms-commons/companies/formatters";
import { formatterIndexSubTrade } from "src/api/tms-projects/subTrades/formatters";
import {
    Type_index_task,
    Type_post_task,
    Type_put_task,
    Type_sch_index_task,
    Type_sch_post_task,
    Type_sch_put_task,
    Type_sch_show_task,
    Type_show_task,
} from "src/api/tms-scheduling/tasks/types";
import { Enum_typeFlowTask } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { Type_flowTask } from "src/components/Components_Teamoty/Flow/Flow.type";
import { DEFAULT_LANGUAGE } from "src/contexts/languages";
import { Type_event_task } from "src/hooks/useChannel";
import { formatDateForBack, formatDateForFront } from "src/utils/date";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const formatterTasksToFlow = (
    tasks: Type_sch_index_task[],
): Type_flowTask[] | null => {
    if (!tasks) return null;
    return tasks.map(formatterTaskToFlow);
};

export const formatterTaskToFlow = (
    task: Type_event_task | Type_sch_index_task,
): Type_flowTask => {
    return {
        id: task.id,
        name: task.name,
        type: task.type as Enum_typeFlowTask,
        color: getTaskColor(
            task.type,
            task.withTradeColor,
            task?.color || undefined,
            task.subTrade?.trade.color,
        ),
        code: task.code,
        duration: task.duration,
        areaQuantity: task.areaQuantity,
        team: task.team,
        group: "", // TODO: need to add group for tasks
        pt: {
            x: task.xy ? task.xy[0] : 0,
            y: task.xy ? task.xy[1] : 0,
        },
    };
};

export const formatterIndexTasks = (
    data: Type_sch_index_task[],
): Type_index_task[] => {
    return data.map((item: Type_sch_index_task) => {
        return formatterIndexTask(item);
    });
};

export const formatterIndexTask = (
    data: Type_sch_index_task,
): Type_index_task => {
    return {
        id: data.id,
        name: data.name,
        type: data.type,
        code: data.code,
        color: data.color,
        withTradeColor: data.withTradeColor,
    };
};

export const formatterShowTask = ({
    subTrade,
    company,
    group_id,
    timeModel,
    workpackage_id,
    ganttParent_id,
    earliestDate,
    ...data
}: Type_sch_show_task): Type_show_task => {
    const task: Type_show_task = {
        timeModel,
        ...data,
    };

    if (subTrade) {
        task.subTrade = { ...formatterIndexSubTrade(subTrade) };
    }
    if (company) {
        task.company = { ...formatterSelectListCompany(company) };
    }

    // null ou 0 doit correspondre à la valeur CUSTOM
    if (timeModel?.id === 0) {
        task.timeModel = { id: 0, name: "Custom", enabled: true };
    }
    if (earliestDate) {
        task.earliestDate = formatDateForFront(earliestDate)!;
    }
    if (group_id) {
        task.group = { id: group_id };
    }
    if (workpackage_id) {
        task.workpackage = { id: workpackage_id };
    }
    if (ganttParent_id) {
        task.ganttParent = { id: ganttParent_id };
    }

    return task;
};

export const formatterCreateTask = (
    data: Type_post_task,
): Type_sch_post_task => {
    return {
        names: data.names,
        sequence_id: data.sequence.id,
        taskCategory_id: data.taskCategory.id,
    };
};

export const formatterUpdateTask = (data: Type_put_task): Type_sch_put_task => {
    const {
        sequence,
        subTrade,
        company,
        tags,
        slideOutResourceType,
        slideOutMovementType,
        timeModel,
        ratioUnit,
        earliestDate,
        taskCategory,
        ...rest
    } = data;
    const task: Type_sch_put_task = rest;

    if (sequence) {
        task.sequence_id = sequence.id;
    }
    if (subTrade) {
        task.subTrade_id = subTrade.id;
    }
    if (company) {
        task.company_id = company?.id;
    }
    if (earliestDate) {
        task.earliestDate = formatDateForBack(earliestDate);
    }
    if (timeModel?.id !== null && timeModel?.id !== undefined)
        task.timeModel_id = timeModel?.id;
    if (ratioUnit?.id !== null && ratioUnit?.id !== undefined)
        task.ratioUnit_id = ratioUnit?.id;
    if (tags) {
        task.tags = tags?.map((item) => item?.id);
    }
    if (slideOutResourceType) {
        task.slideOutResourceType_id = slideOutResourceType.id;
    }
    if (slideOutMovementType) {
        task.slideOutMovementType = !slideOutResourceType?.id
            ? undefined
            : slideOutMovementType;
    }
    if (taskCategory?.id !== null && taskCategory?.id !== undefined)
        task.taskCategory_id = taskCategory?.id;

    return task;
};

export const formatterShowToPutTask = (data: Type_show_task): Type_put_task => {
    const { subTrade, areaRhythmLevel, flowRhythmLevel, ...rest } = data;

    return {
        subTrade: subTrade
            ? {
                  id: subTrade.id as number,
                  name: subTrade.name,
                  team: subTrade.team,
                  code: subTrade.code,
                  trade: subTrade.trade,
                  enabled: subTrade.enabled,
              }
            : null,
        // areaRythmeLevel and flowRhythmLevel have default value of 0
        // but SelectBreakLevel component can't handle it and should be set to undefined
        areaRhythmLevel: areaRhythmLevel === 0 ? undefined : areaRhythmLevel,
        flowRhythmLevel: flowRhythmLevel === 0 ? undefined : flowRhythmLevel,
        ...rest,
    };
};

export const formatterShowToTaskEvent = (
    data: Type_sch_show_task,
): Type_event_task => {
    const language = getLocalStorageItem("language") || DEFAULT_LANGUAGE;

    return {
        id: data.id,
        name: data.names[language] ?? data.names[0],
        type: data.type,
        code: data.code,
        color: data.color,
        withTradeColor: data.withTradeColor,
        subTrade: data.subTrade,
        duration: data.duration,
        areaQuantity: data.areaQuantity,
        team: data.team,
        xy: data.xy,
    };
};
