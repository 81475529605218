import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";
import "react-data-grid/lib/styles.css";

export const DataGridStyled = styled(Box)(({ theme }: any) => ({
    position: "relative",
    ".rdg": {
        height: "50rem",
        ".rdg-text-editor": {
            appearance: "none",
            boxSizing: "border-box",
            inlineSize: "100%",
            blockSize: "100%",
            paddingBlock: 0,
            paddingInline: "6px",
            border: "2px solid #ccc",
            verticalAlign: "top",
            color: "#000",
            backgroundColor: "hsl(0deg 0% 100%)",

            fontFamily: "inherit",
            fontSize: "14px",

            "&:focus": {
                borderColor: "#66afe9",
                outline: "none",
            },

            "&::placeholder": {
                color: "#999",
                opacity: 1,
            },
        },
    },
    ".cell-updated": {
        border: `1px solid ${COLORS.yellow800} !important`,
    },
    ".rdg-cell": {
        border: `1px solid ${COLORS.moon300}`,
    },
    ".rdg-header-row": {
        border: `1px solid ${COLORS.moon300}`,
        ...theme.typography.body3Bold,
        backgroundColor: COLORS.moon200,
        color: COLORS.moon950,

        "& > .rdg-cell": {
            display: "flex",
            alignItems: "center",
        },
    },
}));

export const LoadingAbsolute = styled(Box)(() => ({
    fontSize: "40px",
    position: "absolute",
    width: "100%",
    display: "flex",
    alignItems: "baseline",
    zIndex: "1201",
    top: "45px",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "rgba(239 239 240 40%)",
    height: "100%",
}));
