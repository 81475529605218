import { useTheme } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Type_index_projectUser } from "src/api/tms-projects/projectUsers/types";
import { USER_STATUS } from "src/api/tms-users/users/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_UserSubscriptionCell = {
    props: GridRenderCellParams;
    handleAccept: (projectUser: Type_index_projectUser) => void;
    handleDecline: (id: number) => void;
    disabled: boolean;
};

export const UserSubscriptionCell = ({
    props,
    handleAccept,
    handleDecline,
    disabled,
}: Type_Props_UserSubscriptionCell) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Users",
    );

    const theme = useTheme();

    if (props.row.status !== USER_STATUS.TO_VALIDATE) {
        return <></>;
    }

    return (
        <>
            <IconButton
                title={fmt("Decline")}
                color={"error"}
                data-testid={`DeclineSubscription-${props.row.id}`}
                onClick={(event) => {
                    event.stopPropagation();
                    handleDecline(props.row.id);
                }}
                sx={{
                    width: "24px",
                    height: "24px",
                    border: `1px solid ${!disabled ? theme.palette.error.main : theme.palette.text.disabled}`,
                    borderRadius: "4px 0px 0px 4px",
                }}
                disabled={disabled}
            >
                <Icon variant={"solid"} icon={"xmark"} />
            </IconButton>
            <IconButton
                title={fmt("Accept")}
                color={"success"}
                data-testid={`AcceptSubscription-${props.row.id}`}
                onClick={(event) => {
                    event.stopPropagation();
                    handleAccept(props.row as Type_index_projectUser);
                }}
                sx={{
                    width: "24px",
                    height: "24px",
                    border: `1px solid ${!disabled ? theme.palette.success.main : theme.palette.text.disabled}`,
                    borderRadius: "0px 4px 4px 0px",
                    ".Mui-disabled": {
                        color: theme.palette.text.disabled,
                    },
                }}
                disabled={disabled}
            >
                <Icon variant={"solid"} icon={"check"} />
            </IconButton>
        </>
    );
};
