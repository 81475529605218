import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ProjectCompanyForm } from "src/forms/projectCompanies/ProjectCompanyForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_CompanyDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    action: Type_action;
    companyIdToUpdate: number | null;
    page: string;
    requestConfigParams: Type_RequestConfig;
};

export const ProjectCompanyDrawer = ({
    open,
    onClose,
    action,
    companyIdToUpdate,
    page,
    requestConfigParams,
}: Type_Props_CompanyDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectCompanies",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Company-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            page={page}
            isFormLoading={isLoading}
            formId={"projectCompanies"}
        >
            <ProjectCompanyForm
                onClose={onClose}
                action={action}
                companyIdToUpdate={companyIdToUpdate}
                setIsLoading={setIsLoading}
                requestConfigParams={requestConfigParams}
            />
        </DrawerGeneric>
    );
};
