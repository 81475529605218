import { useState } from "react";

import {
    mutationStatusResource,
    useDeleteResource,
    useIndexResources,
} from "src/api/tms-projects/resources";
import { Type_index_resource } from "src/api/tms-projects/resources/types";
import { Page_ResourcesComponent } from "src/pages/Pages_Teamoty/Project/Settings/Page_Resources/Page_Resources.component";

export type Type_modalDeleteResource = {
    isOpen: boolean;
    props?: Type_index_resource;
};

export const Page_ResourcesContainer = () => {
    const { isFetching, data: fetchedResources = [] } =
        useIndexResources() || {};

    // STATUS
    const { mutate: mutationResourceStatus } = mutationStatusResource() || {};

    const changeStatus = async (id: number, resource: Type_index_resource) => {
        mutationResourceStatus({
            id,
            enabled: !resource.enabled,
        });
    };

    //DELETE
    const [modalDeleteResource, setModalDeleteResource] =
        useState<Type_modalDeleteResource>({
            isOpen: false,
        });

    const removeResource = (id: number) => {
        mutateDeleteResource(id);
    };

    const { mutateAsync: mutateDeleteResource } = useDeleteResource() || {};

    return (
        <Page_ResourcesComponent
            resources={fetchedResources}
            isFetching={isFetching}
            deleteResource={removeResource}
            modalDeleteResource={modalDeleteResource}
            setModalDeleteResource={setModalDeleteResource}
            changeStatus={changeStatus}
        />
    );
};
