import { yupResolver } from "@hookform/resolvers/yup";
import React, { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateSequenceFolder,
    mutationUpdateSequenceFolder,
    useShowSequenceFolder,
} from "src/api/tms-scheduling/sequenceFolders";
import {
    Type_post_sequenceFolder,
    Type_put_sequenceFolder,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { Type_DialogParams } from "src/components/Components_Common/Dialog/Dialog";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { useFormDefaultConfig } from "src/configurations/app";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const validationSchema = Yup.object().shape({
    names: Yup.object().shape(inputMultiLanguagesSchema.fields),
});

type Type_Props_SequenceFoldersForm = {
    dialogParams: Type_DialogParams;
    setDialogParams: Dispatch<SetStateAction<Type_DialogParams>>;
    setIsFormLoading: Dispatch<SetStateAction<boolean>>;
};
export const SequenceFolderForm = ({
    dialogParams,
    setDialogParams,
    setIsFormLoading,
}: Type_Props_SequenceFoldersForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );

    const { data } = useShowSequenceFolder(dialogParams.data?.id);

    const { mutateAsync: mutateAsyncCreateFolder } =
        mutationCreateSequenceFolder() || {};

    const { mutateAsync: mutateAsyncUpdateFolder } =
        mutationUpdateSequenceFolder() || {};

    const form = useForm({
        ...useFormDefaultConfig,
        defaultValues: {
            id: null,
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(validationSchema),
    });

    const handleSubmit = async (values: any): Promise<void> => {
        setIsFormLoading(true);
        try {
            if (dialogParams.action === "update") {
                await mutateAsyncUpdateFolder(
                    values as Type_put_sequenceFolder,
                );
            } else {
                await mutateAsyncCreateFolder(
                    values as Type_post_sequenceFolder,
                );
            }
            setDialogParams((prevParams) => ({
                ...prevParams,
                open: false,
                data: {},
            }));
        } catch (e: any) {
            console.error("Error when creating or updating metadata");
        }
        setIsFormLoading(false);
    };

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={dialogParams.data?.name}
            >
                <InputMultiLanguages
                    name="names"
                    label={fmt("Form.FolderName")}
                />
            </form>
        </FormProvider>
    );
};
