import { styled } from "@mui/material/styles";
import {
    TreeItem2Checkbox,
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2Icon,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Provider,
    TreeItem2Root,
    UseTreeItem2Parameters,
    useTreeItem2Utils,
} from "@mui/x-tree-view";
import { unstable_useTreeItem2 as useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import React, { forwardRef } from "react";

import {
    Enum_TreeItem_ItemType,
    Type_sequenceFolderTree_item,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { useChannel } from "src/hooks/useChannel";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { Type_unChecked } from "./SequencesContextualDrawer";
import { SequencesTreeItemLabel } from "./SequencesTreeItemLabel";

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    flexDirection: "row-reverse",
    padding: theme.spacing(1.5, 4),
    borderRadius: 0,
    ":hover": {
        backgroundColor: theme.palette.action.hover,
        ".buttonGroup": {
            visibility: "visible",
        },
    },
    ".buttonGroup": {
        visibility: "hidden",
    },
}));

export const Styled_TreeItem2Label = styled(TreeItem2Label)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
        color: theme.palette.text.primary,
        border: theme.border.default,
    },
}));

const isExpandable = (reactChildren: React.ReactNode) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

interface CustomTreeItemProps
    extends Omit<UseTreeItem2Parameters, "rootRef">,
        Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {
    unChecked: Type_unChecked;
    setUnChecked: React.Dispatch<React.SetStateAction<Type_unChecked>>;
}

export const SequencesTreeItem = forwardRef(function CustomTreeItem(
    { unChecked, setUnChecked, ...props }: CustomTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { id, itemId, label, disabled, children, ...other } = props;
    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        getCheckboxProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item: Type_sequenceFolderTree_item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);

    const { interactions } = useTreeItem2Utils({
        itemId: props.itemId,
        children: props.children,
    });

    const { sendEvent } = useChannel({});
    const { openPaper } = useContextualDrawer();

    const handleContentClick = (): void => {
        const { itemType, id } = item;
        if (item.enabled) {
            if (itemType === Enum_TreeItem_ItemType.sequence) {
                sendEvent("openPert", {
                    id: id,
                    open: true,
                });
            } else if (itemType === Enum_TreeItem_ItemType.task) {
                openPaper("task", { id });
            }
        }
    };

    const handleIconContainerClick = (event: React.MouseEvent): void => {
        interactions.handleExpansion(event);
        event.stopPropagation();
    };

    const getFilterKey = (
        itemType: string,
    ): "folders" | "sequences" | "tasks" => {
        const keyMap: { [key: string]: keyof Type_unChecked } = {
            folder: "folders",
            sequence: "sequences",
            task: "tasks",
        };
        return keyMap[itemType] || "folders";
    };

    const checkSelectedItem = (): {
        checked: boolean;
        indeterminate: boolean;
    } => {
        const { itemType, id } = item;
        const filterKey = getFilterKey(itemType);
        let isChecked,
            isIndeterminate = false;

        switch (itemType) {
            case Enum_TreeItem_ItemType.folder: {
                // For folders, check their sequences
                const sequences = item.children?.filter(
                    (child) =>
                        child.itemType === Enum_TreeItem_ItemType.sequence,
                );
                const allSequencesChecked = sequences?.every(
                    (task) => !unChecked["sequences"].includes(task.id),
                );
                const someSequencesUnchecked = sequences?.some((task) =>
                    unChecked["sequences"].includes(task.id),
                );

                isChecked =
                    allSequencesChecked && !unChecked[filterKey].includes(id);
                isIndeterminate =
                    someSequencesUnchecked &&
                    !unChecked[filterKey].includes(id);

                return { checked: isChecked, indeterminate: isIndeterminate };
            }
            case Enum_TreeItem_ItemType.sequence: {
                // For sequences, check their tasks
                const tasks = item.children?.filter(
                    (child) => child.itemType === Enum_TreeItem_ItemType.task,
                );
                const allTasksChecked = tasks?.every(
                    (task) => !unChecked["tasks"].includes(task.id),
                );
                const someTasksUnchecked = tasks?.some((task) =>
                    unChecked["tasks"].includes(task.id),
                );

                isChecked =
                    allTasksChecked &&
                    !unChecked[filterKey].includes(id) &&
                    item.enabled;
                isIndeterminate =
                    someTasksUnchecked && !unChecked[filterKey].includes(id);

                return { checked: isChecked, indeterminate: isIndeterminate };
            }
            default: {
                // For individual tasks, no indeterminate state
                isChecked = !unChecked[filterKey].includes(id) && item.enabled;
                return { checked: isChecked, indeterminate: isIndeterminate };
            }
        }
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const isChecked = event.target.checked;
        const { itemType, id } = item;
        const filterKey = getFilterKey(itemType);

        setUnChecked((prev) => {
            const updatedFilter = isChecked
                ? prev[filterKey].filter((num) => num !== id)
                : [...prev[filterKey], id];

            if (itemType === Enum_TreeItem_ItemType.folder) {
                const sequencesIds = item.children
                    ?.filter(
                        (child) =>
                            child.itemType === Enum_TreeItem_ItemType.sequence,
                    )
                    .map((seq) => seq.id);

                const tasksIds = item.children?.flatMap((seq) =>
                    seq.children
                        ?.filter(
                            (child) =>
                                child.itemType === Enum_TreeItem_ItemType.task,
                        )
                        .map((task) => task.id),
                );

                const updatedSequences = isChecked
                    ? prev["sequences"].filter(
                          (id) => !sequencesIds.includes(id),
                      )
                    : [...prev["sequences"], ...sequencesIds];

                const updatedTasks = isChecked
                    ? prev["tasks"].filter((id) => !tasksIds.includes(id))
                    : [...prev["tasks"], ...tasksIds];

                return {
                    ...prev,
                    [filterKey]: updatedFilter,
                    sequences: updatedSequences,
                    tasks: updatedTasks,
                };
            }

            if (itemType === Enum_TreeItem_ItemType.sequence) {
                const tasksIds = item.children
                    ?.filter(
                        (child) =>
                            child.itemType === Enum_TreeItem_ItemType.task,
                    )
                    .map((task) => task.id);

                const updatedTasks = isChecked
                    ? prev["tasks"].filter((id) => !tasksIds.includes(id))
                    : [...prev["tasks"], ...tasksIds];

                return {
                    ...prev,
                    [filterKey]: updatedFilter,
                    tasks: updatedTasks,
                };
            }

            return {
                ...prev,
                [filterKey]: updatedFilter,
            };
        });
    };

    const Root: React.ElementType = TreeItem2Root;
    const Content: React.ElementType = CustomTreeItemContent;
    const IconContainer: React.ElementType = TreeItem2IconContainer;
    const Checkbox: React.ElementType = TreeItem2Checkbox;

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <TreeItem2Provider itemId={itemId}>
            <Root {...getRootProps(other)}>
                <Content {...getContentProps()} onClick={handleContentClick}>
                    <IconContainer
                        {...getIconContainerProps()}
                        onClick={handleIconContainerClick}
                    >
                        <TreeItem2Icon status={status} />
                    </IconContainer>

                    <SequencesTreeItemLabel
                        {...getLabelProps({
                            item,
                            expandable: +expandable && +status.expanded,
                        })}
                    />
                    <Checkbox
                        {...getCheckboxProps()}
                        visible={true}
                        disabled={!item.enabled}
                        checked={checkSelectedItem().checked}
                        indeterminate={checkSelectedItem().indeterminate}
                        onChange={handleCheckboxChange}
                        onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.stopPropagation()
                        }
                    />
                </Content>
                {children && (
                    <TreeItem2GroupTransition {...getGroupTransitionProps()} />
                )}
            </Root>
        </TreeItem2Provider>
    );
});
