import { RenderEditCellProps } from "react-data-grid";

function autoFocusAndSelect(input: HTMLInputElement | null) {
    input?.focus();
    input?.select();
}

type InputCellProps<TRow, TSummaryRow> = RenderEditCellProps<
    TRow,
    TSummaryRow
> & {
    type: "text" | "number";
    className?: string;
};

export default function InputTextCell<TRow, TSummaryRow>({
    row,
    column,
    onRowChange,
    onClose,
    type = "text",
    className,
}: InputCellProps<TRow, TSummaryRow>) {
    return (
        <input
            className={`${className} rdg-text-editor`}
            type={type}
            ref={autoFocusAndSelect}
            value={row[column.key as keyof TRow] as unknown as string}
            onChange={(event) =>
                onRowChange({ ...row, [column.key]: event.target.value })
            }
            onBlur={() => onClose(true, false)}
        />
    );
}
