import Konva from "konva";
import React from "react";
import { Group, Path, Rect } from "react-konva";

import {
    sizePlanningButtonPictogram,
    sizePlanningScroll,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { Type_Props_PlanningWorkforceButton } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { usePlanningContext } from "src/contexts/planning";

export const PlanningWorkforceButton = ({
    x,
    y,
    width,
    height,
    onChange,
    selected,
    pictogram,
}: Type_Props_PlanningWorkforceButton) => {
    const { defaultCursor } = usePlanningContext();
    const theme = usePlanningTheme();

    const maxValue = Math.max(width, height);

    const offsetX = (maxValue - width) / 2;
    const offsetY = (maxValue - height) / 2;

    return (
        <Group
            x={x}
            y={y}
            onClick={() => {
                onChange();
            }}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) =>
                changeCursor(e, "pointer")
            }
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) =>
                changeCursor(e, defaultCursor)
            }
        >
            <Rect
                width={sizePlanningScroll}
                height={sizePlanningScroll}
                fill={
                    selected ? theme.colorPlanningSelected : theme.transparent
                }
            />

            <Path
                scaleX={sizePlanningButtonPictogram / maxValue}
                scaleY={sizePlanningButtonPictogram / maxValue}
                x={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                y={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                offsetX={-offsetX}
                offsetY={-offsetY}
                fill={
                    selected
                        ? theme.colorPlanningButtonTextSelected
                        : theme.colorPlanningButtonText
                }
                data={pictogram}
            />
        </Group>
    );
};
