import { createContext, ReactElement, useContext, useState } from "react";

type FilterValue = {
    [key: string]: string | string[];
};

type SortValues = {
    [key: string]: "asc" | "desc" | null;
};

type SetFilters = React.Dispatch<React.SetStateAction<FilterValue>>;
type SetSort = React.Dispatch<React.SetStateAction<SortValues>>;

type Type_filtersContext = {
    setFilters: SetFilters;
    filters: FilterValue;
    setSorting: SetSort;
    sort: SortValues;
};

const defaultContextValue: Type_filtersContext = {
    setFilters: () => {},
    filters: {},
    setSorting: () => {},
    sort: {},
};

const FiltersContext = createContext<Type_filtersContext>(defaultContextValue);

export const useFiltersContext = () => {
    const context = useContext(FiltersContext);
    if (!context) {
        throw new Error(
            "useFiltersContext must be used within a FiltersProvider",
        );
    }
    return context;
};

type Type_Props_filtersProvider = {
    children: ReactElement;
};

export const FiltersProvider = ({ children }: Type_Props_filtersProvider) => {
    const [filters, setFilters] = useState<FilterValue>({});
    const [sort, setSorting] = useState<SortValues>({});

    const contextValue = { setSorting, sort, setFilters, filters };

    return (
        <FiltersContext.Provider value={contextValue}>
            {children}
        </FiltersContext.Provider>
    );
};
