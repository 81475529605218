import React, { useState } from "react";

import {
    mutationStatusResourceType,
    useDeleteResourceType,
    useIndexResourceTypes,
} from "src/api/tms-projects/resourceTypes";
import {
    Type_index_resourceType,
    Type_prj_put_resourceType,
} from "src/api/tms-projects/resourceTypes/types";
import { Page_ResourceTypesComponent } from "src/pages/Pages_Teamoty/Project/Settings/Page_ResourceTypes/Page_ResourceTypes.component";

export type Type_modalDeleteResourceType = {
    isOpen: boolean;
    props?: Type_index_resourceType;
};

export const Page_ResourceTypesContainer = () => {
    // Queries & Mutations
    const { isFetching, data: fetchedResourceTypes = [] } =
        useIndexResourceTypes() || {};
    const { mutate: mutationResourceTypeStatus } =
        mutationStatusResourceType() || {};

    // STATUS
    const changeStatus = (resourceType: Type_index_resourceType) => {
        mutationResourceTypeStatus({
            id: resourceType.id,
            enabled: !resourceType.enabled,
        } as Type_prj_put_resourceType);
    };

    // DELETE
    const [modalDelete, setModalDelete] =
        useState<Type_modalDeleteResourceType>({
            isOpen: false,
        });

    const { mutateAsync: mutateDeleteResourceType } =
        useDeleteResourceType() || {};

    const removeResourceType = async (id: number) => {
        await mutateDeleteResourceType(id);
    };

    return (
        <Page_ResourceTypesComponent
            resourceTypes={fetchedResourceTypes}
            isFetching={isFetching}
            changeStatus={changeStatus}
            removeResourceType={removeResourceType}
            modalDelete={modalDelete}
            setModalDelete={setModalDelete}
        />
    );
};
