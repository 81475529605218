import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { DropdownMenu } from "src/components/Components_Common/DropdownMenu/DropdownMenu";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Enum_typeFlowTask } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { TaskPunchlistDialog } from "src/dialogs/taskPunchlist/TaskPunchlistDialog";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { invertColor } from "src/utils/colors";

type Type_size = "small" | "medium" | "large";

type Type_Props_CompleteButtonRhf<T> = {
    name: string;
    taskType: number;
    backgroundColor: string;
    size?: Type_size;
    task: Type_show_taskArea;
    handleSubmit: (values: T) => void;
};

// TODO: rename to CompleteTaskAreaButton
export const CompleteButtonRhf = <T,>({
    name,
    taskType,
    backgroundColor,
    size = "medium",
    task,
    handleSubmit,
}: Type_Props_CompleteButtonRhf<T>) => {
    const { setValue, trigger, getValues } = useFormContext();

    const switchTaskToDone = async () => {
        setValue(name, getValues(name) === 100 ? 0 : 100, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        await trigger(name);

        handleSubmit(getValues() as T);
    };

    useChannel({
        eventHandler: ({ event }: Type_event_message) => {
            if (event === "postPunchlist") {
                switchTaskToDone();
            }
        },
    });

    const [openTaskPunchlistDialog, setOpenTaskPunchlistDialog] =
        useState<boolean>(false);

    const handleCompleteStatus = (option?: "withComplement" | "withDate") => {
        if (option === "withComplement") {
            setOpenTaskPunchlistDialog(true);
        } else {
            // TODO: _option withDate AP-3264
            switchTaskToDone();
        }
    };

    const hideDropdownMenu = [
        Enum_typeFlowTask.Milestone,
        Enum_typeFlowTask.Delivery,
    ].includes(taskType);

    return (
        <>
            <TaskPunchlistDialog
                data={task}
                open={openTaskPunchlistDialog}
                onClose={() => {
                    setOpenTaskPunchlistDialog(false);
                }}
            />
            <Controller
                name={name}
                render={({ field: { value } }) => (
                    <CompleteButton
                        completed={value === 100}
                        size={size}
                        handleCompleteStatus={handleCompleteStatus}
                        hideDropdownMenu={hideDropdownMenu}
                        backgroundColor={backgroundColor}
                    />
                )}
            />
        </>
    );
};

const sizeConfig = (size: Type_size) => {
    const config = {
        small: {
            container: {
                width: "32px",
                height: "20px",
            },
            iconBox: {
                width: "16px",
                height: "16px",
            },
            icon: {
                fontSize: "8px",
            },
        },
        medium: {
            container: {
                width: "36px",
                height: "24px",
            },
            iconBox: {
                width: "20px",
                height: "20px",
            },
            icon: {
                fontSize: "10px",
            },
        },
        large: {
            container: {
                width: "48px",
                height: "32px",
            },
            iconBox: {
                width: "22px",
                height: "22px",
            },
            icon: {
                fontSize: "12px",
            },
        },
    };

    return config[size];
};

type Type_Props_CompleteButton = {
    size: Type_size;
    handleCompleteStatus: (option?: "withComplement" | "withDate") => void;
    hideDropdownMenu: boolean;
    completed: boolean;
    backgroundColor: string;
};

export const CompleteButton = (props: Type_Props_CompleteButton) => {
    // Props & state
    const {
        completed,
        size,
        handleCompleteStatus,
        hideDropdownMenu,
        backgroundColor,
    } = props;

    const [hovered, setHovered] = useState(false);

    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Components.CompleteButton",
    );

    // utils
    const configSize = sizeConfig(size);

    const menuItems = [
        {
            id: "markAsCompleted",
            icon: { name: "circle-check", variant: "light" },
            label: (
                <Typography variant="body1">
                    {fmt("MarkAsCompleted")}
                </Typography>
            ),
            call: handleCompleteStatus,
        },
        /*   {  TODO: lors d'une prochaine feature
            label: (
                <Typography variant="body1">
                    <Icon
                        variant="light"
                        icon="edit"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("MarkAsCompletedWithDate")}
                </Typography>
            ),
            call: () => handleCompleteStatus("withDate"),
        }, */
        {
            id: "completeWithAComplement",
            icon: { name: "light-circle-check-circle-plus", variant: "kit" },
            label: (
                <Typography variant="body1">
                    {fmt("CompleteWithAComplement")}
                </Typography>
            ),
            call: () => handleCompleteStatus("withComplement"),
        },
    ];

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <Stack
            flexWrap="nowrap"
            direction="row"
            sx={{
                ...configSize["container"],
                padding: 1,
                "&:hover": {
                    backgroundColor: "action.hover",
                    ...(!completed && { opacity: 0.6 }),
                },
                borderRadius: 1,
            }}
            justifyContent="center"
        >
            <Tooltip
                title={fmt(
                    completed ? "UndoMarkAsCompleted" : "MarkAsCompleted",
                )}
            >
                <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => handleCompleteStatus()}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    data-testid={`CompleteButton`}
                >
                    {completed ? (
                        <Box
                            sx={{
                                ...configSize["iconBox"],
                                border: "2px solid",
                                borderColor: "common.white",
                                backgroundColor: "success.main",
                                borderRadius: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Icon
                                variant="solid"
                                icon="check"
                                fontSize={"inherit"}
                                sx={{
                                    ...configSize["icon"],
                                    color: "common.white",
                                }}
                            />
                        </Box>
                    ) : hovered ? (
                        <Icon
                            variant="light"
                            icon="circle-check"
                            fontSize={size}
                            sx={{ color: invertColor(backgroundColor) }}
                        />
                    ) : (
                        <Icon
                            variant="light"
                            icon="circle"
                            fontSize={size}
                            sx={{ color: invertColor(backgroundColor) }}
                        />
                    )}
                </IconButton>
            </Tooltip>
            {!completed && !hideDropdownMenu && (
                <DropdownMenu
                    id="CompleteButton"
                    menuItems={menuItems}
                    data-testid={`CompleteButton-DropdownMenu`}
                    iconButton={{
                        sx: {
                            padding: 0,
                            lineHeight: 0,
                        },
                    }}
                    icon={
                        <Icon
                            variant="solid"
                            icon="caret-down"
                            fontSize={size === "large" ? "medium" : "small"}
                            sx={{
                                padding: 0,
                                color: invertColor(backgroundColor),
                            }}
                        />
                    }
                />
            )}
        </Stack>
    );
};
