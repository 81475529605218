import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import { useSelectListAreaTypes } from "src/api/tms-commons/areaTypes";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";

type Type_Props_AutocompleteAreaTypes = {
    name: string;
    label: string;
    projectId?: number;
    subProjectId?: number;
};

/**
 * @deprecated use AutocompleteAreaTypes Rhf instead
 * @param name
 * @param label
 * @constructor
 */
export const AutocompleteAreaTypes = ({
    name,
    label,
}: Type_Props_AutocompleteAreaTypes) => {
    const formik: any = useFormikContext();
    const { isFetching, data } = useSelectListAreaTypes() || {};

    return (
        <Autocomplete
            data-testid={`autocomplete-areaTypes-${name}`}
            id={`autocomplete-areaTypes-${name}`}
            multiple={false}
            loading={isFetching}
            onChange={(e, value) => formik.setFieldValue(name, value)}
            value={formik.values[name] || []}
            options={data || []}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, selectedValues): boolean =>
                option?.id === selectedValues.id
            }
            fullWidth
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TMC_TextField
                    {...params}
                    name={name}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": `autocomplete-areaTypes-${name}-input`,
                    }}
                />
            )}
            renderOption={(props: object, option: any) => (
                <li
                    {...props}
                    data-testid={`autocomplete-areaTypes-${name}-input-${option.name}`}
                >
                    {option.name}
                </li>
            )}
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `autocomplete-areaTypes-clearButton-${name}`,
                },
            }}
        />
    );
};
