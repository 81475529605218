import * as React from "react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";

import { mutationCreateResourceLogistics } from "src/api/tms-logistics/resources";
import { ResourceKeys, ResourcePeriodKeys } from "src/api/tms-projects/keys";
import { mutationCreateResourcePeriod } from "src/api/tms-projects/resourcePeriods";
import { indexResourcePeriods } from "src/api/tms-projects/resourcePeriods/services";
import { Type_post_resourcePeriod } from "src/api/tms-projects/resourcePeriods/types";
import {
    formatterCreateResource,
    formatterIndexResource,
    formatterIndexResources,
    formatterSelectResources,
    formatterShowResource,
    formatterUpdateResource,
} from "src/api/tms-projects/resources/formatters";
import {
    createResource,
    deleteResource,
    indexResources,
    selectListResources,
    showResource,
    statusResource,
    updateResource,
} from "src/api/tms-projects/resources/services";
import {
    Type_post_resource,
    Type_prj_put_resource,
    Type_show_resource,
} from "src/api/tms-projects/resources/types";
import { mutationCreateResourceScheduling } from "src/api/tms-scheduling/resources";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexResources = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ResourceKeys.INDEX, requestConfig],
        queryFn: () => indexResources(requestConfig),
        refetchOnWindowFocus: false,
        enabled: !!requestConfig.projectId,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexResources");
            }

            return formatterIndexResources(data.data.data);
        },
    });
};

export const useShowResource = (id: number) => {
    const { requestConfig } = useProject();

    const results = useQueries([
        {
            queryKey: [ResourceKeys.SHOW, requestConfig, id],
            queryFn: () => showResource(requestConfig, id),
            refetchOnWindowFocus: false,
            enabled: !!requestConfig.projectId && !!id,
        },
        {
            queryKey: [
                ResourcePeriodKeys.INDEX,
                { ...requestConfig, resourceId: id },
            ],
            queryFn: () =>
                indexResourcePeriods({ ...requestConfig, resourceId: id }),
            refetchOnWindowFocus: false,
            enabled: !!requestConfig.projectId && !!id,
        },
    ]);

    const isLoading = results.some((result) => result.isLoading);
    const isSuccess = results.every(
        (result: any) => result.status === "success",
    );

    const resource = formatterShowResource(
        results[0].data?.data?.data,
        results[1].data?.data?.data,
    );

    return {
        isSuccess,
        isLoading,
        resource,
    };
};

export const mutationCreateResource = (callback?: (id: number) => void) => {
    const { requestConfig } = useProject();
    const { addWarning, addSuccess } = useToast();
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    const { mutateAsync: mutateCreateScheduling } =
        mutationCreateResourceScheduling() || {};
    const { mutateAsync: mutateCreateLogistics } =
        mutationCreateResourceLogistics() || {};
    const { mutateAsync: mutateCreateResourcePeriod } =
        mutationCreateResourcePeriod() || {};

    return useMutation({
        mutationFn: (data: Type_post_resource) => {
            return createResource(requestConfig, formatterCreateResource(data));
        },
        onSuccess: async (data, variables) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutateCreateResource");
            }

            const resource = formatterIndexResource(data.data.data);

            Promise.all([
                mutateCreateResourcePeriod({
                    ...(variables as unknown as Type_post_resourcePeriod),
                    id: resource.id,
                }),
                mutateCreateScheduling({ id: resource.id }),
                mutateCreateLogistics({ id: resource.id }),
                queryClient.invalidateQueries([
                    ResourceKeys.INDEX,
                    requestConfig,
                ]),
            ]).then(async () => {
                if (callback) {
                    callback(resource.id);
                }

                addSuccess({
                    description: fmt("ToastSuccess", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            resource: resource.name,
                        },
                    }),
                });
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateResource = () => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (resource: Type_show_resource) => {
            return updateResource(
                formatterUpdateResource(resource),
                requestConfig,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateResource");
            }

            const resource = formatterIndexResource(data.data.data);

            await queryClient.invalidateQueries([
                ResourceKeys.INDEX,
                requestConfig,
            ]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resource: resource.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationStatusResource = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_prj_put_resource) => {
            return statusResource(data, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusResource");
            }
            const resource = formatterIndexResource(data.data.data);

            await queryClient.invalidateQueries([
                ResourceKeys.INDEX,
                requestConfig,
            ]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resource: resource.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: err?.data?.message
                    ? fmtErr(err?.data?.message)
                    : fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useDeleteResource = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteResource(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutateCreateResource");
            }

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });

            await queryClient.invalidateQueries([
                ResourceKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListResources = (enabled?: boolean) => {
    const { requestConfig: req } = useProject();
    return useQuery({
        queryKey: [ResourceKeys.SELECT_LIST, req],
        queryFn: () => selectListResources(req),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListResources");
            }

            return formatterSelectResources(data.data.data);
        },
        enabled: !!req.projectId && (enabled ?? true),
    });
};
