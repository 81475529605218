import { getEnv } from "src/utils/urlFormatter";

// URL
export const Url = {
    COMMONS: `https://${getEnv("api")}/commons/v1/`,
    CSRF: `https://${getEnv("api")}/sanctum/csrf-cookie`,
    LOGISTICS: `https://${getEnv("api")}/logistics/v1/`,
    PLANNING: `https://${getEnv("api")}/planning/v1/`,
    PRINT: `https://${getEnv("api")}/print/v1/`,
    PROJECTS: `https://${getEnv("api")}/projects/v1/`,
    SCHEDULING: `https://${getEnv("api")}/scheduling/v1/`,
    USERS: `https://${getEnv("api")}/users/v1/`,
    GATEWAY: `https://${getEnv("api")}/`,
    TFE: `https://${getEnv("api")}/tfe/v1/`,
};

// AUTH
export enum AuthPath {
    // TMS-GATEWAY
    ME = "auth/me",
    LOGIN = "auth/login",
    LOGOUT = "auth/logout",
    // TMS-USERS
    REGISTER = "users/register",
    CONFIRM_MAIL = "users/confirm-mail",
    FORGOT_PASSWORD = "users/forgot-password",
    CHANGE_PASSWORD = "users/reset-password",
    VALIDATE_CGU = "users/cgu/validation",
    CHECK_CGU_VALIDATION = "users/cgu/check",
}

// COMPANY
export enum CompanyPath {
    COMPANIES = "companies",
    COMPANIES_SELECT_LIST = "companies/select-list",
}

// PLANNING
export enum PlanningPath {
    MAKE = "make",
}

// SEQUENCE
export enum SequencePath {
    DUPLICATE = "duplicate",
    SEQUENCES = "sequences",
    SEQUENCES_SELECT_LIST = "sequences/select-list",
    NOTES = "sequence-notes",
    NOTES_SELECT_LIST = "sequences-notes/select-list",
}

// CATEGORIES
export enum SequenceFolderPath {
    SEQUENCE_FOLDERS = "sequence-folders",
    SEQUENCE_FOLDERS_SELECT_LIST = "sequence-folders/select-list",
    SEQUENCE_FOLDERS_TREE = "sequence-folders/sequence-folders-tree",
}

// FORMS
export enum FormsPath {
    NEW_USER = "users/form-user",
    NEW_METADATA = "users/form-user-metadata",
}

// DAYS OFF
export enum DaysOff {
    DAYS_OFF = "day-offs",
}

// METADATA
export enum MetadataPath {
    METADATA = "metadata",
}

// ROLES
export enum RolesPath {
    ROLES = "roles",
    ROLE_TYPES = "roles/role-types",
    SELECT_LIST = "roles/select-list",
    PROJECTS_ROLES = "projects/select-roles",
}

// PROJECT_TYPES
export enum ProjectPaths {
    PROJECT_TYPES = "project-types",
    SELECT_LIST = "project-types/select-list",
}

// LICENSE TYPES
export enum LicenseTypesPath {
    LICENSE_TYPES = "license-types",
    LICENSE_TYPES_SELECT_LIST = "license-types/select-list",
    LICENSE_TYPES_MODES = "license-types/modes",
}

// LICENSES
export enum LicensesPath {
    LICENSES = "licenses",
}

// DRAWING
export enum DrawingPath {
    DRAWINGS = "drawings",
}

// UNIT
export enum UnitPath {
    UNIT_TYPES = "unit-types",
    UNITS = "units",
    UNITS_SELECT_LIST = "units/select-list",
    UNIT_TYPES_SELECT_LIST = "unit-types/select-list",
}

// AREA
export enum AreaPath {
    AREA_TYPES = "area-types",
    SELECT_LIST = "area-types/select-list",
    AREAS = "areas",
    AREAS_SELECT_LIST = "areas/select-list",
    AREAS_SELECT_LIST_WORK_LEVELS = "areas/work-levels",
    AREAS_EXPORT = "areas/export",
    AREAS_IMPORT = "areas/import",
}

export enum SequenceArea {
    SEQUENCE_AREAS = "sequence-areas",
}

// RESOURCES
export enum ResourcePath {
    //Commons
    RESOURCE_MODELS = "resource-models",
    RESOURCE_MODELS_SELECT_LIST = "resource-models/select-list",
    //Commons
    RESOURCE_FAMILIES = "resource-families",
    RESOURCE_FAMILIES_SELECT_LIST = "resource-families/select-list",
    //Projects
    RESOURCE_TYPES = "resource-types",
    RESOURCE_TYPES_SELECT_LIST = "resource-types/select-list",
    RESOURCES = "resources",
    RESOURCE_PERIODS = "resource-periods",
    RESOURCES_SELECT_LIST = "resources/select-list",
    RESOURCE_TASKS = "resource-tasks",
}

// PEOPLE TASKS
export enum PeopleTasksPath {
    PEOPLE_TASKS = "people-tasks",
}

// PEOPLE
export enum PeoplePath {
    PEOPLE = "people",
    PEOPLE_LIST = "people/select-list",
}

// PUNCHLIST
export enum PunchlistPath {
    PUNCHLISTS = "punchlists",
    PROGRESS = "progress",
}

// TIME MODELS
export enum TimeModelsPath {
    TIME_MODELS = "time-models",
    SELECT_LIST = "time-models/select-list",
}

// CAUSES OF DELAY
export enum CauseOfDelayPath {
    CAUSES_OF_DELAY = "causes-of-delay",
}

// PUNCHLIST ITEM CAUSES
export enum PunchlistItemCausePath {
    PUNCHLIST_ITEM_CAUSE = "punchlist-item-causes",
    SELECT_LIST = "punchlist-item-causes/select-list",
}

// PROJECTS
export enum ProjectPath {
    PROJECTS = "projects",
    SHOW = "projects/show",
    IS_NAME_AVAILABLE = "projects/is-name-available",
}

export enum ProductTypesPath {
    PRODUCT_TYPES = "product-types",
    SELECT_LIST = "product-types/select-list",
}

export enum TaskProductTypesPath {
    TASK_PRODUCT_TYPES = "task-product-types",
    SELECT_LIST = "task-product-types/select-list",
}
// FAVOURITE PROJECTS
export enum FavouriteProjectPath {
    FAVOURITE_PROJECTS = "favourite-projects",
}

// METADATA PROJECTS
export enum MetadataProjects {
    METADATA_PROJECTS = "metadata-projects",
    FORM = "metadata-projects/form",
}

// METADATA USERS
export enum MetadataUsers {
    METADATA_USERS = "metadata-users",
}

// STORAGE (IMAGES base64)
export enum StoragePath {
    DOWNLOAD = "projects",
}

export enum TagsPath {
    TAGS = "tags",
    TAGS_LIST = "tags/select-list",
}

export enum TfePath {
    OPEN = "open",
    CLOSE = "close",
    PING = "ping",
    TASKS_AREAS_REPORT = "reports/tasks-areas-status",
    PUNCHLIST = "reports/punchlist-list",
    // TODO completer avec le nom du service tfe
    ROADBLOCK = "",
}

// TASKS

export enum ProgressHistory {
    PROGRESS_HISTORY = "progress-history",
}

export enum TasksPath {
    TASKS = "tasks",
    TASKS_SELECT_LIST = "tasks/select-list",
    TASK_LINKS = "task-links",
    TASK_AREAS = "task-areas",
    TASK_CATEGORIES = "task-categories",
    TASK_NOTES = "task-notes",
    TASK_PRODUCT_TYPES = "task-product-types",
    TASK_AREA_NOTES = "task-area-notes",
}

// CONSTRAINTS
export enum ConstrainPath {
    CONSTRAINTS = "constraints",
    CONSTRAINT_COMMENTS = "constraint-comments",
}

export enum TaskAreasPath {
    TASK_AREAS = "task-areas",
    TASK_AREAS_PROGRESS = "task-areas/progress",
    TASK_AREA_PEOPLE = "task-area-people",
}

export enum UsersPath {
    USERS = "users",
    USERS_CHANGE_PASSWORD = "users/change-password",
    CHECK_TOKEN = "users/check-token",
}

// SUBPROJECTS
export enum SubProjectsPath {
    SUB_PROJECTS = "sub-projects",
    SUB_PROJECTS_SELECT_LIST = "sub-projects/select-list",
    SUB_PROJECTS_CURRENT_WORKSPACE = "sub-projects/current-workspace",
}

// TRADES
export enum TradePath {
    TRADES = "trades",
    TRADES_EXPORT = "trades/export",
    TRADES_IMPORT = "trades/import",
}

// SUB_TRADES
export enum SubTradePath {
    SUB_TRADES = "sub-trades",
    SUB_TRADES_LIST = "sub-trades/select-list",
}

// PROJECT USERS
export enum ProjectUsersPath {
    PROJECT_USERS = "project-users",
    PROJECT_USERS_SELECT_LIST = "project-users/select-list",
}

//AREA TYPES
export enum AreaTypesPath {
    AREA_TYPES = "area-types",
}

// VERSIONS
export enum Version {
    VERSIONS = "versions",
    SELECT_LIST = "versions/select-list",
    VERSION = "version",
    NOTES = "version-notes",
    NOTES_SELECT_LIST = "version-notes/select-list",
}

// LANGUAGES
export enum LanguagesPath {
    LANGUAGES = "languages",
}

// WORKSPACES
export enum WorkspacesPath {
    WORKSPACES = "workspaces",
}

// VIEWS
export enum ViewsPath {
    VIEWS = "views",
}

//PRIORITY OF CONSTRAINTS
export enum PriorityOfConstraintsPath {
    PRIORITY_OF_CONSTRAINTS = "priority-of-constraints",
}

//PRIORITY OF CONSTRAINTS
export enum PlatformSettingsPath {
    PLATFORM_SETTINGS = "platform-settings",
}

// KEY DATES
export enum KeyDatesPath {
    KEY_DATES = "key-dates",
}

// PRINT
export enum PrintPath {
    PRINT = "print",
}
export enum ToolsPath {
    TIMELINES = "timelines",
    PAPERS = "papers",
}
