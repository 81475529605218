import { Box, Skeleton, Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";

import {
    languagesList as languagesListDefault,
    LanguagesListType,
} from "src/assets/translations";
import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { DrawerLabel } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { Styled_Box } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.style";
import { Input } from "src/components/Components_Common/forms/reactHookFormComponents/Input/Input";
import { Type_Props_InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { DEFAULT_LANGUAGE } from "src/contexts/languages";
import { useCoreIntl } from "src/hooks/useCoreIntl";

interface Styled_FieldGroupProps extends StackProps {
    ownerState: {
        multiple: boolean;
    };
}

const Styled_FieldGroup = styled(Stack)<Styled_FieldGroupProps>(
    ({ theme, ownerState }) => ({
        marginTop: "1px",
        marginBottom: "1px",
        position: "relative",
        padding: theme.spacing(2),
        outline: theme.border.default,
        ":last-of-type": {
            borderRadius: ownerState?.multiple
                ? theme.spacing(0, 0, 1, 1)
                : theme.spacing(1),
        },
        ":first-of-type": {
            borderRadius: ownerState?.multiple
                ? theme.spacing(1, 1, 0, 0)
                : theme.spacing(1),
        },
        label: {
            display: "flex",
        },
        "&:focus-within": {
            outlineColor: theme.palette.primary.main,
            zIndex: 2,
        },
    }),
);

const Styled_Input = styled(Input)(() => ({
    display: "flex",
}));

export const inputMultiLanguagesSchema = Object.keys(
    languagesListDefault,
).reduce((schema, language) => {
    if (language !== DEFAULT_LANGUAGE) {
        return schema.shape({
            [language]: Yup.string()
                .nullable()
                .max(100, FORM_ERR_FMT.MAX_LENGTH)
                .trim(),
        });
    } else {
        return schema.shape({
            [DEFAULT_LANGUAGE]: Yup.string()
                .required(FORM_ERR_FMT.REQUIRED)
                .max(100, FORM_ERR_FMT.MAX_LENGTH)
                .trim(),
        });
    }
}, Yup.object());

type Type_Props_InputMultiLanguagesComponent =
    Type_Props_InputMultiLanguages & {
        languagesList?: LanguagesListType;
    };

export const InputMultiLanguagesComponent = ({
    languagesList,
    name,
    label,
}: Type_Props_InputMultiLanguagesComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Teamoty");
    const { register, formState } = useFormContext();

    return (
        <Box width={"100%"}>
            {label &&
                (typeof label == "string" ? (
                    <DrawerLabel
                        variant="h3"
                        data-testid={`Form_MultiLangues-Title-${name}`}
                    >
                        {label}
                    </DrawerLabel>
                ) : (
                    label
                ))}
            {!languagesList && (
                <Stack gap={0.5} direction="column">
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                </Stack>
            )}
            {languagesList && (
                <Styled_Box>
                    <Styled_FieldGroup
                        ownerState={{
                            multiple: Object.keys(languagesList).length > 1,
                        }}
                    >
                        <Styled_Input
                            variant={"standard"}
                            label={
                                fmt(
                                    `Configuration.Labels.${languagesList[DEFAULT_LANGUAGE]}`,
                                ) +
                                " " +
                                fmt(`Configuration.Labels.DefaultLanguage`)
                            }
                            {...register(`${name}.${DEFAULT_LANGUAGE}`)}
                            data-testid={`Form-MultiLanguages-input-${name}-${DEFAULT_LANGUAGE}`}
                            autoComplete="off"
                        />
                        {
                            // @ts-expect-error fix later
                            !!formState.errors?.names?.[DEFAULT_LANGUAGE] && (
                                <TMC_FormHelperText
                                    helperText={
                                        // @ts-expect-error fix later
                                        formState.errors?.names?.[
                                            DEFAULT_LANGUAGE
                                        ]?.message
                                    }
                                    inputName={`${name}-${DEFAULT_LANGUAGE}`}
                                />
                            )
                        }
                    </Styled_FieldGroup>
                    {Object.entries(languagesList).map(([key, language]) => {
                        if (key === DEFAULT_LANGUAGE) return null;
                        return (
                            <Styled_FieldGroup
                                ownerState={{
                                    multiple: true,
                                }}
                                key={key}
                            >
                                <Styled_Input
                                    variant={"standard"}
                                    label={fmt(
                                        `Configuration.Labels.${language}`,
                                    )}
                                    {...register(`${name}.${key}`)}
                                    data-testid={`Form-MultiLanguages-input-${name}-${key}`}
                                    autoComplete="off"
                                />
                                {
                                    // @ts-expect-error fix later
                                    !!formState.errors?.names?.[key] && (
                                        <TMC_FormHelperText
                                            helperText={
                                                // @ts-expect-error fix later
                                                formState.errors?.names?.[key]
                                                    ?.message
                                            }
                                            inputName={`${name}-${key}`}
                                        />
                                    )
                                }
                            </Styled_FieldGroup>
                        );
                    })}
                </Styled_Box>
            )}
        </Box>
    );
};
