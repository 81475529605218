import { Type_CountryCode } from "src/assets/translations";

type Type_Props_Language = {
    userLocale: string;
    languages: Type_CountryCode[];
};
export const getUserLanguageList = ({
    userLocale,
    languages,
}: Type_Props_Language): Type_CountryCode[] => {
    // Find the index of the user's locale
    const userLocaleIndex = languages.findIndex(
        (language) => language === userLocale,
    );

    // If the user's locale is in the array
    if (userLocaleIndex !== -1) {
        // Remove the user's locale from its current position
        const userLocaleObject = languages.splice(userLocaleIndex, 1)[0];

        // Add the user's locale to the beginning of the array
        languages.unshift(userLocaleObject);
    }

    return languages;
};
