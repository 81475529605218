import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Typography,
} from "@mui/material";
import { CellMouseEvent } from "react-data-grid";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

type Type_Props_ContextMenu = {
    row: any;
    anchor: CellMouseEvent;
    onClose: () => void;
    onUpdate: (row: any) => void;
    onDelete: (row: any) => void;
};
export const ContextMenu = ({
    anchor,
    row,
    onClose,
    onUpdate = () => {},
    onDelete = () => {},
}: Type_Props_ContextMenu) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Actions");

    const handleDelete = () => {
        onDelete(row);
        onClose();
    };

    const handleUpdate = () => {
        onUpdate(row);
        onClose();
    };

    return (
        <Menu
            id="matrix-context-menu"
            anchorEl={anchor.currentTarget}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: anchor.clientY,
                left: anchor.clientX,
            }}
            sx={{ backgroundColor: "transparent !important" }}
            open={Boolean(anchor)}
            onClose={onClose}
        >
            <MenuList dense>
                <MenuItem
                    data-testid={`matrix-update-row-${row.id}`}
                    onClick={handleUpdate}
                >
                    <ListItemIcon>
                        <Icon
                            sx={{ color: COLORS.moon950 }}
                            icon="edit"
                            variant="light"
                        />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body1">
                                {fmt("Update")}
                            </Typography>
                        }
                    />
                </MenuItem>
                <MenuItem
                    data-testid={`matrix-delete-row-${row.id}`}
                    onClick={handleDelete}
                >
                    <ListItemIcon>
                        <Icon
                            sx={{ color: COLORS.warning }}
                            icon="trash"
                            variant="light"
                        />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography
                                variant="body1"
                                style={{ color: COLORS.warning }}
                            >
                                {fmt("Delete")}
                            </Typography>
                        }
                    />
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
