import {
    day,
    typeMilestones,
} from "@cimba-digital-construction-solution/utils/dist/decoder/const";
import {
    Type_planningElement,
    Type_planningLink,
    Type_planningTask,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import React, { ReactElement } from "react";
import { Arrow, Group } from "react-konva";

import { Type_planningLine } from "src/components/Components_Teamoty/Planning/Areas/PlanningArea.type";
import { PlanningLink } from "src/components/Components_Teamoty/Planning/Link/PlanningLink";
import { PlanningSequence } from "src/components/Components_Teamoty/Planning/Sequence/PlanningSequence";
import { PlanningShape } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape";
import { Type_planningTaskWithDate } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { PlanningShapeTaskSummary } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeTaskSummary";

import {
    Type_PlanningTaskLine,
    Type_Props_PlanningTasks,
} from "./PlanningTasks.type";

export const PlanningTasks = ({
    x,
    y,
    width,
    height,

    datesActif,
    areasOffset,

    widthDate,
    heightArea,

    selected,
    setSelected,

    withLinksTaskArea,
    links,
}: Type_Props_PlanningTasks) => {
    const getBeginDate = (task: Type_planningTaskWithDate, pos: number) => {
        const prev = {
            date: task.beginDate,
            offset: task.beginOffset,
            duration: task.duration,
        };

        let dateIndex = datesActif.findIndex((date) => date.pos >= pos);

        if (dateIndex !== -1) {
            let date = datesActif[dateIndex];
            while (
                dateIndex < datesActif.length &&
                (!date.workDay || !date.isWorkingDay)
            ) {
                dateIndex++;
                date = datesActif[dateIndex];
            }

            prev.date = date;
            !typeMilestones.includes(task.type) && (prev.offset = 0);
        }

        return prev;
    };

    const getEndDate = (
        task: Type_planningTaskWithDate,
        pos: number,
        duration: number = -1,
        beginDate: any = null,
    ) => {
        const prev = {
            date: task.endDate,
            offset: task.endOffset,
            duration: task.duration,
        };

        if (duration != -1) {
            if (beginDate && duration) {
                let dateIndex = datesActif.findIndex(
                    (date) => date.pos === beginDate.date.pos,
                );
                let currentDuration: number = 0;
                for (
                    let d = datesActif[dateIndex];
                    currentDuration < duration;
                    currentDuration++, dateIndex++
                ) {
                    d = datesActif[dateIndex];
                    while (!d.workDay || !d.isWorkingDay) {
                        dateIndex++;
                        d = datesActif[dateIndex];
                    }
                }

                const restDuration = duration % 1;
                if (restDuration) {
                    prev.date = datesActif[dateIndex - 1];
                    prev.offset = restDuration;
                    prev.duration = duration;
                } else {
                    prev.date = datesActif[dateIndex - 1];
                    prev.offset = (day - 1) / day;
                    prev.duration = duration;
                }
            }
        } else {
            const startPos = task.beginDate.pos + task.beginOffset;
            pos += startPos;
            if (pos > startPos) {
                let dateIndex = datesActif.findIndex(
                    (date) => date.pos > pos - widthDate,
                );
                dateIndex--;
                if (
                    dateIndex !== -1 &&
                    datesActif[dateIndex].pos >= task.beginDate.pos
                ) {
                    let date = datesActif[dateIndex];
                    while (
                        dateIndex !== -1 &&
                        (!date.workDay || !date.isWorkingDay)
                    ) {
                        dateIndex--;
                        date = datesActif[dateIndex];
                    }
                    if (date) {
                        const offset = (day - 1) / day;
                        let newDuration = 0;
                        let countDayOff = 0;
                        for (
                            let d = datesActif[dateIndex];
                            d.pos != task.beginDate.pos && dateIndex >= 0;
                            dateIndex--
                        ) {
                            d = datesActif[dateIndex];
                            newDuration++;
                            countDayOff +=
                                !d.workDay || !d.isWorkingDay ? 1 : 0;
                        }

                        prev.date = date;
                        prev.offset = offset;
                        prev.duration = Math.max(1, newDuration - countDayOff);
                    }
                }
            } else {
                return null;
            }
        }

        return prev;
    };

    let sequence: Type_planningElement | undefined = undefined;
    let minY: number = 0;

    const generateTaskElements = (): {
        elmTask: Array<ReactElement>;
        tasks: Map<string, Type_PlanningTaskLine>;
    } => {
        const newElmTask: Array<ReactElement> = [];
        const newElmTaskObject: Map<string, Type_PlanningTaskLine> = new Map();

        areasOffset.lines.forEach((line: Type_planningLine) => {
            if (line.pos >= 0 && line.pos < height) {
                const tasks = line.area.lines[line.index];
                if (Array.isArray(tasks)) {
                    tasks.forEach((task: Type_planningTask) => {
                        if (
                            task.beginDate &&
                            task.endDate &&
                            (task.beginDate.pos >= 0 || task.endDate.pos >= 0)
                        ) {
                            task.key.split(".").length == 2 &&
                                newElmTaskObject.set(task.key, {
                                    task: task as Type_planningTaskWithDate,
                                    line: line,
                                });
                            newElmTask.push(
                                <PlanningShape
                                    key={"Task-" + task.key}
                                    task={task as Type_planningTaskWithDate}
                                    line={line}
                                    widthDate={widthDate}
                                    heightArea={heightArea}
                                    selected={selected}
                                    setSelected={setSelected}
                                    getBeginDate={getBeginDate}
                                    getEndDate={getEndDate}
                                />,
                            );
                        }
                        if (selected && selected.taskKey == task.key) {
                            sequence = task.parent;
                            minY = line.pos - task.y * heightArea;
                        }
                    });
                } else if (tasks) {
                    if (
                        line?.area.beginDate &&
                        line?.area.endDate &&
                        (line.area.beginDate.pos >= 0 ||
                            line.area.endDate.pos >= 0)
                    ) {
                        newElmTask.push(
                            <PlanningShapeTaskSummary
                                key={"Summary-" + line.area.key}
                                line={line}
                                widthDate={widthDate}
                                heightArea={heightArea}
                            />,
                        );
                    }
                }
            }
        });

        if (selected) {
            if (sequence) {
                newElmTask.push(
                    <PlanningSequence
                        key={`planningSequence_${selected.taskKey}`}
                        id={`planningSequence_${selected.taskKey}`}
                        minY={minY}
                        widthDate={widthDate}
                        sequence={sequence}
                        heightArea={heightArea}
                    />,
                );
            }
        }

        return { elmTask: newElmTask, tasks: newElmTaskObject };
    };

    const { elmTask, tasks } = generateTaskElements();

    withLinksTaskArea &&
        links &&
        links.forEach((link: Type_planningLink) => {
            if (link.areaFromId && link.areaToId) {
                const fromKey = link.taskFromId + "." + link.areaFromId;
                const toKey = link.taskToId + "." + link.areaToId;

                const taskLineFrom = tasks.get(fromKey);
                const taskLineTo = tasks.get(toKey);

                if (taskLineFrom && taskLineTo) {
                    elmTask.push(
                        <PlanningLink
                            key={"link" + fromKey + "-" + toKey}
                            id={link.id}
                            modeId={link.modeId}
                            taskLineFrom={taskLineFrom}
                            taskLineTo={taskLineTo}
                            widthDate={widthDate}
                            heightArea={heightArea}
                        />,
                    );
                }
            }
        });

    return (
        <Group x={x} y={y}>
            <Group
                name="bottomTasks"
                clipX={-0.5}
                clipY={-0.5}
                clipWidth={width + 1}
                clipHeight={height + 1}
            ></Group>
            <Group
                clipX={-0.5}
                clipY={-0.5}
                clipWidth={width + 1}
                clipHeight={height + 1}
            >
                {elmTask}
            </Group>
            <Group
                name="topTasks"
                clipX={-0.5}
                clipY={-0.5}
                clipWidth={width + 1}
                clipHeight={height + 1}
            ></Group>

            {selected && selected.link && (
                <Arrow
                    points={[
                        selected.link.ptFrom.x,
                        selected.link.ptFrom.y,
                        selected.link.ptTo.x,
                        selected.link.ptTo.y,
                    ]}
                    stroke="black"
                    pointerLength={10}
                    pointerWidth={10}
                    fill={"black"}
                />
            )}
        </Group>
    );
};
