import React, {
    createContext,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { Type_RequestConfig } from "src/api/fetch";
import { useShowProjectBySlug } from "src/api/tms-commons/projects";
import { Type_show_project } from "src/api/tms-commons/projects/types";
import { SubProjectKeys } from "src/api/tms-projects/keys";
import { useIndexProjectLanguages } from "src/api/tms-projects/languages";
import { formatterLocaleProjectLanguages } from "src/api/tms-projects/languages/formatters";
import { useShowSubProject } from "src/api/tms-projects/subProjects";
import { Type_show_subProject } from "src/api/tms-projects/subProjects/types";
import { countryCodeLanguages } from "src/assets/translations";
import { useLanguage } from "src/contexts/languages";
import { redirectToIdentification } from "src/utils/urlFormatter";

type Type_contextProject = {
    setContextState: React.Dispatch<React.SetStateAction<Type_contextProject>>;
    project: Type_show_project | null;
    subProject: Type_show_subProject | null;
    isLoading: boolean;
    error: boolean;
    requestConfig: Type_RequestConfig;
};

const initialProjectState: Type_contextProject = {
    setContextState: () => {},
    project: null,
    subProject: null,
    isLoading: false,
    error: false,
    requestConfig: {
        versionId: 1, // TODO Change this with the current version id when is it possible
    },
};

type Type_projectProvider = {
    children: ReactElement;
};

const ProjectContext = createContext<Type_contextProject>(initialProjectState);

export const useProject = () => {
    const context = useContext(ProjectContext);
    if (!context) {
        throw new Error(
            "useProject must be used within a ProjectContextProvider",
        );
    }
    return context;
};

export const ProjectProvider = ({ children }: Type_projectProvider) => {
    const { projectSlug, subProjectId } = useParams();

    const [contextState, setContextState] =
        useState<Type_contextProject>(initialProjectState);
    const { setLanguages } = useLanguage();
    const queryClient = useQueryClient();

    ////////////////////////////////////////////
    ///         Queries                      ///
    ////////////////////////////////////////////

    const {
        isFetching: isFetchingProject,
        data: project,
        error: errorProject,
        refetch: refetchProject,
    } = useShowProjectBySlug(projectSlug);

    const {
        isFetching: isFetchingSubProject,
        data: subProject,
        error: errorSubProject,
    } = useShowSubProject(
        contextState.project?.id,
        parseInt(subProjectId || "0"),
    );

    const { isFetching: isFetchingProjectLanguages, data: projectLanguages } =
        useIndexProjectLanguages(contextState.project?.id);

    if (errorProject || errorSubProject) {
        console.error("Error fetching project:", errorProject);
        redirectToIdentification();
    }

    ////////////////////////////////////////////
    ///         Effects                      ///
    ////////////////////////////////////////////

    useEffect(() => {
        if (isFetchingProject) {
            setContextState((prev) => ({
                ...prev,
                isLoading: true,
            }));
        }

        if (!project) {
            // reset context
            setContextState(initialProjectState);
            setLanguages(countryCodeLanguages);
            return;
        }

        setContextState((prev) => ({
            ...prev,
            project: project,
            isLoading: false,
            error: false,
            requestConfig: {
                ...prev.requestConfig,
                projectId: project?.id,
            },
        }));

        queryClient.invalidateQueries({
            queryKey: [
                SubProjectKeys.SHOW,
                contextState.project?.id,
                subProjectId,
            ],
        });
    }, [isFetchingProject]);

    useEffect(() => {
        if (isFetchingSubProject) return;
        if (!subProject) return;

        setContextState((prev) => ({
            ...prev,
            isLoading: false,
            subProject,
            requestConfig: {
                ...prev.requestConfig,
                ...(subProjectId
                    ? { subProjectId: subProject.id }
                    : { subProjectId: undefined }),
            },
        }));
    }, [isFetchingSubProject]);

    useEffect(() => {
        if (isFetchingProjectLanguages) return;
        setLanguages(
            projectLanguages
                ? formatterLocaleProjectLanguages(projectLanguages)
                : countryCodeLanguages,
        );
    }, [isFetchingProjectLanguages]);

    /**
     * Lorsque le projectSlug change :
     *  - s'il existe, je relance la requête pour récupérer les données du projet
     *  - s'il est undefined, je réinitialise le state
     */
    useEffect(() => {
        if (projectSlug !== undefined) {
            refetchProject();
        } else if (contextState.project !== null) {
            setContextState(initialProjectState);
            setLanguages(countryCodeLanguages);
        }
    }, [projectSlug]);

    /**
     * Lorsqu'il n'y a plus de subProjectId dans l'url, je réinitialise le contextState du sous-projet
     */
    useEffect(() => {
        if (subProjectId === undefined) {
            setContextState((prev) => ({
                ...prev,
                subProject: null,
                requestConfig: {
                    ...prev.requestConfig,
                    subProjectId: undefined,
                },
            }));
        }
    }, [subProjectId]);

    return (
        <ProjectContext.Provider value={{ ...contextState, setContextState }}>
            {children}
        </ProjectContext.Provider>
    );
};
