import { Divider, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useIndexAreaTypes } from "src/api/tms-commons/areaTypes";
import { Type_index_areaType } from "src/api/tms-commons/areaTypes/types";
import { useIndexAreas } from "src/api/tms-projects/areas";
import {
    mutationCreateSequenceArea,
    mutationDeleteSequenceArea,
    mutationUpdateSequenceArea,
    useIndexSequenceAreas,
} from "src/api/tms-scheduling/sequenceAreas";
import { Type_index_sequenceArea } from "src/api/tms-scheduling/sequenceAreas/types";
import { MatrixDataGrid, TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { rowFormatter } from "src/components/Components_Common/MatrixDataGrid/Helpers/RowFormatter";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import { getFlattenAreas } from "src/utils/area";

import { TableSequenceAreasColumns } from "./TableSequenceAreasColumns";
import { useFiltersContext } from "./TableSequenceAreasFilterContext";
import {
    formatAreas,
    generateComparator,
    getKeyPath,
    getMaxOrder,
    sortAreas,
} from "./TableSequenceAreasHelpers";

type Type_Props_TableSequenceAreas = {
    sequenceId: number;
};

export const TableSequenceAreas = ({
    sequenceId,
}: Type_Props_TableSequenceAreas) => {
    const { filters, sort } = useFiltersContext();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    const [areas, setAreas] = useState<any[]>([]);
    const [areasTypes, setAreasTypes] = useState<Type_index_areaType[]>([]);
    const [sequenceAreasOrder, setSequenceAreasOrder] = useState<
        Type_index_sequenceArea[]
    >([]);

    const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
    const [manualOrder, setManualOrder] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<Set<number>>(
        (): Set<number> => new Set(),
    );

    // Fetch Area Types
    const { isFetching: isFetchingAreaTypes, data } = useIndexAreaTypes() || {};

    useEffect(() => {
        if (data) {
            setAreasTypes(data);
        }
    }, [data]);

    // Fetch and Format Areas
    const { isFetching: isFetchingAreas, data: fetchedAreas } =
        useIndexAreas() || {};

    useEffect(() => {
        if (fetchedAreas && !isFetchingAreas) {
            const orderArea = fetchedAreas
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((area) => rowFormatter(area));

            const formattedAreas = getFlattenAreas({ items: orderArea });

            const formattedAreasDepth = formatAreas(formattedAreas);

            setAreas(sortAreas(formattedAreasDepth));
        }
    }, [isFetchingAreas]);

    // Fetch Sequence/Area Order
    const { data: sequenceAreasData, isFetching: isFetchingSequenceAreas } =
        useIndexSequenceAreas({ sequence_id: sequenceId }, areas.length > 0);

    useEffect(() => {
        if (sequenceAreasData) {
            setSequenceAreasOrder(sequenceAreasData);
            const sequenceAreasMap = new Map(
                sequenceAreasData.map((seqArea) => [
                    seqArea.area_id,
                    seqArea.order,
                ]),
            );
            const updatedAreas = areas.map((area) => ({
                ...area,
                order: sequenceAreasMap.get(area.id) ?? null,
                selected: sequenceAreasMap.has(area.id),
            }));
            const updatedAreasMap = new Set(
                updatedAreas
                    .filter((area) => area.selected)
                    .map((area) => area.id),
            );
            setSelectedRows(updatedAreasMap);
            setAreas(updatedAreas);
        }
    }, [sequenceAreasData]);

    // Mutation to create a new Sequence Area Order
    const { mutateAsync: mutateCreateSequenceArea } =
        mutationCreateSequenceArea((seqAreaOrder: Type_index_sequenceArea) => {
            const alreadyExistOrder = sequenceAreasOrder.findIndex(
                (order) => order.area_id == seqAreaOrder.area_id,
            );

            if (alreadyExistOrder >= 0) {
                sequenceAreasOrder[alreadyExistOrder] = seqAreaOrder;
            } else {
                sequenceAreasOrder.push(seqAreaOrder);
            }
        });

    // Mutation to update the order of an existing area in sequence
    const { mutateAsync: mutateUpdateSequenceArea } =
        mutationUpdateSequenceArea((seqAreaOrder: Type_index_sequenceArea) => {
            const orderIndex = sequenceAreasOrder.findIndex(
                (order) => order.area_id == seqAreaOrder.area_id,
            );

            sequenceAreasOrder[orderIndex] = seqAreaOrder;
        });

    const { mutateAsync: mutateDeleteSequenceArea } =
        mutationDeleteSequenceArea((id) => {
            setSequenceAreasOrder((prevSequence) => {
                return prevSequence.filter((order) => order.id !== id);
            });
        });

    const isFetching = useMemo(
        () => isFetchingSequenceAreas || isFetchingAreas || isFetchingAreaTypes,
        [isFetchingSequenceAreas, isFetchingAreas, isFetchingAreaTypes],
    );

    /**
     * Generate the dynamique columns for Sequence Areas Matrix
     */
    const areaSeqColumns = TableSequenceAreasColumns(
        areasTypes,
        manualOrder,
        areas,
    );

    /**
     * Retrieves the order ID associated with a given area ID from the sequenceAreasOrder array.
     * @param {number} areaId - The ID of the area to search for.
     * @returns {number | undefined} - The order ID if found, otherwise undefined.
     */
    const getSequenceAreaOrderID = (areaId: number): number | undefined =>
        sequenceAreasOrder.find((order) => order.area_id === areaId)?.id;

    const onRowsChange = async (
        updatedRows: any[] | any,
        columnKey: string,
        autoOrder: boolean = false,
    ) => {
        const updatedAreas = areas.map((area) => ({ ...area }));
        for (const row of updatedRows) {
            const rowOrder = row.order;
            const maxOrder = getMaxOrder(areas);
            const seqAreaOrderId = getSequenceAreaOrderID(row.id);
            if (columnKey === "selected") {
                if (row.selected) {
                    setSelectedRows((rows) => rows.add(row.id));
                    if (manualOrder || autoOrder) {
                        row.order = maxOrder + 1;
                        areas.map((area) => {
                            if (area.id == row.id) {
                                area.order = row.order;
                            }
                            return area;
                        });
                        if (autoOrder && seqAreaOrderId) {
                            await mutateUpdateSequenceArea({
                                id: seqAreaOrderId,
                                order: row.order,
                            });
                        } else {
                            await mutateCreateSequenceArea({
                                area_id: row.id,
                                sequence_id: sequenceId,
                                order: row.order,
                            });
                        }
                    } else {
                        await mutateCreateSequenceArea({
                            area_id: row.id,
                            sequence_id: sequenceId,
                        });
                    }
                } else {
                    selectedRows.delete(row.id);
                    if (rowOrder) {
                        row.order = null;
                        areas.map(async (area) => {
                            if (area.order > rowOrder) {
                                area.order = parseInt(area.order) - 1;
                            }
                            return area;
                        });
                        for (const _area of updatedAreas) {
                            if (_area.order > rowOrder) {
                                await mutateUpdateSequenceArea({
                                    id: Number(
                                        getSequenceAreaOrderID(_area.id),
                                    ),
                                    order: parseInt(_area.order) - 1,
                                });
                            }
                        }
                    }
                    if (seqAreaOrderId) {
                        await mutateDeleteSequenceArea(seqAreaOrderId);
                    }
                }
            } else if (columnKey === "order" && manualOrder) {
                if (isNaN(parseInt(row.order))) {
                    row.selected = false;
                    selectedRows.delete(row.id);
                } else {
                    row.selected = true;
                    setSelectedRows((rows) => rows.add(row.id));
                    const orderAlreadyExist = areas.find(
                        (area) => area.order == row.order,
                    );

                    if (orderAlreadyExist) {
                        // check if current cell is empty or contain order
                        const prevOrder = areas.find(
                            (area) => area.id === row.id,
                        ).order;

                        // if it contain order swap between this and the order that will be updated
                        if (prevOrder && seqAreaOrderId) {
                            areas.map((area) => {
                                if (area.id === orderAlreadyExist.id) {
                                    area.order = prevOrder;
                                }
                                return area;
                            });
                            await mutateUpdateSequenceArea({
                                id: seqAreaOrderId,
                                order: row.order,
                            });
                            await mutateUpdateSequenceArea({
                                id: Number(
                                    getSequenceAreaOrderID(
                                        orderAlreadyExist.id,
                                    ),
                                ),
                                order: prevOrder,
                            });
                        } else {
                            areas.map(async (area) => {
                                if (area.order >= row.order) {
                                    area.order = parseInt(area.order) + 1;
                                }
                                return area;
                            });
                            await mutateCreateSequenceArea({
                                area_id: row.id,
                                order: row.order,
                                sequence_id: sequenceId,
                            });
                            for (const _area of updatedAreas) {
                                if (_area.order >= row.order) {
                                    await mutateUpdateSequenceArea({
                                        id: Number(
                                            getSequenceAreaOrderID(_area.id),
                                        ),
                                        order: parseInt(_area.order) + 1,
                                    });
                                }
                            }
                        }
                    } else if (row.order) {
                        const currentArea = areas.find(
                            (area) => area.id == row.id,
                        );

                        if (currentArea.order) {
                            if (currentArea.order == maxOrder) {
                                areas.map((area) => {
                                    if (area.id === row.id) {
                                        row.order = maxOrder;
                                        area.order = maxOrder;
                                    }
                                    return area;
                                });
                            } else if (row.order > maxOrder) {
                                areas.map((area) => {
                                    if (area.order === maxOrder) {
                                        area.order = currentArea.order;
                                    }
                                    return area;
                                });
                                areas.map((area) => {
                                    if (area.id === row.id) {
                                        area.order = maxOrder;
                                    }
                                    return area;
                                });
                                row.order = maxOrder;
                                for (const _area of updatedAreas) {
                                    if (_area.order === maxOrder) {
                                        await mutateUpdateSequenceArea({
                                            id: Number(
                                                getSequenceAreaOrderID(
                                                    _area.id,
                                                ),
                                            ),
                                            order: updatedAreas.find(
                                                (area) => area.id == row.id,
                                            ).order,
                                        });
                                    }
                                }
                                for (const _area of updatedAreas) {
                                    if (_area.id === row.id) {
                                        await mutateUpdateSequenceArea({
                                            id: Number(
                                                getSequenceAreaOrderID(
                                                    _area.id,
                                                ),
                                            ),
                                            order: maxOrder,
                                        });
                                    }
                                }
                            }
                        } else {
                            row.order = maxOrder + 1;
                            await mutateCreateSequenceArea({
                                area_id: row.id,
                                sequence_id: sequenceId,
                                order: parseInt(row.order),
                            });
                        }
                    }
                }
            }
        }
    };

    /**
     * Updates selected areas from header selection.
     * If selection contains elements, sets those areas as selected and triggers 'onRowsChange' with the updated selection.
     * If selection is empty, clears all selections, resets the 'selected' property of all areas to false, sets their 'order' property to null, and triggers deletion of sequence areas.
     * @param {Set<any>} selection - The set of selected rows.
     * @returns {Promise<void>} - Resolves after updating the selected rows.
     */
    const updatedSelectedRow = async (selection: Set<any>): Promise<void> => {
        setSelectedRows(selection);
        let updatedArea = [...areas];
        if (selection.size > 0) {
            onRowsChange(
                updatedArea
                    .filter((area) => !area.selected)
                    .map((area) => ({ ...area, selected: true })),
                "selected",
            );
        } else {
            updatedArea = updatedArea.map((area) => ({
                ...area,
                selected: false,
                order: null,
            }));
            setAreas(updatedArea);
            for (const order of sequenceAreasOrder) {
                await mutateDeleteSequenceArea(order.id);
            }
        }
    };

    /**
     * Toggles the selection of areas.
     * If 'showOnlySelected' is true, shows only selected areas, otherwise shows all areas.
     * @returns {void}
     */
    const toggleSelection = (): void => {
        if (!showOnlySelected) {
            const filterAreas = areas.map((area) => {
                area.hidden = selectedRows.has(area.id) ? false : true;
                return area;
            });
            setAreas(filterAreas.sort((a, b) => a.hidden - b.hidden));
        } else {
            const filterAreas = areas
                .map((area) => {
                    area.hidden = false;
                    return area;
                })
                .sort((a, b) => a.sortOrder - b.sortOrder);
            setAreas(sortAreas(filterAreas));
        }
        setShowOnlySelected(!showOnlySelected);
    };

    /**
     * This function establishes an order for selected areas that do not already have one.
     * @returns {void}
     */
    const autoOrder = (): void => {
        const onlySelectedArea = areas.filter(
            (area) => area.selected && !area.order,
        );

        onRowsChange(onlySelectedArea, "selected", true);
    };

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            const filteredAreas = areas.map((area) => {
                let hidden = false;

                Object.entries(filters).forEach(([key, value]) => {
                    const areaValue = getKeyPath(area, key);
                    const filterValue = value;

                    if (areaValue !== null && areaValue !== undefined) {
                        if (Array.isArray(filterValue)) {
                            if (
                                !filterValue.some(
                                    (filter) => areaValue === filter,
                                )
                            ) {
                                hidden = true;
                            }
                        } else {
                            if (
                                !String(areaValue)
                                    .toLowerCase()
                                    .includes(filterValue.toLowerCase())
                            ) {
                                hidden = true;
                            }
                        }
                    }

                    // Check if areaValue is empty
                    if (
                        areaValue === "" ||
                        areaValue === null ||
                        (Array.isArray(filterValue) && filterValue.length === 0)
                    ) {
                        hidden = true;
                    }
                });

                return { ...area, hidden };
            });

            setAreas(
                sortAreas(filteredAreas).sort(
                    (a: any, b: any) => a.hidden - b.hidden,
                ),
            );
        } else {
            setAreas((areas) => {
                return sortAreas(
                    areas.map((area) => {
                        area.hidden = false;
                        return area;
                    }),
                );
            });
        }
    }, [filters]);

    useEffect(() => {
        let sortedAreas = [...areas];
        if (Object.keys(sort).length > 0) {
            const key = Object.keys(sort)[0];
            const order = sort[key];

            sortedAreas = sortedAreas.sort((a, b) => {
                const comparator = generateComparator(key, order);

                const result = comparator(a, b);
                if (result !== 0) {
                    return result;
                }

                return 0;
            });

            setAreas(sortedAreas);
        } else {
            setAreas(
                sortAreas(
                    sortedAreas.sort((a, b) => a.sortOrder - b.sortOrder),
                ),
            );
        }
    }, [sort]);

    return (
        <Stack mt={5} spacing={5}>
            <Stack
                mx={12}
                justifyContent="space-between"
                direction="row"
                alignItems="center"
            >
                {/* Filters & Selections */}
                <Stack direction="row" spacing={4} alignItems={"center"}>
                    <IconButton
                        data-testid="AreaSequence_Matrix_Eye_btn"
                        onClick={toggleSelection}
                        size="small"
                    >
                        <Stack
                            spacing={1}
                            direction={"row"}
                            alignItems={"center"}
                        >
                            <Icon
                                icon={showOnlySelected ? "eye-slash" : "eye"}
                                variant="light"
                                fontSize="medium"
                            />
                        </Stack>
                    </IconButton>
                </Stack>
                {/* ManualSelection & AutoOrder */}
                <Stack direction="row" alignItems={"center"} spacing={2}>
                    <TMC_Button
                        data-testid="AreaSequence_Matrix_Selection_btn"
                        onClick={() => setManualOrder(!manualOrder)}
                        color={!manualOrder ? "primary" : "secondary"}
                    >
                        <Icon variant="light" icon={"arrow-up-right-dots"} />
                    </TMC_Button>

                    <Divider
                        textAlign="center"
                        orientation="vertical"
                        variant="middle"
                        flexItem
                    />

                    <TMC_Button
                        data-testid="AreaSequence_Matrix_AutoOrder_btn"
                        onClick={() => autoOrder()}
                        variant="text"
                    >
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            color={COLORS.black}
                            spacing={2}
                        >
                            <Icon
                                variant="light"
                                icon={"wand-magic-sparkles"}
                            />
                            <Typography variant="body1">
                                {fmt("AutoOrder")}
                            </Typography>
                        </Stack>
                    </TMC_Button>
                </Stack>
            </Stack>

            <MatrixDataGrid<any>
                columns={areaSeqColumns}
                disableHighlightCells
                handleRowsChange={onRowsChange}
                hideContextMenu
                hideIdColumn
                hideSelectColumn
                isFetching={isFetching}
                onSelectedRowsChange={updatedSelectedRow}
                rows={areas}
                selectedRows={selectedRows}
                setRows={setAreas}
            />
        </Stack>
    );
};
