import { FormikValues } from "formik";
import { useMutation } from "react-query";

import {
    changeCurrentPassword,
    checkCguValidation,
    forgetPassword,
} from "src/api/tms-users/auth/services";

export const mutationCheckCGU = () => {
    return useMutation({
        mutationFn: () => checkCguValidation(),
        onError: (err: any) => {
            return err;
        },
    });
};

export const useForgetPassword = () => {
    return useMutation({
        mutationFn: (email: string) => forgetPassword(email),
    });
};

export const mutationChangeCurrentPassword = () => {
    return useMutation({
        mutationFn: (data: FormikValues) => changeCurrentPassword(data),
    });
};
