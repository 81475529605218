import { CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import * as Sentry from "@sentry/react";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "inter-ui/inter.css";

import { LanguagesProvider } from "src/contexts/languages";
import { ThemeProvider } from "src/contexts/theme";
import { ToastProvider } from "src/contexts/toasts";
import { UserProvider } from "src/contexts/user";
import { Router } from "src/router";

import { LocalizationWrapper } from "./configurations/LocalizationWrapper";

import "src/index.css";

console.log(`--- APP VERSION : ${__APP_VERSION__}`);

///////////////////////////////
// React Query              ///
///////////////////////////////

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});

///////////////////////////////
// Sentry                   ///
///////////////////////////////

if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "production") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

///////////////////////////////
// MUI PRO LICENSE          ///
///////////////////////////////
LicenseInfo.setLicenseKey(
    "97d71a8d19fde10b06c4aef0df381297Tz05ODQ2NyxFPTE3NTgzNzk1NTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==",
);

///////////////////////////////
// Render                   ///
///////////////////////////////

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ThemeProvider>
            <>
                <CssBaseline enableColorScheme />
                {/* Contextes Packages */}
                <QueryClientProvider client={queryClient}>
                    {/* Contextes TEAMOTY */}
                    <LanguagesProvider>
                        <ToastProvider>
                            <UserProvider>
                                <DndProvider backend={HTML5Backend}>
                                    <LocalizationWrapper>
                                        {/* Application */}
                                        <Router />
                                    </LocalizationWrapper>
                                </DndProvider>
                            </UserProvider>
                        </ToastProvider>
                    </LanguagesProvider>
                    {/* Affichage du debugger de REACT QUERY */}
                    {import.meta.env.VITE_SENTRY_ENVIRONMENT !==
                        "production" && (
                        <ReactQueryDevtools initialIsOpen={false} />
                    )}
                </QueryClientProvider>
            </>
        </ThemeProvider>
    </React.StrictMode>,
);
