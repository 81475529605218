import { Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { DropzoneInputProps } from "react-dropzone";

import { TMC_Chip } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Dropzone } from "src/components/Components_Common/Dropzone/Dropzone";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { FileImage, FilePdf } from "./Formatters";

export type AcceptedFormat = "png" | "jpg" | "xlsx" | "pdf" | "xls";

export type Type_Resource = {
    file: File | null;
    image?: HTMLImageElement | null;
    errors?: boolean;
};

export const emptyResource = {
    file: null,
    image: null,
    errors: false,
};

type Type_Props_InputFile = DropzoneInputProps & {
    state: Type_Resource;
    setState: Dispatch<SetStateAction<Type_Resource>>;
    acceptFormats: AcceptedFormat[];
};

export const InputFile = ({
    state,
    setState,
    acceptFormats,
    ...inputProps
}: Type_Props_InputFile) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Components.Dropzone",
    );

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const reader = new FileReader();
    // Dynamically generate accepted formats from acceptFormats prop
    const acceptedFormats: Record<string, string[]> = acceptFormats.reduce(
        (acc: any, format) => {
            switch (format) {
                case "png":
                    acc["image/png"] = [".png"];
                    break;
                case "jpg":
                    acc["image/jpeg"] = [".jpg", ".jpeg"];
                    break;
                case "xlsx":
                    acc[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ] = [".xlsx", ".xls"];
                    break;
                case "pdf":
                    acc["application/pdf"] = [".pdf"];
                    break;
                default:
                    break;
            }
            return acc;
        },
        {},
    );

    const clearState = () => {
        setState(emptyResource);
        setError(null);
        setIsLoading(false);
    };

    /**
     * When new file imported we should:
     * - load the file with the reader
     */
    const onDropAccepted = useCallback((files: File[]) => {
        const file = files[0];

        if (file) {
            setState((prev) => ({
                ...prev,
                file: file,
            }));
            reader.readAsDataURL(file);
            reader.onloadstart = handleLoadStart;
            reader.onprogress = handleProgress;
            reader.onabort = handleAbort;
            reader.onerror = handleError;
            reader.onload = () => handleLoad(file);
        }
    }, []);

    const handleLoadStart = async () => {
        console.debug("Load start");
        setIsLoading(true);
        setError(null);
    };

    const handleProgress = (event: ProgressEvent<FileReader>) => {
        if (event.lengthComputable) {
            const percentage = (event.loaded / event.total) * 100;
            console.debug(`Loading file... ${percentage}%`);
        }
    };

    const handleAbort = () => {
        console.debug("File reading was aborted");
        clearState();
    };

    const handleError = (event: ProgressEvent<FileReader>) => {
        const err = event.target?.error?.message || "error";
        setIsLoading(false);
        setError(err);
        setState((prev) => ({
            ...prev,
            errors: true,
        }));
        console.error("File reading has failed", err);
    };

    const handleLoad = async (file: File) => {
        // Get the file temp url
        let fileUrl = reader.result;

        try {
            // convert pdf to image
            if (file?.type === "application/pdf" && fileUrl) {
                fileUrl = await FilePdf(fileUrl);
            }
            if (file?.type === "image/jpeg" && fileUrl) {
                fileUrl = await FileImage(fileUrl);
            }

            const img = new Image();
            img.src = fileUrl as string;
            img.onload = () => {
                setState((prev) => ({
                    ...prev,
                    image: img,
                }));
            };

            setError(null);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message as string);
            } else {
                setError("Error loading file !");
            }
            setState((prev) => ({
                ...prev,
                errors: true,
            }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Stack spacing={2}>
                {!state.file && !state.image ? (
                    <Dropzone
                        onDropAccepted={onDropAccepted} // option multiple disable, so we get only one file
                        options={{
                            accept: acceptedFormats,
                            multiple: false,
                        }}
                        acceptFormats={acceptFormats}
                        {...inputProps}
                    />
                ) : (
                    state.file &&
                    !state.image && (
                        <Stack direction="column">
                            <Stack
                                direction={"row"}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack alignItems="baseline" direction={"row"}>
                                    <Typography
                                        noWrap
                                        maxWidth={"300px"}
                                        variant={"body1"}
                                    >
                                        {state.file.name.split(".")[0]}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        .{state.file.name.split(".")[1]}
                                    </Typography>
                                    <Typography variant={"body3"} ml={2}>
                                        {state.file.size} KB
                                    </Typography>
                                    <IconButton
                                        onClick={clearState}
                                        data-testid="Form-Drawing-Image-Delete-Btn"
                                    >
                                        <Icon
                                            variant={"regular"}
                                            icon={"xmark"}
                                            color={"primary"}
                                        />
                                    </IconButton>
                                </Stack>

                                {isLoading && (
                                    <TMC_Chip
                                        backgroundColor={COLORS.blue300}
                                        label={fmt("Status.Uploading")}
                                        icon={
                                            <Icon
                                                variant={"solid"}
                                                icon={"spinner-third"}
                                                spin
                                            />
                                        }
                                    />
                                )}

                                {!isLoading &&
                                    !error &&
                                    !state.errors &&
                                    state.file && (
                                        <TMC_Chip
                                            backgroundColor={COLORS.success700}
                                            label={fmt("Status.Success")}
                                            color="success"
                                        />
                                    )}
                                {!isLoading && (error || state.errors) && (
                                    <TMC_Chip
                                        label={fmt("Status.Error")}
                                        backgroundColor={COLORS.error700}
                                    />
                                )}
                            </Stack>
                            {!isLoading && error && (
                                <Typography
                                    variant="body3"
                                    color={COLORS.error700}
                                >
                                    {error}
                                </Typography>
                            )}
                        </Stack>
                    )
                )}
            </Stack>
        </>
    );
};
