import { Stack, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { RectReadOnly } from "react-use-measure";

import { SequencePertDiagram } from "src/components/Components_Teamoty/SequencePert/SequencePertDiagram";
import { SequencePertLeftBar } from "src/components/Components_Teamoty/SequencePert/SequencePertLeftBar";
import { SequencePertToolbar } from "src/components/Components_Teamoty/SequencePert/SequencePertToolbar";

type Type_Props_SequencePertDrawer = {
    sequenceId: number;
    setShowPertDiagram: Dispatch<SetStateAction<boolean>>;
    bounds: RectReadOnly;
};
export const SequencePertDrawer = ({
    sequenceId,
    setShowPertDiagram,
    bounds,
}: Type_Props_SequencePertDrawer) => {
    const theme = useTheme();

    const [fixedSize, setFixedSize] = useState<boolean>(false);

    return (
        <>
            <Stack
                sx={{
                    width: bounds.width,
                    borderTop: "3px solid " + theme.palette.moonAlpha[200],
                }}
                data-testid="SequencePertDrawer-StackY"
            >
                <SequencePertToolbar
                    handleClose={() => setShowPertDiagram(false)}
                    sequenceId={sequenceId}
                    setFixedSize={setFixedSize}
                />
                <Stack
                    flexDirection={"row"}
                    sx={{
                        width: "100%",
                        height: bounds.height - 70,
                    }}
                    data-testid="SequencePertDrawer-StackX"
                >
                    <SequencePertLeftBar sequenceId={sequenceId} />
                    <SequencePertDiagram
                        sequenceId={sequenceId}
                        fixedSize={fixedSize}
                    />
                </Stack>
            </Stack>
        </>
    );
};
