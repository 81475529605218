import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { useIndexProgressHistory } from "src/api/tms-scheduling/progressHistory";
import { Type_index_progressHistory } from "src/api/tms-scheduling/progressHistory/types";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { Empty } from "src/components/Components_Common/Empty/Empty";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { Type_event_idsTaskArea, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { ProgressionHistory } from "./ProgressionHistory";

type Type_Props_ProgressionHistories = ItemChildrenProps & {
    taskAreaId: number;
    open: boolean;
};

export const ProgressionHistories = ({
    taskAreaId,
    open,
}: Type_Props_ProgressionHistories) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Progression",
    );

    const [progressionHistories, setProgressionHistories] = useState<
        Type_index_progressHistory[]
    >([]);

    /////////////////////////
    ///     Queries      ///
    /////////////////////////
    const { isFetching, data, refetch } = useIndexProgressHistory(
        taskAreaId,
        !!taskAreaId && open,
        "accordionProgressionHistories",
        MEDIUM_STALE_TIME,
    );

    useEffect(() => {
        if (!isFetching) {
            setProgressionHistories(data as Type_index_progressHistory[]);
        }
    }, [data, isFetching]);

    /////////////////////////
    ///     Channels      ///
    /////////////////////////

    // TODO : Maybe useless, use by taskAreaForm onSubmit
    // Check what update the planning
    useChannel({
        eventHandler: ({ event, data: _data }) => {
            if (event === "updateProgress") {
                const progressEventData = _data as Type_event_idsTaskArea;
                if (progressEventData.taskAreaId === taskAreaId) {
                    refetch();
                }
            }
        },
    });

    return isFetching ? (
        <FullSpinner />
    ) : (
        <Stack gap={2}>
            {progressionHistories?.length ? (
                progressionHistories.map((progression) => (
                    <ProgressionHistory
                        key={progression.id}
                        progression={progression}
                    />
                ))
            ) : (
                <Empty
                    actionBtnVariant="text"
                    message={fmt("EmptyMessage")}
                    onClick={() => {}}
                    dataTestIdRef="TaskProgression-empty"
                />
            )}
        </Stack>
    );
};
