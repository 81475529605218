import { Box, Skeleton, Typography } from "@mui/material";
import * as React from "react";

import { useIndexResourcePeriods } from "src/api/tms-projects/resourcePeriods";
import { Type_index_resourcePeriod } from "src/api/tms-projects/resourcePeriods/types";
import { StartDateEndDateCell } from "src/components/Components_Common/cellComponents/StartDateEndDateCell/StartDateEndDateCell";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ResourcePeriodsListCell = {
    resourceId: number;
};

export const ResourcePeriodsListCell = ({
    resourceId,
}: Type_Props_ResourcePeriodsListCell) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Resources",
    );
    const { data: resourcesPeriods, isFetching } =
        useIndexResourcePeriods(resourceId);

    return (
        <>
            {isFetching ? (
                <Box>
                    <Skeleton variant="text" />
                </Box>
            ) : (
                <>
                    {!resourcesPeriods?.length ? (
                        <Typography>{fmt("NoTimeslot")}</Typography>
                    ) : (
                        resourcesPeriods?.map(
                            (item: Type_index_resourcePeriod) => {
                                return (
                                    <StartDateEndDateCell
                                        data-testid={`resource-period-${item.id}`}
                                        key={`resourcePeriods-${item.id}`}
                                        startDate={item.startDate}
                                        endDate={item.endDate}
                                    />
                                );
                            },
                        )
                    )}
                </>
            )}
        </>
    );
};
