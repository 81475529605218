import { formatterShowResourcePeriod } from "src/api/tms-projects/resourcePeriods/formatters";
import { Type_prj_index_resourcePeriod } from "src/api/tms-projects/resourcePeriods/types";
import {
    Type_index_resource,
    Type_post_resource,
    Type_prj_index_resource,
    Type_prj_post_resource,
    Type_prj_put_resource,
    Type_prj_select_resource,
    Type_prj_show_resource,
    Type_select_resource,
    Type_selectList_resource,
    Type_show_resource,
} from "src/api/tms-projects/resources/types";
import { DEFAULT_COLORS } from "src/theme/stylesheet";
import { sortData } from "src/utils/sortData";

export const formatterIndexResources = (
    resources: Type_prj_index_resource[],
): Type_index_resource[] => {
    if (!resources) return [];

    return resources.map((resource: any) => {
        return formatterIndexResource(resource);
    });
};

export const formatterIndexResource = (
    resource: Type_prj_index_resource,
): Type_index_resource => {
    return {
        id: resource.id,
        name: resource.name,
        shortName: resource.shortName,
        color: resource.color ?? DEFAULT_COLORS.ressource,
        resourceType: resource.resourceType,
        resourceTypeName: resource.resourceType.name,
        subProject: resource.subProject,
        enabled: resource.enabled,
    };
};

export const formatterCreateResource = (
    resource: Type_post_resource,
): Type_prj_post_resource => {
    return {
        names: resource.names,
        shortName: resource.shortName,
        color: resource.color ?? DEFAULT_COLORS.ressource,
        resource_type_id: resource.resourceType,
    };
};

export const formatterUpdateResource = (
    resource: Type_show_resource,
): Type_prj_put_resource => {
    return {
        id: resource.id,
        names: resource.names,
        shortName: resource.shortName,
        color: resource.color ?? DEFAULT_COLORS.ressource,
        resource_type_id: resource.resourceType,
    };
};

export const formatterShowResource = (
    resource: Type_prj_show_resource,
    period: Type_prj_index_resourcePeriod[],
): Type_show_resource | undefined => {
    if (resource && period)
        return {
            id: resource.id,
            names: resource.names,
            shortName: resource.shortName,
            color: resource.color ?? DEFAULT_COLORS.ressource,
            resourceType: resource.resourceType.id,
            period: period.length ? formatterShowResourcePeriod(period[0]) : [],
            periodId: period.length ? period[0].id : undefined,
        };
};

export const formatterSelectResources = (
    data: Type_prj_select_resource[],
): Type_select_resource[] => {
    return data.map((datum: Type_prj_select_resource) => ({
        id: datum.id,
        color: datum.color ?? DEFAULT_COLORS.ressource,
        name: datum.name,
        shortName: datum.shortName,
        enabled: datum.enabled,
        resourceType: datum.resourceType,
        resourceTypeName: datum.resourceType.name,
        subProject: datum.subProject,
    }));
};

export const formatterSelectListResources = (
    data: Type_prj_select_resource[],
): Type_selectList_resource[] => {
    return sortData(data, "name").map((datum: Type_prj_select_resource) => ({
        id: datum.id,
        name: datum.name,
    }));
};
