type CellExpanderFormatterProps = {
    tabIndex: number;
    expanded: boolean;
    onCellExpand: () => void;
    style?: any;
    row: any;
};

export const CellExpanderFormatter = ({
    tabIndex,
    expanded,
    onCellExpand,
    style,
    row,
}: CellExpanderFormatterProps) => {
    function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            onCellExpand();
        }
    }

    return (
        <div
            style={{
                float: "inline-start",
                display: "table",
                blockSize: "100%",
                marginRight: 4,
                ...style,
            }}
        >
            <span
                style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    cursor: "pointer",
                }}
                onClick={onCellExpand}
                data-testid={`expand-row-${row.id}`}
                onKeyDown={handleKeyDown}
            >
                <span tabIndex={tabIndex}>
                    {expanded ? "\u25BC" : "\u25B6"}
                </span>
            </span>
        </div>
    );
};
