import { Type_index_sequenceArea, Type_sch_index_sequenceArea } from "./types";

export const formatterIndexSequenceAreas = (
    sequenceAreas: Type_sch_index_sequenceArea[],
): Type_index_sequenceArea[] => {
    return sequenceAreas.map((sequenceArea: Type_sch_index_sequenceArea) =>
        formatterIndexSequenceArea(sequenceArea),
    );
};

export const formatterIndexSequenceArea = (
    sequenceArea: Type_sch_index_sequenceArea,
): Type_index_sequenceArea => {
    return {
        id: sequenceArea.id,
        area_id: sequenceArea.area_id,
        sequence_id: sequenceArea.sequence_id,
        order: sequenceArea.order,
    };
};
