import { getLocalStorageItem } from "src/utils/localStorageServices";

export enum PathElement {
    LANG = 0,
    APPLICATION = 1,
    PROJECT = 2,
    CATEGORY = 5,
}

export const replaceUrlField = (
    fieldIndex: number,
    fieldReplacement: string,
): string => {
    const url: string = window.location.href;
    const urlFields: Array<string> = url.split("/");
    urlFields[fieldIndex + 3] = fieldReplacement;
    return `${urlFields.join("/")}${urlFields.length < 5 ? "/login" : ""}`;
};

export const redirectTo = (newUrl: string): void => {
    window.location.href = newUrl;
};

export const getEnv = (env: string): string => {
    const url: string = window.location.href;
    const domain: string = url?.split("/")[2];

    return domain.replace("app.", env + ".");
};

export const redirectToIdentification = (url: string = "") => {
    const lang = getLocalStorageItem("language");

    if (!url.includes("login")) redirectTo(`/${lang}/login`);
};

export const getElementFromUrl = (elementIndex: number) => {
    const elements = location.pathname.split("/");
    return elements[elementIndex];
};

export const getUrlName = () => {
    const splittedHref = window.location.href.split("/");

    return `${splittedHref[0]}//${splittedHref[2]}/`;
};
