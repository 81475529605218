export enum AreaTypeKeys {
    INDEX = "indexAreaTypes",
    SHOW = "showAreaType",
    SELECT_LIST = "selectListAreaTypes",
}

export enum CauseOfDelayKeys {
    INDEX = "indexCausesOfDelay",
    SHOW = "showCauseOfDelay",
}

export enum CompanyKeys {
    INDEX = "indexCompanies",
    SHOW = "showCompany",
    SELECT_LIST = "selectListCompanies",
}

export enum FavouriteProjectKeys {
    INDEX = "indexFavouriteProjects",
}

export enum LicenseKeys {
    INDEX = "indexLicenses",
    SHOW = "showLicense",
}

export enum LicenseTypeKeys {
    INDEX = "indexLicenseTypes",
    INDEX_MODES = "indexLicenseTypeModes",
    SHOW = "showLicenseType",
    SELECT_LIST = "selectListLicenseTypes",
}

export enum MetadataProjectKeys {
    INDEX = "indexMetadataProjects",
    SHOW = "showMetadataProject",
}

export enum PlatformSettingKeys {
    SHOW = "showPlatformSetting",
}

export enum PriorityOfConstraintKeys {
    INDEX = "indexPriorityOfConstraints",
    SHOW = "showPriorityOfConstraint",
    DEFAULT_PRIORITY = "indexDefaultPriority",
}

export enum ProjectKeys {
    INDEX = "indexProjects",
    SHOW = "showProject",
    SLUG = "showProjectBySlug",
}

export enum ProjectTypeKeys {
    INDEX = "indexProjectTypes",
    SHOW = "showProjectType",
    SELECT_LIST = "selectListProjectTypes",
}

export enum PunchlistItemCauseKeys {
    INDEX = "indexPunchlistItemCauses",
    SHOW = "showPunchlistItemCause",
    SELECT_LIST = "selectPunchlistItemCause",
}

export enum ResourceFamilyKeys {
    INDEX = "indexResourceFamilies",
    SHOW = "showResourceFamily",
    SELECT_LIST = "selectListResourceFamilies",
}

export enum ResourceModelKeys {
    INDEX = "indexResourceModels",
    SHOW = "showResourceModel",
    SELECT_LIST = "selectListResourceModels",
}

export enum TfeModelKeys {
    CLOSE = "closeTFE",
    OPEN = "openTFE",
    PING = "getPingTFE",
    REPORT = "getTasksAreasReport",
    PUNCHLIST = "getPunchlists",
    ROADBLOCK = "getRoadblocks",
}

export enum TimeModelKeys {
    INDEX = "indexTimeModels",
    SHOW = "showTimeModel",
    SELECT_LIST = "selectListTimeModels",
}

export enum UnitKeys {
    INDEX = "indexUnits",
    SHOW = "showUnit",
    SELECT_LIST = "selectListUnits",
}

export enum UnitTypeKeys {
    INDEX = "indexUnitTypes",
    SHOW = "showUnitType",
    SELECT_LIST = "selectListUnitTypes",
}

export enum PunchlistKeys {
    INDEX = "indexPunchlists",
    SHOW = "showPunchlist",
}
