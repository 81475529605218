import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { UnitKeys } from "src/api/tms-commons/keys";
import {
    formatterCreateUnit,
    formatterIndexUnit,
    formatterIndexUnits,
    formatterSelectListUnit,
    formatterShowUnit,
    formatterStatusUnit,
    formatterUpdateUnit,
} from "src/api/tms-commons/units/formatters";
import {
    createUnit,
    deleteUnit,
    indexUnits,
    selectListUnits,
    showUnit,
    statusUnit,
    updateUnit,
} from "src/api/tms-commons/units/services";
import {
    Type_index_unit,
    Type_post_unit,
    Type_put_unit,
    Type_show_unit,
} from "src/api/tms-commons/units/types";
import { LONG_STALE_TIME } from "src/configurations/app";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexUnits = () => {
    return useQuery({
        queryKey: [UnitKeys.INDEX],
        queryFn: indexUnits,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexUnits");
            }

            return formatterIndexUnits(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowUnit = (id: number) => {
    return useQuery([UnitKeys.SHOW, id], () => showUnit(id), {
        enabled: !!id,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowUnit");
            }

            return formatterShowUnit(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationCreateUnit = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Units");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newUnit: Type_post_unit) =>
            createUnit(formatterCreateUnit(newUnit)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateUnit");
            }

            const unit: Type_index_unit = formatterIndexUnit(data.data.data);

            await queryClient.invalidateQueries([UnitKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        unit: unit.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateUnit = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Units");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (unitToUpdate: Type_show_unit) =>
            updateUnit(unitToUpdate.id, formatterUpdateUnit(unitToUpdate)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateUnit");
            }

            const unit: Type_index_unit = formatterIndexUnit(data.data.data);

            await queryClient.invalidateQueries([UnitKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        unit: unit.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusUnit = () => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (newStatus: Type_put_unit) => {
            return statusUnit(newStatus.id, formatterStatusUnit(newStatus));
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusUnit");
            }

            await queryClient.invalidateQueries([UnitKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListUnits = (enabled: boolean = true) => {
    return useQuery({
        queryKey: [UnitKeys.SELECT_LIST],
        queryFn: selectListUnits,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListUnits");
            }
            return formatterSelectListUnit(data.data.data);
        },
        onError: (err: any) => {
            return err;
        },
        enabled: enabled,
        staleTime: LONG_STALE_TIME,
    });
};

export const mutationDeleteUnit = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Units");

    const queryClient = useQueryClient();

    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deleteUnit(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteUnit");
            }
            await queryClient.invalidateQueries({
                queryKey: [UnitKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
