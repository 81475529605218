import {
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
    mutationImportAreas,
    useExportAreas,
} from "src/api/tms-projects/areas";
import { TMC_ExportImportDropDown } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { MatrixTableAreas } from "src/components/Components_Teamoty/tables/TableAreas/MatrixTableAreas";
import { TableAreasFullScreen } from "src/components/Components_Teamoty/tables/TableAreas/TableAreasFullScreen";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { TableAreasContainer } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/Table_Areas/TableAreas.container";

export const Page_Areas = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const { formatMessageWithPartialKey: fmtTable } = useCoreIntl("Table");
    const page = "areas";

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentView, setCurrentView] = useState<string | null>(
        searchParams.get("view") || "list",
    );
    const [fullScreenModal, setFullScreenModal] = useState<boolean>(false);

    const { refetch, isFetching: isExportingAreas } = useExportAreas();

    const {
        mutateAsync: mutateImportAreas,
        error: importErrors,
        isLoading: isImportingAreas,
        reset: resetImportAreasState,
    } = mutationImportAreas();

    const handleExport = () => {
        refetch();
    };

    const [selectedArea, setSelectedArea] = useState<number | null>(null);
    const [openAreaDrawer, setOpenAreaDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawerArea = (id: number | null, action: Type_action) => {
        setSelectedArea(id);
        setAction(action);
        setOpenAreaDrawer(true);
    };

    const handleAreaCreate = () => {
        openDrawerArea(null, "create");
    };

    const handleAreaEdit = (row: GridRowModel) => {
        openDrawerArea(row.id, "update");
    };

    const handleCloseAreaDrawer = () => {
        setOpenAreaDrawer(false);
    };

    return (
        <>
            <HeaderGrid>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <ToggleButtonGroup
                        exclusive
                        size="small"
                        sx={{ borderRadius: 5 }}
                        value={currentView}
                        onChange={(e, value) => {
                            if (value) {
                                setSearchParams({ view: value });
                                setCurrentView(value);
                            }
                        }}
                        aria-label="view"
                    >
                        <ToggleButton
                            sx={{
                                p: 3,
                            }}
                            value="list"
                            aria-label="list view"
                        >
                            <Tooltip
                                title={fmtTable("List")}
                                placement="bottom"
                            >
                                <Box>
                                    <Icon
                                        icon="list"
                                        variant="light"
                                        fontSize="medium"
                                    />
                                </Box>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton
                            sx={{
                                p: 3,
                            }}
                            value="table"
                            aria-label="table view"
                        >
                            <Tooltip
                                title={fmtTable("Table")}
                                placement="bottom"
                            >
                                <Box>
                                    <Icon
                                        icon="table"
                                        variant="light"
                                        fontSize="medium"
                                    />
                                </Box>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                >
                    <TMC_ExportImportDropDown
                        title={fmt(`Drawer.ImportAreas`)}
                        importTypes={["xlsx", "xls"]}
                        exportType="xlsx"
                        isLoading={isExportingAreas || isImportingAreas}
                        errors={importErrors}
                        onAbort={resetImportAreasState}
                        onExport={handleExport}
                        onImport={mutateImportAreas}
                    />
                    <AddButton
                        data-testid="Areas-AddOne-btn"
                        onClick={handleAreaCreate}
                        startIcon={<Icon variant="solid" icon="plus" />}
                        page={page}
                    />
                </Stack>
            </HeaderGrid>
            {currentView === "list" && (
                <TableAreasContainer
                    page={page}
                    handleAreaEdit={handleAreaEdit}
                    handleAreaCreate={handleAreaCreate}
                    handleCloseAreaDrawer={handleCloseAreaDrawer}
                    selectedArea={selectedArea}
                    openAreaDrawer={openAreaDrawer}
                    action={action}
                />
            )}
            {currentView === "table" && (
                <Box>
                    <MatrixTableAreas />
                </Box>
            )}
            <TableAreasFullScreen
                openDialog={fullScreenModal}
                setToggleDialog={setFullScreenModal}
            />
        </>
    );
};
