import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

/*
 *
 * Pour tout ajout d'un import, il faut rajouter la langue correspondante.
 * Suivre les instructions : src/assets/translations
 *
 * Chaque nouvel import pour une langue doit se faire en deux fois :
 * - dayjs/locale/xxx
 *
 */
import "dayjs/locale/fr.js";
import "dayjs/locale/de.js";
import "dayjs/locale/en.js";
import "dayjs/locale/en-gb.js";
import "dayjs/locale/es.js";
import "dayjs/locale/ar.js";
import "dayjs/locale/nl.js";

import { languageLocaleMap } from "src/assets/translations";
import { useLanguage } from "src/contexts/languages";

export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
export const BACKEND_TIME_FORMAT = "HH:mm";
export const BACKEND_DATE_TIME_FORMAT = `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`;

type Type_Props_LocalizationWrapper = {
    children: React.ReactElement;
};

export const LocalizationWrapper = ({
    children,
}: Type_Props_LocalizationWrapper) => {
    const { language } = useLanguage();

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={languageLocaleMap[language]}
        >
            {children}
        </LocalizationProvider>
    );
};
