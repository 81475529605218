import {
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { RenderCellProps, RenderEditCellProps } from "react-data-grid";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLORS } from "src/theme/stylesheet";

export const DropDownCell = ({ column, row }: RenderCellProps<any>) => {
    const valueColumn = column.value?.split(".") || "";
    let value = row;
    for (const key of valueColumn) {
        // check if value[key] exists
        if (value[key]) value = value[key];
    }

    return (
        <Stack
            display={"flex"}
            alignItems={"center"}
            direction={"row"}
            height={"100%"}
            justifyContent={"space-between"}
        >
            <Typography variant="body2">{value}</Typography>
            <Icon
                sx={{ color: COLORS.gray600 }}
                icon="chevron-down"
                variant="solid"
                fontSize="small"
            />
        </Stack>
    );
};

export const DropDownCellEditor = ({
    column,
    row,
    onRowChange,
}: RenderEditCellProps<any>) => {
    const keys = column.key.split(".");
    let columnKey = row;
    for (const key of keys) {
        columnKey = columnKey[key]; // Traverse nested properties
    }
    const options = column.options as {
        id: number;
        name: string;
    }[];
    return (
        <FormControl fullWidth>
            <Select
                size="small"
                data-testid={`matrix-${column.key}`}
                id="demo-simple-select"
                value={columnKey}
                label={column.name}
                onChange={(e) => {
                    const newId = e.target.value;
                    return onRowChange({
                        ...row,
                        [keys[0]]:
                            options.find((option) => option.id == newId) ||
                            null,
                    });
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
