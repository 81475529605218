import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import * as React from "react";
import { useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateLicense,
    mutationUpdateLicense,
    useShowLicense,
} from "src/api/tms-commons/licenses";
import { Type_show_license } from "src/api/tms-commons/licenses/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { AutocompleteLicenseTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteLicenseTypes";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDate } from "src/utils/date";

type Type_Props_LicenseForm = {
    onClose: () => void;
    updateId?: number | undefined;
    action: Type_action;
    projectId: number;
};

const Schema_LicenseForm = Yup.lazy(() => {
    return Yup.object().shape({
        licenseType: Yup.object().shape({
            id: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        }),
        startDate: Yup.mixed<Dayjs>().required(FORM_ERR_FMT.REQUIRED),
        renewal: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
            .nullable()
            .max(1000, FORM_ERR_FMT.TOO_LONG),
        cost: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
            .nullable(),
    });
});

export const LicenseForm = ({
    projectId,
    onClose,
    updateId,
    action,
}: Type_Props_LicenseForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Licenses");

    const { data, isFetching: isFetchingLicense } = useShowLicense(
        updateId as number,
    );

    const form = useForm<Type_show_license>({
        ...useFormDefaultConfig,
        defaultValues: {
            startDate: "",
            endDate: "",
        },
        values: data,
        resolver: yupResolver<any>(Schema_LicenseForm),
    });

    const { mutateAsync: mutateCreate } =
        mutationCreateLicense(projectId) || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateLicense(updateId) || {};

    const handleSubmit = async (
        values: Type_show_license,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        try {
            if (action === "update") {
                await mutateUpdate(values);
            } else {
                await mutateCreate(values);
            }
            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating license");
        }
    };

    const [licenseTypeWatch, startDateWatch] = useWatch({
        name: ["licenseType", "startDate"],
        control: form.control,
    });

    useEffect(() => {
        if (licenseTypeWatch && startDateWatch) {
            const startDate = getDate(startDateWatch);
            const durationValue = licenseTypeWatch.duration;
            const licenseEndDate = startDate.add(
                parseInt(durationValue, 10),
                "month",
            );

            form.setValue("endDate", licenseEndDate);
        }
    }, [licenseTypeWatch, startDateWatch, form]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"licenses"}>
                {isFetchingLicense ? (
                    <LoadingBox />
                ) : (
                    <Stack spacing={4}>
                        <AutocompleteLicenseTypes
                            name="licenseType"
                            multiple={false}
                            label={fmt("LicenseType")}
                            renderInputProps={{
                                variant: "outlined",
                            }}
                        />
                        <Typography variant="h3">
                            {fmt(`LicenseDates`)}
                        </Typography>
                        <Typography variant="body3">
                            {fmt(`LicenseDatesInfo`)}
                        </Typography>
                        <Stack
                            direction="row"
                            flexWrap="nowrap"
                            justifyContent={"space-between"}
                            spacing={4}
                        >
                            <DatePicker
                                name="startDate"
                                label={fmt("StartDate")}
                                data-testid="Licence-DrawerContent-startDate"
                            />

                            <DatePicker
                                name="endDate"
                                label={fmt("EndDate")}
                                data-testid="Licence-DrawerContent-endDate"
                                disabled
                            />
                        </Stack>
                        <Typography variant="h3">
                            {fmt(`LicenseOptions`)}
                        </Typography>
                        <Stack
                            direction="row"
                            flexWrap="nowrap"
                            justifyContent={"space-between"}
                            spacing={4}
                        >
                            <TextField
                                name="renewal"
                                label={fmt(`Renewal`)}
                                type="number"
                                variant="outlined"
                            />
                            <TextField
                                name="cost"
                                label={fmt(`Cost`)}
                                type={`number`}
                                variant="outlined"
                            />
                        </Stack>
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
