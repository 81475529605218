import { FormikValues } from "formik";
import { useMutation, useQuery } from "react-query";

import { SequenceFolderKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateSequenceFolder,
    formatterCreateSequenceFolderLegacy,
    formatterIndexSequenceFolder,
    formatterIndexSequencesFolder,
    formatterSelectListSequenceFolders,
    formatterShowSequenceFolder,
    formatterTreeSequenceFolder,
    formatterTreeSequenceFolders,
    formatterUpdateSequenceFolder,
    formatterUpdateSequenceFolderLegacy,
} from "src/api/tms-scheduling/sequenceFolders/formatters";
import {
    createSequenceFolder,
    deleteSequenceFolder,
    indexSequenceFolders,
    selectListSequenceFolders,
    showSequenceFolder,
    treeSequenceFolders,
    updateSequenceFolder,
    updateSequenceFolderLegacy,
} from "src/api/tms-scheduling/sequenceFolders/services";
import {
    Type_index_sequenceFolderTree,
    Type_post_sequenceFolder,
    Type_put_sequenceFolder,
    Type_sch_index_sequenceFolder,
    Type_sch_index_sequenceFolderTree,
    Type_sch_select_sequenceFolder,
    Type_sch_show_sequenceFolder,
    Type_select_sequenceFolder,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexSequenceFolders = (callback?: any) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceFolderKeys.INDEX, requestConfig],
        queryFn: () => indexSequenceFolders(requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        select: (data) => {
            const sequencesFolder: Type_sch_index_sequenceFolder[] =
                data?.data?.data;
            if (callback) {
                sequencesFolder
                    ? callback(formatterIndexSequencesFolder(sequencesFolder))
                    : null;
            } else {
                return formatterIndexSequencesFolder(sequencesFolder);
            }
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowSequenceFolderLegacy = (callback: any, id: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceFolderKeys.SHOW, id, requestConfig],
        queryFn: () => showSequenceFolder(id, requestConfig),
        enabled: !!id,
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
            const sequenceFolder: Type_sch_show_sequenceFolder = res.data.data;
            callback(formatterShowSequenceFolder(sequenceFolder));
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowSequenceFolder = (id?: number | null) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceFolderKeys.SHOW, id, requestConfig],
        queryFn: () => showSequenceFolder(id || 0, requestConfig),
        enabled: !!id,
        refetchOnWindowFocus: false,
        select: (res) => {
            const sequenceFolder: Type_sch_show_sequenceFolder = res.data.data;
            return formatterShowSequenceFolder(sequenceFolder);
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationCreateSequenceFolderLegacy = (callback: any) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (sequenceFolder: FormikValues) =>
            createSequenceFolder(
                formatterCreateSequenceFolderLegacy(sequenceFolder),
                requestConfig,
            ),
        onSuccess: (data: any) => {
            if (data.success) {
                const sequenceFolder: Type_sch_index_sequenceFolder =
                    data.data.data;
                callback(formatterIndexSequenceFolder(sequenceFolder));
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmt("GenericError"),
            });
            console.error("ERROR mutationCreateSequenceFolderLegacy", err);
            return err;
        },
    });
};

export const mutationCreateSequenceFolder = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newSequenceFolder: Type_post_sequenceFolder) =>
            createSequenceFolder(
                formatterCreateSequenceFolder(newSequenceFolder),
                requestConfig,
            ),
        onSuccess: (resp: any) => {
            sendEvent(
                "addSequenceFolder",
                formatterTreeSequenceFolder(resp.data.data),
            );
            return resp;
        },
        onError: (err: any) => {
            console.debug("ERROR mutationCreateSequenceFolder", err);
            addWarning({
                description: fmtErrors("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateSequenceFolder = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (sequenceFolderToUpdate: Type_put_sequenceFolder) =>
            updateSequenceFolder(
                formatterUpdateSequenceFolder(sequenceFolderToUpdate),
                sequenceFolderToUpdate.id,
                requestConfig,
            ),
        onSuccess: (resp: any) => {
            sendEvent(
                "updateSequenceFolder",
                formatterTreeSequenceFolder(resp.data.data),
            );
            return resp;
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateSequenceFolderLegacy = (callback: any) => {
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (sequenceFolderToUpdate: FormikValues) =>
            updateSequenceFolderLegacy(
                formatterUpdateSequenceFolderLegacy(sequenceFolderToUpdate),
                requestConfig,
            ),
        onSuccess: async (_response) => {
            const sequenceFolderUpdated: Type_sch_index_sequenceFolder =
                _response.data.data;
            callback(formatterIndexSequenceFolder(sequenceFolderUpdated));
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useSelectListSequenceFolders = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceFolderKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListSequenceFolders(requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data): Type_select_sequenceFolder[] => {
            return formatterSelectListSequenceFolders(
                data?.data?.data as Type_sch_select_sequenceFolder[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useDeleteSequenceFolder = (callback: (id: number) => void) => {
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (id: number) => deleteSequenceFolder(id, requestConfig),
        onSuccess: (_data, id) => {
            callback(id);
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useTreeSequenceFolders = (enable = true) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceFolderKeys.TREE, requestConfig],
        queryFn: () => treeSequenceFolders(requestConfig),
        enabled:
            !!requestConfig.projectId && !!requestConfig.subProjectId && enable,
        refetchOnWindowFocus: false,
        select: (data): Type_index_sequenceFolderTree[] => {
            return formatterTreeSequenceFolders(
                data?.data?.data as Type_sch_index_sequenceFolderTree[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};
