import { Box, Checkbox, Stack } from "@mui/material";
import {
    Column,
    type RenderCellProps,
    type RenderGroupCellProps,
    type RenderHeaderCellProps,
    useRowSelection,
} from "react-data-grid";

import { Spinner } from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export const SELECT_COLUMN_KEY = "select-row";

type Type_HeaderSelector_Props = RenderHeaderCellProps<unknown> & {
    isLoading: boolean;
};

function HeaderRenderer({ isLoading, ...props }: Type_HeaderSelector_Props) {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Actions");
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"center"}
        >
            <Box sx={{ width: 26 }}>
                <Spinner
                    sx={{
                        display: isLoading ? "flex" : "none",
                        alignItems: "center",
                        color: COLORS.gray700,
                        justifyContent: "center",
                    }}
                    size="small"
                />
            </Box>

            <Checkbox
                aria-label={fmt("SelectAll")}
                value={isRowSelected}
                checked={isRowSelected}
                tabIndex={props.tabIndex}
                onChange={(e) => {
                    onRowSelectionChange({
                        type: "HEADER",
                        checked: e.target.checked,
                    });
                }}
            />
        </Stack>
    );
}

function SelectFormatter(
    props: RenderCellProps<unknown> & Type_SelectColumn_Props,
) {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();

    const handleIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (props.handleContextMenu) {
            props.handleContextMenu(
                { row: props.row },
                event as React.MouseEvent<HTMLDivElement, MouseEvent>,
            );
        }
    };

    return (
        <Stack display={"flex"} direction={"row"} alignItems={"center"}>
            <IconButton onClick={handleIconClick} size="small">
                <Icon
                    sx={{ cursor: "pointer", color: COLORS.gray500 }}
                    icon="ellipsis-vertical"
                    variant="solid"
                />
            </IconButton>
            <Checkbox
                checked={isRowSelected}
                value={isRowSelected}
                tabIndex={props.tabIndex}
                onChange={(e, isShiftClick) => {
                    onRowSelectionChange({
                        type: "ROW",
                        row: props.row,
                        checked: e.target.checked,
                        isShiftClick,
                    });
                }}
            />
            {props.enableDragDrop && (
                <Icon
                    sx={{
                        color: COLORS.gray400,
                        cursor: "grab",
                    }}
                    icon="grid-2"
                    variant="solid"
                    fontSize="small"
                ></Icon>
            )}
        </Stack>
    );
}

function SelectGroupFormatter(
    props: RenderGroupCellProps<unknown> & Type_SelectColumn_Props,
) {
    const [isRowSelected, onRowSelectionChange] = useRowSelection();

    return (
        <Checkbox
            checked={isRowSelected}
            value={isRowSelected}
            tabIndex={props.tabIndex}
            onChange={(e) => {
                onRowSelectionChange({
                    type: "ROW",
                    row: props.row,
                    checked: e.target.checked,
                    isShiftClick: false,
                });
            }}
        />
    );
}

type Type_SelectColumn_Props = {
    enableDragDrop?: boolean;
    isLoading?: boolean;
    handleContextMenu?: (row: any, event: any) => void;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectColumn = ({
    enableDragDrop = false,
    isLoading = false,
    handleContextMenu,
}: Type_SelectColumn_Props = {}): Column<any> => {
    return {
        key: SELECT_COLUMN_KEY,
        name: "",
        width: 80 + (enableDragDrop ? 30 : 0),
        minWidth: 80 + (enableDragDrop ? 30 : 0),
        maxWidth: 80 + (enableDragDrop ? 30 : 0),
        resizable: false,
        sortable: false,
        frozen: true,
        renderHeaderCell(props) {
            return <HeaderRenderer isLoading={isLoading} {...props} />;
        },
        renderCell(props) {
            return (
                <SelectFormatter
                    enableDragDrop={enableDragDrop}
                    handleContextMenu={handleContextMenu}
                    {...props}
                />
            );
        },
        renderGroupCell(props) {
            return (
                <SelectGroupFormatter
                    enableDragDrop={enableDragDrop}
                    {...props}
                />
            );
        },
    };
};
