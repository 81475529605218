import { typeMilestones } from "@cimba-digital-construction-solution/utils/dist/decoder/const";
import Konva from "konva";
import React, { useEffect, useState } from "react";
import { Group, Label, Line, Rect, Tag, Text } from "react-konva";
import { Portal } from "react-konva-utils";

import {
    mutationCreateTaskArea,
    mutationSetProgressTaskArea,
} from "src/api/tms-scheduling/taskArea";
import cursorCheck from "src/assets/fonts/cursor-check.svg";
import cursorUnCheck from "src/assets/fonts/cursor-uncheck.svg";
import { Type_point } from "src/components/Components_Common/canvas/types";
import { colorFlowSelected } from "src/components/Components_Teamoty/Flow/Flow.const";
import { Enum_typeCircleFlow } from "src/components/Components_Teamoty/Flow/Flow.enum";
import {
    borderDashStylePlanningTask,
    offsetPlanningTaskCircle,
    offsetPlanningTaskSelect,
    offsetPlanningTaskTitle,
    strokeWidthPlanningTask,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    Type_Props_PlanningShapeGroup,
    Type_selected,
} from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { PlanningShapePunchList } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeCirclePunchlist";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import {
    getCoordinates,
    getX,
} from "src/components/Components_Teamoty/Planning/tools/getCoordinates";
import { getWidth } from "src/components/Components_Teamoty/Planning/tools/getWidth";
import { separatorCodeTask } from "src/configurations/app";
import { usePlanningContext } from "src/contexts/planning";
import { useChannel } from "src/hooks/useChannel";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { getDate } from "src/utils/date";

import { PlanningShapeCircle } from "./PlanningShapeSelected/PlanningShapeCircle";
import { PlanningShapeSelected } from "./PlanningShapeSelected/PlanningShapeSelected";

type Type_State_Over = {
    x: number;
    y: number;
    actif: boolean;
    over: boolean;
};

export const PlanningShapeGroup = ({
    width,
    height,

    children,
    task,
    line,

    widthDate,
    heightArea,

    selected,
    setSelected,

    getBeginDate,
    getEndDate,
}: Type_Props_PlanningShapeGroup & Konva.GroupConfig) => {
    const { defaultCursor } = usePlanningContext();
    const theme = usePlanningTheme();

    //State pour savoir si la tache est survolée
    const [over, setOver] = useState<Type_State_Over>({
        x: 0,
        y: 0,
        actif: false,
        over: false,
    });
    const { sendEvent } = useChannel({});
    const { enableTaskDone } = usePlanningContext();
    const { openPaper, isPaperOpen } = useContextualDrawer();

    //Calcul de la largeur et hauteur avec offset
    const doubleOffset: number = offsetPlanningTaskSelect * 2;
    const widthWithOffset: number =
        (typeMilestones.includes(task.type) ? height : width) + doubleOffset;

    const heightWithOffset: number = height + doubleOffset;
    //Calcul de la position de la tache

    const { x, y } = getCoordinates({
        task,
        widthDate,
        line,
        heightArea,
        height,
    });
    // -----------------------------------------------------------------------------------------------------------------

    // Gestion du OVER

    useEffect(() => {
        if (over.over) {
            const timer = setTimeout(() => {
                setOver((prev: Type_State_Over) => ({ ...prev, actif: true }));
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [over.over]);
    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>): void => {
        if (over.over) {
            const shape: Konva.Shape = e.currentTarget as Konva.Shape;
            const mousePos = shape.getRelativePointerPosition() as Type_point;
            setOver((prev: Type_State_Over) => ({
                ...prev,
                x: mousePos.x + x,
                y: mousePos.y + y,
            }));
        }
    };
    const handleMouseOver = (e: Konva.KonvaEventObject<MouseEvent>): void => {
        const shape: Konva.Shape = e.currentTarget as Konva.Shape;
        const mousePos = shape.getRelativePointerPosition() as Type_point;

        setOver({
            x: mousePos.x + x,
            y: mousePos.y + y,
            actif: false,
            over: true,
        });

        const cursor =
            task.progress < 100
                ? `url(${cursorCheck}), none`
                : `url(${cursorUnCheck}), none`;
        changeCursor(e, enableTaskDone ? cursor : "pointer");
    };
    const handleMouseOut = (e: Konva.KonvaEventObject<MouseEvent>): void => {
        setOver((prev: Type_State_Over) => ({
            ...prev,
            actif: false,
            over: false,
        }));
        changeCursor(e, defaultCursor);
    };
    // -----------------------------------------------------------------------------------------------------------------

    // Gestion de la SELECTION
    const handleSelect = (): void => {
        setSelected({
            taskKey: task.key,
            taskType: task.type,
        });
    };
    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>): void => {
        setOver((prev: Type_State_Over) => ({ ...prev, actif: false }));
        if (e.evt.button === 0) {
            handleSelect();
            handleClick(e);
        }
        e.cancelBubble = true;
    };

    const { mutateAsync: mutateAsyncSetProgressValue } =
        mutationSetProgressTaskArea();
    // -----------------------------------------------------------------------------------------------------------------
    // Gestion de l'ouverture / modification des drawer
    const handleClick = async (
        e: Konva.KonvaEventObject<MouseEvent>,
    ): Promise<void> => {
        if (enableTaskDone) {
            task.progress = task.progress === 100 ? 0 : 100;

            task.isDone = task.progress === 100;

            // forced redraw task
            await mutateAsyncSetProgressValue({
                origin: "Planning",
                areaId: task.areaId!,
                taskId: task.id,
                progressValue: task.progress,
                progressLastDate: getDate(),
            });
        }
        if (e.evt.button === 0) {
            // la touche ctrl permet d'ouvrir la tache
            if (e.evt.ctrlKey) {
                if (isPaperOpen("taskArea")) {
                    openPaper("task", { id: task.id }, true);
                }
                sendEvent("updateIdTask", task.id);
            } else {
                if (isPaperOpen("task")) {
                    openPaper(
                        "taskArea",
                        {
                            areaId: line.area.id,
                            taskId: task.id,
                            taskAreaId: task.taskAreaId,
                        },
                        true,
                    );
                }
                sendEvent("updateIdsTaskArea", {
                    taskId: task.id,
                    areaId: line.area.id,
                    taskAreaId: task.taskAreaId,
                });
            }
        }
        e.cancelBubble = true;
    };

    // Overture du drawer taskArea au double clic (premier click selectionne la tache + 2eme click l'ouvre = onClick) d'une tache sur le planning
    const handleDblClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
        // la touche ctrl permet d'ouvrir la tache
        if (e.evt.ctrlKey) {
            openPaper("task", { id: task.id }, true);
        } else {
            openPaper(
                "taskArea",
                {
                    areaId: line.area.id,
                    taskId: task.id,
                    taskAreaId: task.taskAreaId,
                },
                true,
            );
        }
        e.cancelBubble = true;
    };

    // -----------------------------------------------------------------------------------------------------------------
    // Gestion du déplacement

    const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>): void => {
        changeCursor(e, "grabbing");

        setSelected((prev: null | Type_selected) => ({
            ...prev!,
            task: task,
            x: x,
            width: widthWithOffset,
        }));
    };

    const handleDragMove = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;

        // On calcule la nouvelle date de début et de fin de la tache
        // const deltaX: number = e.target.x() - startPosition;
        const newBeginDate =
            selected && getBeginDate(selected.task, e.target.x());

        const newEndDate =
            selected &&
            (typeMilestones.includes(task.type)
                ? newBeginDate
                : getEndDate(
                      selected.task,
                      0,
                      selected.task!.duration,
                      newBeginDate,
                  ));

        setSelected((prev: null | Type_selected) => {
            if (prev && newBeginDate && newEndDate) {
                prev.task!.beginDate = newBeginDate.date;
                prev.task!.beginOffset = newBeginDate.offset;
                prev.task!.endDate = newEndDate.date;
                prev.task!.endOffset = newEndDate.offset;

                prev.x = getX({ task: prev.task!, widthDate, height });
                prev.width =
                    (typeMilestones.includes(task.type)
                        ? height
                        : getWidth({ task: prev.task!, widthDate })) +
                    doubleOffset;

                return { ...prev };
            }
            return prev;
        });

        // Permet de rester sur le meme axe y
        e.target.x(newBeginDate.date.pos);
        e.target.y(y);
    };

    const handleDragEnd = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;

        // on calcule la nouvelle date de début de la tache et on envoie la nouvelle date au back
        const newBeginDate = getBeginDate(selected?.task, e.target.x());

        handleSubmit(newBeginDate);

        changeCursor(e, defaultCursor);
    };

    // -----------------------------------------------------------------------------------------------------------------
    // envoi de la nouvelle date au plus tot de la taskArea au back

    const { mutate } = mutationCreateTaskArea();
    const handleSubmit = (newBeginDate: any) => {
        selected &&
            mutate({
                areaId: line.area.id!,
                taskId: task.id!,
                earliestDate: getDate(newBeginDate.date.timestamp * 1000),
            });
    };

    const isTypeMilestone = typeMilestones.includes(task.type);
    const isSelectedTask = selected?.taskKey === task.key;
    const isTaskMinWithWithCircles = width > 3 * offsetPlanningTaskCircle;
    const isTaskPunchList = task.isPunchlist;
    const isTaskDone = task.isDone;

    const withPunchList = isTaskPunchList && widthDate > 10;
    const withStartCircle = isTaskMinWithWithCircles || !isSelectedTask;
    const withFinishCircle = isTaskMinWithWithCircles || isSelectedTask;

    const withSelected = selected && !enableTaskDone && !isTaskDone;

    return (
        <>
            <Portal
                enabled={selected?.taskKey === task.key}
                selector=".topTasks"
            >
                <Group
                    x={x}
                    y={y}
                    onMouseMove={handleMouseMove}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onMouseDown={handleMouseDown}
                    onDblClick={handleDblClick}
                    draggable={
                        selected != null && !enableTaskDone && !isTaskDone
                    }
                    onDragStart={handleDragStart}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    listening={true}
                >
                    {children}

                    {withPunchList && (
                        <PlanningShapePunchList
                            heightArea={heightArea}
                            width={width}
                        />
                    )}

                    {isTaskDone && (
                        <Line
                            points={[
                                2,
                                heightWithOffset - 2,
                                widthWithOffset - 2,
                                2,
                            ]}
                            offsetX={offsetPlanningTaskSelect}
                            offsetY={offsetPlanningTaskSelect}
                            stroke={theme.strokePlanningTaskDoneLine}
                            lineCap={"round"}
                            lineJoin={"round"}
                            strokeWidth={2}
                        />
                    )}

                    {withSelected && isTypeMilestone && (
                        <>
                            <PlanningShapeCircle
                                x={height / 2}
                                y={height / 2}
                                taskX={x}
                                taskY={y}
                                task={task}
                                type={
                                    isSelectedTask
                                        ? Enum_typeCircleFlow.Finish
                                        : Enum_typeCircleFlow.Start
                                }
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </>
                    )}

                    {withSelected && !isTypeMilestone && (
                        <>
                            {withStartCircle && (
                                <PlanningShapeCircle
                                    x={offsetPlanningTaskCircle}
                                    y={height / 2}
                                    taskX={x}
                                    taskY={y}
                                    task={task}
                                    type={Enum_typeCircleFlow.Start}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            )}

                            {withFinishCircle && (
                                <PlanningShapeCircle
                                    x={width - offsetPlanningTaskCircle}
                                    y={height / 2}
                                    taskX={x}
                                    taskY={y}
                                    task={task}
                                    type={Enum_typeCircleFlow.Finish}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            )}
                        </>
                    )}

                    {withSelected && selected.taskKey === task.key && (
                        <PlanningShapeSelected
                            x={(selected.x || x) - offsetPlanningTaskSelect - x}
                            y={-offsetPlanningTaskSelect}
                            width={selected.width || widthWithOffset}
                            height={heightWithOffset}
                            task={task}
                            selected={selected as Type_selected}
                            setSelected={setSelected}
                            getEndDate={getEndDate}
                        />
                    )}
                </Group>
            </Portal>

            {over.over &&
                (!selected ||
                    selected.taskKey !== task.key ||
                    enableTaskDone ||
                    isTaskDone) && (
                    <Portal enabled={true} selector=".topTasks">
                        <Rect
                            x={x - offsetPlanningTaskSelect}
                            y={y - offsetPlanningTaskSelect}
                            width={widthWithOffset}
                            height={heightWithOffset}
                            dash={borderDashStylePlanningTask}
                            stroke={colorFlowSelected}
                            strokeWidth={strokeWidthPlanningTask}
                            listening={false}
                        />

                        {over.actif && (
                            <Label
                                x={over.x}
                                y={over.y}
                                offsetX={-offsetPlanningTaskTitle}
                                offsetY={-offsetPlanningTaskTitle}
                            >
                                <Tag fill={"white"} cornerRadius={2} />
                                <Text
                                    text={
                                        task.code
                                            ? `${task.code}${separatorCodeTask}${task.name}`
                                            : task.name
                                    }
                                    fill={"black"}
                                    padding={5}
                                    fontSize={10}
                                />
                            </Label>
                        )}
                    </Portal>
                )}
        </>
    );
};
