import { useMutation } from "react-query";

import { createResourceScheduling } from "src/api/tms-scheduling/resources/services";
import { Type_sch_post_resource } from "src/api/tms-scheduling/resources/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCreateResourceScheduling = (callback?: any) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_resource) => {
            return createResourceScheduling(data, requestConfig);
        },
        onSuccess: (data: any): void => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateResourceScheduling",
                );
            }
            callback ? callback(data.data.data) : data.data.data;
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericErrorScheduling"),
            });
            return err;
        },
    });
};
