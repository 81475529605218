import { Stack, Typography } from "@mui/material";
import { UseTreeItem2LabelSlotProps } from "@mui/x-tree-view/useTreeItem2/useTreeItem2.types";
import { ReactNode } from "react";

import { Type_index_sequenceFolderTree } from "src/api/tms-scheduling/sequenceFolders/types";
import { mutationCreateDefaultSequence } from "src/api/tms-scheduling/sequences";
import { mutationCreateTask } from "src/api/tms-scheduling/tasks";
import { Type_sch_post_task } from "src/api/tms-scheduling/tasks/types";
import { Type_CountryCode } from "src/assets/translations";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useLanguage } from "src/contexts/languages";
import { Styled_TreeItem2Label } from "src/drawers/sequences/SequencesContextualDrawer/SequencesTreeItem";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { COLORS_TASK } from "src/theme/stylesheet";

type Type_Props_SequencesTreeItemLabelFolder = UseTreeItem2LabelSlotProps & {
    item: Type_index_sequenceFolderTree;
    children?: ReactNode;
};

export const SequencesTreeItemLabelFolder = ({
    item,
    children,
    ...treeItemProps
}: Type_Props_SequencesTreeItemLabelFolder) => {
    const { sendEvent } = useChannel({});
    const { languages } = useLanguage();
    const { closePaper } = useContextualDrawer();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );

    // Api Hooks
    const { mutateAsync, isLoading: isLoadingCreateSequence } =
        mutationCreateDefaultSequence();
    const { mutateAsync: createTask, isLoading: isLoadingCreateTask } =
        mutationCreateTask();

    // Utils
    const handleCreateFirstTask = async (sequenceId: number) => {
        const defaultValues: Type_sch_post_task = {
            names: {},
            sequence_id: sequenceId,
            taskCategory_id: null,
            color: COLORS_TASK[0],
            xy: [60, 60], // Positionne la tâche sur le PERT
        };

        languages.forEach((language: Type_CountryCode): void => {
            defaultValues.names[language] = fmt(`DefaultTaskNames.${language}`);
        });

        await createTask(defaultValues);
    };

    const handleCreate = async (id: number) => {
        // création nouvelle séquence
        const respSequence = await mutateAsync(id);
        const sequenceId = respSequence.data.data.id;

        // création d'une première tâche associée à la séquence créée
        await handleCreateFirstTask(sequenceId);

        closePaper("sequences");
        sendEvent("openPert", {
            id: sequenceId,
            open: true,
        });
    };

    const handleClick = (
        event: React.MouseEvent,
        action: string,
        id: number,
    ) => {
        event.stopPropagation();
        action === "edit" &&
            sendEvent("openDialogFolder", {
                open: true,
                action: "update",
                data: { name: "createOrUpdateSequenceFolder", id: id },
            });
        if (action === "create") handleCreate(id);
    };

    return (
        <Styled_TreeItem2Label {...treeItemProps}>
            <Stack direction="row" alignItems="center" gap={1}>
                <Icon variant={"light"} icon={"folder"} fontSize={"small"} />
                <Typography variant="body2Medium">
                    {children} ({item.children.length})
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} className={"buttonGroup"}>
                <IconButton
                    onClick={(e) => handleClick(e, "edit", item.id)}
                    name={"editSequenceFolderBtn"}
                    type="button"
                    size="small"
                    title={fmt("EditFolder")}
                >
                    <Icon
                        variant="light"
                        icon="pen"
                        fontSize="small"
                        data-testid="sequences-edit-sequenceFolder-btn"
                    />
                </IconButton>
                <IconButton
                    disabled={isLoadingCreateSequence || isLoadingCreateTask}
                    onClick={(e) => handleClick(e, "create", item.id)}
                    name={"createSequenceBtn"}
                    type="button"
                    size="small"
                    title={fmt("CreateSequence")}
                >
                    <Icon
                        variant="light"
                        icon="plus"
                        fontSize="small"
                        data-testid="sequences-create-sequence-btn"
                    />
                </IconButton>
            </Stack>
        </Styled_TreeItem2Label>
    );
};
