import { GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationDeleteCompany,
    mutationStatusCompany,
    useIndexCompanies,
} from "src/api/tms-commons/companies";
import {
    Type_index_company,
    Type_put_company,
} from "src/api/tms-commons/companies/types";

import { Page_CompaniesComponent } from "./Page_Companies.component";

export type Type_modalDeleteCompany = {
    isOpen: boolean;
    props?: Type_index_company;
};

export const Page_CompaniesContainer = () => {
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            page: 0,
            pageSize: 25,
        },
    );

    const [requestConfig, setRequestConfig] = useState<Type_RequestConfig>({
        params: {
            page: 1,
            per_page: 25,
        },
    });

    // States
    const [modalDeleteCompany, setModalDeleteCompany] =
        useState<Type_modalDeleteCompany>({
            isOpen: false,
        });

    useEffect(() => {
        setRequestConfig({
            params: {
                page: paginationModel.page + 1,
                per_page: paginationModel.pageSize,
            },
        });
    }, [paginationModel]);

    // Queries & Mutations
    const { isFetching, data: companiesFetched } =
        useIndexCompanies(requestConfig) || {};

    const rowCountRef = useRef(companiesFetched?.total || 0);

    const rowCount = useMemo(() => {
        if (companiesFetched?.total !== undefined) {
            rowCountRef.current = companiesFetched?.total;
        }
        return rowCountRef.current;
    }, [companiesFetched?.total]);

    //Delete
    const { mutateAsync: mutateDeleteCompany } =
        mutationDeleteCompany(requestConfig) || {};

    const deleteCompany = async (id: number) => {
        await mutateDeleteCompany(id);
    };

    //Status

    const { mutate: mutationCompaniesStatus } =
        mutationStatusCompany(requestConfig) || {};

    const changeStatus = (company: Type_index_company) => {
        mutationCompaniesStatus({
            id: company.id,
            enabled: !company.enabled,
        } as Type_put_company);
    };

    return (
        <Page_CompaniesComponent
            isFetching={isFetching}
            companies={companiesFetched?.companies ?? []}
            changeStatus={changeStatus}
            deleteCompany={deleteCompany}
            modalDeleteCompany={modalDeleteCompany}
            setModalDeleteCompany={setModalDeleteCompany}
            pagination={paginationModel}
            setPagination={setPaginationModel}
            rowCount={rowCount}
            requestConfig={requestConfig}
        />
    );
};
