import { Divider, Icon, Stack, Typography } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";

import {
    Type_index_version,
    Type_show_version,
} from "src/api/tms-projects/versions/types";
import {
    ConfirmDeleteModal,
    TMC_Badge,
    TMC_DataGridVersion,
} from "src/components";
import { Icon as TMC_Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_ActionMenu_Item } from "src/components/Components_Teamoty/DataGridVersion/DropDownMenu/DataGridDropDownMenu";
import { useToast } from "src/contexts/toasts";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import { displayFormattedDateTime } from "src/utils/date";

type Type_modalDelete = {
    isOpen: boolean;
    id?: number | null;
    name?: string;
};

export type Type_Page_VersionsComponent_Props = {
    versions: Type_index_version[];
    isFetching?: boolean;
    deleteVersion: (id: number) => void;
    openDrawer: (action: "update" | "create", id?: number | undefined) => void;
    openNotesDrawer: (versionId: number) => void;
    openDuplicateDrawer: (id?: number | undefined) => void;
    updateVersion: (version: Omit<Type_show_version, "names">) => Promise<void>;
};

export const Page_VersionsComponent = ({
    versions,
    isFetching,
    deleteVersion,
    openDrawer,
    openNotesDrawer,
    openDuplicateDrawer,
    updateVersion,
}: Type_Page_VersionsComponent_Props) => {
    const { hasPermission } = useUser();
    const CAN_UPDATE_VERSION = hasPermission("versions_update");
    const CAN_CREATE_VERSION = hasPermission("versions_create");
    const CAN_DELETE_VERSION = hasPermission("versions_delete");
    const CAN_SHOW_VERSION_NOTES = hasPermission("versionNotes_index");

    const { add } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions",
    );

    // DELETE
    const [modalDeleteVersion, setModalDeleteVersion] =
        useState<Type_modalDelete>({
            isOpen: false,
            id: null,
            name: "",
        });

    const [rows, setRows] = useState<GridRowModel[]>(versions);

    useEffect(() => {
        setRows(
            versions
                .map((version) => ({
                    ...version,
                    createdAt: version.createdAt,
                    updatedAt: version.updatedAt,
                    notes: version.notes || [],
                    notesLength: version.notes?.length || 0,
                }))
                .sort((a, b) => a.id - b.id),
        );
    }, [versions]);

    const updateVersionAction = async (
        row: Type_index_version,
        action:
            | "baseVersion"
            | "definitiveVersion"
            | "currentVersion"
            | "locked",
    ) => {
        const updatedVersions: Omit<Type_show_version, "names"> = {
            ...row,
            [action]: !row[action],
        };
        await updateVersion(updatedVersions);
        add({
            type: "success",
            description: fmt("Drawer.ToastSuccessUpdate", {
                values: {
                    b: (chunks: string) => <b>{chunks}</b>,
                    version: row.name,
                },
            }),
        });
    };

    /* DataGrid Dropdown items */
    const DropDownItems: Type_ActionMenu_Item[] = [
        {
            key: "DefinitiveVersion",
            label: (params) =>
                params.row.definitiveVersion
                    ? fmt("NotDefinitiveVersion")
                    : fmt("DefinitiveVersion"),
            action: (params) =>
                updateVersionAction(params.row, "definitiveVersion"),
            icon: <TMC_Icon icon="square-small" variant="light" />,
            disabled: (params) => params.row.locked,
        },
        {
            key: "DoNotUseThisVersion",
            label: (params) =>
                params.row.currentVersion
                    ? fmt(`DoNotUseThisVersion`)
                    : fmt("UseThisVersion"),
            icon: <TMC_Icon icon="check" variant="light" />,
            action: (params) =>
                updateVersionAction(params.row, "currentVersion"),
            disabled: (params) =>
                params.row.locked || params.row.definitiveVersion,
        },
        {
            key: "UnsetDefault",
            label: (params) =>
                params.row.baseVersion
                    ? fmt("UnsetDefault")
                    : fmt("TurnAsDefault"),
            icon: <TMC_Icon icon="star" variant="light" />,
            action: (params) => updateVersionAction(params.row, "baseVersion"),
            disabled: (params) =>
                params.row.locked || params.row.definitiveVersion,
        },
        {
            key: "EditVersion",
            label: (_params) => fmt("EditVersion"),
            icon: <TMC_Icon icon="pen-to-square" variant="light" />,
            show: CAN_UPDATE_VERSION,
            action: (params: GridRenderCellParams) =>
                openDrawer("update", params.row.id),
            disabled: (params) => params.row.locked,
        },
        {
            key: "DuplicateVersion",
            label: (_params) => fmt("DuplicateVersion"),
            icon: <TMC_Icon icon="copy" variant="light" />,
            action: (params) => {
                openDuplicateDrawer(params.row.id);
            },
            disabled: (params) => params.row.locked,
        },
        {
            key: "BlockVersion",
            label: (params) =>
                params.row.locked ? fmt("UnBlockVersion") : fmt("BlockVersion"),
            icon: <TMC_Icon icon="ban" variant="light" />,
            action: (params) => updateVersionAction(params.row, "locked"),
        },
        {
            key: "DeleteVersion",
            label: (_params) => fmt("DeleteVersion"),
            icon: <TMC_Icon icon="trash" variant="light" />,
            color: COLORS.red,
            show: CAN_DELETE_VERSION,
            action: (params) => {
                setModalDeleteVersion({
                    isOpen: true,
                    id: params.row.id,
                    name: params.row.name,
                });
            },
            disabled: (params) =>
                params.row.locked ||
                params.row.currentVersion ||
                params.row.baseVersion,
        },
    ];

    /* DataGrid columns */
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "",
            align: "left",
            flex: 0.5,
            maxWidth: 1200,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Stack>
                        <Stack spacing={2} direction="row">
                            <Typography variant="body1Medium">
                                {params.row.name}
                            </Typography>
                            {params.row.locked && (
                                <TMC_Badge
                                    icon={
                                        <Icon className={"fa-lock fa-solid"} />
                                    }
                                    label={fmt("Locked")}
                                    data-testid={`Version-${params.row.id}-locked-btn`}
                                />
                            )}
                        </Stack>
                        <Typography color={COLORS.moon500} variant="body2">
                            {fmt("CreatedAt")}
                            <Typography
                                ml={1}
                                color={COLORS.moon500}
                                variant="body2Medium"
                            >
                                {displayFormattedDateTime(params.row.createdAt)}
                            </Typography>{" "}
                            · {fmt("LastModification")}
                            <Typography
                                ml={1}
                                color={COLORS.moon500}
                                variant="body2Medium"
                            >
                                {displayFormattedDateTime(params.row.updatedAt)}
                            </Typography>
                        </Typography>
                    </Stack>
                );
            },
        },
        {
            field: "badges",
            headerName: "",
            sortable: false,
            flex: 0.46,
            align: "right",
            maxWidth: 1200,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Stack display={"flex"} direction={"row"} spacing={1}>
                        <TMC_Badge
                            data-testid={`Version-${params.row.id}-lock-btn`}
                            label={fmt("Use")}
                            onClick={() => {
                                if (
                                    !params.row.locked &&
                                    !params.row.definitiveVersion
                                ) {
                                    return updateVersionAction(
                                        params.row,
                                        "currentVersion",
                                    );
                                }
                            }}
                            clickable={
                                !params.row.locked &&
                                !params.row.definitiveVersion
                            }
                            disabled={!params.row.currentVersion}
                            icon={<Icon className={"fa-check fa-regular"} />}
                        />

                        <TMC_Badge
                            data-testid={`Version-${params.row.id}-baseVersion-btn`}
                            label={fmt("BaseVersion")}
                            onClick={() => {
                                if (
                                    !params.row.locked &&
                                    !params.row.definitiveVersion
                                ) {
                                    return updateVersionAction(
                                        params.row,
                                        "baseVersion",
                                    );
                                }
                            }}
                            clickable={
                                !params.row.locked &&
                                !params.row.definitiveVersion
                            }
                            disabled={!params.row.baseVersion}
                            icon={
                                <Icon className={"fa-circle-small fa-solid"} />
                            }
                        />

                        <TMC_Badge
                            data-testid={`Version-${params.row.id}-definitiveVersion-btn`}
                            label={fmt("DefinitiveVersion")}
                            onClick={() => {
                                if (!params.row.locked) {
                                    return updateVersionAction(
                                        params.row,
                                        "definitiveVersion",
                                    );
                                }
                            }}
                            clickable={!params.row.locked}
                            disabled={!params.row.definitiveVersion}
                            icon={
                                <Icon className={"fa-square-small fa-solid"} />
                            }
                        />
                        {CAN_SHOW_VERSION_NOTES && (
                            <>
                                <Divider
                                    orientation="vertical"
                                    variant="fullWidth"
                                    flexItem
                                />

                                <TMC_Badge
                                    data-testid={`Version-${params.row.id}-notes-btn`}
                                    onClick={() =>
                                        openNotesDrawer(params.row.id)
                                    }
                                    icon={
                                        <Icon className={"fa-notes fa-solid"} />
                                    }
                                    label={`${fmt("SeeNotes")}`}
                                />
                            </>
                        )}
                    </Stack>
                );
            },
        },
    ];

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <TMC_DataGridVersion
                name="version"
                data-testid="data-grid-version"
                autoHeight
                canAddNewRow={CAN_CREATE_VERSION}
                rows={rows}
                columns={columns}
                dropDownItems={DropDownItems}
                isFetching={isFetching}
                handleAddNewRow={() => openDrawer("create")}
                newButtonText={fmt("CtaCreate")}
            />
            <ConfirmDeleteModal
                open={modalDeleteVersion.isOpen}
                header={{
                    title: fmt("Modal.Header.Title"),
                    onClose: () => setModalDeleteVersion({ isOpen: false }),
                }}
                actions={{
                    onClose: {
                        onClick: () => setModalDeleteVersion({ isOpen: false }),
                        content: fmt("Modal.Actions.OnCloseContent"),
                    },
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteVersion({ isOpen: false });
                            deleteVersion(modalDeleteVersion.id || 0);
                        },
                        content: fmt("Modal.Actions.OnSubmitContent"),
                    },
                }}
                form={{
                    validationString: modalDeleteVersion?.name,
                }}
            >
                {fmt("Modal.Content")}
            </ConfirmDeleteModal>
        </div>
    );
};
