import { TabContext } from "@mui/lab";
import { Stack, Switch, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React, { Dispatch, SetStateAction } from "react";

import { ColorPicker, Header, LoadingBox, TMC_Button } from "src/components";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionsDrawerReverse,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Styled_TabPanelDrawerContent } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { MultiLanguages } from "src/components/Components_Common/forms";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletes/AutocompleteAreas";
import { AutocompleteAreaTypes } from "src/components/Components_Teamoty/autocompletes/AutocompleteAreaTypes";
import { AutocompleteDrawings } from "src/components/Components_Teamoty/autocompletes/AutocompleteDrawings";
import { AutocompleteResources } from "src/components/Components_Teamoty/autocompletes/AutocompleteResources";
import { AutocompleteResourceTypes } from "src/components/Components_Teamoty/autocompletes/AutocompleteResourceType";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

type Type_props_Areas_DrawerContentComponent = {
    tab: "0" | "1";
    setTab: Dispatch<SetStateAction<"0" | "1">>;
    action: "create" | "update";
    onClose: () => void;
    formik: any;
    isLoading: boolean;
    isFetching: boolean;
};

export const Areas_DrawerContentComponent = ({
    tab,
    setTab,
    action,
    formik,
    onClose,
    isLoading,
    isFetching,
}: Type_props_Areas_DrawerContentComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas.Drawer",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const drawerTabs = [
        { label: fmt("Header.Tabs.Area"), value: "0" },
        { label: fmt("Header.Tabs.Scheduling"), value: "1" },
    ];

    return (
        <TabContext value={tab}>
            <Styled_MainStack>
                <Header
                    onClose={onClose}
                    title={fmt(
                        action === "create"
                            ? "Header.Titles.Create"
                            : "Header.Titles.Update",
                    )}
                    drawerTabs={drawerTabs}
                    setTabValue={setTab}
                />
                {isFetching && <LoadingBox />}
                {!isFetching && (
                    <FormikProvider value={formik}>
                        <Styled_FormDrawer onSubmit={formik.handleSubmit}>
                            <Styled_TabPanelDrawerContent value={"0"}>
                                <Styled_StackContentDrawer gap={4}>
                                    <Typography variant="body1Bold">
                                        {fmt("Content.SubTitles.AreaName")}
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                        <MultiLanguages
                                            values={formik.values}
                                            handleChange={formik.handleChange}
                                            errors={formik.errors}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <ColorPicker
                                            data-testid="Areas-Drawer-AddArea-color"
                                            name="color"
                                            label={fmt("Content.Labels.Color")}
                                            fullWidth
                                        />
                                    </Stack>
                                    <Typography variant="body1Bold">
                                        {fmt("Content.SubTitles.AreaOptions")}
                                    </Typography>
                                    <AutocompleteAreaTypes
                                        name={"areaType"}
                                        label={fmt("Content.Labels.AreaType")}
                                    />
                                    <AutocompleteAreas
                                        name={"parentArea"}
                                        label={fmt("Content.Labels.Parent")}
                                    />
                                    <Typography variant="body1Bold">
                                        {fmt(
                                            "Content.SubTitles.DrawingOptions",
                                        )}
                                    </Typography>
                                    <AutocompleteDrawings
                                        name={"drawing"}
                                        label={fmt("Content.Labels.Drawing")}
                                    />
                                </Styled_StackContentDrawer>
                            </Styled_TabPanelDrawerContent>
                            <Styled_TabPanelDrawerContent value={"1"}>
                                <Styled_StackContentDrawer gap={4}>
                                    <Stack
                                        flexDirection={"column"}
                                        width={"100%"}
                                    >
                                        <Stack
                                            display={"inline-flex"}
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                        >
                                            <Typography variant={"body1"}>
                                                {fmt(
                                                    "Content.Labels.Scheduling",
                                                )}
                                            </Typography>
                                            <Switch
                                                name={"scheduling"}
                                                data-testid="Areas-Drawer-AddArea-scheduling"
                                                {...formik.getFieldProps(
                                                    "scheduling",
                                                )}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                checked={
                                                    formik.values.scheduling
                                                }
                                            />
                                        </Stack>
                                        <Typography
                                            variant={"body2"}
                                            color={COLORS.moon500}
                                        >
                                            {fmt("Content.Desc.Scheduling")}
                                        </Typography>
                                    </Stack>

                                    <AutocompleteResourceTypes
                                        name={"resourceType"}
                                        label={fmt(
                                            "Content.Labels.ResourceType",
                                        )}
                                        desc={fmt("Content.Desc.ResourceType")}
                                    />
                                    <AutocompleteResources
                                        name={"resource"}
                                        label={fmt("Content.Labels.Resource")}
                                        desc={fmt("Content.Desc.Resource")}
                                    />
                                    <Stack
                                        flexDirection={"column"}
                                        width={"100%"}
                                    >
                                        <Stack
                                            display={"inline-flex"}
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                        >
                                            <Typography variant={"body1"}>
                                                {fmt(
                                                    "Content.Labels.PermanentUse",
                                                )}
                                            </Typography>
                                            <Switch
                                                name={"permanentUse"}
                                                data-testid="Areas-Drawer-AddArea-permanentUse"
                                                {...formik.getFieldProps(
                                                    "permanentUse",
                                                )}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                checked={
                                                    formik.values.permanentUse
                                                }
                                            />
                                        </Stack>
                                        <Typography
                                            variant={"body2"}
                                            color={COLORS.moon500}
                                        >
                                            {fmt("Content.Desc.PermanentUse")}
                                        </Typography>
                                    </Stack>
                                </Styled_StackContentDrawer>
                            </Styled_TabPanelDrawerContent>

                            <Styled_StackActionsDrawerReverse>
                                <TMC_Button
                                    data-testid="Areas-Drawer-AddArea-Submit"
                                    color="primary"
                                    type="submit"
                                    disabled={isLoading}
                                    sx={{ minWidth: 200 }}
                                    endIcon={
                                        !isLoading && (
                                            <Icon
                                                variant="solid"
                                                icon="check"
                                            />
                                        )
                                    }
                                >
                                    {isLoading ? (
                                        <Icon
                                            variant="solid"
                                            icon="spinner-third"
                                            spin
                                        />
                                    ) : (
                                        fmtCta(
                                            action === "create"
                                                ? "Save"
                                                : "Update",
                                        )
                                    )}
                                </TMC_Button>
                            </Styled_StackActionsDrawerReverse>
                        </Styled_FormDrawer>
                    </FormikProvider>
                )}
            </Styled_MainStack>
        </TabContext>
    );
};
