import { Box, Popover, Stack, TextFieldProps, Typography } from "@mui/material";
import {
    SimpleTreeView,
    TreeItem,
    TreeItemContentProps,
    TreeItemProps,
    useTreeItemState,
} from "@mui/x-tree-view";
import { clsx } from "clsx";
import { useFormikContext } from "formik";
import React, {
    forwardRef,
    ReactElement,
    ReactNode,
    Ref,
    useEffect,
    useState,
} from "react";

import { useSelectListAreas } from "src/api/tms-projects/areas";
import { Type_select_area } from "src/api/tms-projects/areas/types";
import { LoadingBox, TMC_TextField } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

type Type_resetValue = { id: null; name: string };

type Type_Autocomplete_Area = TextFieldProps & {
    label: string;
    name: string;
};

/**
 * deprecated
 */
export const AutocompleteAreas = ({ label, name }: Type_Autocomplete_Area) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const formik: any = useFormikContext();
    const { isFetching, data: areas } = useSelectListAreas() || {};

    const resetValue: Type_resetValue = { id: null, name: "" };

    useEffect((): void => {
        const findValue = (
            arr: Type_select_area[],
            id: number | null,
        ): Type_resetValue | Type_select_area => {
            if (id === null) return resetValue;
            let result: Type_resetValue | Type_select_area = resetValue;
            arr.forEach((obj: Type_select_area): void => {
                if (obj.id === id) {
                    result = obj;
                }
                if (obj.children && result.id === null) {
                    const found: Type_resetValue | Type_select_area = findValue(
                        obj.children,
                        id,
                    );
                    if (found.id !== null) result = found;
                }
            });
            return result;
        };

        !isFetching &&
            formik.values[name] &&
            formik.setFieldValue(
                name,
                findValue(areas as Type_select_area[], formik.values[name].id),
            );
    }, [isFetching]);

    const handleReset = (): void => {
        formik.setFieldValue(name, resetValue);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const open: boolean = Boolean(anchorEl);
    const id: "simple-popover" | undefined = open
        ? "simple-popover"
        : undefined;

    const handleSelect = (datum: Type_select_area): void => {
        formik.setFieldValue(name, datum);
        handleClose();
    };

    const CustomContent = forwardRef(function CustomContent(
        props: TreeItemContentProps,
        ref,
    ) {
        const {
            classes,
            className,
            label,
            itemId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        } = props;

        const {
            disabled,
            expanded,
            selected,
            focused,
            handleExpansion,
            preventSelection,
        } = useTreeItemState(itemId);

        const icon: ReactNode = iconProp || expansionIcon || displayIcon;

        const handleMouseDown = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ): void => {
            preventSelection(event);
        };

        const handleExpansionClick = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ): void => {
            handleExpansion(event);
        };

        return (
            <div
                className={clsx(className, classes.root, {
                    [classes.expanded]: expanded,
                    [classes.selected]: selected,
                    [classes.focused]: focused,
                    [classes.disabled]: disabled,
                })}
                onMouseDown={handleMouseDown}
                ref={ref as React.Ref<HTMLDivElement>}
            >
                <div
                    onClick={handleExpansionClick}
                    className={classes.iconContainer}
                >
                    {icon}
                </div>
                <Typography
                    onClick={props.onClick}
                    component="div"
                    className={classes.label}
                >
                    {label}
                </Typography>
            </div>
        );
    });

    const CustomTreeItem = forwardRef(function CustomTreeItem(
        props: TreeItemProps,
        ref: Ref<HTMLLIElement>,
    ): ReactElement {
        return (
            <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />
        );
    });

    const rendreOptions = (option: Type_select_area) => {
        if (option.children) {
            return (
                <CustomTreeItem
                    key={option.id}
                    itemId={String(option.id)}
                    label={option.name}
                    onClick={() => handleSelect(option)}
                >
                    {option.children.map((child: Type_select_area) =>
                        rendreOptions(child),
                    )}
                </CustomTreeItem>
            );
        }
        return (
            <CustomTreeItem
                key={option.id}
                itemId={String(option.id)}
                label={option.name}
                onClick={() => handleSelect(option)}
            />
        );
    };

    return (
        <Stack sx={{ width: "100%" }}>
            <TMC_TextField
                onClick={handleClick}
                label={label}
                value={
                    formik.values[name] && formik.values[name].name
                        ? formik.values[name].name
                        : ""
                }
                fullWidth
                inputProps={{ readOnly: true }}
                error={formik.touched[name] && Boolean(formik.errors[name])}
                helperText={formik.touched[name] && formik.errors[name]}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <button
                    type="button"
                    onClick={handleReset}
                    style={{ color: COLORS.blue700 }}
                >
                    <Typography variant={"buttonSmall"} sx={{ margin: 0 }}>
                        {fmtActions("Clear")}
                    </Typography>
                </button>
            </div>
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{ backgroundColor: "transparent" }}
                >
                    <Box sx={{ minWidth: "600px", p: 2 }}>
                        <SimpleTreeView
                            slots={{
                                expandIcon: MyExpandIcon,
                                collapseIcon: MyCollapseIon,
                            }}
                        >
                            {isFetching ? (
                                <LoadingBox />
                            ) : (
                                areas?.map((datum: Type_select_area) =>
                                    rendreOptions(datum),
                                )
                            )}
                        </SimpleTreeView>
                    </Box>
                </Popover>
            </div>
        </Stack>
    );
};

const MyExpandIcon = () => {
    return <Icon variant="solid" icon="chevron-right" />;
};

const MyCollapseIon = () => {
    return <Icon variant="solid" icon="chevron-down" />;
};
